import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../../firebase/firebase-config";
import { db } from "../../firebase/firebase-config";
import { doc, getDoc } from "firebase/firestore";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
} from "@mui/material";
import Lottie from "lottie-react";
import animationData from "../../assets/loading.json";
import "../../components/App.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [propertyRoles, setPropertyRoles] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!email) return;

      try {
        const userRef = doc(db, "emailAuthUsers", email.toLowerCase());
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          const userPropertyRoles = userData.propertyRoles || {};

          // Set the property roles for the user
          setPropertyRoles(userPropertyRoles);
        } else {
          // User does not exist in emailAuthUsers
          setPropertyRoles({});
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setError("Failed to fetch user details. Please try again.");
      }
    };

    fetchUserDetails();
  }, [email]);

  const signup = async (email, password) => {
    setIsPending(true);
    setError(null);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );

      if (!userCredential) {
        throw new Error("Unable to register. Please try again later.");
      }

      // Update user profile with the name
      await updateProfile(auth.currentUser, {
        displayName: name,
      });

      // Send email verification
      await sendEmailVerification(userCredential.user);

      setIsPending(false);
      setOpenDialog(true);
      setError(null);
    } catch (error) {
      console.error("Error during sign-up:", error);
      setError(error.message);
      setIsPending(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmedPassword) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setIsPending(true);
      await signup(email, password);
      setIsPending(false);
    } catch (err) {
      setIsPending(false);
      setError(err.message);
    }
  };

  const propertyCodes = Object.keys(propertyRoles);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "69vh",
      }}
    >
      <Grid item xs={12} sm={9} md={9}>
        <Grid item xs={12} container justifyContent="center">
          <Card
            sx={{
              borderRadius: "36px",
              backgroundColor: "#f5f5f5",
              marginTop: "1.8rem",
            }}
          >
            <Lottie animationData={animationData} style={{ height: "9rem" }} />
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      padding: "3rem 4.5rem 3rem 4.5rem",
                      width: "100%",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="Email Address"
                          type="email"
                          value={email}
                          onChange={(e) =>
                            setEmail(e.target.value.toLowerCase())
                          }
                          required
                          fullWidth
                          sx={commonSx}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Name (First Last)"
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                          fullWidth
                          sx={commonSx}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Password"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          fullWidth
                          sx={commonSx}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Confirm Password"
                          type="password"
                          value={confirmedPassword}
                          onChange={(e) => setConfirmedPassword(e.target.value)}
                          required
                          fullWidth
                          sx={commonSx}
                        />
                      </Grid>

                      {propertyCodes.length > 0 && (
                        <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                          <CheckCircleIcon
                            style={{
                              color: "#4a6741",
                              marginRight: "0.5rem",
                              verticalAlign: "middle",
                            }}
                          />
                          <Typography>{propertyCodes.join(", ")}</Typography>
                        </Box>
                      )}

                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={isPending || propertyCodes.length === 0}
                          fullWidth
                          sx={{
                            ...greyButton,
                          }}
                        >
                          REGISTER
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          navigate("/");
        }}
      >
        <DialogTitle>Registration Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please check your email to verify your account.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              navigate("/");
            }}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

const commonSx = {
  marginBottom: "0.2rem",
  transition: "0.3s",
  maxWidth: "99rem",
  ".MuiInputLabel-root": {
    fontSize: "0.9rem",
    fontWeight: 200,
    color: "#020202",
  },
  ".MuiInputBase-input": {
    fontSize: "0.9rem",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "27px",
  },
};
const greenButton = {
  padding: "9.18px 18px 9.18px 18px",
  borderRadius: "0px 18px 18px 0px",
  fontSize: "0.81rem",
  fontFamily: "Outfit",
  letterSpacing: "0.09rem",
  border: "2.7px solid #93A683",
  backgroundColor: "#93A683",
  color: "#f5f5f5",
  fontWeight: "400",
  "&:hover": {
    border: "2.7px solid #202426",
    backgroundColor: "transparent",
    color: "#202426",
  },
};
const greyButton = {
  borderRadius: "9px",
  padding: "9px 18px 9px 18px",
  fontWeight: "400",
  fontSize: "0.81rem",
  fontFamily: "Outfit",
  letterSpacing: "0.09rem",
  border: "2.7px solid lightgrey",
  backgroundColor: "lightgrey",
  color: "#202426",
  "&:hover": {
    border: "2.7px solid #202426",
    backgroundColor: "transparent",
    color: "#202426",
  },
};
