import React, { useEffect, useMemo, useState } from "react";
import Lottie from "lottie-react";
import animationData from "../../assets/working-together.json";
import { db } from "../../firebase/firebase-config";
import {
  collection,
  query,
  where,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  onSnapshot,
} from "firebase/firestore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  Typography,
  Box,
  IconButton,
  Button,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { VscFileBinary } from "react-icons/vsc";
import { BsPersonUp, BsPersonX, BsPersonAdd, BsRobot } from "react-icons/bs";
import { MdClear } from "react-icons/md";
import Slide from "@mui/material/Slide";
import { TfiClose } from "react-icons/tfi";
import { IoDocumentsOutline, IoRefreshOutline } from "react-icons/io5";

export default function Home() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    user,
    userPermissions,
    userRole,
    availableProperties,
    handlePropertyChange,
  } = useAuth();
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [lastClearedDoc, setLastClearedDoc] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentAction, setCurrentAction] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [propertyCounts, setPropertyCounts] = useState({});
  const [selectedPropertyCode, setSelectedPropertyCode] = useState("");

  const hasPermissionForProperty = (permission, propertyCode) => {
    // If the user's role is 'Global' or 'MGMT', they have all permissions
    if (
      userRole === "Global" ||
      userRole === "MGMT" ||
      userRole === "PropertyAdmin"
    ) {
      return true;
    }

    // Otherwise, check the permissions for the specific property
    const permissionsForProperty =
      userPermissions[propertyCode]?.permissions || {};
    return permissionsForProperty[permission] === true;
  };

  // Filter availableProperties to include only those with 'landPayroll' permission
  const propertiesWithLandPayrollPermission = useMemo(
    () =>
      availableProperties.filter((prop) =>
        hasPermissionForProperty("landPayroll", prop.propertyCode),
      ),
    [availableProperties, userPermissions],
  );

  const handlePropertyCodeChange = (event) => {
    const newPropertyCode = event.target.value;
    setSelectedPropertyCode(newPropertyCode);
    console.log("Selected Property Changed:", newPropertyCode);
  };

  const handleRefresh = () => {
    setRefresh((prev) => !prev); // Toggle refresh state to trigger useEffect
  };

  const handleIconClick = async (notificationDocId, status) => {
    console.log(
      "handleIconClick called with notificationDocId:",
      notificationDocId,
      "status:",
      status,
    );

    try {
      // Get the notification document to retrieve the EmpID
      const notificationRef = doc(db, "notifications", notificationDocId);
      const notificationDoc = await getDoc(notificationRef);

      if (notificationDoc.exists()) {
        const notificationData = notificationDoc.data();
        const empId = notificationData.EmpID; // Get EmpID from the notification document

        // Now get the corresponding document from empRegister using EmpID
        const entryRef = doc(db, "empRegister", empId);
        const entryDoc = await getDoc(entryRef);

        if (entryDoc.exists()) {
          const entryData = entryDoc.data();
          const currentStatus =
            status === "TERMINATED" ? entryData.terminated : entryData.Status;
          const currentDate = new Date().toLocaleDateString("en-US"); // Get current date in mm/dd/yyyy format
          const updatedNotes = [
            ...(entryData.Notes || []),
            `${currentDate} - ${currentStatus} employee acknowledged by ${user.displayName}`,
          ];

          // Update empRegister collection
          console.log("Updating empRegister for empId:", empId); // Log before updating empRegister
          await updateDoc(entryRef, {
            [status === "TERMINATED" ? "terminated" : "Status"]: "APPROVED", // Update Status or terminated
            Notes: updatedNotes,
          });
          console.log("empRegister updated successfully for empId:", empId); // Log successful update

          // Now update the notifications collection
          console.log(
            "Updating notifications for notificationDocId:",
            notificationDocId,
          ); // Log before updating notifications
          // Update the 'Status' field to an empty string and other fields as blank
          await updateDoc(notificationRef, {
            Status: "", // Set Status field to a blank string
            paySelection: "",
            Banking: "",
            ID: "",
            SSN: "",
            ["W4-IRS"]: "",
            ["W4-LOCAL"]: "",
          });
          console.log(
            "notifications updated successfully for notificationDocId:",
            notificationDocId,
          ); // Log successful update

          // Show snackbar notification
          setSnackbarOpen(true);

          // Update data state to reflect the change in the table
          setData((prevData) =>
            prevData.map((item) =>
              item.id === notificationDocId
                ? { ...item, Status: "APPROVED", Notes: updatedNotes }
                : item,
            ),
          );
          handleRefresh();
        } else {
          console.log("No document found in empRegister with EmpID:", empId); // Log if document doesn't exist in empRegister
        }
      } else {
        console.log(
          "No document found in notifications with docId:",
          notificationDocId,
        ); // Log if document doesn't exist in notifications
      }
    } catch (error) {
      console.error("Error updating document:", error); // Log error
    }
  };

  // Open the confirmation dialog with docId and action
  const openDialog = (docId, status) => {
    console.log("openDialog called with docId:", docId, "status:", status); // Add logging to debug
    setCurrentItem(docId); // Ensure docId is stored
    setCurrentAction(status); // Ensure status is stored
    setDialogOpen(true);
  };

  // Handle the confirmation from the dialog
  const handleConfirm = async () => {
    console.log(
      "handleConfirm triggered. CurrentItem:",
      currentItem,
      "CurrentAction:",
      currentAction,
    ); // Add logging for debugging
    if (currentItem && currentAction) {
      await handleIconClick(currentItem, currentAction);
    } else {
      console.log("handleConfirm: Missing docId or status");
    }
    setDialogOpen(false); // Close the dialog after confirmation
  };

  const handleClearEntry = async (docId) => {
    const notificationRef = doc(db, "notifications", docId);
    await updateDoc(notificationRef, {
      clearedBy: arrayUnion({
        userId: user.uid,
        date: new Date().toISOString(), // Store the current date and time
      }),
    });

    // Set the last cleared document to enable undo
    setLastClearedDoc(docId);

    // Show snackbar
    setSnackbarOpen(true);
    handleRefresh();
  };

  const handleUndoClear = async () => {
    if (lastClearedDoc) {
      const notificationRef = doc(db, "notifications", lastClearedDoc);
      const docSnapshot = await getDoc(notificationRef);
      if (docSnapshot.exists()) {
        const docData = docSnapshot.data();
        const newClearedBy = docData.clearedBy.filter(
          (entry) => entry.userId !== user.uid,
        );
        await updateDoc(notificationRef, {
          clearedBy: newClearedBy,
        });
        setLastClearedDoc(null);
        setSnackbarOpen(false);
      }
    }
  };

  useEffect(() => {
    if (
      !propertiesWithLandPayrollPermission ||
      propertiesWithLandPayrollPermission.length === 0
    ) {
      setLoading(false);
      return;
    }

    // Extract property codes from propertiesWithLandPayrollPermission
    const propertyCodes = propertiesWithLandPayrollPermission
      .filter((prop) => prop && prop.propertyCode)
      .map((prop) => prop.propertyCode);

    if (propertyCodes.length === 0) {
      setLoading(false);
      return;
    }

    // Log property codes for debugging
    console.log("Property Codes:", propertyCodes);

    // Rest of your data fetching code
    const uniqueDocIds = new Set();
    const newDataMap = new Map(); // Track unique documents by ID
    const notificationsRef = collection(db, "notifications");
    const chunkSize = 10;
    const propertyCodeChunks = [];

    // Split propertyCodes into chunks for Firestore query
    for (let i = 0; i < propertyCodes.length; i += chunkSize) {
      propertyCodeChunks.push(propertyCodes.slice(i, i + chunkSize));
    }

    const unsubscribeArr = [];

    // Iterate through chunks and listen to updates using onSnapshot
    propertyCodeChunks.forEach((chunk) => {
      const q = query(notificationsRef, where("PropID", "in", chunk));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docs.forEach((doc) => {
          if (!uniqueDocIds.has(doc.id)) {
            uniqueDocIds.add(doc.id);
            const docData = doc.data(); // Renamed from 'data' to 'docData'
            const submittedFields = new Set();
            const clearedBy = docData.clearedBy || [];
            const dateUpdated = docData.DateUpdated
              ? docData.DateUpdated.toDate()
              : null;
            const currentDate = new Date();

            // Skip cleared entries or those older than 14 days
            const userCleared = clearedBy.find(
              (entry) =>
                entry.userId === user.uid &&
                new Date(entry.date) >= dateUpdated,
            );
            if (
              userCleared ||
              !dateUpdated ||
              (dateUpdated &&
                currentDate - dateUpdated > 14 * 24 * 60 * 60 * 1000)
            ) {
              return;
            }

            // Add field tracking logic
            if (docData["Status"] === "NEW") submittedFields.add("New Hire");
            if (docData["Status"] === "REHIRED")
              submittedFields.add("Employee Rehired");
            if (docData["Status"] === "Terminated")
              submittedFields.add("Employee Terminated");
            if (docData.BANKING === "SUBMITTED")
              submittedFields.add("New Banking Doc");
            if (docData.ID === "SUBMITTED") submittedFields.add("New ID Doc");
            if (docData.SSN === "SUBMITTED") submittedFields.add("New SSN Doc");
            if (docData["W4-IRS"] === "SUBMITTED")
              submittedFields.add("New W4_IRS Doc");
            if (docData["W4-LOCAL"] === "SUBMITTED")
              submittedFields.add("New W4_LOC Doc");
            if (docData.paySelection === "SUBMITTED")
              submittedFields.add("New Pay Doc");
            if (docData.BANKING === "REJECTED")
              submittedFields.add("Rejected Banking Doc");
            if (docData.ID === "REJECTED")
              submittedFields.add("Rejected ID Doc");
            if (docData.SSN === "REJECTED")
              submittedFields.add("Rejected SSN Doc");
            if (docData["W4-IRS"] === "REJECTED")
              submittedFields.add("Rejected W4_IRS Doc");
            if (docData["W4-LOCAL"] === "REJECTED")
              submittedFields.add("Rejected W4_LOC Doc");
            if (docData.paySelection === "REJECTED")
              submittedFields.add("Rejected Pay Doc");
            if (docData.paySelection === "RESUBMITTED")
              submittedFields.add("Resubmitted Pay Doc");

            const hasSubmittedOrRejectedFields = [
              "Banking",
              "ID",
              "SSN",
              "W4-IRS",
              "W4-LOCAL",
              "paySelection",
            ].some((field) =>
              ["SUBMITTED", "REJECTED", "RESUBMITTED"].includes(docData[field]),
            );

            const entry = {
              id: doc.id,
              DateUpdated: dateUpdated
                ? dateUpdated.toLocaleDateString()
                : "N/A",
              EmpID: docData.EmpID,
              NameFirst: docData.NameFirst,
              NameLast: docData.NameLast,
              PropID: docData.PropID,
              SubmittedFields: Array.from(submittedFields),
              ActionButtons: hasPermissionForProperty(
                "landAcknowledgeChange",
                docData.PropID,
              ) ? (
                <Box
                  display="flex"
                  justifyContent="left"
                  alignItems="center"
                  width="100%"
                  sx={{ borderRadius: "36px", overflow: "hidden" }}
                  bgcolor="#f2f2f2"
                >
                  {docData.Status === "NEW" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        maxWidth: "4.5rem",
                        textAlign: "center",
                      }}
                    >
                      <Tooltip title="ack new hire">
                        <IconButton
                          style={{
                            backgroundColor: "transparent",
                            border: "0",
                            color: "#202426",
                            fontSize: "1.35rem",
                          }}
                          onClick={() => openDialog(doc.id, "NEW")}
                        >
                          <BsPersonAdd />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  {docData.Status === "REHIRED" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        maxWidth: "4.5rem",
                        textAlign: "center",
                      }}
                    >
                      <Tooltip title="ack re-hire">
                        <IconButton
                          style={{
                            backgroundColor: "transparent",
                            border: "0",
                            color: "#202426",
                            fontSize: "1.35rem",
                          }}
                          onClick={() => openDialog(doc.id, "REHIRED")} // Use doc.id
                        >
                          <BsPersonUp />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  {docData.Status === "Terminated" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        maxWidth: "4.5rem",
                        textAlign: "center",
                      }}
                    >
                      <Tooltip title="ack termination">
                        <IconButton
                          style={{
                            backgroundColor: "transparent",
                            border: "0",
                            color: "#202426",
                            fontSize: "1.35rem",
                          }}
                          onClick={() => openDialog(doc.id, "TERMINATED")} // Use doc.id
                        >
                          <BsPersonX />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  {(docData.paySelection === "SUBMITTED" ||
                    docData.paySelection === "REJECTED" ||
                    docData.paySelection === "RESUBMITTED") && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        maxWidth: "4.5rem",
                        textAlign: "center",
                      }}
                    >
                      <a
                        href={docData.paySelectionApproval}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Tooltip title="pay selection">
                          <IconButton
                            style={{
                              backgroundColor: "transparent",
                              border: "0",
                              color:
                                docData.paySelection === "SUBMITTED"
                                  ? "#202426"
                                  : docData.paySelection === "REJECTED"
                                    ? "#F98B85"
                                    : docData.paySelection === "RESUBMITTED"
                                      ? "#FFA500"
                                      : "#202426",
                              fontSize: "1.5rem",
                            }}
                          >
                            <VscFileBinary />
                          </IconButton>
                        </Tooltip>
                      </a>
                    </Box>
                  )}
                  {hasSubmittedOrRejectedFields ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        maxWidth: "4.5rem",
                        textAlign: "center",
                      }}
                    >
                      <Tooltip title="review uploads">
                        <IconButton
                          style={{
                            backgroundColor: "transparent",
                            border: "0",
                            color: "#202426",
                            fontSize: "1.5rem",
                          }}
                          onClick={() =>
                            navigate("/update-Record", {
                              state: {
                                propertyCode: docData.PropID,
                                selectedEmployee: docData.EmpID,
                                formType: "Employee",
                                previewOnly: true,
                              },
                            })
                          }
                        >
                          <IoDocumentsOutline />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        maxWidth: "4.5rem",
                        textAlign: "center",
                      }}
                    >
                      <Tooltip title="employee file">
                        <IconButton
                          style={{
                            backgroundColor: "transparent",
                            border: "0",
                            color: "#202426",
                            fontSize: "1.35rem",
                          }}
                          onClick={() =>
                            navigate("/update-Record", {
                              state: {
                                propertyCode: docData.PropID,
                                selectedEmployee: docData.EmpID,
                                formType: "Employee",
                                previewOnly: true,
                              },
                            })
                          }
                        >
                          <BsRobot />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              ) : null,
            };

            newDataMap.set(doc.id, entry);
          }
        });

        // Update the state with the unique entries from the Map
        setData(Array.from(newDataMap.values())); // Convert Map to array
        // Compute counts per property
        const newDataArray = Array.from(newDataMap.values());
        const counts = newDataArray.reduce((acc, item) => {
          acc[item.PropID] = (acc[item.PropID] || 0) + 1;
          return acc;
        }, {});
        setPropertyCounts(counts);
        setLoading(false);
      });

      unsubscribeArr.push(unsubscribe);
    });

    // Cleanup function to unsubscribe from Firestore listeners
    return () => {
      unsubscribeArr.forEach((unsubscribe) => unsubscribe());
    };
  }, [availableProperties, refresh, userPermissions]);

  useEffect(() => {
    console.log("Selected Property Code:", selectedPropertyCode);
    console.log("Data:", data);

    const newFilteredData =
      selectedPropertyCode && selectedPropertyCode !== ""
        ? data.filter(
            (item) =>
              String(item.PropID).trim().toLowerCase() ===
              String(selectedPropertyCode).trim().toLowerCase(),
          )
        : data;

    console.log("Filtered Data length:", newFilteredData.length);
    setFilteredData(newFilteredData);
  }, [data, selectedPropertyCode]);

  if (loading) {
    return (
      <div>
        <Lottie
          animationData={animationData}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
  }

  return (
    <div>
      {data.length > 0 ? (
        <Card
          sx={{
            borderRadius: "18px",
            padding: "1rem",
            backgroundColor: "#fcfcfc",
          }}
        >
          <TableContainer sx={{ overflowX: "auto" }} component={Paper}>
            <Table sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={maintitle200header}>
                    <Tooltip title="Refresh Data">
                      <IconButton
                        onClick={handleRefresh}
                        style={{
                          backgroundColor: "transparent",
                          border: "0",
                          color: "#202426",
                          fontSize: "1.53rem",
                        }}
                      >
                        <IoRefreshOutline />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left" sx={maintitle200header} colSpan={4}>
                    <Typography
                      sx={{
                        fontFamily: "Outfit",
                        letterSpacing: "0.09rem",
                        fontWeight: "800",
                        fontSize: "2.07rem",
                        color: "#202426",
                        textTransform: "lowercase",
                      }}
                      variant="body1"
                      align="left"
                    >
                      PAYROLL
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={maintitle200header} colSpan={2}>
                    {user && availableProperties.length > 0 && (
                      <div className="property-selector-container">
                        <FormControl variant="outlined" sx={{}}>
                          <InputLabel
                            id="property-select-label"
                            sx={{
                              ...selectInputLabel,
                            }}
                          >
                            TEAM
                          </InputLabel>
                          <Select
                            labelId="property-select-label"
                            value={selectedPropertyCode || ""}
                            onChange={handlePropertyCodeChange} // Using the local function
                            label="PROPERTY"
                            sx={{
                              ...selectSelect,
                            }}
                          >
                            {/* Option for All Properties */}
                            <MenuItem value="" sx={{ ...selectMenuItem }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                All Teams
                                {data.length > 0 && (
                                  <Typography sx={badge2}>
                                    {data.length}
                                  </Typography>
                                )}
                              </div>
                            </MenuItem>

                            {propertiesWithLandPayrollPermission
                              .slice()
                              .sort((a, b) => {
                                const nameA = (
                                  a.propertyCode ||
                                  a.propertyName ||
                                  ""
                                ).toString();
                                const nameB = (
                                  b.propertyCode ||
                                  b.propertyName ||
                                  ""
                                ).toString();
                                return nameA.localeCompare(nameB);
                              })
                              .map((property) => {
                                const count =
                                  propertyCounts[property.propertyCode] || 0;
                                return (
                                  <MenuItem
                                    key={property.propertyCode}
                                    value={property.propertyCode}
                                    sx={{ ...selectMenuItem }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {property.propertyName
                                        ? `${property.propertyCode} - ${property.propertyName}`
                                        : property.propertyCode}
                                      {count > 0 && (
                                        <Typography sx={badge2}>
                                          {count}
                                        </Typography>
                                      )}
                                    </div>
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                  </TableCell>
                  <TableCell align="left" sx={maintitle200header}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        position: "absolute",
                        top: 1.8,
                        right: 1.8,
                      }}
                    >
                      <Typography sx={badge}>{data.length}</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" size="small" sx={maintableBold}>
                      {item.DateUpdated}
                    </TableCell>
                    <TableCell align="left" size="small" sx={maintableBold}>
                      {item.EmpID}
                    </TableCell>
                    <TableCell align="left" size="small" sx={maintableBold}>
                      {item.NameFirst}
                    </TableCell>
                    <TableCell align="left" size="small" sx={maintableBold}>
                      {item.NameLast}
                    </TableCell>
                    <TableCell align="left" size="small" sx={maintableBold}>
                      {item.PropID}
                    </TableCell>
                    <TableCell align="left" size="small" sx={maintableBold}>
                      {item.SubmittedFields.map((field, index) => (
                        <div key={index}>{field}</div>
                      ))}
                    </TableCell>
                    <TableCell align="left" size="small" sx={maintableBold}>
                      {item.ActionButtons}
                    </TableCell>
                    <TableCell align="left" size="small" sx={maintableBold}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <Tooltip title={"Clear Notification"}>
                          <IconButton
                            style={{
                              backgroundColor: "transparent",
                              border: "0",
                              color: "#93A683",
                              fontSize: "1.5rem",
                            }}
                            onClick={() => handleClearEntry(item.id)}
                          >
                            <MdClear />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            PaperProps={{
              sx: {
                padding: "1rem",
                borderRadius: "27px",
                backgroundColor: "#f5f5f5",
                position: "relative",
              },
            }}
          >
            <IconButton
              onClick={() => setDialogOpen(false)}
              sx={{
                position: "absolute",
                top: 9,
                right: 9,
                color: "#101010",
              }}
            >
              <TfiClose />
            </IconButton>
            <DialogTitle
              sx={{
                letterSpacing: "0.09rem",
                fontWeight: "600",
                fontSize: "0.9rem",
                color: "#101010",
              }}
              id="alert-dialog-title"
            >
              {"EMPLOYEE CHANGE"}
            </DialogTitle>
            <DialogContent
              sx={{
                letterSpacing: "0.1rem",
                fontWeight: "400",
                fontSize: "0.9rem",
                color: "#000000",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: "1rem",
                  fontWeight: "400",
                  fontSize: "0.9rem",
                  color: "#101010",
                }}
              >
                Acknowledge employee change?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleConfirm}
                color="primary"
                autoFocus
                fullWidth
                sx={{
                  backgroundColor: "#93A683",
                  borderRadius: "9px",
                  padding: "9.9px 18px 9.9px 18px",
                  color: "#f5f5f5",
                  fontFamily: "'Outfit', sans-serif",
                  letterSpacing: "0.1rem",
                  fontWeight: "800",
                  fontSize: "0.72rem",
                  border: "2px solid #f9f9f9",
                }}
              >
                ACKNOWLEDGE
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            sx={{
              width: 900,
              color: "secondary",
              "& .MuiSnackbarContent-root": {
                backgroundColor: "#93A683",
                fontSize: "0.9rem",
                fontWeight: "800",
              },
              "& .MuiButton-root": {
                color: "#202426",
                fontSize: "0.9rem",
                fontWeight: "800",
              },
            }}
            open={snackbarOpen}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={3699}
            TransitionComponent={Slide}
            TransitionProps={{ enter: true, exit: false }}
            onClose={() => setSnackbarOpen(false)}
            message={
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "400",
                  letterSpacing: "0.09rem",
                }}
              >
                ACKNOWLEDGED
              </span>
            }
            action={
              <Button color="inherit" size="large" onClick={handleUndoClear}>
                UNDO
              </Button>
            }
          />
        </Card>
      ) : (
        <Lottie
          animationData={animationData}
          style={{ width: "100%", height: "100%" }}
        />
      )}
    </div>
  );
}

const badge = {
  fontFamily: "'Outfit', sans-serif",
  letterSpacing: "0.18rem",
  fontWeight: 400,
  fontSize: "1.08rem",
  backgroundColor: "#93A683",
  color: "#FFFDD0",
  width: "3.6rem",
  height: "3.6rem",
  borderRadius: "72%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: "0.18rem",
};
const badge2 = {
  fontFamily: "'Outfit', sans-serif",
  letterSpacing: "0.09rem",
  fontWeight: 800,
  fontSize: "0.9rem",
  backgroundColor: "lightgrey",
  color: "grey",
  width: "1.8rem",
  height: "1.8rem",
  borderRadius: "72%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: "0.09rem",
  marginLeft: "1rem",
};
const maintableBold = {
  fontWeight: 400,
  fontSize: "0.9rem",
  textTransform: "lowercase",
  backgroundColor: "#fcfcfc",
  color: "#202426",
  rowHeight: "0.1rem",
  letterSpacing: "0.018rem",
};
const maintitle200header = {
  fontFamily: "'Nunito', sans-serif",
  fontWeight: 600,
  fontSize: "0.9rem",
  backgroundColor: "#f5f5f5",
  color: "lightgrey",
  letterSpacing: "0.1rem",
};

const sharedTextStyles = {
  fontFamily: "'Outfit', sans-serif",
  fontWeight: 600,
  fontSize: "18px",
  color: "#202426",
  letterSpacing: "1.8px",
};
const selectInputLabel = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Outfit",
  fontWeight: 400,
  color: "#202426",
  transition: "0.3s",
  textTransform: "uppercase",
};
const selectSelect = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Nunito",
  backgroundColor: "#ffffff",
  borderRadius: "18px",
  textTransform: "uppercase",
};
const selectMenuItem = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Nunito",
  fontWeight: 400,
  letterSpacing: "0.045rem",
  color: "#202426",
  transition: "0.3s",
  textTransform: "uppercase",
};
