import React, { useState, useEffect } from "react";
import "../../components/App.css";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  IconButton,
  Tooltip,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase/firebase-config";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  setDoc,
  doc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import axios from "axios";
import { RxReset } from "react-icons/rx";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { US_STATES } from "./constants";
import { NumericFormat } from "react-number-format";

export default function AddProperty() {
  const { user, userPermissions, userRole, availableProperties } = useAuth();
  const [formType] = useState("Employee"); // Hardcoded formType to "Employee"
  const [propertyCode, setPropertyCode] = useState("");
  const [showBlankPropertyCode, setShowBlankPropertyCode] = useState(true);
  const [changes, setChanges] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [payPeriodEnd, setPayPeriodEnd] = useState(null);
  const [payPeriodStart, setPayPeriodStart] = useState(null);
  const [previousPayPeriodEnd, setPreviousPayPeriodEnd] = useState(null);
  const [previousPayPeriodStart, setPreviousPayPeriodStart] = useState(null);
  const [permissionsForProperty, setPermissionsForProperty] = useState({});
  const [selectedDepartments, setSelectedDepartments] = useState("");
  const [disabledDepartments, setDisabledDepartments] = useState([]);
  const [usedDepartments, setUsedDepartments] = useState([]);

  // PERMISSIONS

  const hasPermissionForProperty = (permission, propertyCode) => {
    // If the user's role is 'Global' or 'MGMT', they have all permissions
    if (
      userRole === "Global" ||
      userRole === "MGMT" ||
      userRole === "PropertyAdmin"
    ) {
      return true;
    }

    // Otherwise, check the permissions for the specific property
    const permissionsForProperty =
      userPermissions[propertyCode]?.permissions || {};
    return permissionsForProperty[permission] === true;
  };

  // Filter availableProperties to include only those with 'landPayroll' permission
  const propertiesAddEmployee = availableProperties.filter((prop) =>
    hasPermissionForProperty("hrAddEmployee", prop.propertyCode),
  );

  useEffect(() => {
    if (!propertyCode || !userPermissions[propertyCode]) return;

    // Avoid setting state if permissions haven't changed to prevent re-renders
    const propertyPermissions = userPermissions[propertyCode].permissions || {};
    const permissionsArray = Object.keys(propertyPermissions).filter(
      (key) => propertyPermissions[key] === true,
    );

    setPermissionsForProperty((prevPermissions) => {
      // Only update state if permissions have changed
      const permissionsChanged =
        JSON.stringify(prevPermissions) !== JSON.stringify(permissionsArray);
      return permissionsChanged ? permissionsArray : prevPermissions;
    });
  }, [propertyCode, userPermissions]);

  const handleReset = () => {
    window.location.reload();
  };

  // FETCH DEPARTMENTS

  useEffect(() => {
    if (propertyCode) {
      // Load the current department list from Firestore
      const fetchDepartments = async () => {
        const propertyRef = doc(db, "Properties", propertyCode);
        const propertySnap = await getDoc(propertyRef);

        if (propertySnap.exists()) {
          const propertyData = propertySnap.data();
          setSelectedDepartments(propertyData.Dept || []);
        }
      };

      fetchDepartments();

      // Fetch departments that are in use from empRegister
      const fetchUsedDepartments = async () => {
        const empRef = collection(db, "empRegister");
        const q = query(
          empRef,
          where("PropID", "==", propertyCode),
          where("Status", "!=", "Terminated"),
        );
        const snapshot = await getDocs(q);

        let usedDepts = new Set();
        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          if (data.PayD1) {
            usedDepts.add(data.PayD1);
          }
          if (data.PayD2) {
            usedDepts.add(data.PayD2);
          }
        });

        setDisabledDepartments([...usedDepts]);
        setUsedDepartments([...usedDepts]);
      };

      fetchUsedDepartments();
    }
  }, [propertyCode]);

  const subtractOneDay = (dateStr) => {
    // Parse the date string to get a Date object
    const [month, day, year] = dateStr.split("/").map(Number);
    const date = new Date(year, month - 1, day);

    // Subtract one day
    date.setDate(date.getDate() - 1);

    // Format the new date back to MM/dd/yyyy
    const newMonth = String(date.getMonth() + 1).padStart(2, "0");
    const newDay = String(date.getDate()).padStart(2, "0");
    const newYear = date.getFullYear();

    return `${newMonth}/${newDay}/${newYear}`;
  };

  useEffect(() => {
    if (!user || !user.email || !propertyCode) return;

    const fetchPropertiesAndPermissions = async () => {
      try {
        // Fetch property document
        const propertiesDoc = await getDoc(doc(db, "Properties", propertyCode));
        if (!propertiesDoc.exists()) {
          console.error(`Property with code ${propertyCode} does not exist.`);
          return;
        }

        const { payPeriod, payCycle } = propertiesDoc.data();
        const currentDate = new Date();
        let payPeriodStartDate = new Date(payPeriod.seconds * 1000);
        let payPeriodEndDate;
        let prevPayPeriodStartDate;
        let prevPayPeriodEndDate;

        // Calculate Pay Periods
        const calculatePayPeriod = (start, interval) => {
          const end = new Date(start);
          end.setDate(start.getDate() + interval);
          return [start, end];
        };

        switch (payCycle) {
          case "Monthly":
            while (payPeriodStartDate < currentDate) {
              [payPeriodStartDate, payPeriodEndDate] = calculatePayPeriod(
                payPeriodStartDate,
                30,
              );
              if (payPeriodEndDate > currentDate) break;
              prevPayPeriodStartDate = payPeriodStartDate;
              prevPayPeriodEndDate = payPeriodEndDate;
              payPeriodStartDate = new Date(payPeriodEndDate);
            }
            break;
          case "Bi-Weekly":
            while (payPeriodStartDate < currentDate) {
              [payPeriodStartDate, payPeriodEndDate] = calculatePayPeriod(
                payPeriodStartDate,
                14,
              );
              if (payPeriodEndDate > currentDate) break;
              prevPayPeriodStartDate = payPeriodStartDate;
              prevPayPeriodEndDate = payPeriodEndDate;
              payPeriodStartDate = new Date(payPeriodEndDate);
            }
            break;
          case "Weekly":
            while (payPeriodStartDate < currentDate) {
              [payPeriodStartDate, payPeriodEndDate] = calculatePayPeriod(
                payPeriodStartDate,
                7,
              );
              if (payPeriodEndDate > currentDate) break;
              prevPayPeriodStartDate = payPeriodStartDate;
              prevPayPeriodEndDate = payPeriodEndDate;
              payPeriodStartDate = new Date(payPeriodEndDate);
            }
            break;
          default:
            console.error(`Unsupported pay cycle: ${payCycle}`);
            return;
        }

        // Set Pay Period Dates
        setPayPeriodStart(payPeriodStartDate);
        setPayPeriodEnd(payPeriodEndDate);
        setPreviousPayPeriodStart(prevPayPeriodStartDate);
        setPreviousPayPeriodEnd(prevPayPeriodEndDate);
      } catch (error) {
        console.error(
          "Error fetching properties and calculating pay periods:",
          error,
        );
      }
    };

    console.log(payPeriodEnd);
    fetchPropertiesAndPermissions();
  }, [propertyCode, user]);

  const predefinedDepartments = [
    "BARISTA",
    "BANQUET",
    "BARTENDER",
    "BELLHOP",
    "BREAKFAST",
    "CONVENTION",
    "COOK/CHEF",
    "ENGINEER",
    "EVENT STAFF",
    "F&B",
    "FRONT DESK",
    "HOUSEKEEPING",
    "HOUSEKEEPING /HR",
    "KITCHEN",
    "LAUNDRY",
    "MANAGEMENT",
    "MAINTENANCE",
    "PUBLIC AREAS",
    "SALES",
    "SECURITY",
    "SERVER",
    "SHUTTLE",
    "N/A",
  ];

  const payD1Types = ["PER HOUR", "PER ROOM", "SALARY"];
  const payD2Types = ["PER HOUR", "SALARY", "N/A"];

  const [newEmployeeData, setNewEmployeeData] = useState({
    NameFirst: "",
    NameMid: "",
    NameLast: "",
    Alias: "",
    Ad1: "",
    AdCity: "",
    AdState: "",
    AdZip: "",
    AdPhone: "",
    SS4: "",
    DOB: null,
    Background: "No",
    PayD1: "",
    PayD1_Type: "",
    PayD1_Rate: "0.00",
    PR2: "0.00",
    PR3: "0.00",
    PayD2: "N/A",
    PayD2_Type: "N/A",
    PayD2_Rate: "0.00",
    MustSubmitPaySelection: false,
    EligibleForHealthInsurance: false,
  });

  const handlePropertyCodeChange = (event) => {
    const newPropertyCode = event.target.value;
    setPropertyCode(newPropertyCode);
    setShowBlankPropertyCode(false);
  };

  const handleFieldChange = (name, value) => {
    setNewEmployeeData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      // Automatically set "Alias" if "NameFirst" or "NameLast" changes
      if (name === "NameFirst" || name === "NameLast") {
        updatedData.Alias =
          `${updatedData.NameFirst || ""} ${updatedData.NameLast || ""}`.trim();
      }

      return updatedData;
    });

    setChanges((prevChanges) => ({
      ...prevChanges,
      [name]: value,
    }));
  };

  const handleDOBChange = (date) => {
    const formattedDate = date ? date.toLocaleDateString("en-US") : null;
    handleFieldChange("DOB", formattedDate);
  };

  const handleDateHiredChange = (date) => {
    const formattedDate = date ? date.toLocaleDateString("en-US") : null; // "MM/dd/yyyy" format
    handleFieldChange("DateHired", formattedDate);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false); // Only close the dialog without saving
  };

  const generateUniqueEmpID = async () => {
    let empID;
    let exists = true;
    while (exists) {
      empID = String(Math.floor(100000000 + Math.random() * 900000000));
      if (!empID.includes("9")) continue; // Ensure it contains '9'

      const empRegisterRef = collection(db, "empRegister");
      const q = query(empRegisterRef, where("EmpID", "==", empID));
      const querySnapshot = await getDocs(q);

      exists = !querySnapshot.empty;
    }
    return empID;
  };

  const handleConfirmSave = async () => {
    setConfirmationLoading(true);
    try {
      const empID = await generateUniqueEmpID();

      // Find the property name based on the selected property code
      const selectedProperty = propertiesAddEmployee.find(
        (property) => property.propertyCode === propertyCode,
      );
      const propertyName = selectedProperty?.propertyName || "";

      const dateHired = newEmployeeData.DateHired
        ? new Date(newEmployeeData.DateHired).toLocaleDateString("en-US") // "MM/dd/yyyy" format
        : "";

      // Create the document data, adding all required fields
      const newEmployeeRecord = {
        ...newEmployeeData,
        EmpID: empID,
        DateHired: dateHired, // Add DateHired in "yyyy-mm-dd" format
        DateCreated: serverTimestamp(), // Firebase server timestamp for creation
        DateUpdated: serverTimestamp(), // Firebase server timestamp for update
        Notes: [],
        PropID: propertyCode,
        propName: propertyName,
        Status: "NEW",
        timeclock: "no",
        enrollDate: "",
        gender: "",
        age: "",
        collection: propertyCode,
        faceid: "",
      };

      // Use setDoc to save the document with EmpID as the document ID
      const employeeCollectionRef = collection(db, "empRegister");
      const docRef = doc(employeeCollectionRef, empID); // Specify the EmpID as the document ID
      await setDoc(docRef, newEmployeeRecord);

      console.log("New employee added with EmpID:", empID);
      alert("Employee added successfully!");

      // Add a record to payrollChanges collection to document this addition
      const payrollChangesRef = collection(db, "payrollChanges");
      await addDoc(payrollChangesRef, {
        EmpID: empID,
        changes: [`Status: NEW`],
        ChangedBy: user.email,
        propCode: propertyCode,
        payPeriod: subtractOneDay(payPeriodEnd?.toLocaleDateString()),
      });

      // Send a webhook via axios with employee data, including propertyName
      await axios.post(
        "https://prod-154.westus.logic.azure.com:443/workflows/395e444cd9fb492fab06373c717bcbfa/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=tMQ1C2vMSvm_vLsqPUloGd97Pt4jGlcm7rBmZ6DdiZ8",
        {
          EmpID: empID,
          NameFirst: newEmployeeData.NameFirst,
          NameMid: newEmployeeData.NameMid,
          NameLast: newEmployeeData.NameLast,
          AdPhone: newEmployeeData.AdPhone,
          Email: newEmployeeData.Email,
          propertyCode,
          propertyName,
          Status: "NEW",
          createdAt: new Date().toISOString(), // Include a timestamp for the webhook
        },
      );

      // Reset the form and changes
      setNewEmployeeData({});
      setChanges({});
    } catch (error) {
      console.error("Error adding new employee:", error);
      alert("Failed to add employee. Please try again.");
    } finally {
      setConfirmationLoading(false);
      handleReset();
      handleCloseDialog(); // Close the dialog after saving
    }
  };

  const isFormValid = () => {
    const requiredFields = [
      "Alias",
      "Ad1",
      "AdCity",
      "AdState",
      "AdZip",
      "DateHired",
      "DOB",
      "AdPhone",
      "PayD1",
      "PayD1_Type",
      "PayD1_Rate",
      "PayD2_Type",
      "PayD2_Rate",
    ];
    return requiredFields.every(
      (field) =>
        newEmployeeData[field] &&
        newEmployeeData[field].toString().trim() !== "",
    );
  };

  const renderFormSection = (section) =>
    section.map(({ name, label, isSelect, options }) => {
      const isFieldInvalid =
        newEmployeeData[name] === undefined ||
        newEmployeeData[name] === null ||
        newEmployeeData[name] === "0.00" ||
        newEmployeeData[name].toString().trim() === "";

      // Conditional rendering for PR2 and PR3 based on PayD1_Type
      if (
        (name === "PR2" || name === "PR3") &&
        newEmployeeData.PayD1_Type !== "PER ROOM"
      ) {
        return null; // Skip rendering PR2 and PR3 if PayD1_Type is not "PER ROOM"
      }

      return (
        <Grid item xs={12} sm={4} key={name}>
          {name === "DOB" || name === "DateHired" ? (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                sx={{
                  ...textfieldSx,
                  backgroundColor: isFieldInvalid
                    ? "rgba(147, 166, 131, 0.54)"
                    : "rgba(153, 149, 150, 0.18)",
                }}
                label={label}
                inputFormat="MM/dd/yyyy"
                value={
                  newEmployeeData[name] ? new Date(newEmployeeData[name]) : null
                }
                onChange={
                  name === "DOB" ? handleDOBChange : handleDateHiredChange
                }
                renderInput={(params) => (
                  <TextField {...params} fullWidth sx={{ ...textfieldSx }} />
                )}
              />
            </LocalizationProvider>
          ) : isSelect ? (
            <FormControl fullWidth sx={{ ...textfieldSx }}>
              <InputLabel sx={{ ...selectInputLabel }}>{label}</InputLabel>
              <Select
                value={newEmployeeData[name] || ""}
                onChange={(e) => handleFieldChange(name, e.target.value)}
                sx={{ ...selectSelectClear }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    sx={{ ...selectMenuItem }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : name === "AdState" ? (
            <FormControl
              fullWidth
              sx={{
                ...textfieldSx,
                backgroundColor: isFieldInvalid
                  ? "rgba(147, 166, 131, 0.54)"
                  : "rgba(153, 149, 150, 0.18)",
              }}
            >
              <InputLabel>{label}</InputLabel>
              <Select
                value={newEmployeeData[name] || ""}
                onChange={(e) => handleFieldChange(name, e.target.value)}
                sx={{ ...selectSelectClear }}
              >
                {US_STATES.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : name === "AdPhone" ? (
            <NumericFormat
              customInput={TextField}
              format="1##########"
              mask="_"
              fullWidth
              label={label}
              value={newEmployeeData[name] || ""}
              onValueChange={({ value }) => {
                // Ensure the phone number starts with '1' and is 11 digits
                const formattedValue = value.startsWith("1")
                  ? value.slice(0, 11) // Limit to 11 characters
                  : "1" + value.slice(0, 10); // Add '1' prefix if missing
                handleFieldChange(name, formattedValue);
              }}
              sx={{
                ...textfieldSx,
                backgroundColor: isFieldInvalid
                  ? "rgba(147, 166, 131, 0.54)"
                  : "rgba(153, 149, 150, 0.18)",
              }}
            />
          ) : name === "SS4" ? (
            <NumericFormat
              customInput={TextField}
              format="#########"
              allowNegative={false}
              fullWidth
              label={label}
              value={newEmployeeData[name] || ""}
              onValueChange={({ value }) => {
                // Ensure only 9 numeric digits are stored
                handleFieldChange(name, value.slice(0, 9));
              }}
              sx={{ ...textfieldSx }}
              isNumericString // Ensures only numeric input
            />
          ) : name === "Background" ? (
            <FormControl fullWidth>
              <InputLabel sx={{ ...selectInputLabel }}>{label}</InputLabel>
              <Select
                value={newEmployeeData[name] || "No"}
                onChange={(e) => handleFieldChange(name, e.target.value)}
                sx={{ ...selectSelectClear }}
              >
                <MenuItem value="Yes" sx={{ ...selectMenuItem }}>
                  Yes
                </MenuItem>
                <MenuItem value="No" sx={{ ...selectMenuItem }}>
                  No
                </MenuItem>
              </Select>
            </FormControl>
          ) : name === "MustSubmitPaySelection" ||
            name === "EligibleForHealthInsurance" ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={newEmployeeData[name] || false}
                  onChange={(e) => handleFieldChange(name, e.target.checked)}
                  sx={{
                    "&.Mui-checked": {
                      color: "#FFC107",
                    },
                  }}
                />
              }
              label={
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      height: "90%",
                      fontWeight: "400",
                      fontSize: "0.9rem",
                      fontFamily: "Outfit",
                      color: "#202426",
                      textTransform: "uppercase",
                      letterSpacing: "0.045rem",
                    }}
                  >
                    {label}
                  </Typography>
                </Box>
              }
            />
          ) : ["PayD1_Rate"].includes(name) ? (
            <NumericFormat
              customInput={TextField}
              decimalScale={2}
              fixedDecimalScale
              prefix="$"
              fullWidth
              label={label}
              required
              value={newEmployeeData[name] || ""}
              onValueChange={({ value }) =>
                handleFieldChange(name, parseFloat(value).toFixed(2))
              }
              sx={{
                ...textfieldSx,
                backgroundColor: isFieldInvalid
                  ? "rgba(147, 166, 131, 0.54)"
                  : "rgba(153, 149, 150, 0.18)",
              }}
            />
          ) : ["PayD2_Rate"].includes(name) ? (
            <NumericFormat
              customInput={TextField}
              decimalScale={2}
              fixedDecimalScale
              prefix="$"
              fullWidth
              label={label}
              required
              value={newEmployeeData[name] || ""}
              onValueChange={({ value }) =>
                handleFieldChange(name, parseFloat(value).toFixed(2))
              }
              sx={{
                ...textfieldSx,
              }}
            />
          ) : ["Alias", "Ad1", "AdCity", "AdZip"].includes(name) ? (
            <TextField
              fullWidth
              label={label}
              value={newEmployeeData[name] || ""}
              onChange={(e) => handleFieldChange(name, e.target.value)}
              sx={{
                ...textfieldSx,
                backgroundColor: isFieldInvalid
                  ? "rgba(147, 166, 131, 0.54)"
                  : "rgba(153, 149, 150, 0.18)",
              }}
            />
          ) : (
            <TextField
              fullWidth
              label={label}
              value={newEmployeeData[name] || ""}
              onChange={(e) => handleFieldChange(name, e.target.value)}
              sx={{ ...textfieldSx }}
            />
          )}
        </Grid>
      );
    });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        borderRadius: "0.9rem",
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="left"
        spacing={2}
        style={{ margin: "9px" }}
      >
        <Grid item>
          <FormControl
            variant="outlined"
            sx={{
              minWidth: "15rem",
              "@media (max-width: 600px)": { minWidth: "9rem" },
            }}
          >
            <InputLabel
              sx={{
                ...selectInputLabel,
              }}
              htmlFor="property-code"
            >
              TEAM
            </InputLabel>
            <Select
              sx={{
                ...selectSelect,
              }}
              value={propertyCode || ""}
              onChange={handlePropertyCodeChange}
              inputProps={{ name: "property-code", id: "property-code" }}
              readOnly={!!propertyCode}
            >
              {propertiesAddEmployee
                .slice()
                .sort((a, b) => {
                  const nameA = (
                    a.propertyCode ||
                    a.propertyName ||
                    ""
                  ).toString();
                  const nameB = (
                    b.propertyCode ||
                    b.propertyName ||
                    ""
                  ).toString();
                  return nameA.localeCompare(nameB);
                })
                .map((property) => (
                  <MenuItem
                    key={property.propertyCode}
                    value={property.propertyCode}
                    sx={{
                      ...selectMenuItem,
                    }}
                  >
                    {property.propertyName
                      ? `${property.propertyCode} - ${property.propertyName}`
                      : property.propertyCode}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        {/*<Grid item>*/}
        {/*  <Box*/}
        {/*      sx={{*/}
        {/*        display: "flex",*/}
        {/*        flexDirection: "column",*/}
        {/*        alignItems: "center",*/}
        {/*        paddingRight: "0.81rem",*/}
        {/*      }}*/}
        {/*  >*/}
        {/*    <Tooltip title="RELOAD">*/}
        {/*      <span>*/}
        {/*      <IconButton*/}
        {/*          style={{*/}
        {/*            backgroundColor: "transparent",*/}
        {/*            border: "0",*/}
        {/*            color: "#202426",*/}
        {/*            fontSize: "1.53rem",*/}
        {/*          }}*/}
        {/*          onClick={handleReset}*/}
        {/*          label="RELOAD"*/}
        {/*      >*/}
        {/*        <IoRefreshOutline/>*/}
        {/*      </IconButton>*/}
        {/*      </span>*/}
        {/*    </Tooltip>*/}
        {/*    <Typography variant="caption" sx={{ fontFamily: "Outfit",*/}
        {/*      fontWeight: 800,*/}
        {/*      color: "#202426",*/}
        {/*      fontSize: "0.72rem",*/}
        {/*      letterSpacing: "0.09rem",}} >*/}
        {/*      RELOAD*/}
        {/*    </Typography>*/}
        {/*  </Box>*/}
        {/*</Grid>*/}

        {propertyCode && (
          <>
            <Grid container spacing={2} paddingTop={9} paddingLeft={2}>
              <Grid
                item
                xs={12}
                container
                sx={{
                  padding: "1rem",
                  borderRadius: "36px",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginLeft: 1,
                    letterSpacing: "0.3rem",
                    fontWeight: "200",
                    fontSize: "0.9rem",
                    color: "#101010",
                    paddingBottom: "1rem",
                    paddingTop: "1rem",
                  }}
                >
                  PERSONAL
                </Typography>
                <Grid container spacing={2}>
                  {renderFormSection(
                    [
                      { name: "NameFirst", label: "Legal First Name" },
                      { name: "NameMid", label: "Legal Middle Name" },
                      { name: "NameLast", label: "Legal Last Name" },
                      { name: "Alias", label: "Nickname ***" },
                      { name: "Ad1", label: "Address ***" },
                      { name: "AdCity", label: "City ***" },
                      { name: "AdState", label: "State ***" },
                      { name: "AdZip", label: "Zip Code ***" },
                      { name: "AdPhone", label: "Phone ***" },
                      { name: "SS4", label: "SSN" },
                      { name: "DOB", label: "Date of Birth" },
                      {
                        name: "Background",
                        label: "Background Check",
                        isSelect: true,
                        options: ["Yes", "No"],
                      },
                      { name: "DateHired", label: "Date Hired ***" },
                      // {
                      //   name: "MustSubmitPaySelection",
                      //   label: "Send pay selection notification",
                      // },
                      // {
                      //   name: "EligibleForHealthInsurance",
                      //   label: "Eligible for Health Insurance",
                      // },
                    ],
                    handleFieldChange,
                    newEmployeeData,
                    predefinedDepartments,
                    payD1Types,
                    payD2Types,
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} paddingTop={9} paddingLeft={2}>
              <Grid
                item
                xs={12}
                container
                sx={{
                  padding: "1rem",
                  borderRadius: "36px",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginLeft: 1,
                    letterSpacing: "0.3rem",
                    fontWeight: "200",
                    fontSize: "0.9rem",
                    color: "#101010",
                    paddingBottom: "1rem",
                    paddingTop: "1rem",
                  }}
                >
                  PAY DETAILS
                </Typography>
                <Grid container spacing={2}>
                  {renderFormSection([
                    {
                      name: "PayD1",
                      label: "Department 1",
                      isSelect: true,
                      options: predefinedDepartments.filter(
                        (type) =>
                          usedDepartments.includes(type) ||
                          selectedDepartments.includes(type),
                      ),
                    },
                    { name: "PayD1_Rate", label: "D1 RATE" },
                    {
                      name: "PayD1_Type",
                      label: "D1 TYPE",
                      isSelect: true,
                      options: payD1Types,
                    },
                    { name: "PR2", label: "Per Room 2" },
                    { name: "PR3", label: "Per Room 3" },
                    {
                      name: "PayD2",
                      label: "Department 2",
                      isSelect: true,
                      options: predefinedDepartments.filter(
                        (type) =>
                          (usedDepartments.includes(type) ||
                            selectedDepartments.includes(type)) &&
                          type !== "HOUSEKEEPING",
                      ),
                    },
                    { name: "PayD2_Rate", label: "D2 RATE" },
                    {
                      name: "PayD2_Type",
                      label: "D2 TYPE",
                      isSelect: true,
                      options: payD2Types,
                    },
                  ])}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{
                padding: "1rem",
                borderRadius: "36px",
                justifyContent: "right",
              }}
            >
              <Button
                onClick={handleOpenDialog}
                disabled={!isFormValid() || Object.keys(changes).length === 0}
                sx={{ ...greenButton, marginTop: 2.7 }}
              >
                Add Employee
              </Button>
            </Grid>
            {/* Confirmation Dialog */}
            <Dialog
              open={dialogOpen}
              onClose={handleCloseDialog}
              PaperProps={{
                sx: { ...dialogMain },
              }}
            >
              <DialogTitle sx={{ ...dialogTitle }}>
                {newEmployeeData.NameFirst &&
                newEmployeeData.NameLast &&
                newEmployeeData.SS4
                  ? "CONFIRM EMPLOYEE DETAILS"
                  : "SOME DETAILS ARE MISSING"}
              </DialogTitle>
              <DialogContent>
                {newEmployeeData.NameFirst &&
                newEmployeeData.NameLast &&
                newEmployeeData.SS4 ? (
                  <>
                    <DialogContentText
                      sx={{
                        fontFamily: "Nunito",
                        fontWeight: "400",
                        fontSize: "1.08rem",
                        color: "grey",
                      }}
                    >
                      Confirm the following name matches the employee's Social
                      Security card:
                    </DialogContentText>
                    <Typography sx={{ ...textfieldSx }}>
                      {newEmployeeData.NameFirst} {newEmployeeData.NameMid}{" "}
                      {newEmployeeData.NameLast}
                    </Typography>
                    <DialogContentText
                      sx={{
                        fontFamily: "Nunito",
                        fontWeight: "400",
                        fontSize: "1.08rem",
                        color: "grey",
                        marginTop: 4.5,
                      }}
                    >
                      Social Security Number:
                    </DialogContentText>
                    <Typography sx={{ ...textfieldSx }}>
                      {newEmployeeData.SS4
                        ? `${newEmployeeData.SS4.slice(0, 3)}-${newEmployeeData.SS4.slice(3, 5)}-${newEmployeeData.SS4.slice(5)}`
                        : ""}
                    </Typography>
                    <DialogContentText
                      sx={{
                        fontFamily: "Nunito",
                        fontWeight: "400",
                        fontSize: "1.08rem",
                        color: "grey",
                        marginTop: 4.5,
                      }}
                    >
                      Confirm employee can receive text messages at the
                      following number:
                    </DialogContentText>
                    <Typography sx={{ ...textfieldSx }}>
                      {newEmployeeData.AdPhone
                        ? `+1 ${newEmployeeData.AdPhone}`
                        : "N/A"}
                    </Typography>
                  </>
                ) : (
                  <>
                    <DialogContentText
                      sx={{
                        fontFamily: "Nunito",
                        fontWeight: "400",
                        fontSize: "1.08rem",
                        color: "grey",
                        marginTop: 4.5,
                      }}
                    >
                      Confirm {newEmployeeData.Alias} can receive text messages
                      at the following number:
                    </DialogContentText>
                    <Typography sx={{ ...textfieldSx }}>
                      {newEmployeeData.AdPhone
                        ? `+1 ${newEmployeeData.AdPhone}`
                        : "N/A"}
                    </Typography>
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} sx={{ ...greyButton }}>
                  Cancel
                </Button>
                <Button
                  onClick={handleConfirmSave}
                  disabled={confirmationLoading}
                  sx={{ ...greenButton }}
                >
                  {newEmployeeData.NameFirst &&
                  newEmployeeData.NameLast &&
                  newEmployeeData.SS4
                    ? "Confirm and Submit"
                    : "Submit new employee with incomplete profile"}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Grid>
    </Box>
  );
}

const textfieldSx = {
  marginBottom: "0.45rem",
  marginTop: "0.45rem",
  transition: "0.3s",
  maxWidth: "18rem",
  fontFamily: "Outfit",
  fontWeight: 400,
  color: "#202426",
  letterSpacing: "0.027rem",
  textTransform: "uppercase",
  ".MuiInputLabel-root": {
    fontSize: { xs: "0.81rem" },
    fontFamily: "Outfit",
    fontWeight: 400,
    color: "#202426",
    transition: "0.3s",
    textTransform: "uppercase",
  },
  ".MuiInputBase-input": {
    fontSize: { xs: "0.81rem" },
    fontFamily: "Nunito",
    fontWeight: 400,
    letterSpacing: "0.018rem",
    color: "#202426",
    transition: "0.3s",
    textTransform: "uppercase",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "18px",
  },
};
const selectInputLabel = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Outfit",
  fontWeight: 400,
  color: "#202426",
  transition: "0.3s",
  textTransform: "uppercase",
};
const selectSelect = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Nunito",
  backgroundColor: "#ffffff",
  borderRadius: "18px",
  textTransform: "uppercase",
};
const selectSelectClear = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Nunito",
  backgroundColor: "transparent",
  borderRadius: "18px",
  textTransform: "uppercase",
};
const selectMenuItem = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Nunito",
  fontWeight: 400,
  letterSpacing: "0.045rem",
  color: "#202426",
  transition: "0.3s",
  textTransform: "uppercase",
};
const greenButton = {
  padding: "9px 18px 9px 18px",
  borderRadius: "9px",
  fontSize: "0.81rem",
  fontFamily: "Outfit",
  letterSpacing: "0.09rem",
  border: "2.7px solid #93A683",
  backgroundColor: "#93A683",
  color: "#f5f5f5",
  fontWeight: "400",
  margin: "10.8",
  "&:hover": {
    border: "2.7px solid #202426",
    backgroundColor: "transparent",
    color: "#202426",
  },
};
const greyButton = {
  borderRadius: "9px",
  padding: "9px 18px 9px 18px",
  fontWeight: "400",
  fontSize: "0.81rem",
  fontFamily: "Outfit",
  letterSpacing: "0.09rem",
  border: "2.7px solid lightgrey",
  backgroundColor: "lightgrey",
  color: "#202426",
  "&:hover": {
    border: "2.7px solid #202426",
    backgroundColor: "transparent",
    color: "#202426",
  },
};
const dialogMain = {
  padding: "1rem",
  borderRadius: "36px",
  backgroundColor: "#f5f5f5",
};
const dialogTitle = {
  letterSpacing: "0.1rem",
  fontFamily: "Nunito",
  fontWeight: "200",
  fontSize: "1.44rem",
  color: "#202426",
  backgroundColor: "#f5f5f5",
  borderRadius: "18px",
};
