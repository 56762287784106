import React, { useState, useEffect, useMemo } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Tab,
  Tabs,
  Box,
  Card,
  Switch,
  Badge,
  DialogContentText,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  collection,
  query,
  where,
  updateDoc,
  getDocs,
  doc,
  getDoc,
  addDoc,
  deleteDoc,
  arrayUnion,
  setDoc,
  arrayRemove,
  Timestamp,
  onSnapshot,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db } from "../../firebase/firebase-config";
import { format } from "date-fns";
import { US_STATES } from "./constants";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import { FaFilePdf, FaFileImage } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { TfiClose } from "react-icons/tfi";
import { BsPatchMinusFill, BsPatchCheckFill } from "react-icons/bs";
import { IoRefreshOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import AddProperty from "./AddRecord";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IoTrashBinOutline } from "react-icons/io5";

export default function UpdateProperty() {
  const { user, userPermissions, userRole, availableProperties } = useAuth();
  const location = useLocation(); // Use useLocation to get the navigation state
  const [formType, setFormType] = useState(
    location.state?.formType || "Employee",
  ); // Set initial value from state
  const [propertyCode, setPropertyCode] = useState(
    location.state?.propertyCode || "",
  );
  const [selectedEmployee, setSelectedEmployee] = useState(
    location.state?.selectedEmployee || "",
  );
  const { previewOnly } = location.state || {};
  const [propertyCodes, setPropertyCodes] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [recordData, setRecordData] = useState(null);
  const [changes, setChanges] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isTerminated, setIsTerminated] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedPayPeriod, setSelectedPayPeriod] = useState("");
  const [employeeStatus, setEmployeeStatus] = useState("Active");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmationType, setConfirmationType] = useState("");
  const fileCategories = [
    "W4-IRS",
    "W4-LOCAL",
    "ID",
    "SSN",
    "WRITE-UP",
    "BANKING",
    "CERTIFICATE",
    "OTHER",
  ];
  const [hasPropertyAccess, setHasPropertyAccess] = useState(false);
  const [payPeriodStart, setPayPeriodStart] = useState(null);
  const [payPeriodEnd, setPayPeriodEnd] = useState(null);
  const [previousPayPeriodStart, setPreviousPayPeriodStart] = useState(null);
  const [previousPayPeriodEnd, setPreviousPayPeriodEnd] = useState(null);
  const [originalRecordData, setOriginalRecordData] = useState(null);
  const [newNote, setNewNote] = useState("");
  const [employeeFiles, setEmployeeFiles] = useState([]);
  const [emailFields, setEmailFields] = useState({
    ManagerEmail: { email: "", admin: false, viewPay: false },
    AGMEmail: { email: "", admin: false, viewPay: false },
    OtherAdminEmail: { email: "", admin: false, viewPay: false },
  });

  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [selectedEmailField, setSelectedEmailField] = useState("");
  const [selectedEmailValue, setSelectedEmailValue] = useState("");
  const [emailClaimsChanged, setEmailClaimsChanged] = useState(false);
  const [fileName, setFileName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [phoneDialogOpen, setPhoneDialogOpen] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [submittedCounts, setSubmittedCounts] = useState({
    payroll: 0,
    banking: 0,
  });
  const [confirmApproveDocDialogOpen, setConfirmApproveDocDialogOpen] =
    useState(false);
  const [confirmRejectDocDialogOpen, setConfirmRejectDocDialogOpen] =
    useState(false);
  const [fileToApprove, setFileToApprove] = useState(null);
  const [fileToReject, setFileToReject] = useState(null);
  const [rejectReason, setRejectReason] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [actionType, setActionType] = useState("update");
  const [rolesData, setRolesData] = useState([]);
  const [newRole, setNewRole] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedRoleIndex, setSelectedRoleIndex] = useState(null);
  const [changesMade, setChangesMade] = useState(false);
  const [availableRoles, setAvailableRoles] = useState("");
  const [newRoleToAdd, setNewRoleToAdd] = useState("");
  const [newDeptToAdd, setNewDeptToAdd] = useState("");
  const [isGlobal, setIsGlobal] = useState("");
  const [selectedUserRole, setSelectedUserRole] = useState("");
  const [selectedPermissionsState, setSelectedPermissionsState] = useState({});
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [notes, setNotes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const notesPerPage = 4;
  const [permissionsForProperty, setPermissionsForProperty] = useState({});
  const [isLocked, setIsLocked] = useState({});
  const [newName, setNewName] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [deptDialogOpen, setDeptDialogOpen] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState("");
  const [disabledDepartments, setDisabledDepartments] = useState([]);
  const [usedDepartments, setUsedDepartments] = useState([]);

  useEffect(() => {
    if (propertyCode) {
      const unsubscribe = onSnapshot(
        doc(db, "Properties", propertyCode),
        (docSnap) => {
          if (docSnap.exists()) {
            const { permissions = [] } = docSnap.data();
            fetchRolesForEmails(permissions);
          }
        },
      );
      return () => unsubscribe();
    }
  }, [propertyCode]);

  const fetchRolesForEmails = async (emails) => {
    const fetchedRolesData = [];
    await Promise.all(
      emails.map(async (email) => {
        const userRef = doc(db, "emailAuthUsers", email);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          if (userData.propertyRoles?.[propertyCode]) {
            const { role, adminLOCK, ...permissionsForProperty } =
              userData.propertyRoles[propertyCode];
            const permissionsArray = Object.entries(permissionsForProperty)
              .filter(([_, value]) => value)
              .map(([key]) => key);
            fetchedRolesData.push({
              email,
              name: userData.name || "",
              role,
              permissions: permissionsArray,
              isLocked: !!adminLOCK,
            });
          }
        }
      }),
    );
    setRolesData(fetchedRolesData);
  };

  // PERMISSIONS

  const hasPermissionForProperty = (permission, propertyCode) => {
    // If the user's role is 'Global' or 'MGMT', they have all permissions
    if (
      userRole === "Global" ||
      userRole === "MGMT" ||
      userRole === "PropertyAdmin"
    ) {
      return true;
    }

    // Otherwise, check the permissions for the specific property
    const permissionsForProperty =
      userPermissions[propertyCode]?.permissions || {};
    return permissionsForProperty[permission] === true;
  };

  // Ensure available properties are filtered after userPermissions are fully populated
  const propertiesModifyEmployee = useMemo(
    () =>
      availableProperties.filter((prop) => {
        return (
          userPermissions &&
          hasPermissionForProperty("hrModifyEmployee", prop.propertyCode)
        );
      }),
    [availableProperties, userPermissions],
  );

  const propertiesModifyProperty = useMemo(
    () =>
      availableProperties.filter((prop) => {
        return (
          userPermissions &&
          hasPermissionForProperty("hrModifyProperty", prop.propertyCode)
        );
      }),
    [availableProperties, userPermissions],
  );

  useEffect(() => {
    if (!propertyCode || !userPermissions || !userPermissions[propertyCode])
      return;

    const propertyPermissions =
      userPermissions[propertyCode]?.permissions || {};
    const permissionsArray = Object.keys(propertyPermissions).filter(
      (key) => propertyPermissions[key] === true,
    );

    setPermissionsForProperty((prevPermissions) => {
      const permissionsChanged =
        JSON.stringify(prevPermissions) !== JSON.stringify(permissionsArray);
      return permissionsChanged ? permissionsArray : prevPermissions;
    });
  }, [propertyCode, userPermissions]);

  // Function to determine if a user can view a section
  const canViewSection = (permissions = [], propertyCode) => {
    const requiredPermissions = Array.isArray(permissions)
      ? permissions
      : [permissions];

    // Grant access to Global, MGMT, and PropertyAdmin roles
    if (["Global", "MGMT", "PropertyAdmin"].includes(userRole)) return true;

    // Check specific permissions for the property
    return requiredPermissions.every((permission) => {
      const propertyPermissions =
        userPermissions[propertyCode]?.permissions || {};
      return propertyPermissions[permission] === true;
    });
  };

  const handleReset = () => {
    window.location.reload();
  };

  const handleMainSwitch = (event) => {
    setActionType(event.target.checked ? "addNew" : "update");
  };

  const YOUR_FIREBASE_FUNCTION_URL =
    "https://us-central1-doorman-1985.cloudfunctions.net";

  const handleSwitchChange = (event) => {
    setEmployeeStatus(event.target.checked ? "Active" : "Inactive");
  };

  useEffect(() => {
    if (recordData) {
      setOriginalRecordData({ ...recordData });
    }
  }, [recordData]);

  useEffect(() => {
    if (!user) {
      setLoading(false);
      return;
    }

    // Determine which property list to use based on formType
    const propertiesToUse =
      formType === "Employee"
        ? propertiesModifyEmployee
        : propertiesModifyProperty;

    if (!propertiesToUse) {
      setLoading(false);
      return;
    }

    const fetchPropertyCodes = async () => {
      // Filter properties using permissions
      const filteredProperties = propertiesToUse.filter((property) =>
        formType === "Employee"
          ? hasPermissionForProperty("hrModifyEmployee", property.propertyCode)
          : hasPermissionForProperty("hrModifyProperty", property.propertyCode),
      );

      // Extract the property codes from the filtered properties
      const propertyCodes = filteredProperties.map((prop) => prop.propertyCode);

      // Update state with the filtered property codes
      setPropertyCodes([...new Set(propertyCodes)]);

      // Set hasPropertyAccess if there are any valid properties
      setHasPropertyAccess(propertyCodes.length > 0);

      setLoading(false);
    };

    fetchPropertyCodes();
  }, [
    user,
    userPermissions,
    userRole,
    propertiesModifyEmployee,
    propertiesModifyProperty,
    formType,
  ]);

  const filteredEmployees = employees
    .filter((emp) => {
      const isTerminated = emp.Status === "Terminated";
      const isActive =
        employeeStatus === "Active" ? !isTerminated : isTerminated;
      const hasSalary =
        emp.PayD1_Type === "SALARY" || emp.PayD2_Type === "SALARY";
      return isActive && !hasSalary;
    })
    .sort((a, b) => {
      const nameA = `${a.NameFirst} ${a.NameLast}`.toUpperCase();
      const nameB = `${b.NameFirst} ${b.NameLast}`.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

  useEffect(() => {
    if (propertyCode) {
      if (formType === "Property") {
        const fetchProperty = async () => {
          const docRef = doc(db, "Properties", propertyCode);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setRecordData(docSnap.data());
          } else {
            console.log("No such document!");
            setRecordData(null);
          }
        };

        fetchProperty();
      } else if (formType === "Employee") {
        const fetchEmployees = async () => {
          const empRef = collection(db, "empRegister");
          const q = query(empRef, where("PropID", "==", propertyCode));
          const snapshot = await getDocs(q);
          const employees = snapshot.docs.map((doc) => ({
            id: doc.id,
            EmpID: doc.data().EmpID,
            ...doc.data(),
          }));
          setEmployees(employees);

          console.log("Fetched employees:", employees); // Log fetched employees

          if (employees.length === 1) {
            setSelectedEmployee(employees[0].EmpID);
            setRecordData(employees[0]);
          } else if (selectedEmployee) {
            const selectedEmp = employees.find(
              (emp) => emp.EmpID === selectedEmployee,
            );
            setRecordData(selectedEmp || null);
          } else {
            setSelectedEmployee("");
            setRecordData(null);
          }
          setChanges({});
        };

        fetchEmployees();
      }
    }
  }, [formType, propertyCode]);

  useEffect(() => {
    if (selectedEmployee && employees.length > 0) {
      const selectedEmp = employees.find(
        (emp) => emp.EmpID === selectedEmployee,
      );
      console.log("Selected Employee Data:", selectedEmp); // Log selected employee data
      setRecordData(selectedEmp || {});
    }
  }, [selectedEmployee, employees]);

  useEffect(() => {
    if (selectedEmployee) {
      const fetchSubmittedCounts = async () => {
        try {
          console.log(
            "Fetching submitted counts for employee:",
            selectedEmployee,
          );
          const empDocRef = query(
            collection(db, "empRegister"),
            where("EmpID", "==", selectedEmployee),
          );
          const empDocSnapshot = await getDocs(empDocRef);

          if (!empDocSnapshot.empty) {
            const data = empDocSnapshot.docs[0].data();
            console.log("Employee document data:", data);

            const payrollFields = ["ID", "SSN", "W4-IRS", "W4-LOCAL"];
            const payrollCount = payrollFields.filter(
              (field) => data[field] && data[field] === "SUBMITTED",
            ).length;
            const bankingCount =
              data["BANKING"] && data["BANKING"] === "SUBMITTED" ? 1 : 0;

            console.log("Payroll count:", payrollCount);
            console.log("Banking count:", bankingCount);

            setSubmittedCounts({
              payroll: payrollCount,
              banking: bankingCount,
            });
          } else {
            console.log("No such employee document exists!");
            setSubmittedCounts({ payroll: 0, banking: 0 });
          }
        } catch (error) {
          console.error("Error fetching submitted counts:", error);
          setSubmittedCounts({ payroll: 0, banking: 0 });
        }
      };

      fetchSubmittedCounts();
    }
  }, [selectedEmployee]);
  // Fetch files when recordData changes
  useEffect(() => {
    const fetchFiles = async () => {
      if (
        !recordData ||
        !recordData.NameFirst ||
        !recordData.NameLast ||
        !recordData.EmpID
      ) {
        setEmployeeFiles([]); // Clear files if recordData is invalid
        return;
      }

      // Construct the folder name, including the middle name if it exists
      const folderName = `${recordData.EmpID}`;

      const mainFolderId = "1Bn8FFDHh_1Wd5TPIsq4LdXfqPeaNkw3D"; // Main folder ID

      try {
        const response = await fetch(
          `https://us-central1-doorman-1985.cloudfunctions.net/listFiles?folderName=${encodeURIComponent(
            folderName,
          )}&mainFolderId=${mainFolderId}`,
        );
        if (response.ok) {
          const data = await response.json();
          if (data.files && data.files.length > 0) {
            setEmployeeFiles(data.files);
          } else {
            setEmployeeFiles([]); // Set to empty array if no files are found
          }
        } else {
          console.error("Failed to fetch files:", response.statusText);
          setEmployeeFiles([]); // Clear files on error
        }
      } catch (error) {
        console.error("Error fetching files:", error);
        setEmployeeFiles([]); // Clear files on exception
      }
    };

    fetchFiles();
  }, [recordData, reloadTrigger]);

  const approveFile = async (file) => {
    try {
      const empDocRef = doc(db, "empRegister", selectedEmployee);
      const empDoc = await getDoc(empDocRef);

      if (empDoc.exists()) {
        const changes = {};
        changes[file] = "APPROVED";

        await updateDoc(empDocRef, {
          ...changes,
          Notes: arrayUnion(
            `${new Date().toLocaleDateString()} - ${file} document approved - ${
              user.email || "Unknown User"
            }`,
          ),
        });

        // Fetch the updated document
        const updatedDoc = await getDoc(empDocRef);

        // Update the state with the new document data
        setRecordData(updatedDoc.data());

        setConfirmApproveDocDialogOpen(false); // Close the dialog
      }
    } catch (error) {
      console.error("Error approving file:", error);
    }
  };

  const handleApproveClick = (file) => {
    setFileToApprove(file);
    setConfirmApproveDocDialogOpen(true);
  };

  const handleApproveDialogClose = (confirmed) => {
    if (confirmed) {
      approveFile(fileToApprove);
    } else {
      setConfirmApproveDocDialogOpen(false);
    }
  };

  const rejectFile = async (file) => {
    try {
      const empDocRef = doc(db, "empRegister", selectedEmployee);
      const empDoc = await getDoc(empDocRef);

      if (empDoc.exists()) {
        const changes = {};
        changes[file] = "REJECTED";

        // Update the Firestore document
        await updateDoc(empDocRef, {
          ...changes,
          Notes: arrayUnion(
            `${new Date().toLocaleDateString()} - ${file} document rejected - ${
              user.email || "Unknown User"
            }: ${rejectReason}`,
          ),
        });

        // Translate the rejectReason to Spanish
        const translationResponse = await axios.post(
          "https://translation.googleapis.com/language/translate/v2",
          {},
          {
            params: {
              q: rejectReason,
              target: "es",
              key: "AIzaSyBcMBhYY8c84uVwJHN42gcvY3a5eaM0VBE",
            },
          },
        );

        const translatedRejectReason =
          translationResponse.data.data.translations[0].translatedText;

        // Send axios HTTP request with the translated reason
        await axios.post(
          "https://prod-29.westus.logic.azure.com:443/workflows/2134e5ab1d5f45d69ef0e7e5622ab0ce/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=f41w_dH6Hwli7mWqaRWXgZNvvLdTIBNw3aFvlbPuJcs",
          {
            category: file,
            employeeName: `${recordData.NameFirst} ${recordData.NameLast}`,
            employeeId: recordData.EmpID,
            isoDate: new Date().toISOString(),
            employeePhone: recordData.AdPhone,
            rejectReason: rejectReason,
            translatedRejectReason: translatedRejectReason, // Include the translated reason
          },
        );

        // Fetch the updated document
        const updatedDoc = await getDoc(empDocRef);

        // Update the state with the new document data
        setRecordData(updatedDoc.data());

        setConfirmRejectDocDialogOpen(false); // Close the dialog
        setRejectReason(""); // Reset the reject reason
      }
    } catch (error) {
      console.error("Error rejecting file:", error);
    }
  };

  const handleRejectClick = (file) => {
    setFileToReject(file);
    setConfirmRejectDocDialogOpen(true);
  };

  const handleRejectDialogClose = (confirmed) => {
    if (confirmed) {
      rejectFile(fileToReject);
    } else {
      setConfirmRejectDocDialogOpen(false);
      setRejectReason(""); // Reset the reject reason
    }
  };

  const renderApprovalButtons = (category, files) => {
    return (
      <Grid
        sx={{
          marginLeft: "1rem",
          backgroundColor: "#fcfcfc",
        }}
        container
        spacing={2}
      >
        {files
          .filter((file) => recordData[file] === "SUBMITTED")
          .map((file) => (
            <Grid item xs={12} sm={6} lg={3} key={file}>
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: "f9f9f9",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "72%",
                  border: "1px solid lightgrey",
                  borderRadius: 9,
                  marginBottom: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: "1.8rem",
                    textAlign: "center",
                  }}
                >
                  <IconButton
                    style={{
                      border: "0",
                      color: "#c7d57f",
                      fontSize: "1.17rem",
                      padding: "0.9rem",
                    }}
                    onClick={() => handleApproveClick(file)}
                  >
                    <BsPatchCheckFill />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: "1.8rem",
                    textAlign: "center",
                  }}
                >
                  <IconButton
                    style={{
                      border: "0",
                      color: "#f98b85",
                      fontSize: "1.17rem",
                      padding: "0.9rem",
                    }}
                    onClick={() => handleRejectClick(file)}
                  >
                    <BsPatchMinusFill />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    justifyContent: "center",
                    minWidth: "3.9rem",
                    textAlign: "left",
                    paddingLeft: "0.54rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 800,
                      fontSize: "0.81rem",
                    }}
                    variant="body2"
                  >
                    {file}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      wordBreak: "break-word",
                      fontFamily: "Outfit",
                      fontWeight: 400,
                      color: "#202426",
                      letterSpacing: "0.027rem",
                      textTransform: "uppercase",
                    }}
                  >
                    {"needs approval"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
      </Grid>
    );
  };

  const subtractOneDay = (dateStr) => {
    // Parse the date string to get a Date object
    const [month, day, year] = dateStr.split("/").map(Number);
    const date = new Date(year, month - 1, day);

    // Subtract one day
    date.setDate(date.getDate() - 1);

    // Format the new date back to MM/dd/yyyy
    const newMonth = String(date.getMonth() + 1).padStart(2, "0");
    const newDay = String(date.getDate()).padStart(2, "0");
    const newYear = date.getFullYear();

    return `${newMonth}/${newDay}/${newYear}`;
  };

  const SubmissionDialog = ({ open, onClose, isSubmitting }) => (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          ...dialogMain,
        },
      }}
    >
      <DialogContent>
        {isSubmitting ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              backgroundColor: "#f9f9f9",
            }}
          >
            <CircularProgress style={{ color: "#202426" }} />{" "}
          </div>
        ) : (
          <p
            style={{
              textAlign: "center",
              color: "#202426",
              fontSize: "1rem",
              fontWeight: "800",
              letterSpacing: "0.1rem",
            }}
          >
            <CircularProgress style={{ color: "#202426" }} />{" "}
          </p>
        )}
      </DialogContent>
    </Dialog>
  );

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handlePropertyCodeChange = (event) => {
    const newPropertyCode = event.target.value;
    setPropertyCode(newPropertyCode);

    // Reset state variables that depend on propertyCode
    setRolesData([]);
    setAvailableRoles([]);
    setIsGlobal(false);
    setLoading(true);
    // Reset any other state variables as needed
  };

  const handleEmployeeChange = (event) => {
    const selectedEmpID = event.target.value;
    setSelectedEmployee(selectedEmpID);
    const selected = employees.find((emp) => emp.EmpID === selectedEmpID);
    console.log("Selected employee in handleEmployeeChange:", selected); // Add log
    setRecordData(selected || {});
    setChanges({});
  };

  const handleFieldChange = (
    name,
    value,
    isCheckbox = false,
    checkboxType = null,
  ) => {
    if (isCheckbox) {
      // Ensure emailFields[name] is initialized before accessing it
      setEmailFields((prevFields) => ({
        ...prevFields,
        [name]: {
          ...prevFields[name], // Initialize or preserve existing properties
          [checkboxType]: value, // Update the specific checkbox type (e.g., admin, viewPay, etc.)
        },
      }));

      // Track changes to email and claims in the `changes` state
      setChanges((prevChanges) => ({
        ...prevChanges,
        [name]: {
          oldValue: originalRecordData?.[name] || "N/A",
          newValue: value
            ? emailFields[name]?.email || "" // Safely access emailFields[name].email
            : "", // If checkbox is unchecked, set newValue to empty string
          fieldName: name,
        },
      }));
    } else {
      if (originalRecordData) {
        const oldValue = originalRecordData[name] || "N/A";

        // Only track the change if the new value is different from the old one
        if (value !== oldValue) {
          setChanges((prevChanges) => ({
            ...prevChanges,
            [name]: {
              oldValue, // Use the original value from originalRecordData
              newValue: value,
              fieldName: name,
            },
          }));

          // Special case for PayD1_Rate and PayD2_Rate to track changes in PRR
          if (name === "PayD1_Rate" || name === "PayD2_Rate") {
            const currentDate = new Date().toLocaleDateString();
            setChanges((prevChanges) => ({
              ...prevChanges,
              PRR: {
                oldValue: originalRecordData.PRR || "N/A",
                newValue: currentDate,
                fieldName: "PRR",
              },
            }));
            setRecordData((prevData) => ({ ...prevData, PRR: currentDate }));
          }
        } else {
          // If the value is reverted to the original, remove it from `changes`
          setChanges((prevChanges) => {
            const newChanges = { ...prevChanges };
            delete newChanges[name];
            return newChanges;
          });
        }
      }
      setRecordData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleDialogClose = async (confirm) => {
    if (confirm) {
      setIsSubmitting(true); // Start the submission process
      try {
        await saveChangesToDatabase();
      } catch (error) {
        console.error("Error during save changes process:", error);
        alert(`Failed to save changes: ${error.message}`);
      } finally {
        setIsSubmitting(false); // End the submission process
        setDialogOpen(false);
      }
    } else {
      setDialogOpen(false);
    }
  };

  const saveChangesToDatabase = async () => {
    if (!recordData) return;

    console.log("Saving changes to database:", changes);

    const docRef = doc(
      db,
      formType === "Property" ? "Properties" : "empRegister",
      formType === "Property" ? propertyCode : selectedEmployee,
    );

    // Generate the note entry for the database
    const changeEntries = Object.values(changes)
      .filter(({ fieldName }) => !["newNote"].includes(fieldName))
      .map(
        ({ oldValue, newValue, fieldName }) =>
          `${fieldName}: ${oldValue} -> ${newValue}`,
      );
    const dateStr = new Date().toLocaleDateString();
    const noteEntry = `${dateStr} - ${changeEntries.join(", ")} by ${
      user.email || "Unknown User"
    }`;

    // Define updatedChanges with the note included
    let updatedChanges = {
      ...Object.keys(changes).reduce((acc, key) => {
        acc[key] = changes[key].newValue;
        return acc;
      }, {}),
      Notes: [...(recordData.Notes || []), noteEntry],
      DateUpdated: new Date().toISOString(),
    };

    await updateDoc(docRef, updatedChanges);

    if (formType === "Employee") {
      const payrollChangesRef = collection(db, "payrollChanges");

      // Filter out changes that should not be written to payrollChanges
      const filteredChangeEntries = Object.values(changes)
        .filter(({ fieldName }) => !["PRR", "newNote"].includes(fieldName))
        .map(
          ({ oldValue, newValue, fieldName }) =>
            `${fieldName}: ${oldValue} -> ${newValue}`,
        );

      if (filteredChangeEntries.length > 0) {
        const payPeriodEndDate =
          selectedPayPeriod === "current" ? payPeriodEnd : previousPayPeriodEnd;

        await addDoc(payrollChangesRef, {
          EmpID: recordData.EmpID,
          NameFirst: recordData.NameFirst,
          NameLast: recordData.NameLast,
          changes: filteredChangeEntries,
          payPeriod: subtractOneDay(payPeriodEndDate?.toLocaleDateString()),
          ChangedBy: user.email,
          propCode: propertyCode,
        });
      }
    }

    if (changes.hasOwnProperty("AdPhone")) {
      setNewPhoneNumber(changes.AdPhone.newValue);
      setPhoneDialogOpen(true);
    } else {
      await updateDoc(docRef, updatedChanges);
      alert("Changes saved!");
      setChanges({});
      setDialogOpen(false);
      setEmailClaimsChanged(false); // Reset email claims changed flag
    }
  };

  const handlePhoneDialogClose = async (confirm) => {
    if (confirm) {
      await handlePhoneChange(newPhoneNumber);
    }
    setPhoneDialogOpen(false);
  };

  // Function to handle phone number changes
  const handlePhoneChange = async (newPhoneNumber) => {
    setIsSubmitting(true);

    const oldPhoneNumber = recordData.AdPhone;
    const currentDate = new Date().toISOString();
    const empID = selectedEmployee; // Assuming `selectedEmployee` is the ID of the current employee record

    try {
      // Update the current employee record with the new phone number
      const empDocRef = doc(db, "empRegister", empID);
      await updateDoc(empDocRef, {
        AdPhone: newPhoneNumber,
        AdEmail: "", // Set AdEmail to blank strings
      });

      // Update phoneAuthUsers collection
      const oldUserDocRef = doc(db, "phoneAuthUsers", oldPhoneNumber);
      const oldUserDoc = await getDoc(oldUserDocRef);
      if (oldUserDoc.exists()) {
        const userData = oldUserDoc.data();
        const updatedData = {
          ...userData,
          phoneNumber: newPhoneNumber,
          phoneChangeDate: currentDate, // Record the date of the change
        };

        await deleteDoc(oldUserDocRef);

        // Send an axios post request with the necessary information
        const apiGatewayUrl =
          "https://prod-55.westus.logic.azure.com:443/workflows/bb84288c70994588a9c17e1ca12f6cd6/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=UqBgANaK9RdtLIRKmhzOZiicFF-A56rXvCqyYDu39zY";
        await axios.post(apiGatewayUrl, {
          oldPhoneNumber: oldPhoneNumber,
          newPhoneNumber: newPhoneNumber,
          empIDs: [empID], // Assuming `empID` is the only ID we need to update
          phoneChangeDate: updatedData.phoneChangeDate,
        });

        // Add notes to the empRegister collection for the current employee
        const empDoc = await getDoc(empDocRef);
        if (empDoc.exists()) {
          const empData = empDoc.data();
          const notes = empData.Notes || [];
          notes.push(
            `User phone number change on ${currentDate}: ${oldPhoneNumber} > ${newPhoneNumber}`,
          );

          await updateDoc(empDocRef, { Notes: notes });
        }
      }

      // Inform the user
      alert("Phone number updated successfully!");
      setChanges({});
      setDialogOpen(false);
    } catch (error) {
      console.error("Error updating phone number:", error);
      alert(
        "An error occurred while updating the phone number. Please try again.",
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (!user || !user.email || !propertyCode) return;

    const fetchPropertiesAndPermissions = async () => {
      try {
        // Fetch property document
        const propertiesDoc = await getDoc(doc(db, "Properties", propertyCode));
        if (!propertiesDoc.exists()) {
          console.error(`Property with code ${propertyCode} does not exist.`);
          return;
        }

        const { payPeriod, payCycle } = propertiesDoc.data();
        const currentDate = new Date();
        let payPeriodStartDate = new Date(payPeriod.seconds * 1000);
        let payPeriodEndDate;
        let prevPayPeriodStartDate;
        let prevPayPeriodEndDate;

        // Calculate Pay Periods
        const calculatePayPeriod = (start, interval) => {
          const end = new Date(start);
          end.setDate(start.getDate() + interval);
          return [start, end];
        };

        switch (payCycle) {
          case "Monthly":
            while (payPeriodStartDate < currentDate) {
              [payPeriodStartDate, payPeriodEndDate] = calculatePayPeriod(
                payPeriodStartDate,
                30,
              );
              if (payPeriodEndDate > currentDate) break;
              prevPayPeriodStartDate = payPeriodStartDate;
              prevPayPeriodEndDate = payPeriodEndDate;
              payPeriodStartDate = new Date(payPeriodEndDate);
            }
            break;
          case "Bi-Weekly":
            while (payPeriodStartDate < currentDate) {
              [payPeriodStartDate, payPeriodEndDate] = calculatePayPeriod(
                payPeriodStartDate,
                14,
              );
              if (payPeriodEndDate > currentDate) break;
              prevPayPeriodStartDate = payPeriodStartDate;
              prevPayPeriodEndDate = payPeriodEndDate;
              payPeriodStartDate = new Date(payPeriodEndDate);
            }
            break;
          case "Weekly":
            while (payPeriodStartDate < currentDate) {
              [payPeriodStartDate, payPeriodEndDate] = calculatePayPeriod(
                payPeriodStartDate,
                7,
              );
              if (payPeriodEndDate > currentDate) break;
              prevPayPeriodStartDate = payPeriodStartDate;
              prevPayPeriodEndDate = payPeriodEndDate;
              payPeriodStartDate = new Date(payPeriodEndDate);
            }
            break;
          default:
            console.error(`Unsupported pay cycle: ${payCycle}`);
            return;
        }

        // Set Pay Period Dates
        setPayPeriodStart(payPeriodStartDate);
        setPayPeriodEnd(payPeriodEndDate);
        setPreviousPayPeriodStart(prevPayPeriodStartDate);
        setPreviousPayPeriodEnd(prevPayPeriodEndDate);
      } catch (error) {
        console.error(
          "Error fetching properties and calculating pay periods:",
          error,
        );
      }
    };

    console.log(payPeriodEnd);
    fetchPropertiesAndPermissions();
  }, [propertyCode, user]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFile(file);
    } else {
      setFileName("");
      setFile(null);
    }
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((c) => c !== category)
        : [...prevCategories, category],
    );
  };

  const handleFileUpload = async () => {
    const empDocRef = doc(db, "empRegister", selectedEmployee);
    if (!file || selectedCategories.length === 0) return;

    setIsSubmitting(true);
    const fileExtension = file.name.split(".").pop();
    const now = new Date();
    const formattedDate = `${String(now.getMonth() + 1).padStart(
      2,
      "0",
    )}_${String(now.getDate()).padStart(2, "0")}_${now.getFullYear()}`;

    const getCategoryPrefix = (categories) => {
      const priority = [
        "BANKING",
        "W4-IRS",
        "W4-LOCAL",
        "ID",
        "SSN",
        "OTHER",
        "CERTIFICATE",
        "WRITE-UP",
      ];
      for (const prefix of priority) {
        if (categories.includes(prefix)) return prefix;
      }
      return categories[0];
    };

    const categoryPrefix = getCategoryPrefix(selectedCategories);
    const otherCategories = selectedCategories.filter(
      (category) => category !== categoryPrefix,
    );

    const newFileName = `${categoryPrefix}_${otherCategories.join(
      "_",
    )}_${formattedDate}.${fileExtension}`;

    const folderName = `${recordData.EmpID}`;
    const mainFolderId = "1Bn8FFDHh_1Wd5TPIsq4LdXfqPeaNkw3D"; // Main folder ID

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", newFileName);
    formData.append("EmpID", recordData.EmpID);
    formData.append("PropID", propertyCode);
    formData.append("folderName", folderName); // Append the folder name to the form data
    formData.append("mainFolderId", mainFolderId); // Append the main folder ID to the form data

    try {
      const response = await fetch(
        "https://us-central1-doorman-1985.cloudfunctions.net/uploadFile",
        {
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        },
      );

      await updateDoc(empDocRef, {
        ...changes,
        Notes: arrayUnion(
          `${new Date().toLocaleDateString()} - Uploaded file "${newFileName} - ${user.email || "Unknown User"}`,
        ),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("File uploaded successfully!", data);
        const payrollChangesRef = collection(db, "payrollChanges");

        await addDoc(payrollChangesRef, {
          EmpID: recordData.EmpID,
          NameFirst: recordData.NameFirst,
          NameLast: recordData.NameLast,
          changes: [`File Uploaded: ${newFileName}`],
          payPeriod: subtractOneDay(payPeriodEnd?.toLocaleDateString()),
          ChangedBy: user.email,
          propCode: propertyCode,
        });

        // Update the employee document for each relevant category
        const empDocRef = doc(db, "empRegister", recordData.EmpID);
        const updateData = selectedCategories.reduce((acc, category) => {
          if (
            ["W4-IRS", "W4-LOCAL", "ID", "SSN", "BANKING"].includes(category)
          ) {
            acc[category] = "SUBMITTED";
          }
          return acc;
        }, {});

        if (Object.keys(updateData).length > 0) {
          await updateDoc(empDocRef, {
            ...updateData,
            clearedBy: [],
            DateUpdated: new Date().toISOString(), // Add this line to set DateUpdated
          });
        }

        alert("File uploaded successfully!");
        setFile(null);
        setSelectedCategories([]);
        setReloadTrigger((prev) => prev + 1);
      } else {
        console.error("File upload failed:", response.statusText);
        alert(
          `File upload failed: ${response.statusText || "An error occurred."}`,
        );
      }
    } catch (error) {
      console.error("Error uploading file:", error.message);
      alert(`Error uploading file: ${error.message || "An error occurred."}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddNote = async () => {
    if (!newNote) return;

    const dateStr = new Date().toLocaleDateString();
    const noteEntry = `${dateStr} - ${newNote} by ${
      user.email || "Unknown User"
    }`;

    const updatedNotes = [...(recordData.Notes || []), noteEntry];

    const docRef = doc(db, "empRegister", selectedEmployee);
    await updateDoc(docRef, { Notes: updatedNotes });

    setRecordData((prevData) => ({
      ...prevData,
      Notes: updatedNotes,
    }));
    setNewNote("");
    alert("Note added successfully!");
  };

  const categorizeFiles = (files) => {
    const categories = {
      PAYROLL: [],
      BANKING: [],
      OTHER: [],
    };

    files.forEach((file) => {
      const fileName = file.name.toUpperCase();
      if (
        fileName.startsWith("W4-IRS") ||
        fileName.startsWith("W4-LOCAL") ||
        fileName.startsWith("ID") ||
        fileName.startsWith("SSN")
      ) {
        categories.PAYROLL.push(file);
      } else if (fileName.startsWith("BANKING")) {
        categories.BANKING.push(file);
      } else if (
        fileName.startsWith("OTHER") ||
        fileName.startsWith("CERTIFICATE") ||
        fileName.startsWith("WRITE")
      ) {
        categories.OTHER.push(file);
      } else {
        categories.OTHER.push(file); // Default to OTHER
      }
    });

    return categories;
  };

  const FilingCabinet = ({ employeeFiles, user }) => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const categories = categorizeFiles(employeeFiles);

    return (
      <Grid container spacing={2} paddingTop={9} paddingLeft={2}>
        <Grid
          item
          xs={12}
          container
          sx={{
            padding: "1rem",
            borderRadius: "36px",
            backgroundColor: "#f5f5f5",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              marginLeft: 1,
              letterSpacing: "0.3rem",
              fontWeight: "200",
              fontSize: "0.9rem",
              color: "#202426",
              paddingBottom: "1rem",
              paddingTop: "1rem",
            }}
          >
            FILING CABINET
          </Typography>

          <Grid container spacing={12}>
            <Grid item xs={12}>
              <Card sx={{ borderRadius: "18px" }}>
                <Box
                  sx={{
                    padding: "1rem",
                    backgroundColor: "#fcfcfc",
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="#93A683"
                    textColor="inherit"
                    sx={{
                      "& .MuiTabs-indicator": {
                        backgroundColor: "#93A683",
                      },
                      "& .MuiTab-root": {
                        fontWeight: 800,
                        fontSize: "0.81rem",
                      },
                    }}
                  >
                    {/* Conditionally render PAYROLL tab based on hrViewPayRate permission */}
                    {canViewSection(["hrViewPayrollFiles"], propertyCode) && (
                      <Tab
                        label={
                          <Badge
                            badgeContent={submittedCounts.payroll}
                            color="error"
                            overlap="rectangular"
                            sx={{
                              fontFamily: "Outfit",
                              fontWeight: 800,
                              color: "#202426",
                              letterSpacing: "0.027rem",
                              textTransform: "uppercase",
                            }}
                          >
                            PAYROLL
                          </Badge>
                        }
                      />
                    )}

                    {/* Conditionally render BANKING tab based on hrViewBanking permission */}
                    {canViewSection(["hrViewBankingFiles"], propertyCode) && (
                      <Tab
                        label={
                          <Badge
                            badgeContent={submittedCounts.banking}
                            color="error"
                            overlap="rectangular"
                            sx={{
                              fontFamily: "Outfit",
                              fontWeight: 800,
                              color: "#202426",
                              letterSpacing: "0.027rem",
                              textTransform: "uppercase",
                            }}
                          >
                            BANKING
                          </Badge>
                        }
                      />
                    )}

                    {/* OTHER tab */}
                    {canViewSection(["hrViewOtherFiles"], propertyCode) && (
                      <Tab
                        label="OTHER"
                        sx={{
                          fontFamily: "Outfit",
                          fontWeight: 800,
                          color: "#202426",
                          letterSpacing: "0.027rem",
                          textTransform: "uppercase",
                        }}
                      />
                    )}
                  </Tabs>
                </Box>

                <Box height={16} />
                {value === 0 &&
                  canViewSection(["hrViewPayrollFiles"], propertyCode) && (
                    <Box backgroundColor="#fcfcfc">
                      {submittedCounts.payroll > 0 && (
                        <Box>
                          {renderApprovalButtons("PAYROLL", [
                            "W4-IRS",
                            "W4-LOCAL",
                            "ID",
                            "SSN",
                          ])}
                        </Box>
                      )}
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        backgroundColor="#fcfcfc"
                        padding={3}
                      >
                        <FileList files={categories.PAYROLL} />
                      </Grid>
                    </Box>
                  )}
                {value === 1 &&
                  canViewSection(["hrViewBankingFiles"], propertyCode) && (
                    <Box>
                      {submittedCounts.banking > 0 && (
                        <Box>
                          {renderApprovalButtons("BANKING", ["BANKING"])}
                        </Box>
                      )}
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        backgroundColor="#fcfcfc"
                        padding={3}
                      >
                        <FileList files={categories.BANKING} />
                      </Grid>
                    </Box>
                  )}
                {value === 2 &&
                  canViewSection(["hrViewOtherFiles"], propertyCode) && (
                    <Box>
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        backgroundColor="#fcfcfc"
                        padding={3}
                      >
                        <FileList files={categories.OTHER} />
                      </Grid>
                    </Box>
                  )}
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <SubmissionDialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
            setReloadTrigger((prev) => prev + 1);
          }}
          isSubmitting={isSubmitting}
        />
      </Grid>
    );
  };

  const FileList = ({ files }) => {
    const MAX_FILENAME_LENGTH = 36;

    const handleTruncateFilename = (filename) => {
      if (filename.length > MAX_FILENAME_LENGTH) {
        return `${filename.slice(0, MAX_FILENAME_LENGTH)}...`;
      }
      return filename;
    };

    const getFileIcon = (fileName) => {
      const ext = fileName.split(".").pop().toLowerCase();
      const iconStyle = { marginRight: "0.72rem", fontSize: "1.53rem" };

      if (ext === "pdf")
        return <FaFilePdf style={{ ...iconStyle, color: "#FF0000" }} />;
      if (["jpg", "jpeg", "png"].includes(ext))
        return <FaFileImage style={{ ...iconStyle, color: "#1976d2" }} />;
      return null;
    };

    return (
      <Box sx={{ width: "100%" }}>
        {files.length === 0 ? (
          <Typography
            sx={{
              fontSize: "0.9rem",
              fontWeight: 800,
              color: "#202426",
              paddingLeft: 3,
              paddingBottom: 4.5,
            }}
          >
            No files found.
          </Typography>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "72%",
              justifyContent: "center",
              alignItems: "left",
            }}
          >
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {files.map((file) => (
                <Box
                  key={file.id}
                  sx={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "9px",
                    margin: "0.45rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0.5rem",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}
                  >
                    <Tooltip title={file.name} placement="top">
                      <Button
                        onClick={async (e) => {
                          setOpenDialog(true);
                          e.preventDefault();
                          try {
                            const response = await fetch(
                              `${YOUR_FIREBASE_FUNCTION_URL}/getSignedUrl?fileId=${file.id}`,
                            );
                            const textResponse = await response.text();
                            console.log("Raw response:", textResponse);

                            if (!response.ok) {
                              throw new Error("Failed to get signed URL");
                            }

                            const data = JSON.parse(textResponse);
                            console.log("Signed URL data:", data);

                            if (!data.signedUrl) {
                              throw new Error(
                                "Invalid response from signed URL API",
                              );
                            }

                            window.open(data.signedUrl, "_blank");
                            setOpenDialog(false);
                          } catch (error) {
                            console.error(
                              "Error fetching signed URL:",
                              error.message,
                            );
                          }
                        }}
                        sx={{
                          fontFamily: "Outfit",
                          fontSize: "0.9rem",
                          fontWeight: 400,
                          color: "#202426",
                          letterSpacing: "0.027rem",
                          textTransform: "lowercase",
                          textAlign: "left",
                        }}
                      >
                        {getFileIcon(file.name)}
                        {handleTruncateFilename(file.name)}
                      </Button>
                    </Tooltip>
                  </Box>
                  {canViewSection(["deleteFiles"], propertyCode) && (
                    <Button
                      onClick={() => handleDeleteFile(file.id, file.name)}
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: "1.44rem",
                        fontWeight: 800,
                        color: "#BB2E33",
                        letterSpacing: "0.027rem",
                        textTransform: "lowercase",
                      }}
                    >
                      <IoTrashBinOutline />
                    </Button>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const handleDeleteFile = async (fileId, fileName) => {
    const empDocRef = doc(db, "empRegister", selectedEmployee);

    if (!fileId) {
      alert("File ID is required.");
      return;
    }

    try {
      const response = await fetch(
        `https://us-central1-doorman-1985.cloudfunctions.net/deleteFile?fileId=${fileId}`,
        {
          method: "DELETE", // Use DELETE to align with the operation
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          alert(data.message);
          setReloadTrigger((prev) => prev + 1); // Trigger reload of the file list
          await updateDoc(empDocRef, {
            ...changes,
            Notes: arrayUnion(
              `${new Date().toLocaleDateString()} - Deleted file "${fileName} - ${user.email || "Unknown User"}`,
            ),
          });
        } else {
          console.error("File deletion failed:", data.message);
          alert(`Error: ${data.message}`);
        }
      } else {
        console.error("Failed to delete file:", response.statusText);
        alert(`Failed to delete file: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error deleting file:", error.message);
      alert(`Failed to delete file: ${error.message}`);
    }
  };

  const getSectionForField = (field) => {
    const personalSection = [
      ...["NameFirst", "NameMid", "NameLast", "SS4"],
      "DOB",
      "EmpID",
      "Ad1",
      "AdCity",
      "AdState",
      "AdZip",
      "AdPhone",
      "Email",
    ];

    const sections = {
      PERSONAL: personalSection,
      "PAY D1": ["PayD1", "PayD1_Type", "PayD1_Rate", "PR2", "PR3"],
      "PAY D2": ["PayD2", "PayD2_Type", "PayD2_Rate"],
      OTHER: ["DateHired", "timeclock", "PRR", "Notes"],
      "PROPERTY INFO": [
        "propCode",
        "propertyName",
        "rms1",
        "rms2",
        "rms3",
        "rmsMTG",
        "rmsTotal",
        "propNewBuildDate",
        "propRenovate",
      ],
      "ADMIN DETAILS": [
        "Manager",
        "ManagerEmail",
        "AGM",
        "AGMEmail",
        "OtherAdmin",
        "OtherAdminEmail",
      ],
    };

    for (const [section, fields] of Object.entries(sections)) {
      if (fields.includes(field)) {
        return section;
      }
    }
    return "OTHER";
  };

  const filteredDepartments = usedDepartments.filter(
    (dept) => dept !== "N/A" && dept !== "MANAGEMENT",
  );

  const permissionsConfig = [
    {
      section: "MENU / NAV",
      permissions: [
        { name: "HR", key: "HR", description: "" },
        { name: "APPLICATIONS", key: "APPS", description: "" },
        { name: "PAYROLL PUNCHES", key: "PUNCHES", description: "" },
        { name: "PAYROLL SUMMARY", key: "SUMMARY", description: "" },
        { name: "EMPLOYEE STUBS", key: "STUBS", description: "" },
        { name: "EMPLOYEE ROSTER", key: "ROSTER", description: "" },
        { name: "BREAK ROOM", key: "BREAKRM", description: "" },
      ],
    },
    {
      section: "LANDING PAGE",
      permissions: [
        {
          name: "See Payroll / HR Notifications",
          key: "landPayroll",
          description: "",
        },
        {
          name: "Acknowledge Changes",
          key: "landAcknowledgeChange",
          description: "",
        },
      ],
    },
    {
      section: "HR PERMISSIONS",
      permissions: [
        { name: "Add Employee", key: "hrAddEmployee", description: "" },
        { name: "Modify Employee", key: "hrModifyEmployee", description: "" },
        {
          name: "Terminate Employee",
          key: "hrTerminateEmployee",
          description: "",
        },
        { name: "Change Pay Rate", key: "hrChangePayRate", description: "" },
        {
          name: "Upload Files",
          key: "hrUploadFiles",
          description: "upload files to employee filing cabinet",
        },
        {
          name: "View Other Files",
          key: "hrViewOtherFiles",
          description: "view other files in employee filing cabinet",
        },
        {
          name: "Add Employee Notes",
          key: "hrAddEmployeeNotes",
          description: "",
        },
        { name: "Add Departments", key: "addDept", description: "" },
      ],
    },
    {
      section: "PAYROLL PERMISSIONS",
      permissions: [
        { name: "Add Punch", key: "payrollAddPunch", description: "" },
        { name: "Modify Punch", key: "payrollModifyPunch", description: "" },
        {
          name: "Re-Open Timecard",
          key: "payrollReopenTimecard",
          description: "",
        },
        { name: "Delete Punch", key: "payrollDeletePunch", description: "" },
        {
          name: "Close Employee Timecard",
          key: "payrollCloseTimecard",
          description: "Close individual timecards",
        },
        {
          name: "Close Pay Period",
          key: "payrollClosePayPeriods",
          description: "",
        },
        { name: "View Roster", key: "payrollRoster", description: "" },
        {
          name: "View Summary Timecards",
          key: "summaryTimecards",
          description:
            "View staff timecards in summary view (per dept restrictions)",
        },
        {
          name: "Modify Rooms Cleaned",
          key: "payrollModifyRooms",
          description: "",
        },
      ],
    },
    {
      section: "DEPARTMENT RESTRICTIONS",
      permissions: [
        ...filteredDepartments.map((dept) => ({
          name: dept,
          key: dept,
          description: `Allow department - ${dept}`,
        })),
      ],
    },
    ...(userRole === "Global" ||
    userRole === "MGMT" ||
    userRole === "PropertyAdmin"
      ? [
          {
            section: "ADMIN PERMISSIONS",
            permissions: [
              {
                name: "Add/Remove Admin Users",
                key: "adminAdminUser",
                description: "add or remove other property level admin users",
              },
              {
                name: "Delegate Admin Permissions",
                key: "adminDelegatePermission",
                description: "grant permissions to other admin users",
              },
              {
                name: "Add Admin User Roles",
                key: "adminUserRoles",
                description: "add new property level roles",
              },
              {
                name: "View Payroll Stubs",
                key: "payrollViewStubs",
                description: "",
              },
              {
                name: "View Payroll Summary",
                key: "payrollViewSummary",
                description: "",
              },
              {
                name: "Upload Payroll Stubs",
                key: "payrollUploadStubs",
                description: "***should not*** be a property level permission",
              },
              {
                name: "Set PayrollID",
                key: "payrollID",
                description:
                  "For Accounting / Stubs purposes, do not assign to hotel staff",
              },
              {
                name: "View Banking Files",
                key: "hrViewBankingFiles",
                description:
                  "view *sensitive* banking files in employee filing cabinet",
              },
              {
                name: "View Payroll Files",
                key: "hrViewPayrollFiles",
                description: "view HR files in employee filing cabinet",
              },
              {
                name: "Delete Filing Cabinet Files",
                key: "deleteFiles",
                description: "",
              },
              {
                name: "Modify Property Details",
                key: "hrModifyProperty",
                description: "***REQUIRED*** to modify admins",
              },
              {
                name: "View Pay Rate",
                key: "hrViewPayRate",
                description: "view employee pay rates (excluding salary) ",
              },
              {
                name: "View Employee Notes",
                key: "hrViewEmployeeNotes",
                description:
                  'must have "View Pay Rate" in order to view employee notes',
              },
            ],
          },
          {
            section: "LOCK",
            permissions: [
              {
                name: "Lock Admin User",
                key: "adminLOCK",
                description: "lock admin from being modified or deleted",
              },
            ],
          },
        ]
      : []),
  ];

  const fetchAdminEmailsAndPermissions = async () => {
    try {
      if (!propertyCode || !user) {
        console.error(
          "Property code and user are required to fetch roles and permissions.",
        );
        return;
      }

      // Check if the user has sufficient permissions based on the new permissions structure
      if (!canViewSection(["adminAdminUser"], propertyCode)) {
        console.log(
          "Authenticated user does not have the required permission to manage user roles.",
        );
        setIsGlobal(false);
        return;
      }

      // Fetch permissions field from Properties collection by property code
      const propertiesRef = doc(db, "Properties", propertyCode);
      const propertiesSnap = await getDoc(propertiesRef);

      if (propertiesSnap.exists()) {
        const { permissions = [] } = propertiesSnap.data(); // Fetch email addresses from Properties

        const fetchedRolesData = [];

        // Loop through each email and fetch corresponding document from 'emailAuthUsers'
        await Promise.all(
          permissions.map(async (email) => {
            const userRef = doc(db, "emailAuthUsers", email);
            const userSnap = await getDoc(userRef);

            if (userSnap.exists()) {
              const userData = userSnap.data();

              // Ensure the propertyCode field exists in the user document
              if (
                userData.propertyRoles &&
                userData.propertyRoles[propertyCode]
              ) {
                const { role, adminLOCK, ...permissionsForProperty } =
                  userData.propertyRoles[propertyCode];
                const permissionsArray = Object.entries(permissionsForProperty)
                  .filter(([_, value]) => value) // Filter to get only true permissions
                  .map(([key]) => key); // Map to get the permission names

                // Add the fetched data for rendering
                fetchedRolesData.push({
                  email: email,
                  name: userData.name || "",
                  role: role, // Role for the specific property
                  permissions: permissionsArray, // Permissions array for the specific property
                  isLocked: !!adminLOCK,
                });
              }
            } else {
              console.log(`No document found for email: ${email}`);
            }
          }),
        );

        setRolesData(fetchedRolesData); // Set roles data after processing all emails
        setIsGlobal(true); // Set the global flag state to true since the user has appropriate permissions
      } else {
        console.log(
          "No permissions field found in Properties for this property.",
        );
      }
    } catch (error) {
      console.error("Error fetching admin emails and permissions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (propertyCode) {
      fetchAdminEmailsAndPermissions();
    }
  }, [propertyCode, user]);

  useEffect(() => {
    const fetchRolesForProperty = async () => {
      try {
        const propertiesRef = doc(db, "Properties", propertyCode);
        const propertiesSnap = await getDoc(propertiesRef);

        if (propertiesSnap.exists()) {
          const { Roles = [] } = propertiesSnap.data();
          setAvailableRoles(Roles);
        } else {
          console.log("No roles found in Properties for this property.");
        }
      } catch (error) {
        console.error("Error fetching roles from Properties:", error);
      }
    };

    if (propertyCode) {
      fetchRolesForProperty();
    }
  }, [propertyCode]);

  useEffect(() => {
    fetchNotes();
  }, [propertyCode]);

  const fetchNotes = async () => {
    if (!propertyCode) return;

    try {
      const propertyRef = doc(db, "Properties", propertyCode);
      const propertySnap = await getDoc(propertyRef);

      if (propertySnap.exists()) {
        const propertyData = propertySnap.data();
        let fetchedNotes = propertyData.Notes || [];

        // Filter out improperly structured notes
        fetchedNotes = fetchedNotes.filter(
          (note) =>
            typeof note === "object" &&
            note.action &&
            note.date &&
            (note.date.seconds || note.date.__time__) &&
            note.user,
        );

        // Sort the notes in reverse chronological order (latest first)
        fetchedNotes.sort((a, b) => {
          const dateA = a.date.seconds
            ? a.date.seconds
            : new Date(a.date.__time__).getTime() / 1000;
          const dateB = b.date.seconds
            ? b.date.seconds
            : new Date(b.date.__time__).getTime() / 1000;

          return dateB - dateA;
        });

        setNotes(fetchedNotes);
      } else {
        console.log("Property does not exist.");
        setNotes([]);
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  const indexOfLastNote = currentPage * notesPerPage;
  const indexOfFirstNote = indexOfLastNote - notesPerPage;
  const currentNotes = notes.slice(indexOfFirstNote, indexOfLastNote);
  const totalPages = Math.ceil(notes.length / notesPerPage);

  // SAVE PERMISSIONS
  const handleEmailDialogClose = async (confirm) => {
    if (confirm) {
      if (!selectedEmailValue || !propertyCode) {
        console.error("Invalid email or property code.");
        setSnackbarMessage("Invalid email or property code");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

      try {
        // Construct updatedPermissions using selectedPermissionsState
        const updatedPermissions = {
          role: selectedUserRole,
          ...selectedPermissionsState,
        };

        // Check if the user should be locked
        if (updatedPermissions["adminLOCK"]) {
          setIsLocked(true);
        }

        console.log("Updated Permissions:", updatedPermissions);
        alert("Permissions updated successfully.");
        setEmailDialogOpen(false);

        const userRef = doc(db, "emailAuthUsers", selectedEmailValue);
        const userSnap = await getDoc(userRef);
        const userData = userSnap.exists() ? userSnap.data() : {};

        const propertyRoles = {
          ...userData.propertyRoles,
          [propertyCode]: {
            ...userData.propertyRoles?.[propertyCode],
            ...updatedPermissions,
          },
        };

        await updateDoc(userRef, { propertyRoles });

        // Add a note to the 'Notes' field
        const note = {
          date: Timestamp.now(),
          user: user.email,
          action: `Updated permissions for user ${selectedEmailValue}`,
        };
        await addNoteToProperty(propertyCode, note);

        // Update rolesData in state
        setRolesData((prevRolesData) => {
          const updatedRolesData = [...prevRolesData];
          updatedRolesData[selectedRoleIndex] = {
            ...updatedRolesData[selectedRoleIndex],
            role: selectedUserRole,
            permissions: Object.keys(selectedPermissionsState).filter(
              (key) => selectedPermissionsState[key],
            ),
            isLocked: updatedPermissions["adminLOCK"] || false,
          };
          return updatedRolesData;
        });

        // Refresh data to reflect the user removal
        await fetchAdminEmailsAndPermissions();

        setChangesMade(false);
      } catch (error) {
        console.error("Error updating user permissions:", error);
      } finally {
        setEmailDialogOpen(false);
        setSnackbarOpen(true);
      }
    } else {
      setEmailDialogOpen(false);
    }
  };

  const saveStandardPermissions = async (email, propertyCode) => {
    if (!email || !propertyCode) {
      console.error("Invalid email or property code.");
      setSnackbarMessage("Invalid email or property code");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    const standardPermissions = {
      APPS: true,
      BREAKRM: true,
      HR: true,
      PUNCHES: true,
      ROSTER: true,
      STUBS: true,
      SUMMARY: true,
      addDept: true,
      adminAdminUser: true,
      adminDelegatePermission: true,
      adminLOCK: false,
      adminUserRoles: true,
      deleteFiles: false,
      hrAddEmployee: true,
      hrAddEmployeeNotes: true,
      hrChangePayRate: true,
      hrModifyEmployee: true,
      hrModifyProperty: true,
      hrTerminateEmployee: true,
      hrUploadFiles: true,
      hrViewBankingFiles: true,
      hrViewEmployeeNotes: true,
      hrViewOtherFiles: true,
      hrViewPayRate: true,
      hrViewPayrollFiles: true,
      landAcknowledgeChange: true,
      landPayroll: true,
      payrollAddPunch: true,
      payrollClosePayPeriods: true,
      payrollCloseTimecard: true,
      payrollDeletePunch: true,
      payrollID: false,
      payrollModifyPunch: true,
      payrollModifyRooms: true,
      payrollReopenTimecard: true,
      payrollRoster: true,
      payrollUploadStubs: false,
      payrollViewStubs: true,
      payrollViewSummary: true,
      summaryTimecards: true,
      BARISTA: true,
      BANQUET: true,
      BARTENDER: true,
      BELLHOP: true,
      BREAKFAST: true,
      CONVENTION: true,
      "COOK/CHEF": true,
      ENGINEER: true,
      "EVENT STAFF": true,
      "F&B": true,
      "FRONT DESK": true,
      HOUSEKEEPING: true,
      "HOUSEKEEPING /HR": true,
      KITCHEN: true,
      LAUNDRY: true,
      MANAGEMENT: true,
      MAINTENANCE: true,
      "PUBLIC AREAS": true,
      SALES: true,
      SECURITY: true,
      SERVER: true,
      SHUTTLE: true,
      "N/A": true,
    };

    try {
      const userRef = doc(db, "emailAuthUsers", email.toLowerCase());
      const userSnap = await getDoc(userRef);

      alert("Permissions updated successfully.");
      setEmailDialogOpen(false);

      if (!userSnap.exists()) {
        console.error("User does not exist in Firestore.");
        setSnackbarMessage("User does not exist.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

      const userData = userSnap.data();

      // Retrieve the user's current role for the property
      const currentRole =
        userData.propertyRoles?.[propertyCode]?.role || "Employee";

      const updatedPropertyRoles = {
        ...userData.propertyRoles,
        [propertyCode]: {
          ...standardPermissions,
          role: currentRole, // Preserve the user's current role
        },
      };

      // Update the user's property roles in Firestore
      await updateDoc(userRef, { propertyRoles: updatedPropertyRoles });

      // Add a note to the 'Notes' field
      const note = {
        date: Timestamp.now(),
        user: user.email,
        action: `Assigned Manager Starter Pack to ${email} for property ${propertyCode}`,
      };
      await addNoteToProperty(propertyCode, note);

      // Update the UI state
      setRolesData((prevRolesData) => {
        const existingUser = prevRolesData.find((role) => role.email === email);
        if (existingUser) {
          return prevRolesData.map((role) =>
            role.email === email
              ? {
                  ...role,
                  role: currentRole,
                  permissions: Object.keys(standardPermissions).filter(
                    (key) => standardPermissions[key],
                  ),
                }
              : role,
          );
        }
        return [
          ...prevRolesData,
          {
            email,
            role: currentRole,
            permissions: Object.keys(standardPermissions).filter(
              (key) => standardPermissions[key],
            ),
          },
        ];
      });

      // Refresh the admin emails and permissions
      await fetchAdminEmailsAndPermissions();
    } catch (error) {
      console.error("Error assigning standard permissions:", error);
      alert("Failed to update permissions.");
    }
  };

  const saveSuperUser = async (email, propertyCode) => {
    if (!email || !propertyCode) {
      console.error("Invalid email or property code.");
      setSnackbarMessage("Invalid email or property code");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    const standardPermissions = {
      APPS: true,
      BREAKRM: true,
      HR: true,
      PUNCHES: true,
      ROSTER: true,
      STUBS: true,
      SUMMARY: true,
      addDept: true,
      adminAdminUser: true,
      adminDelegatePermission: true,
      adminLOCK: true,
      adminUserRoles: true,
      deleteFiles: true,
      hrAddEmployee: true,
      hrAddEmployeeNotes: true,
      hrChangePayRate: true,
      hrModifyEmployee: true,
      hrModifyProperty: true,
      hrTerminateEmployee: true,
      hrUploadFiles: true,
      hrViewBankingFiles: true,
      hrViewEmployeeNotes: true,
      hrViewOtherFiles: true,
      hrViewPayRate: true,
      hrViewPayrollFiles: true,
      landAcknowledgeChange: true,
      landPayroll: true,
      payrollAddPunch: true,
      payrollClosePayPeriods: true,
      payrollCloseTimecard: true,
      payrollDeletePunch: true,
      payrollID: true,
      payrollModifyPunch: true,
      payrollModifyRooms: true,
      payrollReopenTimecard: true,
      payrollRoster: true,
      payrollUploadStubs: true,
      payrollViewStubs: true,
      payrollViewSummary: true,
      summaryTimecards: true,
      BARISTA: true,
      BANQUET: true,
      BARTENDER: true,
      BELLHOP: true,
      BREAKFAST: true,
      CONVENTION: true,
      "COOK/CHEF": true,
      ENGINEER: true,
      "EVENT STAFF": true,
      "F&B": true,
      "FRONT DESK": true,
      HOUSEKEEPING: true,
      "HOUSEKEEPING /HR": true,
      KITCHEN: true,
      LAUNDRY: true,
      MANAGEMENT: true,
      MAINTENANCE: true,
      "PUBLIC AREAS": true,
      SALES: true,
      SECURITY: true,
      SERVER: true,
      SHUTTLE: true,
      "N/A": true,
    };

    try {
      const userRef = doc(db, "emailAuthUsers", email.toLowerCase());
      const userSnap = await getDoc(userRef);

      alert("Permissions updated successfully.");
      setEmailDialogOpen(false);

      if (!userSnap.exists()) {
        console.error("User does not exist in Firestore.");
        setSnackbarMessage("User does not exist.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

      const userData = userSnap.data();

      // Retrieve the user's current role for the property
      const currentRole =
        userData.propertyRoles?.[propertyCode]?.role || "Employee";

      const updatedPropertyRoles = {
        ...userData.propertyRoles,
        [propertyCode]: {
          ...standardPermissions,
          role: currentRole, // Preserve the user's current role
        },
      };

      // Update the user's property roles in Firestore
      await updateDoc(userRef, { propertyRoles: updatedPropertyRoles });

      // Add a note to the 'Notes' field
      const note = {
        date: Timestamp.now(),
        user: user.email,
        action: `Assigned Super User Permissions to ${email} for property ${propertyCode}`,
      };
      await addNoteToProperty(propertyCode, note);

      // Update the UI state
      setRolesData((prevRolesData) => {
        const existingUser = prevRolesData.find((role) => role.email === email);
        if (existingUser) {
          return prevRolesData.map((role) =>
            role.email === email
              ? {
                  ...role,
                  role: currentRole,
                  permissions: Object.keys(standardPermissions).filter(
                    (key) => standardPermissions[key],
                  ),
                }
              : role,
          );
        }
        return [
          ...prevRolesData,
          {
            email,
            role: currentRole,
            permissions: Object.keys(standardPermissions).filter(
              (key) => standardPermissions[key],
            ),
          },
        ];
      });

      // Refresh the admin emails and permissions
      await fetchAdminEmailsAndPermissions();
    } catch (error) {
      console.error("Error assigning standard permissions:", error);
      alert("Failed to update permissions.");
    }
  };

  const saveAllDepartments = async (email, propertyCode) => {
    if (!email || !propertyCode) {
      console.error("Invalid email or property code.");
      setSnackbarMessage("Invalid email or property code");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    const standardPermissions = {
      BARISTA: true,
      BANQUET: true,
      BARTENDER: true,
      BELLHOP: true,
      BREAKFAST: true,
      CONVENTION: true,
      "COOK/CHEF": true,
      ENGINEER: true,
      "EVENT STAFF": true,
      "F&B": true,
      "FRONT DESK": true,
      HOUSEKEEPING: true,
      "HOUSEKEEPING /HR": true,
      KITCHEN: true,
      LAUNDRY: true,
      MANAGEMENT: true,
      MAINTENANCE: true,
      "PUBLIC AREAS": true,
      SALES: true,
      SECURITY: true,
      SERVER: true,
      SHUTTLE: true,
      "N/A": true,
    };

    try {
      const userRef = doc(db, "emailAuthUsers", email.toLowerCase());
      const userSnap = await getDoc(userRef);

      alert("Permissions updated successfully.");
      setEmailDialogOpen(false);

      if (!userSnap.exists()) {
        console.error("User does not exist in Firestore.");
        setSnackbarMessage("User does not exist.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

      const userData = userSnap.data();

      // Retrieve the user's current role for the property
      const currentRole =
        userData.propertyRoles?.[propertyCode]?.role || "Employee";

      // Merge existing permissions with standardPermissions
      const existingPermissions = userData.propertyRoles?.[propertyCode] || {};
      const updatedPermissions = {
        ...existingPermissions,
        ...standardPermissions, // Append new permissions, preserving existing ones
        role: currentRole, // Preserve the user's current role
      };

      const updatedPropertyRoles = {
        ...userData.propertyRoles,
        [propertyCode]: updatedPermissions,
      };

      // Update the user's property roles in Firestore
      await updateDoc(userRef, { propertyRoles: updatedPropertyRoles });

      // Add a note to the 'Notes' field
      const note = {
        date: Timestamp.now(),
        user: user.email,
        action: `Assigned Manager Starter Pack to ${email} for property ${propertyCode}`,
      };
      await addNoteToProperty(propertyCode, note);

      // Update the UI state
      setRolesData((prevRolesData) => {
        const existingUser = prevRolesData.find((role) => role.email === email);
        if (existingUser) {
          return prevRolesData.map((role) =>
            role.email === email
              ? {
                  ...role,
                  role: currentRole,
                  permissions: [
                    ...new Set([
                      ...(role.permissions || []),
                      ...Object.keys(standardPermissions).filter(
                        (key) => standardPermissions[key],
                      ),
                    ]),
                  ], // Combine existing and new permissions
                }
              : role,
          );
        }
        return [
          ...prevRolesData,
          {
            email,
            role: currentRole,
            permissions: Object.keys(standardPermissions).filter(
              (key) => standardPermissions[key],
            ),
          },
        ];
      });

      setEmailDialogOpen(false);

      // Refresh the admin emails and permissions
      await fetchAdminEmailsAndPermissions();
    } catch (error) {
      console.error("Error assigning standard permissions:", error);
    }
  };

  const handleAddNewRoleToProperty = async () => {
    if (newRoleToAdd && propertyCode) {
      const trimmedRole = newRoleToAdd.trim();

      // Prevent adding "Global", "Property Admin", or "Owner" as new roles
      const forbiddenRoles = ["Global", "Property Admin", "Staff"];
      if (
        forbiddenRoles.some(
          (role) => role.toLowerCase() === trimmedRole.toLowerCase(),
        )
      ) {
        // Add a note to the 'Notes' field logging the attempt
        const note = {
          date: Timestamp.now(),
          user: user.email,
          action: `Attempted to add forbidden role "${trimmedRole}"`,
        };
        await addNoteToProperty(propertyCode, note);

        // Provide feedback to the user
        setSnackbarMessage("Cannot add reserved role names");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return; // Exit the function
      }

      // Check if the role already exists
      if (
        availableRoles.some(
          (role) => role.toLowerCase() === trimmedRole.toLowerCase(),
        )
      ) {
        setSnackbarMessage("This role already exists");
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
        return;
      }

      try {
        const propertyRef = doc(db, "Properties", propertyCode);

        // Update the roles array by adding a new role to it
        await updateDoc(propertyRef, {
          Roles: arrayUnion(trimmedRole),
        });

        console.log("Role added successfully to the property.");

        // Add the new role to the available roles list (only update UI after Firestore update succeeds)
        setAvailableRoles((prevRoles) => [...prevRoles, trimmedRole]);

        // Add a note to the 'Notes' field
        const note = {
          date: Timestamp.now(),
          user: user.email,
          action: `Added new role "${trimmedRole}"`,
        };
        await addNoteToProperty(propertyCode, note);

        // Clear the new role input
        setNewRoleToAdd("");

        // Refresh data to reflect the user removal
        await fetchAdminEmailsAndPermissions();

        // Set success message
        setSnackbarMessage("Role added successfully");
        setSnackbarSeverity("success");
      } catch (error) {
        console.error("Error adding new role to property:", error);

        // Provide user feedback for the failure
        setSnackbarMessage("Failed to add new role");
        setSnackbarSeverity("error");
      } finally {
        setSnackbarOpen(true);
      }
    }
  };

  const predefinedDepartments = [
    "BARISTA",
    "BANQUET",
    "BARTENDER",
    "BELLHOP",
    "BREAKFAST",
    "CONVENTION",
    "COOK/CHEF",
    "ENGINEER",
    "EVENT STAFF",
    "F&B",
    "FRONT DESK",
    "HOUSEKEEPING",
    "HOUSEKEEPING /HR",
    "KITCHEN",
    "LAUNDRY",
    "MANAGEMENT",
    "MAINTENANCE",
    "PUBLIC AREAS",
    "SALES",
    "SECURITY",
    "SERVER",
    "SHUTTLE",
    "N/A",
  ];

  // FETCH DEPARTMENTS

  useEffect(() => {
    if (propertyCode) {
      // Load the current department list from Firestore
      const fetchDepartments = async () => {
        const propertyRef = doc(db, "Properties", propertyCode);
        const propertySnap = await getDoc(propertyRef);

        if (propertySnap.exists()) {
          const propertyData = propertySnap.data();
          setSelectedDepartments(propertyData.Dept || []);
        }
      };

      fetchDepartments();

      // Fetch departments that are in use from empRegister
      const fetchUsedDepartments = async () => {
        const empRef = collection(db, "empRegister");
        const q = query(
          empRef,
          where("PropID", "==", propertyCode),
          where("Status", "!=", "Terminated"),
        );
        const snapshot = await getDocs(q);

        let usedDepts = new Set();
        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          if (data.PayD1) {
            usedDepts.add(data.PayD1);
          }
          if (data.PayD2) {
            usedDepts.add(data.PayD2);
          }
        });

        setDisabledDepartments([...usedDepts]);
        setUsedDepartments([...usedDepts]);
      };

      fetchUsedDepartments();
    }
  }, [propertyCode]);

  const handleDeptDialogOpen = () => {
    setDeptDialogOpen(true);
  };

  const handleDeptDialogClose = () => {
    setDeptDialogOpen(false);
  };

  const handleCheckboxChange = async (department) => {
    if (disabledDepartments.includes(department)) return; // Prevent changing if department is disabled

    const isChecked = selectedDepartments.includes(department);
    try {
      const propertyRef = doc(db, "Properties", propertyCode);
      if (isChecked) {
        // If currently checked, remove the department
        await updateDoc(propertyRef, {
          Dept: arrayRemove(department),
        });
        setSelectedDepartments((prev) =>
          prev.filter((dept) => dept !== department),
        );
      } else {
        // If not checked, add the department
        await updateDoc(propertyRef, {
          Dept: arrayUnion(department),
        });
        setSelectedDepartments((prev) => [...prev, department]);
      }
      console.log(
        `Department ${isChecked ? "removed" : "added"} successfully.`,
      );
    } catch (error) {
      console.error("Error updating department list:", error);
    }
  };

  const handlePermissionChange = (key, isChecked) => {
    setSelectedPermissionsState((prevState) => ({
      ...prevState,
      [key]: isChecked,
    }));
    setChangesMade(true); // If you have a flag to track changes
  };

  const initializePermissions = (defaultValue = false) => {
    const permissions = {};
    permissionsConfig.forEach((section) => {
      section.permissions.forEach((perm) => {
        permissions[perm.key] = defaultValue;
      });
    });
    return permissions;
  };

  const confirmRemoveUser = async () => {
    try {
      if (!selectedEmailValue || !propertyCode) {
        console.error("Invalid email or property code.");
        setSnackbarMessage("Invalid email or property code");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

      alert("Admin user removed successfully.");
      setDeleteDialogOpen(false);

      const userRef = doc(db, "emailAuthUsers", selectedEmailValue);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userData = userSnap.data();

        // Remove the propertyRoles entry for the property
        const updatedPropertyRoles = { ...userData.propertyRoles };
        delete updatedPropertyRoles[propertyCode];

        // Update the user's document
        await updateDoc(userRef, {
          propertyRoles: updatedPropertyRoles,
        });

        // Remove the email from the permissions array in Properties
        const empRegisterRef = doc(db, "Properties", propertyCode);
        await updateDoc(empRegisterRef, {
          permissions: arrayRemove(selectedEmailValue),
        });

        // Add a note to the 'Notes' field
        const note = {
          date: Timestamp.now(),
          user: user.email,
          action: `Removed user ${selectedEmailValue}`,
        };
        await addNoteToProperty(propertyCode, note);

        // Update the rolesData state
        setRolesData((prevRolesData) => {
          const updatedRolesData = [...prevRolesData];
          updatedRolesData.splice(selectedRoleIndex, 1);
          return updatedRolesData;
        });

        // Refresh data to reflect the user removal
        await fetchAdminEmailsAndPermissions();
        setDeleteDialogOpen(false);

        setSnackbarMessage("User removed successfully");
        setSnackbarSeverity("success");
      } else {
        console.error("User does not exist.");
        setSnackbarMessage("User does not exist");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      console.error("Error removing user:", error);
      setSnackbarMessage("Failed to remove user");
      setSnackbarSeverity("error");
    } finally {
      setDeleteDialogOpen(false);
      setSnackbarOpen(true);
    }
  };

  const addNoteToProperty = async (propertyCode, note) => {
    const propertyRef = doc(db, "Properties", propertyCode);
    try {
      await updateDoc(propertyRef, {
        Notes: arrayUnion(note),
      });
      // After adding the note
      await addNoteToProperty(propertyCode, note);
      // Fetch the updated notes
      fetchNotes();
      console.log("Note added to property.");
    } catch (error) {
      console.error("Error adding note to property:", error);
    }
  };

  const renderAdminEmailFields = () => {
    const generateTempPassword = () => {
      return Math.floor(100000000 + Math.random() * 900000000).toString(); // Generate a 9-digit password
    };

    const createNewUserWithTempPassword = async (
      email,
      displayName,
      tempPassword,
    ) => {
      try {
        const manageUserResponse = await fetch(
          "https://us-central1-doorman-1985.cloudfunctions.net/manageUserByEmail",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: email.toLowerCase(),
              displayName,
              password: tempPassword,
            }),
          },
        );

        if (!manageUserResponse.ok) {
          const error = await manageUserResponse.json();
          throw new Error(
            error.message ||
              "Failed to create or manage user with temporary password",
          );
        }

        return await manageUserResponse.json();
      } catch (error) {
        console.error("Error in createNewUserWithTempPassword:", error);
        throw error;
      }
    };

    const handleAddNewUser = async () => {
      if (!newEmail || !newRole) return;

      try {
        const userRef = doc(db, "emailAuthUsers", newEmail.toLowerCase());
        const empRegisterRef = doc(db, "Properties", propertyCode);
        const initialPermissions = initializePermissions(false);
        const webhookUrl =
          "https://prod-158.westus.logic.azure.com:443/workflows/df0a9547f10244f2b63c482e577fd4a9/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ltx6ZfWiLkZ8bBctvl8KlOjsQsKhqspdcGeVNz0yY04";
        let userUid = null;
        let tempPassword = null;

        // Fetch AdCity and AdState from the current property
        const propertySnap = await getDoc(empRegisterRef);
        if (!propertySnap.exists()) {
          console.error("Property does not exist.");
          setSnackbarMessage("Property does not exist.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          return;
        }

        const { city, state } = propertySnap.data();

        // Check if the user exists in Firestore
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();

          // Check if the user already has roles for the property
          if (userData.propertyRoles?.[propertyCode]) {
            setSnackbarMessage("User already exists for this property");
            setSnackbarSeverity("warning");
            setSnackbarOpen(true);
            return;
          }

          // User exists in Firestore but lacks property roles
          userUid = userData.uid;

          // Trigger the webhook for updating roles
          await axios.post(webhookUrl, {
            trigger: "EmailAuthUserProp",
            oldEmail: "",
            newEmail: newEmail.toLowerCase(),
            code: propertyCode,
            oldPhoneNumber: "",
            newPhoneNumber: "",
            message: newName,
          });

          console.log(
            "Webhook triggered for existing Firestore user:",
            newEmail,
          );
        } else {
          // User doesn't exist in Firestore; use the Cloud Function
          tempPassword = generateTempPassword();
          const { uid, message } = await createNewUserWithTempPassword(
            newEmail,
            newName,
            tempPassword,
          );
          userUid = uid;

          console.log(`User ${message}:`, newEmail);

          // Mark email as verified
          await fetch(
            "https://us-central1-doorman-1985.cloudfunctions.net/markEmailVerifiedAndUpdateUser",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ uid, email: newEmail.toLowerCase() }),
            },
          );

          // Send webhook for new user
          await axios.post(webhookUrl, {
            trigger: "newEmailAuthUser",
            oldEmail: "",
            newEmail: newEmail.toLowerCase(),
            code: tempPassword,
            oldPhoneNumber: "",
            newPhoneNumber: "",
            message: newName,
          });

          console.log("Webhook triggered for new user:", newEmail);
        }

        alert("Adding user, please wait...");

        // Add or update the user's roles in Firestore
        await setDoc(
          userRef,
          {
            propertyRoles: {
              [propertyCode]: { role: newRole, ...initialPermissions },
            },
            name: newName,
            uid: userUid,
            tempPassword, // Save the temporary password only if new user was created
            AdCity: city,
            AdState: state,
          },
          { merge: true },
        );

        // Update the permissions in Properties collection
        await updateDoc(empRegisterRef, {
          permissions: arrayUnion(newEmail.toLowerCase()),
        });

        // Add a note to the property
        await addNoteToProperty(propertyCode, {
          date: Timestamp.now(),
          user: user.email,
          action: userSnap.exists()
            ? `Updated user ${newEmail} with new role for property ${propertyCode}`
            : `Added user ${newEmail} with role ${newRole}`,
        });

        // Update UI state
        setRolesData((prev) => [
          ...prev,
          { role: newRole, email: newEmail, permissions: [] },
        ]);

        setNewEmail("");
        setNewRole("");
        setNewName("");

        // Refresh admin emails and permissions
        await fetchAdminEmailsAndPermissions();

        setSnackbarOpen(true);
        setSnackbarMessage("User added or updated successfully");
        setSnackbarSeverity("success");
      } catch (error) {
        console.error("Error adding or updating user:", error);
        setSnackbarMessage("Failed to add or update user");
        setSnackbarSeverity("error");
      } finally {
        setTimeout(() => setSnackbarOpen(true), 100);
      }
    };

    const openEmailDialog = async (index) => {
      setLoading(true); // Optional: Show a loading indicator

      const selectedRole = rolesData[index];
      const email = selectedRole.email;

      try {
        // Fetch the latest user data
        const userRef = doc(db, "emailAuthUsers", email);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          const propertyData = userData.propertyRoles[propertyCode] || {};
          const { role, ...permissionsForProperty } = propertyData;

          // Update state with the latest data
          setSelectedRoleIndex(index);
          setSelectedEmailField(email);
          setSelectedUserRole(role || selectedRole.role);
          setSelectedEmailValue(email);
          setSelectedName(userData.name || "Unknown");

          // Initialize the permissions state
          const permissionsState = {};
          permissionsConfig.forEach((section) => {
            section.permissions.forEach((perm) => {
              permissionsState[perm.key] = !!permissionsForProperty[perm.key];
            });
          });
          setSelectedPermissionsState(permissionsState);
        } else {
          console.error("User does not exist.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setChangesMade(false); // Reset changesMade when opening dialog
        setEmailDialogOpen(true);
        setLoading(false); // Hide loading indicator
      }
    };

    if (loading) {
      return <Typography>Loading roles...</Typography>;
    }

    const handleRemoveUser = (index) => {
      // Get the selected user's email
      const selectedUserEmail = rolesData[index].email;

      // Check if the selected user is the currently authenticated user
      if (selectedUserEmail === user.email) {
        setSnackbarMessage("You cannot remove yourself");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

      // Set the selected role index and email value
      setSelectedRoleIndex(index);
      setSelectedEmailValue(selectedUserEmail);

      // Open the confirmation dialog
      setDeleteDialogOpen(true);
    };

    const isValidEmail = (email) => {
      // Simple regex for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    return (
      <>
        {
          <Typography
            variant="h5"
            sx={{
              fontFamily: "Outfit",
              letterSpacing: "0.045rem",
              fontWeight: "800",
              fontSize: "0.9rem",
              marginBottom: "0.81rem",
            }}
          >
            NEW USER
          </Typography>
        }
        {/* ADD NEW USER SECTION */}
        {canViewSection(["adminAdminUser"], propertyCode) && (
          <>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={3}>
                <Button
                  fullwidth
                  onClick={handleAddNewUser}
                  disabled={!newEmail || !newRole || !isEmailValid || !newName}
                  sx={{
                    borderRadius: "9px",
                    padding: "9.9px 27px 9.9px 27px",
                    fontWeight: "400",
                    fontSize: "0.72rem",
                    fontFamily: "Outfit",
                    letterSpacing: "0.09rem",
                    border: "2.7px solid lightgrey",
                    backgroundColor: "lightgrey",
                    color: "#202426",
                    "&:hover": {
                      border: "2.7px solid #202426",
                      backgroundColor: "transparent",
                      color: "#202426",
                    },
                  }}
                >
                  Add Admin
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{ ...selectFormControl }}
                >
                  <InputLabel
                    shrink
                    id="role-select-label"
                    sx={{
                      ...selectInputLabel,
                      ".MuiInputBase-input": { fontSize: "1rem" },
                    }}
                  >
                    Title
                  </InputLabel>
                  <Select
                    labelId="role-select-label"
                    value={newRole}
                    onChange={(e) => setNewRole(e.target.value)}
                    label="Role"
                    sx={{ ...selectSelectClear }}
                  >
                    {availableRoles.map((role) => (
                      <MenuItem
                        key={role}
                        value={role}
                        sx={{ ...selectMenuItem }}
                      >
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={0} sm={1}></Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Name (First/Last)"
                  variant="outlined"
                  value={newName}
                  onChange={(e) => {
                    const capitalizedValue = e.target.value.toUpperCase(); // Capitalize the input value
                    setNewName(capitalizedValue);
                    setSelectedName(capitalizedValue);
                  }}
                  sx={{ ...textfieldSx }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="New User Email"
                  variant="outlined"
                  value={newEmail}
                  onChange={(e) => {
                    const email = e.target.value;
                    setNewEmail(email);
                    setIsEmailValid(isValidEmail(email));
                  }}
                  sx={{ ...textfieldSx }}
                />
              </Grid>
            </Grid>
          </>
        )}
        {/* ADD NEW ROLE SECTION */}
        {canViewSection(["adminUserRoles"], propertyCode) && (
          <>
            <Grid
              container
              spacing={2}
              alignItems="center"
              sx={{ marginBottom: 7.2 }}
            >
              <Grid item xs={6} sm={3}>
                <Button
                  fullwidth
                  onClick={handleAddNewRoleToProperty}
                  disabled={!newRoleToAdd}
                  sx={{
                    borderRadius: "9px",
                    padding: "9.9px 27px 9.9px 27px",
                    fontWeight: "400",
                    fontSize: "0.72rem",
                    fontFamily: "Outfit",
                    letterSpacing: "0.09rem",
                    border: "2.7px solid lightgrey",
                    backgroundColor: "lightgrey",
                    color: "#202426",
                    "&:hover": {
                      border: "2.7px solid #202426",
                      backgroundColor: "transparent",
                      color: "#202426",
                    },
                  }}
                >
                  ADD NEW TITLE
                </Button>
              </Grid>
              <Grid item xs={6} sm={2}>
                <TextField
                  fullWidth
                  label="Add New TITLE"
                  variant="outlined"
                  value={newRoleToAdd}
                  onChange={(e) => setNewRoleToAdd(e.target.value)}
                  sx={{ ...textfieldSx }}
                />
              </Grid>
              <Grid item xs={0} sm={7}></Grid>
            </Grid>
          </>
        )}
        {/* EXISTING USERS SECTION */}
        {
          <>
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Outfit",
                letterSpacing: "0.045rem",
                fontWeight: "800",
                fontSize: "0.9rem",
                marginBottom: "0.81rem",
              }}
            >
              EXISTING USER
            </Typography>
            {rolesData.length > 0 ? (
              rolesData.map(({ role, email, name, permissions }, index) => (
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  key={index}
                  sx={{ marginBottom: 0.9 }}
                >
                  <Grid item xs={12} sm={3}>
                    <Grid container spacing={2} alignItems="center" key={index}>
                      <Grid item sm={5}>
                        {(canViewSection([], propertyCode) ||
                          (canViewSection(
                            ["adminDelegatePermission"],
                            propertyCode,
                          ) &&
                            !isLocked)) && (
                          <Button
                            fullWidth
                            onClick={() => openEmailDialog(index)} // Open dialog to edit permissions
                            sx={{
                              ...greenButton,
                            }}
                          >
                            Permissions
                          </Button>
                        )}
                      </Grid>

                      <Grid item sm={5}>
                        {(canViewSection([], propertyCode) ||
                          (canViewSection(["adminAdminUser"], propertyCode) &&
                            !isLocked)) && (
                          <Button
                            fullWidth
                            onClick={() => handleRemoveUser(index)}
                            sx={{
                              ...redButton,
                            }}
                          >
                            Remove
                          </Button>
                        )}
                      </Grid>
                      <Grid item sm={2}></Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      fullWidth
                      label="Role"
                      variant="outlined"
                      value={role}
                      disabled
                      sx={{ ...textfieldSx }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      fullWidth
                      label="Name"
                      variant="outlined"
                      value={name}
                      disabled
                      sx={{ ...textfieldSx }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      fullWidth
                      label="Email"
                      variant="outlined"
                      value={email}
                      disabled
                      sx={{ ...textfieldSx }}
                    />
                  </Grid>
                </Grid>
              ))
            ) : (
              <Typography></Typography>
            )}
          </>
        }

        <>
          {/* MANAGE DEPARTMENTS */}
          {/*{canViewSection(["addDept"], propertyCode) && (*/}
          {/*  <>*/}
          {/*    <Typography*/}
          {/*      variant="h5"*/}
          {/*      sx={{*/}
          {/*        fontFamily: "Outfit",*/}
          {/*        letterSpacing: "0.045rem",*/}
          {/*        fontWeight: "800",*/}
          {/*        fontSize: "0.9rem",*/}
          {/*        marginBottom: "0.81rem",*/}
          {/*        marginTop: "3.6rem",*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      DEPARTMENTS*/}
          {/*    </Typography>*/}
          {/*    <Grid container spacing={2} sx={{ marginBottom: 4.5 }}>*/}
          {/*      <Grid item xs={12} sm={2}>*/}
          {/*        <Button*/}
          {/*          fullWidth*/}
          {/*          onClick={handleDeptDialogOpen}*/}
          {/*          sx={{ ...greyButton }}*/}
          {/*        >*/}
          {/*          MANAGE DEPARTMENTS*/}
          {/*        </Button>*/}
          {/*      </Grid>*/}
          {/*      <Grid item xs={0} sm={10}>*/}
          {/*        {" "}*/}
          {/*      </Grid>*/}
          {/*    </Grid>*/}
          {/*  </>*/}
          {/*)}*/}

          {/* Department Dialog */}
          <Dialog
            open={deptDialogOpen}
            onClose={handleDeptDialogClose}
            PaperProps={{
              sx: { ...dialogMain },
            }}
          >
            <DialogTitle sx={{ ...dialogTitle }}>
              MANAGE DEPARTMENTS
            </DialogTitle>
            <DialogContent sx={{ ...dialogContent }}>
              <Grid container spacing={1}>
                {predefinedDepartments.map((department) => (
                  <Grid item xs={12} sm={6} key={department}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            selectedDepartments.includes(department) ||
                            disabledDepartments.includes(department) // Ensure disabledDepartments are also checked
                          }
                          disabled={disabledDepartments.includes(department)} // Disable if department is used
                          onChange={() => handleCheckboxChange(department)}
                          sx={{
                            "&.Mui-checked": {
                              color: "#FFC107",
                            },
                          }}
                        />
                      }
                      label={
                        <Box>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "400",
                              fontSize: "0.9rem",
                              fontFamily: "Outfit",
                              color: "#202426",
                              textTransform: "uppercase",
                              letterSpacing: "0.045rem",
                            }}
                          >
                            {department}
                          </Typography>
                        </Box>
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleDeptDialogClose}
                color="primary"
                autoFocus
                fullWidth
                sx={{ ...greenButton }}
              >
                SAVE
              </Button>
            </DialogActions>
          </Dialog>
        </>

        {/* CHANGE LOG SECTION */}
        {(userRole === "Global" ||
          userRole === "MGMT" ||
          userRole === "PropertyAdmin") && (
          <Box sx={{ marginTop: "2rem" }}>
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Outfit",
                letterSpacing: "0.045rem",
                fontWeight: "800",
                fontSize: "0.9rem",
                marginBottom: "0.81rem",
              }}
            >
              CHANGE LOG
            </Typography>

            {notes.length > 0 ? (
              <List>
                {currentNotes.map((note, index) => (
                  <ListItem key={index} alignItems="flex-start">
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          sx={{
                            fontFamily: "Outfit",
                            fontWeight: "400",
                            fontSize: "0.9rem",
                          }}
                        >
                          {note.action}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: "0.9rem",
                          }}
                        >
                          {`By ${note.user} on ${new Date(note.date.seconds * 1000).toLocaleString()}`}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Outfit",
                  fontSize: "0.9rem",
                  color: "lightgrey",
                }}
              >
                No changes have been logged for this property.
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#f5f5f5",
                padding: "0.9rem 1.8rem",
              }}
            >
              <Button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                sx={{
                  color: "grey",
                  letterSpacing: "0.1rem",
                  fontSize: "1rem",
                  fontFamily: "Outfit",
                  fontWeight: "200",
                }}
              >
                Previous
              </Button>
              <Typography
                sx={{
                  margin: "0 1rem",
                  lineHeight: "2.5rem",
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: "#202426",
                  fontFamily: "Outfit",
                }}
              >
                Page {currentPage} of {totalPages}
              </Typography>
              <Button
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={currentPage === totalPages}
                sx={{
                  color: "#202426",
                  letterSpacing: "0.1rem",
                  fontSize: "1rem",
                  fontFamily: "Outfit",
                  fontWeight: "200",
                }}
              >
                Next
              </Button>
            </Box>
          </Box>
        )}

        {/* PERMISSIONS DIALOG */}
        <Dialog
          open={emailDialogOpen}
          onClose={() => handleEmailDialogClose(false)}
          PaperProps={{
            sx: { ...dialogMain },
          }}
        >
          <Box
            sx={{
              ...dialogBox,
            }}
          >
            <IconButton onClick={() => handleEmailDialogClose(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogTitle
            sx={{
              ...dialogTitle,
            }}
          >
            EDIT PERMISSIONS
            {selectedName && (
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Nunito",
                  fontWeight: "400",
                  fontSize: "0.9rem",
                  color: "#93A683",
                }}
              >
                {`${selectedName}`}
              </Typography>
            )}
          </DialogTitle>
          <DialogContent
            sx={{
              ...dialogContent,
            }}
          >
            {(canViewSection([], propertyCode) ||
              (canViewSection(["adminDelegatePermission"], propertyCode) &&
                !isLocked)) && (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ marginBottom: "0.9rem" }}
              >
                <Button
                  onClick={() =>
                    saveAllDepartments(selectedEmailValue, propertyCode)
                  }
                  color="secondary"
                  disabled={!selectedEmailValue || !propertyCode}
                  sx={{
                    borderRadius: "9px",
                    padding: "9.9px 27px",
                    fontWeight: "400",
                    fontSize: "0.72rem",
                    fontFamily: "Outfit",
                    letterSpacing: "0.09rem",
                    backgroundColor: "#F2B70A",
                    color: "#202426",
                    "&:hover": {
                      backgroundColor: "#FFD54F",
                    },
                  }}
                >
                  Add All Dept's to user
                </Button>
                <Button
                  onClick={() =>
                    saveStandardPermissions(selectedEmailValue, propertyCode)
                  }
                  color="secondary"
                  disabled={!selectedEmailValue || !propertyCode}
                  sx={{
                    borderRadius: "9px",
                    padding: "9.9px 27px",
                    fontWeight: "400",
                    fontSize: "0.72rem",
                    fontFamily: "Outfit",
                    letterSpacing: "0.09rem",
                    backgroundColor: "#F2B70A",
                    color: "#202426",
                    "&:hover": {
                      backgroundColor: "#FFD54F",
                    },
                  }}
                >
                  Manager's Starter Pack
                </Button>
                {/*SUPER USER*/}
                {/*<Button*/}
                {/*  onClick={() =>*/}
                {/*    saveSuperUser(selectedEmailValue, propertyCode)*/}
                {/*  }*/}
                {/*  color="secondary"*/}
                {/*  disabled={!selectedEmailValue || !propertyCode}*/}
                {/*  sx={{*/}
                {/*    borderRadius: "9px",*/}
                {/*    padding: "9.9px 27px",*/}
                {/*    fontWeight: "400",*/}
                {/*    fontSize: "0.72rem",*/}
                {/*    fontFamily: "Outfit",*/}
                {/*    letterSpacing: "0.09rem",*/}
                {/*    backgroundColor: "#F2B70A",*/}
                {/*    color: "#202426",*/}
                {/*    "&:hover": {*/}
                {/*      backgroundColor: "#FFD54F",*/}
                {/*    },*/}
                {/*  }}*/}
                {/*>*/}
                {/*  Super User*/}
                {/*</Button>*/}
              </Box>
            )}
            {permissionsConfig.map((section) => (
              <Accordion key={section.section}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${section.section}-content`}
                  id={`${section.section}-header`}
                  sx={{
                    backgroundColor: "#f5f5f5",
                    border: "none",
                    "& .MuiAccordionSummary-content": {
                      alignItems: "center",
                    },
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "400",
                      fontSize: "0.72rem",
                      fontFamily: "Outfit",
                      letterSpacing: "0.09rem",
                      color: "grey",
                    }}
                  >
                    {section.section}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {section.permissions.map((perm) => (
                      <Grid item xs={12} key={perm.key}>
                        <FormControlLabel
                          sx={{ marginTop: "0.72rem" }}
                          control={
                            <Checkbox
                              checked={
                                selectedPermissionsState[perm.key] || false
                              }
                              onChange={(e) =>
                                handlePermissionChange(
                                  perm.key,
                                  e.target.checked,
                                )
                              }
                              sx={{
                                "&.Mui-checked": {
                                  color: "#FFC107",
                                },
                              }}
                            />
                          }
                          label={
                            <Box>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontWeight: "400",
                                  fontSize: "0.9rem",
                                  fontFamily: "Outfit",
                                  color: "#202426",
                                  textTransform: "uppercase",
                                  letterSpacing: "0.045rem",
                                }}
                              >
                                {perm.name}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  fontWeight: "400",
                                  fontSize: "0.81rem",
                                  fontFamily: "Nunito",
                                  color: "grey",
                                  textTransform: "lowercase",
                                  letterSpacing: "0.018rem",
                                }}
                              >
                                {`${perm.description}`}
                              </Typography>
                            </Box>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              onClick={() => handleEmailDialogClose(true)}
              color="primary"
              disabled={!selectedEmailValue || !changesMade}
              sx={{
                ...greenButton,
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          PaperProps={{
            sx: {
              ...dialogMain,
            },
          }}
        >
          <Box
            sx={{
              ...dialogBox,
            }}
          >
            <IconButton onClick={() => setDeleteDialogOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogTitle
            sx={{
              ...dialogTitle,
            }}
          >
            CONFIRM USER REMOVAL
          </DialogTitle>
          <DialogContent>
            <Typography
              sx={{
                fontFamily: "Outfit",
                fontWeight: "400",
                fontSize: "0.9rem",
                marginTop: "0.9rem",
              }}
            >
              Are you sure you want to remove user{" "}
              <strong>{selectedEmailValue}</strong>?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={confirmRemoveUser}
              sx={{
                ...redButton,
              }}
            >
              CONFIRM REMOVAL
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const renderForm = () => {
    if (!recordData) return null;

    const hiddenFields = [
      "Id",
      "gender",
      "faceid",
      "DateUpdated",
      "timeSheetID",
      "collection",
      "propName",
      "timeTableID",
      "PropID",
      "LinkUpdate",
      "DateCreated",
      "AdEmail",
      "ID",
      "age",
      "enrollDate",
      "peachTreeID",
      "Background",
    ];

    const readOnlyFields = [
      "EmpID",
      "PRR",
      "Status",
      "enrollDate",
      "timeclock",
      "DateHired",
    ];

    const payTypes = [
      "BARISTA",
      "BANQUET",
      "BARTENDER",
      "BELLHOP",
      "BREAKFAST",
      "CONVENTION",
      "COOK/CHEF",
      "ENGINEER",
      "EVENT STAFF",
      "F&B",
      "FRONT DESK",
      "HOUSEKEEPING",
      "HOUSEKEEPING /HR",
      "KITCHEN",
      "LAUNDRY",
      "MANAGEMENT",
      "MAINTENANCE",
      "PUBLIC AREAS",
      "SALES",
      "SECURITY",
      "SERVER",
      "SHUTTLE",
      "N/A",
    ];

    const payD1Types = ["PER HOUR", "PER ROOM", "SALARY"];
    const payD2Types = ["PER HOUR", "SALARY", "N/A"];

    const firstSection = [
      { name: "NameFirst", label: "First Name" },
      { name: "NameMid", label: "Middle Name" },
      { name: "NameLast", label: "Last Name" },
      { name: "EmpID", label: "Employee ID" },
      { name: "SS4", label: "SSN" },
      { name: "DOB", label: "Date of Birth" },
      { name: "Ad1", label: "Address" },
      { name: "AdCity", label: "City" },
      { name: "AdState", label: "State" },
      { name: "AdZip", label: "Zip Code" },
      { name: "AdPhone", label: "Phone" },
      { name: "Email", label: "Email" },
    ];

    const payD1Section = [
      { name: "PayD1", label: "Department 1" },
      { name: "PayD1_Type", label: "Pay Type" },
      { name: "PayD1_Rate", label: "Pay Rate" },
      { name: "PR2", label: "Per Room 2" },
      { name: "PR3", label: "Per Room 3" },
    ];

    const payD2Section = [
      { name: "PayD2", label: "Department 2" },
      { name: "PayD2_Type", label: "Pay Type" },
      { name: "PayD2_Rate", label: "Pay Rate" },
    ];

    const thirdSection = [
      { name: "DateHired", label: "Date Hired" },
      { name: "timeclock", label: "Punch Clock Enrolled" },
      { name: "PRR", label: "Pay Rate Reviewed" },
      { name: "Notes", label: "Notes", multiline: true, rows: 4 },
    ];

    const propertySectionReadOnly = [
      { name: "propCode", label: "Property Code" },
      { name: "propertyName", label: "Property Name" },
      { name: "AdPhone", label: "Phone" },
      { name: "Ad1", label: "Address" },
      { name: "city", label: "City" },
      { name: "state", label: "State" },
      { name: "rms1", label: "RMS 1" },
      { name: "rms2", label: "RMS 2" },
      { name: "rms3", label: "RMS 3" },
      { name: "rmsMTG", label: "RMS MTG" },
      { name: "rmsTotal", label: "RMS Total" },
      { name: "propNewBuildDate", label: "New Build Date" },
      { name: "propRenovate", label: "Renovate Date" },
      { name: "GroupID", label: "Management" },
      { name: "propEntity", label: "Operating Entity" },
    ];

    const renderCustomSection = (section, sectionName, readOnly = false) => {
      return section.map(({ name, label, isCheckbox, multiline, rows }) => {
        // Don't render if field doesn't exist or is hidden
        if (hiddenFields.includes(name)) {
          return null;
        }

        // Handle PR2 and PR3 visibility based on PayD1_Type
        if (
          (name === "PR2" || name === "PR3") &&
          recordData["PayD1_Type"] !== "PER ROOM"
        ) {
          return null;
        }

        const renderField = () => {
          if (isCheckbox) {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isTerminated}
                    onChange={(e) => setIsTerminated(e.target.checked)}
                  />
                }
                label={label}
              />
            );
          }

          if (name === "DateHired") {
            const formattedDate = recordData[name]
              ? format(new Date(recordData[name]), "MM/dd/yyyy")
              : "";

            return (
              <TextField
                fullWidth
                label={label}
                variant="outlined"
                name={name}
                value={formattedDate}
                onChange={(e) => handleFieldChange(name, e.target.value)} // Update the field if needed
                InputProps={{
                  readOnly: true,
                  shrink: true,
                }}
                sx={{ ...textfieldSx }}
              />
            );
          }

          if (name === "DOB") {
            const handleDOBChange = (date) => {
              const formattedDate = date ? format(date, "MM/dd/yyyy") : null;
              handleFieldChange(name, formattedDate); // Update with formatted date
            };

            return (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  sx={{ ...textfieldSx }}
                  label={label}
                  inputFormat="MM/dd/yyyy" // Display format
                  value={recordData[name] ? new Date(recordData[name]) : null}
                  onChange={handleDOBChange}
                  readOnly={employeeStatus === "Inactive"}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth sx={{ ...textfieldSx }} />
                  )}
                />
              </LocalizationProvider>
            );
          }

          if (name === "AdState") {
            return (
              <FormControl fullWidth sx={{ ...textfieldSx }}>
                <InputLabel sx={{ ...selectInputLabel }}>{label}</InputLabel>
                <Select
                  value={recordData[name]}
                  onChange={(e) => handleFieldChange(name, e.target.value)}
                  sx={{ ...selectSelectClear }}
                >
                  {US_STATES.map((state) => (
                    <MenuItem
                      key={state}
                      value={state}
                      sx={{ ...selectMenuItem }}
                    >
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }

          if (name === "AdPhone") {
            const handlePhoneChange = (values) => {
              let { value } = values;

              // Remove non-numeric characters
              value = value.replace(/[^0-9]/g, "");

              // Ensure it starts with a "1" and is followed by exactly 10 digits
              if (value.length > 0 && !value.startsWith("1")) {
                value = "1" + value;
              }

              if (value.length > 11) {
                value = value.slice(0, 11);
              }

              handleFieldChange(name, value);
            };

            // Ensure the phone number is formatted correctly
            let formattedPhoneNumber = recordData[name];
            if (formattedPhoneNumber) {
              formattedPhoneNumber = formattedPhoneNumber.replace(
                /[^0-9]/g,
                "",
              );
              if (!formattedPhoneNumber.startsWith("1")) {
                formattedPhoneNumber = "1" + formattedPhoneNumber;
              }
              if (formattedPhoneNumber.length > 11) {
                formattedPhoneNumber.slice(0, 11);
              }
            }

            return (
              <NumericFormat
                customInput={TextField}
                format="1##########"
                mask="_"
                fullWidth
                label={label}
                value={recordData[name]}
                onValueChange={handlePhoneChange}
                sx={{ ...textfieldSx }}
                InputProps={{
                  readOnly: recordData.Status !== "Terminated",
                }}
              />
            );
          }

          if (name === "SS4") {
            const formatSSN = (ssn) => {
              if (ssn.length === 4) return ssn;
              if (ssn.length === 9) return `*****${ssn.slice(-4)}`;
              if (ssn.length === 11 && ssn.includes("-"))
                return `*****-${ssn.slice(-4)}`;
              return ssn;
            };

            return (
              <TextField
                fullWidth
                label={label}
                value={formatSSN(recordData[name] || "")}
                onChange={(e) => handleFieldChange(name, e.target.value)}
                InputProps={{}}
                sx={{ ...textfieldSx }}
              />
            );
          }

          if (
            name === "PayD1" ||
            name === "PayD2" ||
            name === "PayD1_Type" ||
            name === "PayD2_Type"
          ) {
            const options =
              name === "PayD1" || name === "PayD2"
                ? predefinedDepartments.filter(
                    (type) =>
                      usedDepartments.includes(type) ||
                      selectedDepartments.includes(type),
                  )
                : name === "PayD1_Type"
                  ? payD1Types
                  : payD2Types;

            const initialValue = recordData[name] || "";

            return (
              <FormControl fullWidth sx={{ ...textfieldSx }}>
                <InputLabel sx={{ ...selectInputLabel }}>{label}</InputLabel>
                <Select
                  value={initialValue}
                  onChange={(e) => handleFieldChange(name, e.target.value)}
                  sx={{ ...selectSelectClear }}
                >
                  {options.map((type) => (
                    <MenuItem
                      key={type}
                      value={type}
                      sx={{ ...selectMenuItem }}
                    >
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }

          if (["PayD1_Rate", "PayD2_Rate", "PR2", "PR3"].includes(name)) {
            return (
              <NumericFormat
                customInput={TextField}
                thousandSeparator
                prefix="$"
                fullWidth
                label={label}
                value={recordData[name]}
                onValueChange={(values) => {
                  const { value } = values;
                  handleFieldChange(name, value);
                }}
                sx={{ ...textfieldSx }}
              />
            );
          }

          if (name === "PRR") {
            const formattedDate = recordData[name]
              ? format(new Date(recordData[name]), "MM-dd-yyyy")
              : "";

            return (
              <TextField
                fullWidth
                label={label}
                variant="outlined"
                name={name}
                value={formattedDate}
                InputProps={{ readOnly: true }}
                sx={{ ...textfieldSx }}
              />
            );
          }

          const isInactiveEmployee =
            formType === "Employee" && employeeStatus === "Inactive";
          const isRehireButton =
            name === "Status" && recordData.Status === "Terminated";

          if (sectionName === "OTHER" && name === "Notes") {
            return null;
          }

          return (
            <TextField
              fullWidth
              label={label}
              variant="outlined"
              name={name}
              value={recordData[name] || ""}
              onChange={(e) => handleFieldChange(name, e.target.value)}
              InputProps={{
                readOnly:
                  (isInactiveEmployee && !isRehireButton) ||
                  readOnly ||
                  (formType === "Employee" && readOnlyFields.includes(name)),
                multiline: multiline || false,
                rows: rows || 1,
              }}
              sx={{ ...textfieldSx }}
            />
          );
        };

        return (
          <Grid item xs={12} sm={4} key={name}>
            {renderField()}
          </Grid>
        );
      });
    };

    return (
      <Grid
        container
        justifyContent="center"
        direction="row"
        alignItems="center"
        spacing={2}
        marginLeft={1}
        marginRight={1}
      >
        {formType === "Employee" && (
          <>
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="center"
              xs={12}
              sx={{
                marginTop: 2.7,
                marginBottom: 2.7,

                borderRadius: "18px",
                backgroundColor: "#f5f5f5",
              }}
            ></Grid>
            <Grid
              item
              xs={12}
              container
              sx={{
                padding: "1rem",
                borderRadius: "36px",
                backgroundColor: "#f5f5f5",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  marginLeft: 1,
                  fontFamily: "Outfit",
                  letterSpacing: "0.09rem",
                  fontWeight: "800",
                  fontSize: "2.07rem",
                  textTransform: "lowercase",
                  color: "black",
                  paddingBottom: "1rem",
                  paddingTop: "1rem",
                }}
              >
                team member
              </Typography>
              <Grid item xs={12} container justifyContent="left">
                {renderCustomSection(
                  firstSection,
                  "PERSONAL",
                  recordData.Status === "Terminated",
                )}
              </Grid>
            </Grid>

            {canViewSection("hrChangePayRate", propertyCode) && (
              <>
                <Grid container spacing={2} paddingTop={9} paddingLeft={2}>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{
                      padding: "1rem",
                      borderRadius: "36px",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        marginLeft: 1,
                        letterSpacing: "0.3rem",
                        fontWeight: "200",
                        fontSize: "0.9rem",
                        color: "#202426",
                        paddingBottom: "1rem",
                        paddingTop: "1rem",
                      }}
                    >
                      PAY DEPARTMENT 1
                    </Typography>

                    <Grid item xs={12} container>
                      {renderCustomSection(
                        payD1Section,
                        "PAY D1",
                        recordData.Status === "Terminated",
                      )}
                    </Grid>

                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        marginLeft: 1,
                        letterSpacing: "0.3rem",
                        fontWeight: "200",
                        fontSize: "0.9rem",
                        color: "#202426",
                        paddingBottom: "1rem",
                        paddingTop: "1rem",
                      }}
                    >
                      PAY DEPARTMENT 2
                    </Typography>

                    <Grid item xs={12} container>
                      {renderCustomSection(
                        payD2Section,
                        "PAY D2",
                        recordData.Status === "Terminated",
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid
              item
              xs={12}
              container
              sx={{
                padding: "1rem",
                borderRadius: "36px",
                justifyContent: "space-between",
              }}
            >
              {canViewSection(["hrTerminateEmployee"], propertyCode) &&
                (recordData.Status !== "Terminated" ? (
                  <Button
                    onClick={() => {
                      setConfirmationType("Terminate");
                      setConfirmDialogOpen(true);
                    }}
                    sx={{
                      ...redButton,
                    }}
                  >
                    TERMINATE
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setConfirmationType("Rehire");
                      setConfirmDialogOpen(true);
                    }}
                    sx={{
                      ...greenButton,
                    }}
                  >
                    RE-HIRE EMPLOYEE
                  </Button>
                ))}

              {canViewSection(["hrModifyEmployee"], propertyCode) &&
                recordData.Status !== "Terminated" && (
                  <Button
                    onClick={() => setDialogOpen(true)}
                    disabled={
                      Object.keys(changes).length === 0 ||
                      recordData.Status === "Terminated" ||
                      emailClaimsChanged // Disable if only email claims changed
                    }
                    sx={{
                      padding: "9px 18px 9px 18px",
                      borderRadius: "9px",
                      fontSize: "0.81rem",
                      fontFamily: "Outfit",
                      letterSpacing: "0.09rem",
                      border: "2.7px solid #93A683",
                      backgroundColor: "#93A683",
                      color: "#f5f5f5",
                      fontWeight: "400",
                      margin: "10.8",
                      "&:hover": {
                        border: "2.7px solid #202426",
                        backgroundColor: "transparent",
                        color: "#202426",
                      },
                    }}
                  >
                    SAVE CHANGES
                  </Button>
                )}
            </Grid>

            {/* UPLOAD FILE */}

            {canViewSection("hrUploadFiles", propertyCode) && (
              <>
                <Grid container spacing={2} paddingTop={9} paddingLeft={2}>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{
                      padding: "1rem",
                      borderRadius: "36px",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        marginLeft: 1,
                        letterSpacing: "0.3rem",
                        fontWeight: "200",
                        fontSize: "0.9rem",
                        color: "#202426",
                        paddingBottom: "1rem",
                        paddingTop: "1rem",
                      }}
                    >
                      UPLOAD FILE
                    </Typography>

                    <Grid container spacing={2}>
                      {/* Checkboxes and Upload Functions */}
                      <Grid item xs={12}>
                        <Grid container>
                          {fileCategories.map((category) => (
                            <Grid item xs={12} sm={3} key={category}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={selectedCategories.includes(
                                      category,
                                    )}
                                    onChange={() =>
                                      handleCategoryChange(category)
                                    }
                                    sx={{
                                      "&.Mui-checked": {
                                        color: "#FFC107",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <Box>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontWeight: "400",
                                        fontSize: "0.9rem",
                                        fontFamily: "Nunito",
                                        color: "#202426",
                                        textTransform: "uppercase",
                                        letterSpacing: "0.045rem",
                                      }}
                                    >
                                      {category}
                                    </Typography>
                                  </Box>
                                }
                              />
                            </Grid>
                          ))}
                        </Grid>

                        <Grid
                          container
                          alignItems="center"
                          justifyContent="left"
                          spacing={2}
                          paddingTop={3}
                        >
                          <Grid item>
                            <input
                              type="file"
                              accept=".pdf,.jpg,.jpeg,.png"
                              onChange={handleFileChange}
                              id="file-upload"
                              style={{ display: "none" }}
                            />
                            <label htmlFor="file-upload">
                              <Button
                                component="span"
                                sx={{
                                  marginLeft: "1rem",
                                  backgroundColor: "transparent",
                                  borderRadius: "9px",
                                  padding: "9px 9px 9px 9px",
                                  color: "#202426",
                                  fontFamily: "Outfit",
                                  fontWeight: "400",
                                  fontSize: "0.72rem",
                                  border: "2.7px solid #202426",

                                  "&:hover": {
                                    backgroundColor: "lightgrey",
                                    color: "#202426",
                                  },
                                }}
                              >
                                CHOOSE FILE
                              </Button>
                            </label>
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={handleFileUpload}
                              disabled={
                                !file || selectedCategories.length === 0
                              }
                              sx={{
                                ...greyButton,
                              }}
                            >
                              UPLOAD
                            </Button>
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{
                                fontFamily: "Nunito",
                                fontWeight: 400,
                                color: "#202426",
                                letterSpacing: "0.027rem",
                                textTransform: "lowercase",
                              }}
                            >
                              {fileName ||
                                "Select file categories above, then upload file"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {/* FILING CABINET */}

            <FilingCabinet employeeFiles={employeeFiles} user={user} />

            {/* Other Items Section */}

            <Grid
              container
              spacing={2}
              paddingTop={9}
              paddingLeft={2}
              paddingBottom={3}
            >
              <Grid
                item
                xs={12}
                container
                sx={{
                  padding: "1rem",
                  borderRadius: "36px",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Grid item xs={12} container>
                  {renderCustomSection(
                    thirdSection,
                    "OTHER",
                    recordData.Status === "Terminated",
                  )}
                </Grid>
              </Grid>
            </Grid>

            {/* NOTES Section */}

            {canViewSection("hrAddEmployeeNotes", propertyCode) && (
              <>
                <Grid container spacing={2} paddingTop={9} paddingLeft={2}>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{
                      padding: "1rem",
                      borderRadius: "36px",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        marginLeft: 1,
                        letterSpacing: "0.3rem",
                        fontWeight: "200",
                        fontSize: "0.9rem",
                        color: "#202426",
                        paddingBottom: "1rem",
                        paddingTop: "1rem",
                      }}
                    >
                      EMPLOYEE NOTES
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Add Note"
                          variant="outlined"
                          name="newNote"
                          value={newNote}
                          onChange={(e) => setNewNote(e.target.value)}
                          multiline
                          rows={4}
                          borderRadius={5}
                          sx={{
                            fontFamily: "Outfit",
                            fontWeight: 400,
                            color: "#202426",
                            letterSpacing: "0.027rem",
                            ".MuiInputLabel-root": {
                              fontSize: {
                                xs: "0.63rem",
                                sm: "0.72rem",
                                md: "0.81rem",
                              },
                              fontFamily: "Outfit",
                              fontWeight: 400,
                              color: "#202426",
                              transition: "0.3s",
                              textTransform: "uppercase",
                            },
                            ".MuiInputBase-input": {
                              fontSize: {
                                xs: "0.63rem",
                                sm: "0.72rem",
                                md: "0.81rem",
                              },
                              fontFamily: "Nunito",
                              fontWeight: 400,
                              letterSpacing: "0.018rem",
                              color: "#202426",
                              transition: "0.3s",
                            },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "18px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} container justifyContent="flex-end">
                        <Button
                          onClick={handleAddNote}
                          disabled={!newNote}
                          sx={{
                            ...outlineButton,
                          }}
                        >
                          ADD NOTE
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {canViewSection(
              ["hrViewPayRate", "hrChangePayRate"],
              propertyCode,
            ) && (
              <>
                <Grid container spacing={2} paddingTop={9} paddingLeft={2}>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{
                      padding: "1rem",
                      borderRadius: "36px",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        marginLeft: 1,
                        letterSpacing: "0.3rem",
                        fontWeight: "200",
                        fontSize: "0.9rem",
                        color: "#202426",
                        paddingBottom: "1rem",
                        paddingTop: "1rem",
                      }}
                    >
                      EXISTING NOTES
                    </Typography>
                    {recordData.Notes && (
                      <ul>
                        {recordData.Notes.map((note, index) => (
                          <li key={index}>{note}</li>
                        ))}
                      </ul>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}

        {formType === "Property" && (
          <>
            <Grid container spacing={2} paddingTop={9}>
              <Grid
                item
                xs={12}
                container
                sx={{
                  padding: "1rem",
                  borderRadius: "36px",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginLeft: 1.8,
                    fontFamily: "Outfit",
                    letterSpacing: "0.09rem",
                    fontWeight: "800",
                    fontSize: "2.07rem",
                    textTransform: "lowercase",
                    color: "black",
                    paddingBottom: "1.8rem",
                    paddingTop: "1.8rem",
                  }}
                >
                  PROPERTY INFO
                </Typography>
                <Grid container spacing={2}>
                  {renderCustomSection(
                    propertySectionReadOnly,
                    "PROPERTY INFO",
                    true,
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} paddingTop={9}>
              <Grid
                item
                xs={12}
                container
                sx={{
                  padding: "1rem",
                  borderRadius: "36px",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginLeft: 2.7,
                    fontFamily: "Outfit",
                    letterSpacing: "0.09rem",
                    fontWeight: "800",
                    fontSize: "2.07rem",
                    textTransform: "lowercase",
                    color: "black",
                    paddingBottom: "1.8rem",
                    paddingTop: "1.8rem",
                  }}
                >
                  ADMIN USERS
                </Typography>

                <Grid
                  item
                  xs={12}
                  container
                  sx={{
                    padding: "1rem",
                    borderRadius: "36px",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  {renderAdminEmailFields()}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        <Dialog
          open={dialogOpen}
          onClose={() => handleDialogClose(false)}
          PaperProps={{
            sx: {
              ...dialogMain,
            },
          }}
        >
          <DialogTitle
            sx={{
              ...dialogTitle,
            }}
          >
            CONFIRM CHANGES
          </DialogTitle>
          <DialogContent
            sx={{
              letterSpacing: "0.4rem",
              fontWeight: "400",
              fontSize: "1rem",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} container>
                {formType === "Employee" && (
                  <>
                    <Grid
                      item
                      sx={{
                        marginLeft: 9,
                        letterSpacing: "0.3rem",
                        fontWeight: "200",
                        fontSize: "0.9rem",
                        color: "#202426",
                        paddingBottom: "1rem",
                        paddingTop: "1rem",
                      }}
                    >
                      {Object.keys(changes).map((field) => {
                        const { oldValue, newValue, fieldName } =
                          changes[field];
                        return (
                          <Typography variant="body2" key={field}>
                            {`${fieldName}: ${oldValue} -> ${newValue} (Section: ${getSectionForField(
                              field,
                            )})`}
                          </Typography>
                        );
                      })}
                    </Grid>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        ...textfieldSx,
                      }}
                    >
                      WHICH PERIOD DOES THIS CHANGE BELONG TO?
                    </Typography>

                    <FormControl fullWidth>
                      <InputLabel
                        sx={{
                          ...selectInputLabel,
                        }}
                      >
                        PAY PERIOD
                      </InputLabel>
                      <Select
                        value={selectedPayPeriod}
                        onChange={(e) => setSelectedPayPeriod(e.target.value)}
                        sx={{ ...selectSelect }}
                      >
                        <MenuItem
                          value="current"
                          sx={{
                            ...selectMenuItem,
                          }}
                        >
                          {`${payPeriodStart?.toLocaleDateString()} - ${
                            payPeriodEnd
                              ? subtractOneDay(
                                  payPeriodEnd.toLocaleDateString(),
                                )
                              : ""
                          }`}
                        </MenuItem>
                        <MenuItem
                          value="previous"
                          sx={{
                            ...selectMenuItem,
                          }}
                        >
                          {`${previousPayPeriodStart?.toLocaleDateString()} - ${
                            previousPayPeriodEnd
                              ? subtractOneDay(
                                  previousPayPeriodEnd.toLocaleDateString(),
                                )
                              : ""
                          }`}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleDialogClose(false)}
              color="primary"
              sx={{
                ...greyButton,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleDialogClose(true)}
              color="primary"
              disabled={formType === "Employee" && !selectedPayPeriod}
              sx={{
                ...greenButton,
              }}
            >
              CONFIRM
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
          PaperProps={{
            sx: {
              ...dialogMain,
            },
          }}
        >
          <DialogTitle
            sx={{
              ...dialogTitle,
            }}
          >
            {confirmationType === "Terminate"
              ? "CONFIRM TERMINATION"
              : "CONFIRM RE-HIRE"}
          </DialogTitle>
          <DialogContent
            sx={{
              ...dialogContent,
            }}
          >
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                ...textfieldSx,
                textTransform: "lowercase",
              }}
            >
              Are you sure you want to{" "}
              {confirmationType === "Terminate" ? "terminate" : "re-hire"} this
              employee?
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Select Date"
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue)}
                format="MM/dd/yyyy"
                sx={{ ...textfieldSx }}
              />
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setConfirmDialogOpen(false)}
              color="primary"
              sx={{
                ...greyButton,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (confirmationType === "Terminate") {
                  handleTerminate();
                } else {
                  handleReHire();
                }
                setConfirmDialogOpen(false);
              }}
              color="primary"
              sx={{
                ...greenButton,
              }}
              disabled={!selectedDate}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={phoneDialogOpen}
          onClose={() => handlePhoneDialogClose(false)}
          PaperProps={{
            sx: {
              ...dialogMain,
            },
          }}
        >
          <DialogTitle
            sx={{
              ...dialogTitle,
            }}
          >
            CONFIRM PHONE NUMBER CHANGE
          </DialogTitle>
          <DialogContent>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "1.17rem",
              }}
            >
              Are you sure you want to change the phone number to{" "}
              {newPhoneNumber}{" "}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handlePhoneDialogClose(false)}
              color="primary"
              sx={{
                ...greyButton,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handlePhoneDialogClose(true)}
              color="primary"
              sx={{
                ...greenButton,
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  };

  const handleTerminate = async () => {
    setIsSubmitting(true);
    try {
      if (!selectedDate) {
        alert("Please select a date before confirming.");
        return;
      }
      const payload = {
        propCode: propertyCode,
        EmpID: recordData.EmpID,
        faceid: recordData.faceid,
      };

      await axios.post(
        "https://prod-21.westus.logic.azure.com:443/workflows/642890dd951a4d17acb90bbd69409e63/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ZWLbvMgupY4-O4_MXpLOTcSre6f0P4vAHgeBnj-QrO8",
        payload,
      );

      const formattedDate = selectedDate.toLocaleDateString();

      handleFieldChange("Status", "Terminated");
      handleFieldChange("Notes", [
        ...recordData.Notes,
        `Terminated (${formattedDate}) - ${new Date().toLocaleDateString()}`,
      ]);

      await updateDoc(doc(db, "empRegister", selectedEmployee), {
        Status: "Terminated",
        terminated: "TERMINATED",
        Notes: [
          ...recordData.Notes,
          `Terminated (${formattedDate}) - ${new Date().toLocaleDateString()}`,
        ],
        // clearedBy: [],
        DateUpdated: new Date().toISOString(),
        timeclock: "no",
      });

      const payrollChangesRef = collection(db, "payrollChanges");
      await addDoc(payrollChangesRef, {
        EmpID: recordData.EmpID,
        NameFirst: recordData.NameFirst,
        NameLast: recordData.NameLast,
        changes: [`Status: Terminated ${formattedDate}`],
        payPeriod: subtractOneDay(payPeriodEnd?.toLocaleDateString()),
        ChangedBy: user.email,
        propCode: propertyCode,
      });

      alert("Employee terminated!");
      setChanges({});
      setSelectedDate({});
    } catch (error) {
      console.error("Error during termination:", error);
      alert("Failed to terminate employee: " + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReHire = async () => {
    setIsSubmitting(true);
    try {
      if (!selectedDate) {
        alert("Please select a date before confirming.");
        return;
      }
      const originalDateHired = recordData.DateHired
        ? new Date(recordData.DateHired)
        : null;

      const formattedOriginalDate = originalDateHired
        ? format(originalDateHired, "MM/dd/yyyy")
        : "";

      const newDateHired = new Date();
      const formattedNewDateHired = format(newDateHired, "MM-dd-yyyy");
      const formattedRealDateHired = selectedDate.toLocaleDateString();

      handleFieldChange("Status", "Updated");
      handleFieldChange("DateHired", formattedNewDateHired);
      handleFieldChange("Notes", [
        ...recordData.Notes,
        `Rehired (${formattedRealDateHired}) - (Originally Hired: ${formattedOriginalDate})`,
      ]);

      await updateDoc(doc(db, "empRegister", selectedEmployee), {
        Status: "REHIRED",
        DateHired: formattedNewDateHired,
        Notes: [
          ...recordData.Notes,
          `Rehired (${formattedRealDateHired}) - (Originally Hired: ${formattedOriginalDate})`,
        ],
        // clearedBy: [],
        DateUpdated: new Date().toISOString(),
        terminated: "",
      });

      const payrollChangesRef = collection(db, "payrollChanges");
      await addDoc(payrollChangesRef, {
        EmpID: recordData.EmpID,
        NameFirst: recordData.NameFirst,
        NameLast: recordData.NameLast,
        changes: [`Status: Rehired, DateHired: ${formattedRealDateHired}`],
        payPeriod: subtractOneDay(payPeriodEnd?.toLocaleDateString()),
        ChangedBy: user.email,
        propCode: propertyCode,
      });

      alert("Employee rehired!");
      setChanges({});
      setSelectedDate({});
    } catch (error) {
      console.error("Error during re-hire:", error);
      alert("Failed to re-hire employee: " + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <>
      {/* Switch to toggle between Update and Add New */}
      {actionType && !previewOnly && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            borderRadius: "0.9rem",
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="left"
            spacing={2}
            style={{ margin: "9px" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                backgroundColor: "transparent",
                borderRadius: "1.8rem",
                paddingBottom: "0.9rem",
              }}
            >
              {(actionType === "update" || actionType === "addNew") &&
                !propertyCode && (
                  <Grid item xs={6} sm={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={actionType === "addNew"}
                          onChange={handleMainSwitch}
                          name="actionTypeSwitch"
                          color="primary"
                          sx={{
                            "& .MuiSwitch-switchBase": {
                              "&.Mui-checked": {
                                color: "#93A683",
                                "& + .MuiSwitch-track": {
                                  backgroundColor: "#f5f5f5",
                                  border: "2.7px solid lightgrey",
                                },
                              },
                              "&.Mui-checked:hover": {
                                backgroundColor: "rgba(249, 139, 133, 0.08)",
                              },
                              "&.Mui-checked .MuiSwitch-thumb": {
                                backgroundColor: "#93A683",
                                border: "1.8px solid #93A683",
                              },
                              "& .MuiSwitch-thumb": {
                                backgroundColor: "#93A683",
                                "&::before": {
                                  content: '""',
                                  position: "absolute",
                                  width: "100%",
                                  height: "100%",
                                  backgroundSize: "cover",
                                },
                              },
                            },
                            "& .MuiSwitch-thumb": {
                              backgroundColor: "#93A683",
                              border: "1.8px solid #93A683",
                              "&::before": {
                                content: '""',
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                backgroundSize: "cover",
                              },
                            },
                            "& .MuiSwitch-track": {
                              backgroundColor: "#f5f5f5",
                              border: "2.7px solid lightgrey",
                            },
                          }}
                        />
                      }
                      label={
                        actionType === "addNew" ? "ADD EMPLOYEE" : "UPDATE"
                      }
                      labelPlacement="start"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontFamily: "Outfit",
                          letterSpacing: "0.09rem",
                          fontWeight: "800",
                          fontSize: "2.07rem",
                          color: "#202426",
                          textTransform: "lowercase",
                          marginRight: "0.9rem",
                          position: "relative",
                          "::after": {
                            content: '""',
                            position: "absolute",
                            left: 0,
                            bottom: 0.9,
                            width: "100%",
                            height: "2.7px",
                            backgroundColor: "grey",
                          },
                        },
                      }}
                    />
                  </Grid>
                )}

              {actionType === "update" && !propertyCode && (
                <>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formType === "Employee"}
                          onChange={(event) => {
                            setPropertyCode("");
                            setSelectedEmployee("");
                            setFormType(
                              event.target.checked ? "Employee" : "Property",
                            );
                          }}
                          name="formTypeSwitch"
                          color="primary"
                          sx={{
                            "& .MuiSwitch-switchBase": {
                              "&.Mui-checked": {
                                color: "#FFC107",
                                "& + .MuiSwitch-track": {
                                  backgroundColor: "#f5f5f5",
                                  border: "2.7px solid lightgrey",
                                },
                              },
                              "&.Mui-checked:hover": {
                                backgroundColor: "rgba(249, 139, 133, 0.08)",
                              },
                              "&.Mui-checked .MuiSwitch-thumb": {
                                backgroundColor: "#FFC107",
                                border: "1.8px solid #FFC107",
                              },
                              "& .MuiSwitch-thumb": {
                                backgroundColor: "#FFC107",
                                "&::before": {
                                  content: '""',
                                  position: "absolute",
                                  width: "100%",
                                  height: "100%",
                                  backgroundSize: "cover",
                                },
                              },
                            },
                            "& .MuiSwitch-thumb": {
                              backgroundColor: "#FFC107",
                              border: "1.8px solid #FFC107",
                              "&::before": {
                                content: '""',
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                backgroundSize: "cover",
                              },
                            },
                            "& .MuiSwitch-track": {
                              backgroundColor: "#f5f5f5",
                              border: "2.7px solid lightgrey",
                            },
                          }}
                        />
                      }
                      label={formType === "Employee" ? "EMPLOYEE" : "PROPERTY"}
                      labelPlacement="end"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontFamily: "Outfit",
                          letterSpacing: "0.09rem",
                          fontWeight: "800",
                          fontSize: "2.07rem",
                          color: "#202426",
                          textTransform: "lowercase",
                          marginLeft: "0.9rem",
                        },
                      }}
                    />
                  </Grid>
                </>
              )}
            </Box>
            {actionType === "update" &&
              formType === "Property" &&
              propertyCode && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    backgroundColor: "transparent",
                    borderRadius: "1.8rem",
                    paddingBottom: "0.9rem",
                  }}
                >
                  <Grid item>
                    <Typography
                      sx={{
                        fontFamily: "Outfit",
                        letterSpacing: "0.09rem",
                        fontWeight: "800",
                        fontSize: "2.07rem",
                        color: "#202426",
                        textTransform: "lowercase",
                        marginLeft: "0.9rem",
                        position: "relative",
                        "::after": {
                          content: '""',
                          position: "absolute",
                          left: 0,
                          bottom: 0.9,
                          width: "100%",
                          height: "2.7px",
                          backgroundColor: "grey",
                        },
                      }}
                    >
                      UPDATE PROPERTY
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginLeft: "1rem",
                      }}
                    >
                      <Tooltip title="RELOAD">
                        <span>
                          <IconButton
                            style={{
                              backgroundColor: "transparent",
                              border: "0",
                              color: "#202426",
                              fontSize: "1.8rem",
                            }}
                            onClick={handleReset}
                            label="RELOAD"
                          >
                            <IoRefreshOutline />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Box>
              )}
            {actionType === "update" &&
              formType === "Employee" &&
              propertyCode && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    backgroundColor: "transparent",
                    borderRadius: "1.8rem",
                    paddingBottom: "0.9rem",
                  }}
                >
                  <Grid item>
                    <Typography
                      sx={{
                        fontFamily: "Outfit",
                        letterSpacing: "0.09rem",
                        fontWeight: "800",
                        fontSize: "2.07rem",
                        color: "#202426",
                        textTransform: "lowercase",
                        marginLeft: "0.9rem",
                        position: "relative",
                        "::after": {
                          content: '""',
                          position: "absolute",
                          left: 0,
                          bottom: 0.9,
                          width: "100%",
                          height: "2.7px",
                          backgroundColor: "grey",
                        },
                      }}
                    >
                      UPDATE EMPLOYEE
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginLeft: "1rem",
                      }}
                    >
                      <Tooltip title="RELOAD">
                        <span>
                          <IconButton
                            style={{
                              backgroundColor: "transparent",
                              border: "0",
                              color: "#202426",
                              fontSize: "1.8rem",
                            }}
                            onClick={handleReset}
                            label="RELOAD"
                          >
                            <IoRefreshOutline />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Box>
              )}
          </Grid>
        </Box>
      )}

      {/* Conditionally render form based on actionType */}
      {actionType === "update" && (
        <div>
          <Grid
            container
            alignItems="center"
            justifyContent="left"
            spacing={2}
            style={{ margin: "9px" }}
          >
            <Grid item>
              <Grid container direction="row" alignItems="center" spacing={2}>
                {/* Property Code Selector */}
                {!previewOnly && (
                  <>
                    <Grid item>
                      <FormControl
                        variant="outlined"
                        sx={{
                          minWidth: "15rem",
                          "@media (max-width: 600px)": { minWidth: "9rem" },
                        }}
                      >
                        <InputLabel
                          id="property-select-label"
                          htmlFor="property-code"
                          sx={{
                            ...selectInputLabel,
                          }}
                        >
                          TEAM
                        </InputLabel>
                        <Select
                          labelId="property-select-label"
                          value={propertyCode || ""}
                          onChange={handlePropertyCodeChange}
                          inputProps={{
                            name: "property-code",
                            id: "property-code",
                          }}
                          label="PROPERTY"
                          sx={{
                            ...selectSelect,
                          }}
                        >
                          {(formType === "Employee"
                            ? propertiesModifyEmployee
                            : propertiesModifyProperty
                          )
                            .slice()
                            .sort((a, b) => {
                              const nameA = (
                                a.propertyCode ||
                                a.propertyName ||
                                ""
                              ).toString();
                              const nameB = (
                                b.propertyCode ||
                                b.propertyName ||
                                ""
                              ).toString();
                              return nameA.localeCompare(nameB);
                            })
                            .map((property) => (
                              <MenuItem
                                key={property.propertyCode}
                                value={property.propertyCode}
                                sx={{
                                  ...selectMenuItem,
                                }}
                              >
                                {property.propertyName
                                  ? `${property.propertyCode} - ${property.propertyName}`
                                  : property.propertyCode}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}

                {formType === "Employee" && !previewOnly && (
                  <>
                    {/* ACTIVE/INACTIVE USER TOGGLE */}
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={employeeStatus === "Active"}
                            onChange={handleSwitchChange}
                            name="employeeStatusSwitch"
                            color="default"
                            sx={{
                              "& .MuiSwitch-switchBase": {
                                "&.Mui-checked": {
                                  color: "#74c365",
                                  "& + .MuiSwitch-track": {
                                    backgroundColor: "#74c365",
                                  },
                                },
                                "&.Mui-checked:hover": {
                                  backgroundColor: "rgba(249, 139, 133, 0.08)",
                                },
                                "&.Mui-checked .MuiSwitch-thumb": {
                                  backgroundColor: "#74c365",
                                },
                                "& .MuiSwitch-thumb": {
                                  backgroundColor: "#F98B85",
                                  "&::before": {
                                    content: '""',
                                    position: "left",
                                    width: "100%",
                                    height: "100%",
                                    backgroundSize: "cover",
                                  },
                                },
                              },
                              "& .MuiSwitch-thumb": {
                                backgroundColor: "#F98B85",
                                "&::before": {
                                  content: '""',
                                  position: "left",
                                  width: "100%",
                                  height: "100%",
                                  backgroundSize: "cover",
                                },
                              },
                              "& .MuiSwitch-track": {
                                backgroundColor: "#F98B85",
                              },
                              marginLeft: "9px",
                            }}
                          />
                        }
                        label={
                          employeeStatus === "Active" ? "ACTIVE" : "INACTIVE"
                        }
                        labelPlacement="top"
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontFamily: "Outfit",
                            fontWeight: 800,
                            color: "#202426",
                            fontSize: "0.72rem",
                            letterSpacing: "0.09rem",
                          },
                        }}
                      />
                    </Grid>
                    {/* Employee Selector */}
                    <Grid item>
                      <FormControl
                        sx={{
                          minWidth: "15rem",
                          "@media (max-width: 600px)": {
                            minWidth: "9rem",
                          },
                        }}
                      >
                        <InputLabel
                          htmlFor="employee-selector"
                          sx={{
                            ...selectInputLabel,
                          }}
                        >
                          TEAM MEMBER
                        </InputLabel>
                        <Select
                          value={selectedEmployee}
                          onChange={(event) => handleEmployeeChange(event)}
                          label="Employee"
                          sx={{
                            ...selectSelect,
                          }}
                        >
                          <MenuItem
                            sx={{
                              ...selectMenuItem,
                            }}
                            value=""
                          >
                            {" "}
                          </MenuItem>
                          {filteredEmployees.map((emp) => (
                            <MenuItem
                              key={emp.EmpID}
                              value={emp.EmpID}
                              sx={{
                                ...selectMenuItem,
                              }}
                            >
                              {emp.NameFirst} {emp.NameLast}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Dialog
              open={confirmApproveDocDialogOpen}
              onClose={() => handleApproveDialogClose(false)}
              PaperProps={{
                sx: {
                  ...dialogMain,
                },
              }}
            >
              <IconButton
                onClick={() => handleApproveDialogClose(false)}
                sx={{
                  ...dialogBox,
                }}
              >
                <TfiClose />
              </IconButton>
              <DialogTitle
                sx={{
                  ...dialogTitle,
                }}
              >
                APPROVE DOCUMENT
              </DialogTitle>
              <DialogContent
                sx={{
                  ...dialogContent,
                }}
              >
                <DialogContentText>
                  Are you sure you want to approve the {fileToApprove} document?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => handleApproveDialogClose(true)}
                  color="primary"
                  autoFocus
                  fullWidth
                  sx={{
                    ...greenButton,
                  }}
                >
                  APPROVE
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={confirmRejectDocDialogOpen}
              onClose={() => handleRejectDialogClose(false)}
              PaperProps={{
                sx: {
                  ...dialogMain,
                },
              }}
            >
              <IconButton
                onClick={() => handleRejectDialogClose(false)}
                sx={{
                  ...dialogBox,
                }}
              >
                <TfiClose />
              </IconButton>
              <DialogTitle
                sx={{
                  ...dialogTitle,
                }}
              >
                REJECT DOCUMENT
              </DialogTitle>
              <DialogContent
                sx={{
                  ...dialogContent,
                }}
              >
                <DialogContentText>
                  Are you sure you want to reject the {fileToReject} file?
                  Please provide a reason for rejection.
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Rejection Reason"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={rejectReason}
                  onChange={(e) => setRejectReason(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => handleRejectDialogClose(true)}
                  color="primary"
                  autoFocus
                  fullWidth
                  sx={{
                    ...redButton,
                  }}
                >
                  REJECT
                </Button>
              </DialogActions>
            </Dialog>

            {renderForm()}

            <Snackbar
              sx={{
                width: 900,
                color: "secondary",
                "& .MuiSnackbarContent-root": {
                  backgroundColor: "grey",
                  fontSize: "0.9rem",
                  fontWeight: "800",
                },
                "& .MuiButton-root": {
                  color: "black",
                  fontSize: "0.9rem",
                  fontWeight: "800",
                },
              }}
              open={snackbarOpen}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              TransitionComponent={Slide}
              TransitionProps={{ enter: true, exit: false }}
              autoHideDuration={3699}
              onClose={handleSnackbarClose}
              message={
                <span
                  style={{
                    fontFamily: "Nunito",
                    fontSize: "0.99rem",
                    fontWeight: "400",
                    letterSpacing: "0.036rem",
                    textTransform: "lowercase",
                  }}
                >
                  {snackbarMessage}
                </span>
              }
            ></Snackbar>

            {/* Submission Dialog */}
            <SubmissionDialog
              open={isSubmitting}
              onClose={() => {}}
              isSubmitting={isSubmitting}
            />
          </Grid>
        </div>
      )}

      {actionType === "addNew" && (
        <div>
          <AddProperty />
        </div>
      )}
    </>
  );
}

const textfieldSx = {
  marginBottom: "0.9rem",
  marginTop: "0.9rem",
  transition: "0.3s",
  maxWidth: "18rem",
  fontFamily: "Outfit",
  fontWeight: 400,
  color: "#202426",
  letterSpacing: "0.027rem",
  textTransform: "uppercase",
  ".MuiInputLabel-root": {
    fontSize: { xs: "0.63rem", sm: "0.72rem", md: "0.81rem" },
    fontFamily: "Outfit",
    fontWeight: 400,
    color: "#202426",
    transition: "0.3s",
    textTransform: "uppercase",
  },
  ".MuiInputBase-input": {
    fontSize: { xs: "0.63rem", sm: "0.72rem", md: "0.81rem" },
    fontFamily: "Nunito",
    fontWeight: 400,
    letterSpacing: "0.018rem",
    color: "#202426",
    transition: "0.3s",
    textTransform: "uppercase",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "18px",
  },
};
const dialogMain = {
  padding: "1rem",
  borderRadius: "36px",
  backgroundColor: "#f5f5f5",
};
const dialogBox = {
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  top: 9,
  right: 9,
  backgroundColor: "#f5f5f5",
  padding: "0.9rem",
};
const dialogTitle = {
  fontFamily: "Outfit",
  letterSpacing: "0.09rem",
  fontWeight: "800",
  fontSize: "2.07rem",
  textTransform: "lowercase",
  color: "black",
  backgroundColor: "transparent",
  borderRadius: "18px",
};
const dialogContent = {
  fontFamily: "Outfit",
  letterSpacing: "0.4rem",
  fontWeight: "400",
  fontSize: "1rem",
};
const selectFormControl = {
  minWidth: "15rem",
  "@media (max-width: 600px)": {
    minWidth: "9rem",
  },
};
const selectInputLabel = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Outfit",
  fontWeight: 400,
  color: "#202426",
  transition: "0.3s",
  textTransform: "uppercase",
};
const selectSelect = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Nunito",
  backgroundColor: "#ffffff",
  borderRadius: "18px",
  textTransform: "uppercase",
};
const selectSelectClear = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Nunito",
  backgroundColor: "transparent",
  borderRadius: "18px",
  textTransform: "uppercase",
};
const selectMenuItem = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Nunito",
  fontWeight: 400,
  letterSpacing: "0.045rem",
  color: "#202426",
  transition: "0.3s",
  textTransform: "uppercase",
};
const greyButton = {
  borderRadius: "9px",
  padding: "9px 18px 9px 18px",
  fontWeight: "400",
  fontSize: "0.81rem",
  fontFamily: "Outfit",
  letterSpacing: "0.09rem",
  border: "2.7px solid lightgrey",
  backgroundColor: "lightgrey",
  color: "#202426",
  "&:hover": {
    border: "2.7px solid #202426",
    backgroundColor: "transparent",
    color: "#202426",
  },
};
const outlineButton = {
  backgroundColor: "transparent",
  borderRadius: "9px",
  padding: "9px 18px 9px 18px",
  color: "#202426",
  fontFamily: "Outfit",
  fontWeight: "400",
  fontSize: "0.81rem",
  border: "2.7px solid #202426",
  marginRight: "0.45rem",
  "&:hover": {
    backgroundColor: "lightgrey",
    border: "2.7px solid lightgrey",
    color: "#202426",
  },
};
const greenButton = {
  padding: "9px 18px 9px 18px",
  borderRadius: "9px",
  fontSize: "0.81rem",
  fontFamily: "Outfit",
  letterSpacing: "0.09rem",
  border: "2.7px solid #93A683",
  backgroundColor: "#93A683",
  color: "#f5f5f5",
  fontWeight: "400",
  margin: "10.8",
  "&:hover": {
    border: "2.7px solid #202426",
    backgroundColor: "transparent",
    color: "#202426",
  },
};
const redButton = {
  border: "2.7px solid #BB2E33",
  backgroundColor: "#BB2E33",
  color: "#f5f5f5",
  borderRadius: "9px",
  padding: "9px 18px 9px 18px",
  fontWeight: "400",
  fontSize: "0.81rem",
  fontFamily: "Outfit",
  letterSpacing: "0.09rem",
  margin: "10.8",
  "&:hover": {
    border: "2.7px solid #202426",
    backgroundColor: "transparent",
    color: "#202426",
  },
};
