import React, { useState, useEffect, useMemo } from "react";
import { db } from "../../firebase/firebase-config";
import { useAuth } from "../../contexts/AuthContext";
import moment from "moment";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  onSnapshot,
  orderBy,
  doc,
  Timestamp,
} from "firebase/firestore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Box,
  Card,
  Typography,
  Autocomplete,
  Chip,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { FaFilePdf } from "react-icons/fa";

const PunchTimecards = () => {
  const { user, userPermissions, userRole, availableProperties } = useAuth();
  const [payRollData, setPayRollData] = useState([]);
  const [propertyCode, setPropertyCode] = useState([]);
  const [selectedPropertyCode, setSelectedPropertyCode] = useState("");
  const [currentPayRollIndex, setCurrentPayRollIndex] = useState(0);
  const [missedPunches, setMissedPunches] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [payPeriodStart, setPayPeriodStart] = useState(null);
  const [payPeriodEnd, setPayPeriodEnd] = useState(null);
  const [payCycle, setPayCycle] = useState("");
  const [departments, setDepartments] = useState([]);
  const [employeeData, setEmployeeData] = useState(null);
  const [allEmployees, setAllEmployees] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [setPermissionsForProperty] = useState({});
  const [usedDepartments, setUsedDepartments] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState("");
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);

  // PERMISSIONS

  const hasPermissionForProperty = (permission, propertyCode) => {
    // If the user's role is 'Global' or 'MGMT', they have all permissions
    if (
      userRole === "Global" ||
      userRole === "MGMT" ||
      userRole === "PropertyAdmin"
    ) {
      return true;
    }

    // Otherwise, check the permissions for the specific property
    const permissionsForProperty =
      userPermissions[propertyCode]?.permissions || {};
    return permissionsForProperty[permission] === true;
  };

  // Filter availableProperties to include only those with 'landPayroll' permission
  const viewPayrollSummary = useMemo(
    () =>
      availableProperties.filter((prop) => {
        return (
          userPermissions &&
          hasPermissionForProperty("summaryTimecards", prop.propertyCode)
        );
      }),
    [availableProperties, userPermissions],
  );

  useEffect(() => {
    if (!propertyCode || !userPermissions[propertyCode]) return;

    // Avoid setting state if permissions haven't changed to prevent re-renders
    const propertyPermissions = userPermissions[propertyCode].permissions || {};
    const permissionsArray = Object.keys(propertyPermissions).filter(
      (key) => propertyPermissions[key] === true,
    );

    setPermissionsForProperty((prevPermissions) => {
      // Only update state if permissions have changed
      const permissionsChanged =
        JSON.stringify(prevPermissions) !== JSON.stringify(permissionsArray);
      return permissionsChanged ? permissionsArray : prevPermissions;
    });
  }, [propertyCode, userPermissions]);

  // Function to determine if a user can view a section
  const canViewSection = (permissions = [], propertyCode) => {
    const requiredPermissions = Array.isArray(permissions)
      ? permissions
      : [permissions];

    // Grant access to Global, MGMT, and PropertyAdmin roles
    if (["Global", "MGMT", "PropertyAdmin"].includes(userRole)) return true;

    // Check specific permissions for the property
    return requiredPermissions.every((permission) => {
      const propertyPermissions =
        userPermissions[propertyCode]?.permissions || {};
      return propertyPermissions[permission] === true;
    });
  };

  const handlePropertyCodeChange = (event) => {
    const newPropertyCode = event.target.value;

    // Update the selected property code in AuthContext
    setSelectedPropertyCode(newPropertyCode);
  };

  useEffect(() => {
    if (selectedPropertyCode) {
      const fetchDepartments = async () => {
        try {
          const propertyRef = doc(db, "Properties", selectedPropertyCode);
          const propertySnap = await getDoc(propertyRef);

          if (propertySnap.exists()) {
            const propertyData = propertySnap.data();
            console.log("Retrieved Dept field:", propertyData.Dept);

            const allDepartments = propertyData.Dept || [];

            // If the user has permission to view all sections, set all departments
            if (canViewSection("viewAllDepartments", selectedPropertyCode)) {
              setUsedDepartments(allDepartments);
            } else {
              // Filter departments by checking permissions
              const filteredDepartments = allDepartments.filter((dept) =>
                canViewSection(dept, selectedPropertyCode),
              );
              setUsedDepartments(filteredDepartments);
            }
          } else {
            console.error(
              "No property document found for code:",
              selectedPropertyCode,
            );
            setUsedDepartments([]); // Clear in case of no data
          }
        } catch (error) {
          console.error("Error fetching property document:", error);
          setUsedDepartments([]); // Clear on error
        }
      };

      fetchDepartments();
    }
  }, [selectedPropertyCode, userPermissions, userRole]);

  useEffect(() => {
    if (selectedDate) {
      const newIndex = payRollData.findIndex(
        (period) =>
          new Date(period.PeriodEndDate.toDate())
            .toISOString()
            .split("T")[0] === selectedDate,
      );
      if (newIndex !== -1) {
        setCurrentPayRollIndex(newIndex);
      }
    }
  }, [selectedDate, payRollData]);

  useEffect(() => {
    const q = query(
      collection(db, "empRegister"),
      where("PropID", "==", selectedPropertyCode),
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const employees = snapshot.docs.map((doc) => ({
        id: doc.id,
        EmployeeId: doc.data().EmpID,
        FirstName: doc.data().NameFirst,
        LastName: doc.data().NameLast,
        Phone: doc.data().AdPhone,
        PayD1: doc.data().PayD1,
        PayD1_Rate: doc.data().PayD1_Rate,
        PayD1_Type: doc.data().PayD1_Type,
        PayD2: doc.data().PayD2,
        PayD2_Rate: doc.data().PayD2_Rate,
        PayD2_Type: doc.data().PayD2_Type,
        Status: doc.data().Status,
        PRR: doc.data().PRR,
        Email: doc.data().Email,
      }));

      setAllEmployees(employees);
    });

    return unsubscribe;
  }, [selectedPropertyCode]);

  const toDate = (value) => {
    if (value instanceof Timestamp) {
      return value.toDate();
    }
    // If value is not a Firebase Timestamp, assume it's a Date
    return value;
  };

  useEffect(() => {
    const fetchProperties = async () => {
      if (!selectedPropertyCode) return;

      const propertiesDoc = await getDoc(
        doc(db, "Properties", selectedPropertyCode),
      );
      const { payPeriod, payCycle } = propertiesDoc.data();
      const currentDate = new Date();
      let payPeriodStartDate = new Date(payPeriod.seconds * 1000);
      let payPeriodEndDate;

      setPayCycle(payCycle);

      switch (payCycle) {
        case "Monthly":
          while (payPeriodStartDate < currentDate) {
            payPeriodEndDate = new Date(payPeriodStartDate);
            payPeriodEndDate.setMonth(payPeriodEndDate.getMonth() + 1);
            if (payPeriodEndDate > currentDate) break;
            payPeriodStartDate = new Date(payPeriodEndDate);
          }
          break;
        case "Bi-Weekly":
          while (payPeriodStartDate < currentDate) {
            payPeriodEndDate = new Date(payPeriodStartDate);
            payPeriodEndDate.setDate(payPeriodEndDate.getDate() + 14);
            if (payPeriodEndDate > currentDate) break;
            payPeriodStartDate = new Date(payPeriodEndDate);
          }
          break;
        case "Weekly":
          while (payPeriodStartDate < currentDate) {
            payPeriodEndDate = new Date(payPeriodStartDate);
            payPeriodEndDate.setDate(payPeriodEndDate.getDate() + 7);
            if (payPeriodEndDate > currentDate) break;
            payPeriodStartDate = new Date(payPeriodEndDate);
          }
          break;
        default:
          break;
      }

      setPayPeriodStart(payPeriodStartDate);
      setPayPeriodEnd(payPeriodEndDate);
    };

    fetchProperties();
  }, [selectedPropertyCode]);

  useEffect(() => {
    if (selectedPropertyCode) {
      const q = query(
        collection(db, "empRegister"),
        where("PropID", "==", selectedPropertyCode),
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const employees = snapshot.docs.map((doc) => ({
          id: doc.id,
          EmployeeId: doc.data().EmpID,
          FirstName: doc.data().NameFirst,
          LastName: doc.data().NameLast,
        }));

        setEmployeeList(employees);

        if (employees.length > 0) {
          setEmployeeName(`${employees[0].FirstName} ${employees[0].LastName}`);
        } else {
          setEmployeeName("");
        }
      });

      return unsubscribe;
    }
  }, [selectedPropertyCode]);

  useEffect(() => {
    if (payPeriodStart && payPeriodEnd && selectedPropertyCode) {
      const fetchPunches = async () => {
        try {
          // Generate the payPeriodId prefix
          const payPeriodStartFormatted = payPeriodStart
            .toISOString()
            .split("T")[0]; // Format as YYYY-MM-DD
          const payPeriodIdPrefix = `${payPeriodStartFormatted}_${selectedPropertyCode}_`;

          const punchesQuery = query(
            collection(db, "PUNCHES"),
            where("payPeriodId", ">=", payPeriodIdPrefix), // Start of the range
            where("payPeriodId", "<", `${payPeriodIdPrefix}\uf8ff`), // End of the range
          );

          const unsubscribe = onSnapshot(punchesQuery, (snapshot) => {
            const punches = snapshot.docs.map((doc) => {
              const data = doc.data();
              return {
                id: doc.id,
                ...data,
                isEditable:
                  data.Status === "Open" ||
                  data.Status === "Manual" ||
                  data.Status === "Modified",
              };
            });

            setEmployees(punches);
          });

          return unsubscribe;
        } catch (error) {
          console.error("Error fetching punches:", error);
        }
      };

      fetchPunches();
    }
  }, [payPeriodStart, payPeriodEnd, selectedPropertyCode]);

  const departmentNames = ["NO-SHOW", "IN-HOUSE", "FIX", "VERIFY"];

  const handleDepartmentChange = (dept) => {
    setSelectedCheckbox(
      (prevSelected) => (prevSelected === dept ? null : dept), // Toggle selection or clear it if already selected
    );
  };

  const handleUsedDepartmentChange = (event, value) => {
    // Maintain logic for "HOUSEKEEPING" and "HOUSEKEEPING /HR"
    const updatedSelections = usedDepartments.reduce((acc, dept) => {
      if (value.includes(dept)) {
        acc[dept] = true;
        if (dept === "HOUSEKEEPING") {
          acc["HOUSEKEEPING /HR"] = true;
        }
      } else {
        acc[dept] = false;
        if (dept === "HOUSEKEEPING") {
          acc["HOUSEKEEPING /HR"] = false;
        }
      }
      return acc;
    }, {});

    setSelectedDepartments(updatedSelections);
  };

  const getTimeDifference = (inTime, outTime) => {
    const difference = outTime - inTime;
    return difference;
  };

  const formatTimeDifference = (difference) => {
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  const differenceToDecimal = (difference) => {
    const hours = difference / (1000 * 60 * 60);
    return hours.toFixed(2);
  };

  const calculateEarningsPerHour = (
    payType,
    payRate,
    employee,
    employeeData,
  ) => {
    if (payType !== "PER ROOM" || !employeeData) {
      return 0;
    }

    const aEarnings = (employee.A || 0) * (employeeData.PayD1_Rate || 0);
    const bEarnings = (employee.B || 0) * (employeeData.PR2 || 0);
    const cEarnings = (employee.C || 0) * (employeeData.PR3 || 0);
    const totalEarnings = aEarnings + bEarnings + cEarnings;

    const inTime =
      employee.InTime instanceof Timestamp
        ? employee.InTime.toDate()
        : employee.InTime;
    const outTime =
      employee.OutTime instanceof Timestamp
        ? employee.OutTime.toDate()
        : employee.OutTime;

    const totalTimeDecimal = differenceToDecimal(
      getTimeDifference(inTime, outTime),
    );

    return totalTimeDecimal > 0 ? totalEarnings / totalTimeDecimal : 0;
  };

  const groupByWeek = (punchesList, payPeriodStart) => {
    const payPeriodStartMoment = moment(payPeriodStart);

    return punchesList.reduce((acc, punch) => {
      const punchDate = punch.InTime.toDate();
      const punchMoment = moment(punchDate);
      const weeksDifference = punchMoment.diff(payPeriodStartMoment, "weeks");
      const weekNumber = 1 + weeksDifference;

      if (!acc[weekNumber]) {
        acc[weekNumber] = [];
      }
      acc[weekNumber].push(punch);
      return acc;
    }, {});
  };

  function groupEmployeesOfEmployeeByWeek(employeesOfEmployee, payPeriodStart) {
    return employeesOfEmployee.reduce((acc, employee) => {
      const inTimeMoment = moment(employee.InTime.toDate());
      const weekNumber = inTimeMoment.diff(payPeriodStart, "weeks");

      if (!acc[weekNumber]) {
        acc[weekNumber] = [];
      }

      acc[weekNumber].push(employee);

      return acc;
    }, {});
  }

  const calculateTotalDecimalWithoutPayTypeRestriction = (
    punches,
    payPeriodStart,
  ) => {
    const punchesByWeek = groupByWeek(punches, payPeriodStart);

    return Object.values(punchesByWeek).reduce((weeklyTotal, punchesInWeek) => {
      const weeklyHours = punchesInWeek.reduce((weekTotal, punch) => {
        if (!punch.InTime || !punch.OutTime) {
          console.error("InTime or OutTime is null or undefined:", punch);
          return weekTotal;
        }
        const inTime = toDate(punch.InTime);
        const outTime = toDate(punch.OutTime);
        const hours = Number(
          differenceToDecimal(getTimeDifference(inTime, outTime)),
        );
        return weekTotal + hours;
      }, 0);
      return weeklyTotal + weeklyHours;
    }, 0);
  };

  const calculateOvertimeHours = (decimalHoursWorked, holidayHours) => {
    const standardWorkWeekHours = 40;
    decimalHoursWorked =
      typeof decimalHoursWorked === "number" ? decimalHoursWorked : 0;
    holidayHours = typeof holidayHours === "number" ? holidayHours : 0;
    const effectiveWorkHours =
      decimalHoursWorked > 40
        ? decimalHoursWorked - holidayHours
        : decimalHoursWorked;

    const overtimeHours = Math.max(
      0,
      effectiveWorkHours - standardWorkWeekHours,
    );

    return overtimeHours;
  };

  const calculateOvertimeForEmployees = (employees, payPeriodStart) => {
    const employeeIds = [
      ...new Set(employees.map((employee) => employee.EmployeeId)),
    ];

    return employeeIds.reduce((totalOvertime, employeeId) => {
      const employeePunches = employees.filter(
        (employee) => employee.EmployeeId === employeeId,
      );

      // Group the punches by week
      const punchesByWeek = groupByWeek(employeePunches, payPeriodStart);

      // Calculate the total overtime for each week
      const totalOvertimeForEmployee = Object.values(punchesByWeek).reduce(
        (total, weekPunches) => {
          const weeklyTotalDecimal =
            calculateTotalDecimalWithoutPayTypeRestriction(
              weekPunches,
              payPeriodStart,
            );
          const weeklyHolidayHours =
            calculateTotalHolidayHoursForEmployees(weekPunches);
          const weeklyOvertime = calculateOvertimeHours(
            weeklyTotalDecimal,
            weeklyHolidayHours,
          );
          return total + weeklyOvertime;
        },
        0,
      );

      return totalOvertime + totalOvertimeForEmployee;
    }, 0);
  };

  const calculateRegularHours = (punches) => {
    const punchesByWeek = groupByWeek(punches, payPeriodStart);

    return Object.values(punchesByWeek).reduce((weeklyTotal, punchesInWeek) => {
      const weeklyHours = punchesInWeek.reduce((weekTotal, punch) => {
        if (punch.PayType === "PER HOUR") {
          let inTime =
            punch.InTime instanceof Date
              ? punch.InTime
              : punch.InTime?.toDate();
          let outTime =
            punch.OutTime instanceof Date
              ? punch.OutTime
              : punch.OutTime?.toDate();

          if (inTime && outTime) {
            return (
              weekTotal +
              Number(differenceToDecimal(getTimeDifference(inTime, outTime)))
            );
          }
        }
        return weekTotal;
      }, 0);

      // Always subtract holiday hours from weekly hours
      const weeklyHolidayHours =
        calculateTotalHolidayHoursForEmployees(punchesInWeek);
      const regularHours = weeklyHours - weeklyHolidayHours;

      return weeklyTotal + Math.min(40, Math.max(0, regularHours));
    }, 0);
  };

  const calculateRegularHoursForEmployees = (employees) => {
    const employeeIds = [
      ...new Set(employees.map((employee) => employee.EmployeeId)),
    ];

    return employeeIds.reduce((total, employeeId) => {
      const employeePunches = employees.filter(
        (employee) => employee.EmployeeId === employeeId,
      );
      const employeeRegularHours = calculateRegularHours(employeePunches);

      return total + Number(employeeRegularHours);
    }, 0);
  };

  const changePayPeriod = (direction) => {
    let newPayPeriodStart = new Date(payPeriodStart);
    let newPayPeriodEnd = new Date(payPeriodEnd);

    switch (payCycle) {
      case "Monthly":
        if (direction === "left") {
          newPayPeriodStart.setMonth(newPayPeriodStart.getMonth() - 1);
          newPayPeriodEnd.setMonth(newPayPeriodEnd.getMonth() - 1);
        } else {
          newPayPeriodStart.setMonth(newPayPeriodStart.getMonth() + 1);
          newPayPeriodEnd.setMonth(newPayPeriodEnd.getMonth() + 1);
        }
        break;
      case "Bi-Weekly":
        if (direction === "left") {
          newPayPeriodStart.setDate(newPayPeriodStart.getDate() - 14);
          newPayPeriodEnd.setDate(newPayPeriodEnd.getDate() - 14);
        } else {
          newPayPeriodStart.setDate(newPayPeriodStart.getDate() + 14);
          newPayPeriodEnd.setDate(newPayPeriodEnd.getDate() + 14);
        }
        break;
      case "Weekly":
        if (direction === "left") {
          newPayPeriodStart.setDate(newPayPeriodStart.getDate() - 7);
          newPayPeriodEnd.setDate(newPayPeriodEnd.getDate() - 7);
        } else {
          newPayPeriodStart.setDate(newPayPeriodStart.getDate() + 7);
          newPayPeriodEnd.setDate(newPayPeriodEnd.getDate() + 7);
        }
        break;
      default:
        break;
    }

    setPayPeriodStart(newPayPeriodStart);
    setPayPeriodEnd(newPayPeriodEnd);
  };

  const formatDate = (date) => {
    const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const day = dayNames[date.getDay()];
    const dayOfMonth = date.getDate();
    return `${dayOfMonth} ${day}`;
  };

  const formatDateTime = (date) => {
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const calculateTotalPTOForEmployees = (employees) => {
    return employees.reduce(
      (total, employee) =>
        total + (employee.PTO ? parseFloat(employee.PTO) : 0),
      0,
    );
  };

  function calculateTotalHolidayHoursForEmployees(employees) {
    return employees.reduce(
      (total, employee) =>
        total + (employee.HolidayHours ? parseFloat(employee.HolidayHours) : 0),
      0,
    );
  }

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const isPayPeriodEndGreaterThanCurrent = new Date(payPeriodEnd) > currentDate;

  const groupedEmployeesByEmployee = employees.reduce((groups, employee) => {
    const employeeId = employee.EmployeeId;
    if (!groups[employeeId]) {
      groups[employeeId] = [];
    }
    groups[employeeId].push(employee);
    return groups;
  }, {});

  const getMissedPunches = async (propertyCode) => {
    const missedPunchesQuery = query(
      collection(db, "missedPunches"),
      where("propCode", "==", propertyCode),
    );
    const missedPunchesSnapshot = await getDocs(missedPunchesQuery);
    let fetchedMissedPunches = missedPunchesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Sort missed punches by FirstName
    fetchedMissedPunches.sort((a, b) => {
      let nameA = a.FirstName.toUpperCase(); // Ignore upper and lowercase
      let nameB = b.FirstName.toUpperCase(); // Ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // Names must be equal
      return 0;
    });

    setMissedPunches(fetchedMissedPunches);
  };

  useEffect(() => {
    if (selectedPropertyCode) {
      getMissedPunches(selectedPropertyCode);
    }
  }, [selectedPropertyCode]);

  let calculatedDate;
  if (payRollData.length > 0 && currentPayRollIndex < payRollData.length) {
    let endDate = payRollData[currentPayRollIndex].PeriodEndDate?.toDate();
    endDate.setDate(endDate.getDate() - 1); // subtract one day
    calculatedDate = endDate.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  }

  // PDF STYLESHEET
  const styles = StyleSheet.create({
    page: { flexDirection: "row", backgroundColor: "#ffffff", padding: 9 },
    table: { display: "table", width: "auto", margin: "auto" },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 0.5,
      borderBottomColor: "grey",
      pageBreakInside: "avoid",
      borderBottomStyle: "solid",
      alignItems: "stretch",
      paddingBottom: 5,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    tableCol: { width: "7%", flexDirection: "column" },
    tableColWide: { width: "10%", flexDirection: "column" },
    tableColWider: { width: "20%", flexDirection: "column" },
    tableCell: { margin: 3, fontSize: 9 },
    strikethrough: {
      textDecoration: "line-through",
    },
    tableHead: { margin: 3, fontSize: 11, fontWeight: "bold" },
  });

  // TIMECARD PUNCH EXPORT:
  const payPeriodEndDisplay = new Date(payPeriodEnd);
  payPeriodEndDisplay.setDate(payPeriodEndDisplay.getDate() - 1);
  const EmployeeDataDocument = ({
    groupedEmployeesByEmployee,
    employeeList,
  }) => (
    <Document>
      {Object.entries(groupedEmployeesByEmployee)
        .sort(([, employeesA], [, employeesB]) =>
          employeesA[0]?.FirstName.localeCompare(employeesB[0]?.FirstName),
        )
        .map(([employeeId, employeesOfEmployee]) => {
          const employeeDetails = employeeList.find(
            (emp) => emp.EmployeeId === employeeId,
          );

          // Check if at least one department of the employee is selected
          const hasSelectedDepartment = employeesOfEmployee.some(
            (employee) =>
              selectedDepartments[employee.Department] ||
              (employee.Department === "HOUSEKEEPING /HR" &&
                selectedDepartments["HOUSEKEEPING"]),
          );

          // If no selected department for this employee, skip rendering
          if (!hasSelectedDepartment) {
            return null;
          }

          // Identify missing departments
          const allDepartments = [
            ...new Set(
              employeesOfEmployee.map((employee) => employee.Department),
            ),
          ];
          const missingDepartments = allDepartments.filter(
            (dept) =>
              !selectedDepartments[dept] &&
              !(
                dept === "HOUSEKEEPING /HR" &&
                selectedDepartments["HOUSEKEEPING"]
              ),
          );

          // **NEW LOGIC**: Filter based on selected checkbox value
          const matchesCheckbox =
            !selectedCheckbox || missingDepartments.includes(selectedCheckbox);

          // If no match with the checkbox, skip rendering
          if (!matchesCheckbox) {
            return null;
          }

          // Group employees of the employee by week using the original array
          const groupedEmployeesOfEmployeeByWeek =
            groupEmployeesOfEmployeeByWeek(employeesOfEmployee, payPeriodStart);

          return (
            <Page
              size="A4"
              orientation="landscape"
              style={styles.page}
              key={employeeId}
            >
              <View style={styles.section}>
                <Text>{`${employeeDetails.FirstName} ${
                  employeeDetails.LastName
                } - PAY PERIOD ENDING: ${payPeriodEndDisplay.toLocaleDateString()}`}</Text>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}></Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>DATE</Text>
                  </View>
                  <View style={styles.tableColWide}>
                    <Text style={styles.tableHead}>PAY TYPE</Text>
                  </View>
                  <View style={styles.tableColWider}>
                    <Text style={styles.tableHead}>DEPARTMENT</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>IN</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>OUT</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>ROOMS</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>TIME</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>TIPS</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>VAC</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>HOLIDAY</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>TOTAL TIME</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>STATUS</Text>
                  </View>
                </View>

                {employeesOfEmployee
                  .sort((a, b) => toDate(a.InTime) - toDate(b.InTime))
                  .map((employee, index) => {
                    const {
                      InTime,
                      Department,
                      PTO,
                      Rooms,
                      Tips,
                      PayType,
                      Status,
                      PayRate,
                    } = employee;

                    // Get week number
                    const inTimeMoment = moment(toDate(employee.InTime));
                    const weekNumber = inTimeMoment.diff(
                      payPeriodStart,
                      "weeks",
                    );
                    // Get employees of this employee for the current week
                    const employeesOfEmployeeInWeek =
                      groupedEmployeesOfEmployeeByWeek[weekNumber] || [];
                    // Find the index of the current employee in this week
                    const weeklyIndex = employeesOfEmployeeInWeek.findIndex(
                      (e) => e.id === employee.id,
                    );
                    // Calculate cumulative total hours in the context of the current week
                    const cumulativeTotalHours = calculateCumulativeHours(
                      employeesOfEmployeeInWeek,
                      weeklyIndex,
                    );

                    function calculateCumulativeHours(employees, index) {
                      return employees
                        .slice(0, index + 1)
                        .reduce((totalHours, currentEmployee) => {
                          const inTime = toDate(currentEmployee.InTime);
                          const outTime = toDate(currentEmployee.OutTime);
                          const timeDifference = getTimeDifference(
                            inTime,
                            outTime,
                          );
                          const decimalHours = parseFloat(
                            differenceToDecimal(timeDifference),
                          );

                          const newTotalHours =
                            parseFloat(totalHours) + (decimalHours || 0);

                          return newTotalHours;
                        }, 0);
                    }

                    const inTime = toDate(InTime);
                    const outTime = toDate(employee.OutTime);
                    const timeDifference = getTimeDifference(inTime, outTime);
                    const ptoValue = PTO ? parseInt(PTO, 10) : null;
                    const totalTime = ptoValue ? ptoValue : timeDifference;

                    const ratePerHour = calculateEarningsPerHour(
                      PayType,
                      PayRate,
                      employee,
                      employeeData,
                    );

                    const employeesForId =
                      groupedEmployeesByEmployee[employee.EmployeeId];

                    const sortedEmployees = employeesForId.sort((a, b) => {
                      return toDate(a.InTime) - toDate(b.InTime);
                    });

                    return (
                      <View style={styles.tableRow} key={employee.id}>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {formatDate(toDate(InTime))}
                          </Text>
                        </View>
                        <View style={styles.tableColWide}>
                          <Text style={styles.tableCell}>{PayType}</Text>
                        </View>
                        <View style={styles.tableColWider}>
                          <Text style={styles.tableCell}>{Department}</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {formatDateTime(toDate(employee.InTime))}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {formatDateTime(toDate(employee.OutTime))}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {Rooms !== 0 ? Rooms : ""}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {formatTimeDifference(timeDifference)}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {Tips !== 0 ? Tips : ""}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {PTO !== 0 ? PTO : ""}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {employee.Holiday ? "Yes" : "No"}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {cumulativeTotalHours.toFixed(2)}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{Status}</Text>
                        </View>
                      </View>
                    );
                  })}

                {/* Totals header */}
                <View style={styles.tableRow}>
                  <View style={styles.tableColWider}>
                    <Text style={styles.tableHead}></Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}></Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>Total Time</Text>
                  </View>
                  <View style={styles.tableColWide}>
                    <Text style={styles.tableHead}>Decimal Hours</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>Overtime</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>Vacation Hours</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>Holiday Pay</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>Tips</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>Total Rooms</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>Rms A</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>Rms B</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>Rms C</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColWider}>
                    <Text style={styles.tableCell}>TIMECARD TOTAL:</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {formatTimeDifference(
                        employeesOfEmployee.reduce(
                          (total, employee) =>
                            total +
                            getTimeDifference(
                              toDate(employee.InTime),
                              toDate(employee.OutTime),
                            ),
                          0,
                        ),
                      )}
                    </Text>
                  </View>
                  {/* DECIMAL */}
                  <View style={styles.tableColWide}>
                    <Text style={styles.tableCell}>
                      {parseFloat(
                        calculateRegularHoursForEmployees(employeesOfEmployee),
                      ).toFixed(2)}
                    </Text>
                  </View>
                  {/* OVERTIME */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {calculateOvertimeForEmployees(
                        employeesOfEmployee,
                        payPeriodStart,
                      ).toFixed(2)}
                    </Text>
                  </View>
                  {/* PTO */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {calculateTotalPTOForEmployees(employeesOfEmployee)}
                    </Text>
                  </View>
                  {/* HOLIDAY */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {parseFloat(
                        calculateTotalHolidayHoursForEmployees(
                          employeesOfEmployee,
                        ),
                      ).toFixed(2)}
                    </Text>
                  </View>
                  {/* TIPS */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {employeesOfEmployee.reduce(
                        (total, employee) => total + employee.Tips,
                        0,
                      )}
                    </Text>
                  </View>
                  {/* RMS */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {employeesOfEmployee.reduce(
                        (total, employee) => total + employee.Rooms,
                        0,
                      )}
                    </Text>
                  </View>
                  {/* A */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {employeesOfEmployee.reduce(
                        (total, employee) => total + employee.A,
                        0,
                      )}
                    </Text>
                  </View>
                  {/* B */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {employeesOfEmployee.reduce(
                        (total, employee) => total + employee.B,
                        0,
                      )}
                    </Text>
                  </View>
                  {/* C */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {employeesOfEmployee.reduce(
                        (total, employee) => total + employee.C,
                        0,
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            </Page>
          );
        })}
    </Document>
  );

  // RETURN

  return (
    <div>
      {/* HEADER */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        marginTop={2}
        marginBottom={2}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            paddingTop: { xs: "1rem", sm: 0 },
            paddingBottom: { xs: "1rem", sm: 0 },
          }}
        >
          <FormControl
            variant="outlined"
            sx={{
              minWidth: "15rem",
              "@media (max-width: 600px)": { minWidth: "9rem" },
            }}
          >
            <InputLabel
              sx={{
                ...selectInputLabel,
              }}
              htmlFor="property-code"
            >
              TEAM
            </InputLabel>
            <Select
              value={selectedPropertyCode || ""}
              onChange={handlePropertyCodeChange}
              inputProps={{ name: "property-code", id: "property-code" }}
              // readOnly={!!selectedPropertyCode} // Makes it read-only if selectedPropertyCode exists
              sx={{
                ...selectSelect,
              }}
            >
              {viewPayrollSummary
                .slice()
                .sort((a, b) => {
                  const nameA = (
                    a.propertyCode ||
                    a.propertyName ||
                    ""
                  ).toString();
                  const nameB = (
                    b.propertyCode ||
                    b.propertyName ||
                    ""
                  ).toString();
                  return nameA.localeCompare(nameB);
                })
                .map((property) => (
                  <MenuItem
                    key={property.propertyCode}
                    value={property.propertyCode}
                    sx={{ ...selectMenuItem }}
                  >
                    {property.propertyName
                      ? `${property.propertyCode} - ${property.propertyName}`
                      : property.propertyCode}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            paddingTop: { xs: "0.45rem", sm: 0 },
            paddingBottom: { xs: "0.45rem", sm: 0 },
          }}
        >
          {payPeriodStart && payPeriodEnd && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="left"
                spacing={1}
              >
                <Grid item>
                  <IconButton
                    onClick={() => changePayPeriod("left")}
                    sx={{
                      fontSize: "1.26rem",
                      color: "grey",
                      transition: "0.3s",
                      marginBottom: "9px",
                    }}
                  >
                    <FaChevronLeft />
                  </IconButton>
                </Grid>
                <Grid item>
                  <header
                    className="pay-period"
                    style={{
                      color: "#202426",
                      marginBottom: "9px",
                      fontFamily: "Outfit",
                      fontSize: "0.99rem",
                      fontWeight: 600,
                      letterSpacing: 1.8,
                    }}
                  >
                    {(() => {
                      const payPeriodEndDisplay = new Date(payPeriodEnd);
                      payPeriodEndDisplay.setDate(
                        payPeriodEndDisplay.getDate() - 1,
                      );
                      return `${payPeriodStart.toLocaleDateString()}  -  ${payPeriodEndDisplay.toLocaleDateString()}`;
                    })()}
                  </header>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={() => changePayPeriod("right")}
                    disabled={isPayPeriodEndGreaterThanCurrent}
                    sx={{
                      fontSize: "1.26rem",
                      color: "grey",
                      transition: "0.3s",
                      marginBottom: "9px",
                    }}
                  >
                    <FaChevronRight />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>

      <Box height={16} />

      <Card
        sx={{
          borderRadius: "18px",
        }}
      >
        <Box height={16} />

        {/* TIMECARDS */}

        <Box>
          <Grid
            container
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            spacing={2}
            backgroundColor="#fcfcfc"
          >
            <Grid item xs={12}>
              <PDFDownloadLink
                document={
                  <EmployeeDataDocument
                    groupedEmployeesByEmployee={groupedEmployeesByEmployee}
                    employeeList={employeeList}
                  />
                }
                fileName={`${payPeriodEndDisplay.toLocaleDateString()}_TIMECARDS_${selectedPropertyCode}.pdf`}
              >
                {() => (
                  <span>
                    <IconButton
                      sx={{
                        fontSize: "1.5rem",
                        color: "#FF0000",
                        backgroundColor: "transparent",
                        marginLeft: "1rem",
                      }}
                    >
                      <FaFilePdf />
                    </IconButton>
                  </span>
                )}
              </PDFDownloadLink>
              <Typography
                variant="caption"
                sx={{
                  fontFamily: "Outfit",
                  fontWeight: 800,
                  color: "#202426",
                  fontSize: "0.72rem",
                  letterSpacing: "0.09rem",
                }}
              >
                DL PDF
              </Typography>
            </Grid>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  options={["Select All", ...usedDepartments]} // Add "Select All" option
                  value={
                    Object.keys(selectedDepartments).filter(
                      (key) => selectedDepartments[key],
                    ).length === usedDepartments.length
                      ? ["Select All"]
                      : Object.keys(selectedDepartments).filter(
                          (key) => selectedDepartments[key],
                        )
                  }
                  onChange={(event, newValue) => {
                    if (newValue.includes("Select All")) {
                      // If "Select All" is clicked, select all or deselect all
                      const allSelected =
                        Object.keys(selectedDepartments).filter(
                          (key) => selectedDepartments[key],
                        ).length === usedDepartments.length;

                      const updatedSelection = usedDepartments.reduce(
                        (acc, dept) => {
                          acc[dept] = !allSelected; // Toggle selection
                          return acc;
                        },
                        {},
                      );

                      setSelectedDepartments(updatedSelection);
                    } else {
                      // Handle individual department selection
                      const updatedSelection = usedDepartments.reduce(
                        (acc, dept) => {
                          acc[dept] = newValue.includes(dept);
                          return acc;
                        },
                        {},
                      );

                      setSelectedDepartments(updatedSelection);
                    }
                  }}
                  disableCloseOnSelect
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option}
                        label={option}
                        {...getTagProps({ index })}
                        sx={{
                          margin: "1.8px",
                          backgroundColor: "#FFC107",
                          color: "white",
                          fontWeight: 400,
                          letterSpacing: "0.1rem",
                        }}
                      />
                    ))
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        checked={
                          option === "Select All"
                            ? Object.keys(selectedDepartments).filter(
                                (key) => selectedDepartments[key],
                              ).length === usedDepartments.length
                            : selected
                        }
                        sx={{ marginRight: 1 }}
                      />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Department(s)"
                      sx={{
                        ...selectInputLabel,
                        ...selectFormControl,
                      }}
                    />
                  )}
                  sx={{
                    ...selectFormControl,
                    margin: "20px",
                    paddingLeft: "1rem",
                    "& .MuiAutocomplete-inputRoot": {
                      ...selectSelect,
                    },
                    "& .MuiMenuItem-root": {
                      ...selectMenuItem,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                {departmentNames.map((dept) => (
                  <FormControlLabel
                    key={dept}
                    control={
                      <Checkbox
                        checked={selectedCheckbox === dept} // Check if this is the selected checkbox
                        onChange={() => handleDepartmentChange(dept)}
                        sx={{
                          "&.Mui-checked": {
                            color: "#FFC107",
                          },
                        }}
                      />
                    }
                    label={
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "400",
                            fontSize: "0.9rem",
                            fontFamily: "Outfit",
                            color: "#202426",
                            textTransform: "uppercase",
                            letterSpacing: "0.045rem",
                            marginLeft: "0.18rem",
                          }}
                        >
                          {dept}
                        </Typography>
                      </Box>
                    }
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
          <TableContainer
            sx={{
              overflowX: "auto",
            }}
            component={Paper}
          >
            {/* MAIN TABLE */}
            <Table id="PAYROLL" sx={maintable}>
              <React.Fragment>
                {Object.entries(groupedEmployeesByEmployee)
                  .sort(([, employeesA], [, employeesB]) =>
                    employeesA[0]?.FirstName.localeCompare(
                      employeesB[0]?.FirstName,
                    ),
                  )
                  .map(([employeeId, employeesOfEmployee]) => {
                    const employeeDetails = employeeList.find(
                      (emp) => emp.EmployeeId === employeeId,
                    );

                    // Check if at least one department of the employee is selected
                    const hasSelectedDepartment = employeesOfEmployee.some(
                      (employee) =>
                        selectedDepartments[employee.Department] ||
                        (employee.Department === "HOUSEKEEPING /HR" &&
                          selectedDepartments["HOUSEKEEPING"]),
                    );

                    // If no selected department for this employee, skip rendering
                    if (!hasSelectedDepartment) {
                      return null;
                    }

                    // Identify missing departments
                    const allDepartments = [
                      ...new Set(
                        employeesOfEmployee.map(
                          (employee) => employee.Department,
                        ),
                      ),
                    ];
                    const missingDepartments = allDepartments.filter(
                      (dept) =>
                        !selectedDepartments[dept] &&
                        !(
                          dept === "HOUSEKEEPING /HR" &&
                          selectedDepartments["HOUSEKEEPING"]
                        ),
                    );

                    // **NEW LOGIC**: Filter based on selected checkbox value
                    const matchesCheckbox =
                      !selectedCheckbox ||
                      missingDepartments.includes(selectedCheckbox);

                    // If no match with the checkbox, skip rendering
                    if (!matchesCheckbox) {
                      return null;
                    }

                    // Group employees of the employee by week using the original array
                    const groupedEmployeesOfEmployeeByWeek =
                      groupEmployeesOfEmployeeByWeek(
                        employeesOfEmployee,
                        payPeriodStart,
                      );

                    return (
                      <React.Fragment key={`employee-${employeeId}`}>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              style={{ fontWeight: "bold" }}
                            >
                              {employeeDetails
                                ? `${employeeDetails.FirstName} ${employeeDetails.LastName}`
                                : ""}
                            </TableCell>
                            <TableCell sx={maintable} align="left">
                              IN
                            </TableCell>
                            <TableCell sx={maintable} align="left">
                              OUT
                            </TableCell>
                            <TableCell sx={maintable} align="left">
                              RMS
                            </TableCell>
                            <TableCell sx={maintable} align="left">
                              TIME
                            </TableCell>
                            <TableCell sx={maintable} align="left">
                              TIPS
                            </TableCell>
                            <TableCell sx={maintable} align="left">
                              VAC
                            </TableCell>
                            <TableCell sx={maintable} align="left">
                              HOL
                            </TableCell>
                            <TableCell sx={maintable} align="left">
                              TOTAL
                            </TableCell>
                            <TableCell sx={maintable} align="left">
                              STATUS
                            </TableCell>
                          </TableRow>

                          {employeesOfEmployee
                            .sort((a, b) => toDate(a.InTime) - toDate(b.InTime))
                            .map((employee, index) => {
                              const {
                                InTime,
                                Department,
                                PTO,
                                Rooms,
                                Tips,
                                PayType,
                                Status,
                                PayRate,
                              } = employee;

                              // Get week number
                              const inTimeMoment = moment(
                                toDate(employee.InTime),
                              );
                              const weekNumber = inTimeMoment.diff(
                                payPeriodStart,
                                "weeks",
                              );
                              // Get employees of this employee for the current week
                              const employeesOfEmployeeInWeek =
                                groupedEmployeesOfEmployeeByWeek[weekNumber] ||
                                [];
                              // Find the index of the current employee in this week
                              const weeklyIndex =
                                employeesOfEmployeeInWeek.findIndex(
                                  (e) => e.id === employee.id,
                                );
                              // Calculate cumulative total hours in the context of the current week
                              const cumulativeTotalHours =
                                calculateCumulativeHours(
                                  employeesOfEmployeeInWeek,
                                  weeklyIndex,
                                );

                              function calculateCumulativeHours(
                                employees,
                                index,
                              ) {
                                return employees
                                  .slice(0, index + 1)
                                  .reduce((totalHours, currentEmployee) => {
                                    const inTime = toDate(
                                      currentEmployee.InTime,
                                    );
                                    const outTime = toDate(
                                      currentEmployee.OutTime,
                                    );
                                    const timeDifference = getTimeDifference(
                                      inTime,
                                      outTime,
                                    );
                                    const decimalHours = parseFloat(
                                      differenceToDecimal(timeDifference),
                                    );

                                    const newTotalHours =
                                      parseFloat(totalHours) +
                                      (decimalHours || 0);

                                    return newTotalHours;
                                  }, 0);
                              }

                              const inTime = toDate(InTime);
                              const outTime = toDate(employee.OutTime);
                              const timeDifference = getTimeDifference(
                                inTime,
                                outTime,
                              );
                              const ptoValue = PTO ? parseInt(PTO, 10) : null;
                              const totalTime = ptoValue
                                ? ptoValue
                                : timeDifference;

                              const ratePerHour = calculateEarningsPerHour(
                                PayType,
                                PayRate,
                                employee,
                                employeeData,
                              );

                              const employeesForId =
                                groupedEmployeesByEmployee[employee.EmployeeId];

                              const sortedEmployees = employeesForId.sort(
                                (a, b) => {
                                  return toDate(a.InTime) - toDate(b.InTime);
                                },
                              );

                              return (
                                <React.Fragment key={`employee-${employee.id}`}>
                                  <TableRow hover key={employee.id}>
                                    <TableCell
                                      sx={maintable}
                                      size="small"
                                      align="left"
                                    >
                                      {formatDate(toDate(InTime))}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={maintable}
                                    >
                                      {PayType !== "error" ? PayType : ""}
                                    </TableCell>
                                    {/* DEPT */}
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={maintable}
                                    >
                                      {Department}
                                    </TableCell>
                                    {/* IN TIME */}
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={maintable}
                                    >
                                      {formatDateTime(toDate(employee.InTime))}
                                    </TableCell>
                                    {/* OUT TIME */}
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={maintable}
                                    >
                                      {formatDateTime(toDate(employee.OutTime))}
                                    </TableCell>
                                    {/* ROOMS */}
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={maintable}
                                    >
                                      {Rooms !== 0 ? Rooms : ""}
                                    </TableCell>
                                    {/* TOTAL TIME WORKED */}
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={maintable}
                                    >
                                      {formatTimeDifference(timeDifference)}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={maintable}
                                    >
                                      {Tips !== 0 ? Tips : ""}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={maintable}
                                    >
                                      {PTO !== 0 ? PTO : ""}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={maintable}
                                    >
                                      {employee.Holiday ? "Yes" : "No"}
                                    </TableCell>
                                    {/* CUMULATIVE TIME */}
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={{
                                        ...maintable,
                                        backgroundColor:
                                          cumulativeTotalHours > 40
                                            ? "#F98B85"
                                            : cumulativeTotalHours > 35
                                              ? "#FFC107"
                                              : "inherit",
                                        color:
                                          cumulativeTotalHours > 35
                                            ? "black"
                                            : "inherit",
                                      }}
                                    >
                                      {cumulativeTotalHours.toFixed(2)}
                                    </TableCell>
                                    {/* STATUS */}
                                    <TableCell
                                      sx={maintable}
                                      align="left"
                                      size="small"
                                    >
                                      {Status}
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              );
                            })}
                          {/* TOTALS */}
                          <TableRow>
                            <TableCell sx={totals} align="left" size="small">
                              TIME
                            </TableCell>
                            <TableCell sx={totals} align="left" size="small">
                              HRS
                            </TableCell>
                            <TableCell sx={totals} align="left" size="small">
                              OT
                            </TableCell>

                            <TableCell sx={totals} align="left" size="small">
                              VAC
                            </TableCell>
                            <TableCell sx={totals} align="left" size="small">
                              HOL
                            </TableCell>
                            <TableCell sx={totals} align="left" size="small">
                              TIPS
                            </TableCell>
                            <TableCell sx={totals} align="left" size="small">
                              RMS
                            </TableCell>
                            <TableCell sx={totals} align="left" size="small">
                              A
                            </TableCell>
                            <TableCell sx={totals} align="left" size="small">
                              B
                            </TableCell>
                            <TableCell sx={totals} align="left" size="small">
                              C
                            </TableCell>
                            <TableCell
                              sx={totals}
                              align="left"
                              size="small"
                            ></TableCell>
                            <TableCell
                              sx={totals}
                              align="left"
                              size="small"
                            ></TableCell>
                          </TableRow>
                          <TableRow>
                            {/* TIME */}
                            <TableCell sx={totals} align="left" size="small">
                              {formatTimeDifference(
                                employeesOfEmployee.reduce(
                                  (total, employee) =>
                                    total +
                                    getTimeDifference(
                                      toDate(employee.InTime),
                                      toDate(employee.OutTime),
                                    ),
                                  0,
                                ),
                              )}
                            </TableCell>
                            {/* DECIMAL */}
                            <TableCell sx={totals} align="left" size="small">
                              {parseFloat(
                                calculateRegularHoursForEmployees(
                                  employeesOfEmployee,
                                ),
                              ).toFixed(2)}
                            </TableCell>
                            {/* OVERTIME */}
                            <TableCell sx={totals} align="left" size="small">
                              {calculateOvertimeForEmployees(
                                employeesOfEmployee,
                                payPeriodStart,
                              ).toFixed(2)}
                            </TableCell>

                            {/* PTO */}
                            <TableCell sx={totals} align="left" size="small">
                              {calculateTotalPTOForEmployees(
                                employeesOfEmployee,
                              )}
                            </TableCell>
                            {/* HOLIDAY */}
                            <TableCell sx={totals} align="left" size="small">
                              {parseFloat(
                                calculateTotalHolidayHoursForEmployees(
                                  employeesOfEmployee,
                                ),
                              ).toFixed(2)}
                            </TableCell>
                            <TableCell sx={totals} align="left" size="small">
                              {employeesOfEmployee.reduce(
                                (total, employee) => total + employee.Tips,
                                0,
                              )}
                            </TableCell>
                            {/* RMS */}
                            <TableCell sx={totals} align="left" size="small">
                              {employeesOfEmployee.reduce(
                                (total, employee) => total + employee.Rooms,
                                0,
                              )}
                            </TableCell>
                            {/* A */}
                            <TableCell sx={totals} align="left" size="small">
                              {employeesOfEmployee.reduce(
                                (total, employee) => total + employee.A,
                                0,
                              )}
                            </TableCell>
                            {/* B */}
                            <TableCell sx={totals} align="left" size="small">
                              {employeesOfEmployee.reduce(
                                (total, employee) => total + employee.B,
                                0,
                              )}
                            </TableCell>
                            {/* C */}
                            <TableCell sx={totals} align="left" size="small">
                              {employeesOfEmployee.reduce(
                                (total, employee) => total + employee.C,
                                0,
                              )}
                            </TableCell>
                            <TableCell
                              sx={totals}
                              align="left"
                              size="small"
                            ></TableCell>
                            <TableCell
                              sx={totals}
                              align="left"
                              size="small"
                            ></TableCell>
                          </TableRow>
                          <TableRow>
                            {" "}
                            <TableCell
                              sx={{ backgroundColor: "#ebefe3" }}
                              align="left"
                              size="small"
                              colSpan={12}
                            ></TableCell>
                          </TableRow>
                        </TableBody>
                      </React.Fragment>
                    );
                  })}
              </React.Fragment>
            </Table>
          </TableContainer>
        </Box>
      </Card>
    </div>
  );
};

export default PunchTimecards;

const maintable = {
  fontWeight: 400,
  fontSize: "0.7rem",
  backgroundColor: "#fcfcfc",
  color: "#101010",
  rowHeight: "0.1rem",
};
const totals = {
  fontWeight: 600,
  fontSize: "0.7rem",
  backgroundColor: "#fcfcfc",
  color: "#101010",
};
const selectInputLabel = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Outfit",
  fontWeight: 400,
  color: "#202426",
  transition: "0.3s",
  textTransform: "uppercase",
};
const selectSelect = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Nunito",
  backgroundColor: "#ffffff",
  borderRadius: "18px",
  textTransform: "uppercase",
};
const selectMenuItem = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Nunito",
  fontWeight: 400,
  letterSpacing: "0.045rem",
  color: "#202426",
  transition: "0.3s",
  textTransform: "uppercase",
};
const selectFormControl = {
  minWidth: "15rem",
  "@media (max-width: 600px)": {
    minWidth: "9rem",
  },
};
