import React, { useState, useEffect, useMemo } from "react";
import { db } from "../../firebase/firebase-config";
import { useAuth } from "../../contexts/AuthContext";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  onSnapshot,
  orderBy,
  doc,
} from "firebase/firestore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Box,
  Card,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { BsFillPatchCheckFill } from "react-icons/bs";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import * as XLSX from "xlsx";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import PunchTimecards from "./timecards";

const PunchSummary = () => {
  const { user, userPermissions, userRole, availableProperties } = useAuth();
  const [payRollData, setPayRollData] = useState([]);
  const [propertyCode, setPropertyCode] = useState([]);
  const [selectedPropertyCode, setSelectedPropertyCode] = useState("");
  const [currentPayRollIndex, setCurrentPayRollIndex] = useState(0);
  const [tableTitle, setTableTitle] = useState("");
  const [selectedPayRoll, setSelectedPayRoll] = useState(null);
  const [payPeriodStart, setPayPeriodStart] = useState(null);
  const [payPeriodEnd, setPayPeriodEnd] = useState(null);
  const [value, setValue] = React.useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: "FirstName",
    direction: "asc",
  });
  const [payrollChanges, setPayrollChanges] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [isClosed, setIsClosed] = useState(false);
  const [showNonVoided, setShowNonVoided] = useState(true);
  const [enhancedEmployees, setEnhancedEmployees] = useState([]);
  const [claims, setClaims] = useState({});
  const [actionType, setActionType] = useState("summary");
  const [setPermissionsForProperty] = useState({});

  // PERMISSIONS

  const hasPermissionForProperty = (permission, propertyCode) => {
    // If the user's role is 'Global' or 'MGMT', they have all permissions
    if (
      userRole === "Global" ||
      userRole === "MGMT" ||
      userRole === "PropertyAdmin"
    ) {
      return true;
    }

    // Otherwise, check the permissions for the specific property
    const permissionsForProperty =
      userPermissions[propertyCode]?.permissions || {};
    return permissionsForProperty[permission] === true;
  };

  // Filter availableProperties to include only those with 'payrollViewSummary' permission
  const viewPayrollSummary = useMemo(
    () =>
      availableProperties.filter((prop) => {
        return (
          userPermissions &&
          hasPermissionForProperty("payrollViewSummary", prop.propertyCode)
        );
      }),
    [availableProperties, userPermissions],
  );

  useEffect(() => {
    if (!propertyCode || !userPermissions[propertyCode]) return;

    // Avoid setting state if permissions haven't changed to prevent re-renders
    const propertyPermissions = userPermissions[propertyCode].permissions || {};
    const permissionsArray = Object.keys(propertyPermissions).filter(
      (key) => propertyPermissions[key] === true,
    );

    setPermissionsForProperty((prevPermissions) => {
      // Only update state if permissions have changed
      const permissionsChanged =
        JSON.stringify(prevPermissions) !== JSON.stringify(permissionsArray);
      return permissionsChanged ? permissionsArray : prevPermissions;
    });
  }, [propertyCode, userPermissions]);

  // Function to determine if a user can view a section
  const canViewSection = (permissions = [], propertyCode) => {
    const requiredPermissions = Array.isArray(permissions)
      ? permissions
      : [permissions];

    // Grant access to Global, MGMT, and PropertyAdmin roles
    if (["Global", "MGMT", "PropertyAdmin"].includes(userRole)) return true;

    // Check specific permissions for the property
    return requiredPermissions.every((permission) => {
      const propertyPermissions =
        userPermissions[propertyCode]?.permissions || {};
      return propertyPermissions[permission] === true;
    });
  };

  const handlePropertyCodeChange = (event) => {
    const newPropertyCode = event.target.value;

    // Update the selected property code in AuthContext
    setSelectedPropertyCode(newPropertyCode);
  };

  const handleMainSwitch = (event) => {
    setActionType(event.target.checked ? "summary" : "timecards");
  };

  // USE EFFECT FOR PAYROLL SUMMARY
  useEffect(() => {
    let formattedDate = "";
    if (payRollData.length > 0 && currentPayRollIndex < payRollData.length) {
      let periodEndDate = new Date(
        payRollData[currentPayRollIndex].PeriodEndDate?.toDate(),
      );
      periodEndDate.setDate(periodEndDate.getDate() - 1); // subtract one day
      formattedDate = periodEndDate.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    }
    setTableTitle(`${selectedPropertyCode} - ${formattedDate}`);
  }, [selectedPropertyCode, currentPayRollIndex, payRollData]);

  useEffect(() => {
    if (
      payRollData.length > 0 &&
      currentPayRollIndex >= 0 &&
      currentPayRollIndex < payRollData.length
    ) {
      setSelectedPayRoll(payRollData[currentPayRollIndex]);
    }
  }, [currentPayRollIndex, payRollData]);

  // FETCH PAYROLL DATA

  useEffect(() => {
    if (selectedPropertyCode) {
      const q = query(
        collection(db, "payRoll"),
        where("PropertyCode", "==", selectedPropertyCode),
        orderBy("PeriodEndDate", "desc"),
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedPayRollData = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });

        setPayRollData(fetchedPayRollData);
      });

      return () => unsubscribe();
    }
  }, [selectedPropertyCode]);

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  // PDF STYLESHEET
  const styles = StyleSheet.create({
    page: { flexDirection: "row", backgroundColor: "#ffffff", padding: 9 },
    table: { display: "table", width: "auto", margin: "auto" },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 0,
      borderBottomColor: "grey",
      pageBreakInside: "avoid",
      borderBottomStyle: "solid",
      alignItems: "stretch",
      paddingBottom: 5,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    tableCol: { width: "9%", flexDirection: "column" },
    tableColWide: { width: "10%", flexDirection: "column" },
    tableColWider: { width: "20%", flexDirection: "column" },
    tableCell: { margin: 3, fontSize: 7, fontWeight: "bold" },
    tableCellD1: { margin: 3, fontSize: 7, textAlign: "left" },
    tableCellD2: { margin: 3, fontSize: 7, textAlign: "left" },
    strikethrough: {
      textDecoration: "line-through",
    },
    tableHead: { margin: 3, fontSize: 11, fontWeight: "bold" },
    borderedRow: {
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderColor: "black",
      borderStyle: "solid",
    },
    borderedRowTop: {
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 2,
      borderColor: "black",
      borderStyle: "solid",
    },
    borderedRowBottom: {
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 2,
      borderColor: "black",
      borderStyle: "solid",
    },
    additionalRow: {
      flexDirection: "row",
      alignItems: "stretch",
    },
  });

  const formatDateMMDDYY = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    const year = String(date.getFullYear()).slice(-2);

    return month + "/" + day + "/" + year;
  };

  // PAYROLL TABLE EXPORT:
  const PayrollTable = ({
    payRollData,
    selectedPayRoll,
    sortKey,
    sortDirection,
  }) => {
    // Filter out voided entries
    const filteredData = payRollData.filter(
      (payRoll) => payRoll.Status !== "Void",
    );

    // Sort the filtered payroll data based on the provided key and direction
    const sortedData = filteredData
      .filter(
        (payRoll) =>
          payRoll.PeriodEndDate?.toDate().getTime() ===
          selectedPayRoll?.PeriodEndDate?.toDate().getTime(),
      )
      .sort((a, b) => {
        if (sortDirection === "asc") {
          return a[sortKey].localeCompare(b[sortKey]);
        } else {
          return b[sortKey].localeCompare(a[sortKey]);
        }
      });

    return (
      <View style={styles.section}>
        <Text>{`${calculatedDate} - Payroll Summary (${selectedPropertyCode})`}</Text>

        <View style={styles.section}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>ID</Text>
            </View>
            <View style={styles.tableColWide}>
              <Text style={styles.tableHead}>NAME</Text>
            </View>
            <View style={styles.tableColWide}>
              <Text style={styles.tableHead}></Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>TIME</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>DEC</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>OT</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>VAC</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>HOL</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>RMS</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>A</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>B</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>C</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>TIPS</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>CLOSED</Text>
            </View>
          </View>

          {sortedData.map((payRoll, index) => (
            <React.Fragment key={index}>
              <View
                style={[
                  styles.tableRow,
                  payRoll.Status === "Void"
                    ? styles.strikethrough
                    : styles.borderedRowTop,
                ]}
              >
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{payRoll.EmployeeId}</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{payRoll.FirstName}</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{payRoll.LastName}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{payRoll.Totals?.Time}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {payRoll.Totals?.Decimal?.toFixed(2)}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {payRoll.Totals?.Overtime?.toFixed(2)}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{payRoll.Totals?.PTO}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {payRoll.Totals?.Holiday}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{payRoll.Totals?.Rooms}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{payRoll.Totals?.A}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{payRoll.Totals?.B}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{payRoll.Totals?.C}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {payRoll.Totals?.Tips?.toFixed(2)}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {payRoll.EntryDate
                      ? formatDateMMDDYY(
                          new Date(payRoll.EntryDate.seconds * 1000),
                        )
                      : "-"}
                  </Text>
                </View>
              </View>
              {/* Additional row for payroll.D1 */}
              <View style={styles.additionalRow}>
                <View style={styles.tableCol} />

                <View style={styles.tableColWider}>
                  <Text style={styles.tableCellD1}>{payRoll.PayD1}</Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCellD1}>({payRoll.PayD1_Type})</Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    payRoll.PayD1_Type === "PER ROOM" ? strikethroughStyle : {},
                  ]}
                >
                  <Text style={styles.tableCellD1}>
                    {payRoll.D1?.HRS || "0.00"}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellD1}>
                    {payRoll.D1?.OT || "0.00"}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellD1}>
                    {payRoll.D1?.VAC || "0.00"}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellD1}>
                    {payRoll.D1?.HOL || "0.00"}
                  </Text>
                </View>
                <View style={styles.tableCol} />
                <View style={styles.tableCol} />
                <View style={styles.tableCol} />
                <View style={styles.tableCol} />
                <View style={styles.tableCol} />
                <View style={styles.tableCol} />
              </View>
              {/* Additional row for payroll.D2 */}
              {payRoll.PayD2 && payRoll.PayD2 !== "N/A" && (
                <View style={styles.additionalRow}>
                  <View style={styles.tableCol} />

                  <View style={styles.tableColWider}>
                    <Text style={styles.tableCellD2}>{payRoll.PayD2}</Text>
                  </View>

                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellD2}>
                      ({payRoll.PayD2_Type})
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellD2}>
                      {payRoll.D2?.HRS || "0.00"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellD2}>
                      {payRoll.D2?.OT || "0.00"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellD2}>
                      {payRoll.D2?.VAC || "0.00"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellD2}>
                      {payRoll.D2?.HOL || "0.00"}
                    </Text>
                  </View>
                  <View style={styles.tableCol} />
                  <View style={styles.tableCol} />
                  <View style={styles.tableCol} />
                  <View style={styles.tableCol} />
                  <View style={styles.tableCol} />
                  <View style={styles.tableCol} />
                </View>
              )}
            </React.Fragment>
          ))}
        </View>
      </View>
    );
  };

  const MyDocument = ({
    payRollData,
    selectedPayRoll,
    sortKey,
    sortDirection,
  }) => (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <PayrollTable
          payRollData={payRollData}
          selectedPayRoll={selectedPayRoll}
          sortKey={sortKey}
          sortDirection={sortDirection}
        />
      </Page>
    </Document>
  );

  let calculatedDate;
  if (payRollData.length > 0 && currentPayRollIndex < payRollData.length) {
    let endDate = payRollData[currentPayRollIndex].PeriodEndDate?.toDate();
    endDate.setDate(endDate.getDate() - 1); // subtract one day
    calculatedDate = endDate.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  }

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    } else {
      direction = "asc";
    }
    setSortConfig({ key, direction });
  };

  // Function to perform the sorting
  const sortedAndFilteredData = useMemo(() => {
    if (!selectedPayRoll) return [];

    return payRollData
      .filter(
        (payRoll) =>
          payRoll.PeriodEndDate?.toDate().getTime() ===
          selectedPayRoll.PeriodEndDate?.toDate().getTime(),
      )
      .filter((payRoll) =>
        showNonVoided ? payRoll.Status !== "Void" : payRoll.Status === "Void",
      )
      .sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key])
          return sortConfig.direction === "asc" ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key])
          return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
  }, [payRollData, selectedPayRoll, sortConfig, showNonVoided]);

  const getUniqueDates = (data) => {
    const uniqueDates = new Set();
    data.forEach((item) => {
      let endDate = new Date(item.PeriodEndDate.toDate());
      endDate.setDate(endDate.getDate()); // This seems unnecessary unless you're adjusting the date
      uniqueDates.add(endDate.toISOString().split("T")[0]); // Store date as ISO string
    });
    return Array.from(uniqueDates).sort((a, b) => new Date(b) - new Date(a)); // Sort descending
  };

  const uniqueDates = getUniqueDates(payRollData);

  useEffect(() => {
    if (selectedDate && selectedPropertyCode) {
      const fetchPayrollChanges = async () => {
        // Log the initial selectedDate and selectedPropertyCode
        console.log("Selected Date:", selectedDate);
        console.log("Selected Property Code:", selectedPropertyCode);

        // Convert the selectedDate string to a Date object
        const dateObj = new Date(selectedDate);
        // Format the date to MM-dd-yyyy without changing the date
        const formattedDate = dateObj.toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        });

        // Log the transformed date
        console.log("Transformed Date:", formattedDate);

        const payrollChangesQuery = query(
          collection(db, "payrollChanges"),
          where("payPeriod", "==", formattedDate),
          where("propCode", "==", selectedPropertyCode),
        );
        const querySnapshot = await getDocs(payrollChangesQuery);
        const changes = [];
        querySnapshot.forEach((doc) => {
          console.log("Fetched Change:", doc.data());
          changes.push(doc.data());
        });
        setPayrollChanges(changes);
      };

      fetchPayrollChanges();
    }
  }, [selectedDate, selectedPropertyCode]);

  const groupChangesByEmployee = (changes) => {
    return changes.reduce((acc, change) => {
      (acc[change.EmpID] = acc[change.EmpID] || []).push(change);
      return acc;
    }, {});
  };

  useEffect(() => {
    const dates = getUniqueDates(payRollData);
    if (dates.length > 0 && !selectedDate) {
      setSelectedDate(dates[0]); // Set to the most recent date only if selectedDate is not already set
    }
  }, [payRollData, selectedDate]);

  useEffect(() => {
    if (selectedDate) {
      const newIndex = payRollData.findIndex(
        (period) =>
          new Date(period.PeriodEndDate.toDate())
            .toISOString()
            .split("T")[0] === selectedDate,
      );
      if (newIndex !== -1) {
        setCurrentPayRollIndex(newIndex);
      }
    }
  }, [selectedDate, payRollData]);

  const exportToExcel = (payRollData, calculatedDate, selectedPropertyCode) => {
    // Create a new workbook and add a worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(payRollData);

    // Construct a base name for worksheet from date and property code
    const baseName = `${calculatedDate}_${selectedPropertyCode}`;

    // Sanitize the worksheet name to adhere to Excel's requirements
    const sanitizeWorksheetName = (name) => {
      // Excel does not allow certain characters in sheet names and limits the length to 31 characters
      return name.replace(/[:\\/?*\[\]]/g, "").substring(0, 31);
    };

    const worksheetName = sanitizeWorksheetName(baseName);

    // Add the worksheet to the workbook with a sanitized name
    XLSX.utils.book_append_sheet(wb, ws, worksheetName);

    // Write the workbook to a file with a standard naming convention
    const fileName = `${calculatedDate}_Payroll_Summary_${selectedPropertyCode}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  const prepareDataForExport = (data) => {
    return data.map((payRoll) => ({
      Status: payRoll.Status,
      PropCode: selectedPropertyCode,
      PeriodEnd: calculatedDate,
      EmployeeId: payRoll.EmployeeId,
      FirstName: payRoll.FirstName,
      LastName: payRoll.LastName,
      Time: payRoll.Totals?.Time,
      Overtime: payRoll.Totals?.Overtime?.toFixed(2),
      Decimal: payRoll.Totals?.Decimal?.toFixed(2),
      Rooms: payRoll.Totals?.Rooms,
      A: payRoll.Totals?.A,
      B: payRoll.Totals?.B,
      C: payRoll.Totals?.C,
      PTO: payRoll.Totals?.PTO,
      Holiday: payRoll.Totals?.Holiday,
      Tips: payRoll.Totals?.Tips?.toFixed(2),
      EntryDate: payRoll.EntryDate
        ? new Date(payRoll.EntryDate.seconds * 1000).toLocaleDateString()
        : "N/A",
      D1: payRoll.PayD1,
      D1_TYPE: payRoll.PayD1_Type,
      D1_HRS:
        payRoll.PayD1_Type === "PER ROOM"
          ? `~~${payRoll.D1?.HRS || "0.00"}~~`
          : payRoll.D1?.HRS,
      D1_OT: payRoll.D1?.OT,
      D1_VAC: payRoll.D1?.VAC,
      D1_HOL: payRoll.D1?.HOL,
      D2: payRoll.PayD2 === "N/A" ? `` : payRoll.PayD2,
      D2_TYPE: payRoll.PayD2 === "N/A" ? `` : payRoll.PayD2_Type,
      D2_HRS: payRoll.PayD2 === "N/A" ? `` : payRoll.D2?.HRS,
      D2_OT: payRoll.PayD2 === "N/A" ? `` : payRoll.D2?.OT,
      D2_VAC: payRoll.PayD2 === "N/A" ? `` : payRoll.D2?.VAC,
      D2_HOL: payRoll.PayD2 === "N/A" ? `` : payRoll.D2?.HOL,
    }));
  };

  const entryTopBorderStyle = {
    borderTop: "1.8px solid black",
  };

  const entryBottomBorderStyle = {
    borderBottom: "1.8px solid black",
  };

  const strikethroughStyle = {
    textDecoration: "line-through",
    color: "red",
  };

  const calculateTotalGross = (data) => {
    return data
      .reduce((total, payRoll) => {
        if (payRoll.Status !== "Void") {
          return total + (parseFloat(payRoll.Totals?.Gross) || 0);
        }
        return total;
      }, 0)
      .toFixed(2);
  };

  const totalGross = useMemo(
    () => calculateTotalGross(sortedAndFilteredData),
    [sortedAndFilteredData],
  );

  // IS PAY PERIOD CLOSED

  useEffect(() => {
    if (selectedDate && selectedPropertyCode) {
      console.log(
        "Setting payPeriodStart and payPeriodEnd based on selectedDate:",
        selectedDate,
      );
      const propertiesDoc = doc(db, "Properties", selectedPropertyCode);
      getDoc(propertiesDoc).then((doc) => {
        if (doc.exists()) {
          const { payCycle } = doc.data();
          let selectedEndDate = new Date(selectedDate);
          let selectedStartDate = new Date(selectedDate);

          switch (payCycle) {
            case "Monthly":
              selectedStartDate.setMonth(selectedEndDate.getMonth() - 1);
              break;
            case "Bi-Weekly":
              selectedStartDate.setDate(selectedEndDate.getDate() - 14);
              break;
            case "Weekly":
              selectedStartDate.setDate(selectedEndDate.getDate() - 7);
              break;
            default:
              break;
          }

          console.log("Calculated payPeriodStart:", selectedStartDate);
          console.log("Calculated payPeriodEnd:", selectedEndDate);

          setPayPeriodStart(selectedStartDate);
          setPayPeriodEnd(selectedEndDate);
        }
      });
    }
  }, [selectedDate, selectedPropertyCode]);

  useEffect(() => {
    console.log("Fetching punches based on payPeriodStart and payPeriodEnd:");
    console.log("payPeriodStart:", payPeriodStart);
    console.log("payPeriodEnd:", payPeriodEnd);
    console.log("selectedPropertyCode:", selectedPropertyCode);

    if (payPeriodStart && payPeriodEnd && selectedPropertyCode) {
      const q = query(
        collection(db, "PUNCHES"),
        where("PropCode", "==", selectedPropertyCode),
        where("InTime", ">=", payPeriodStart),
        where("InTime", "<=", payPeriodEnd),
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const punches = snapshot.docs.map((doc) => doc.data());
        console.log("Fetched punches:", punches);

        const closedForAll = punches.every(
          (punch) =>
            punch.Status === "Closed" ||
            punch.Status === "Mod/Closed" ||
            punch.Status === "Man/Closed" ||
            punch.Status === "Mod/Missing",
        );

        if (!closedForAll) {
          punches.forEach((punch) => {
            if (
              punch.Status !== "Closed" &&
              punch.Status !== "Mod/Closed" &&
              punch.Status !== "Man/Closed" &&
              punch.Status !== "Mod/Missing"
            ) {
              console.log("Open punch found:", punch);
            }
          });
        }

        console.log("All punches closed:", closedForAll);
        setIsClosed(closedForAll);
      });

      return () => unsubscribe();
    } else {
      console.log("One of the dependencies is missing:");
      console.log("payPeriodStart:", payPeriodStart);
      console.log("payPeriodEnd:", payPeriodEnd);
      console.log("selectedPropertyCode:", selectedPropertyCode);
    }
  }, [payPeriodStart, payPeriodEnd, selectedPropertyCode]);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      const querySnapshot = await getDocs(collection(db, "empRegister"));
      const employeesData = [];

      querySnapshot.forEach((doc) => {
        employeesData.push({
          id: doc.id,
          EmployeeId: doc.data().EmpID, // Ensure this matches the `EmployeeId` field in `payRollData`
          PayD1: doc.data().PayD1,
          PayD1_Type: doc.data().PayD1_Type,
          PayD2: doc.data().PayD2,
          PayD2_Type: doc.data().PayD2_Type,
        });
      });

      setEnhancedEmployees(employeesData);
    };

    fetchEmployeeData();
  }, []);

  const enhancePayRollData = (payRollData, enhancedEmployees) => {
    return payRollData.map((payRoll) => {
      const employee = enhancedEmployees.find(
        (emp) => emp.EmployeeId === payRoll.EmployeeId,
      );
      return {
        ...payRoll,
        PayD1: employee?.PayD1,
        PayD1_Type: employee?.PayD1_Type,
        PayD2: employee?.PayD2,
        PayD2_Type: employee?.PayD2_Type,
      };
    });
  };

  useEffect(() => {
    if (payRollData.length > 0 && enhancedEmployees.length > 0) {
      const enhancedData = enhancePayRollData(payRollData, enhancedEmployees);
      setPayRollData(enhancedData);
    }
  }, [payRollData, enhancedEmployees]);

  return (
    <>
      {actionType && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            borderRadius: "0.9rem",
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="left"
            spacing={1}
            style={{ margin: "9px" }}
          >
            <Grid item>
              {actionType === "timecards" && (
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    letterSpacing: "0.09rem",
                    fontWeight: "800",
                    fontSize: "2.07rem",
                    color: "#202426",
                    textTransform: "lowercase",
                    position: "relative",
                    "::after": {
                      content: '""',
                      position: "absolute",
                      left: 0,
                      bottom: 0.9,
                      width: "100%",
                      height: "2.7px",
                      backgroundColor: "grey",
                    },
                  }}
                  variant="body1"
                  align="left"
                >
                  TIMECARDS
                </Typography>
              )}
              {actionType === "summary" && (
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    letterSpacing: "0.09rem",
                    fontWeight: "800",
                    fontSize: "2.07rem",
                    color: "grey",
                    textTransform: "lowercase",
                    opacity: "0.36",
                  }}
                >
                  TIMECARDS
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Switch
                checked={actionType === "summary"}
                onChange={handleMainSwitch}
                name="actionTypeSwitch"
                color="primary"
                sx={{
                  "& .MuiSwitch-switchBase": {
                    "&.Mui-checked": {
                      color: "#FFC107",
                      "& + .MuiSwitch-track": {
                        backgroundColor: "#FFC107",
                        border: "2.7px solid lightgrey",
                      },
                    },
                    "&.Mui-checked:hover": {
                      backgroundColor: "rgba(249, 139, 133, 0.08)",
                    },
                    "&.Mui-checked .MuiSwitch-thumb": {
                      backgroundColor: "#FFC107",
                      border: "1.8px solid #FFC107",
                    },
                    "& .MuiSwitch-thumb": {
                      backgroundColor: "#93A683",
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backgroundSize: "cover",
                      },
                    },
                  },
                  "& .MuiSwitch-thumb": {
                    backgroundColor: "#93A683",
                    border: "1.8px solid #93A683",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      backgroundSize: "cover",
                    },
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: "#93A683",
                    border: "2.7px solid lightgrey",
                  },
                }}
              />
            </Grid>

            <Grid item>
              {actionType === "summary" && (
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    letterSpacing: "0.09rem",
                    fontWeight: "800",
                    fontSize: "2.07rem",
                    color: "#202426",
                    textTransform: "lowercase",
                    position: "relative",
                    "::after": {
                      content: '""',
                      position: "absolute",
                      left: 0,
                      bottom: 0.9,
                      width: "100%",
                      height: "2.7px",
                      backgroundColor: "grey",
                    },
                  }}
                >
                  SUMMARY
                </Typography>
              )}
              {actionType === "timecards" && (
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    letterSpacing: "0.09rem",
                    fontWeight: "800",
                    fontSize: "2.07rem",
                    color: "grey",
                    textTransform: "lowercase",
                    opacity: "0.36",
                  }}
                  variant="body1"
                  align="left"
                >
                  SUMMARY
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Conditionally render form based on actionType */}
      {actionType === "summary" && (
        <div>
          {/* HEADER */}
          <Grid
            container
            alignItems="center"
            justifyContent="left"
            spacing={2}
            style={{ margin: "9px" }}
          >
            <Grid item>
              <FormControl
                variant="outlined"
                sx={{
                  minWidth: "15rem",
                  "@media (max-width: 600px)": { minWidth: "9rem" },
                }}
              >
                <InputLabel
                  sx={{
                    ...selectInputLabel,
                  }}
                  htmlFor="property-code"
                >
                  TEAM
                </InputLabel>
                <Select
                  value={selectedPropertyCode || ""}
                  onChange={handlePropertyCodeChange}
                  inputProps={{ name: "property-code", id: "property-code" }}
                  // readOnly={!!selectedPropertyCode} // Makes it read-only if selectedPropertyCode exists
                  sx={{
                    ...selectSelect,
                  }}
                >
                  {viewPayrollSummary
                    .slice()
                    .sort((a, b) => {
                      const nameA = (
                        a.propertyCode ||
                        a.propertyName ||
                        ""
                      ).toString();
                      const nameB = (
                        b.propertyCode ||
                        b.propertyName ||
                        ""
                      ).toString();
                      return nameA.localeCompare(nameB);
                    })
                    .map((property) => (
                      <MenuItem
                        key={property.propertyCode}
                        value={property.propertyCode}
                        sx={{ ...selectMenuItem }}
                      >
                        {property.propertyName
                          ? `${property.propertyCode} - ${property.propertyName}`
                          : property.propertyCode}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                sx={{
                  minWidth: "15rem",
                  "@media (max-width: 600px)": { minWidth: "9rem" },
                }}
              >
                <InputLabel
                  htmlFor="Select Pay Period"
                  sx={{
                    ...selectInputLabel,
                  }}
                >
                  PERIOD
                </InputLabel>
                <Select
                  value={selectedDate || ""}
                  onChange={(event) => setSelectedDate(event.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Select Pay Period" }}
                  sx={{
                    ...selectSelect,
                  }}
                >
                  {uniqueDates.map((date, index) => (
                    <MenuItem
                      key={index}
                      value={date}
                      sx={{
                        ...selectMenuItem,
                      }}
                    >
                      {new Date(date).toLocaleDateString("en-US", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box height={16} />

          <Card
            sx={{
              borderRadius: "18px",
            }}
          >
            <Box>
              <Grid
                container
                alignItems="center"
                justifyContent="left"
                spacing={2}
                style={{ margin: "9px" }}
              >
                <Grid item xs={6} sm={3}>
                  {isClosed ? (
                    <Typography
                      variant="caption"
                      sx={{
                        fontFamily: "Outfit",
                        fontWeight: 400,
                        color: "#202426",
                        fontSize: "0.82rem",
                        letterSpacing: "0.09rem",
                      }}
                    >
                      <BsFillPatchCheckFill
                        style={{
                          color: "#4CAF50",
                          marginRight: "0.5rem",
                          fontSize: "1.35rem",
                        }}
                      />
                      ALL TIMECARDS ARE CLOSED
                    </Typography>
                  ) : (
                    <Typography
                      variant="caption"
                      sx={{
                        fontFamily: "Outfit",
                        fontWeight: 400,
                        color: "#202426",
                        fontSize: "0.81rem",
                        letterSpacing: "0.09rem",
                      }}
                    >
                      <BsFillPatchCheckFill
                        style={{
                          color: "#F98B85",
                          marginRight: "0.5rem",
                          fontSize: "1.35rem",
                        }}
                      />
                      INCOMPLETE SUMMARY
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!showNonVoided}
                        onChange={(event) =>
                          setShowNonVoided(!event.target.checked)
                        }
                        sx={{
                          "& .MuiSwitch-switchBase": {
                            "&.Mui-checked": {
                              color: "#F98B85",
                              "& + .MuiSwitch-track": {
                                backgroundColor: "#F98B85",
                              },
                            },
                            "&.Mui-checked:hover": {
                              backgroundColor: "rgba(249, 139, 133, 0.08)",
                            },
                            "&.Mui-checked .MuiSwitch-thumb": {
                              backgroundColor: "#F98B85",
                            },
                            "& .MuiSwitch-thumb": {
                              backgroundColor: "#74c365",
                              "&::before": {
                                content: '""',
                                position: "left",
                                width: "100%",
                                height: "100%",
                                backgroundSize: "cover",
                              },
                            },
                          },
                          "& .MuiSwitch-thumb": {
                            backgroundColor: "#74c365",
                            "&::before": {
                              content: '""',
                              position: "left",
                              width: "100%",
                              height: "100%",
                              backgroundSize: "cover",
                            },
                          },
                          "& .MuiSwitch-track": {
                            backgroundColor: "#74c365",
                          },
                          marginLeft: "9px",
                        }}
                      />
                    }
                    label={!showNonVoided ? "VOIDED CARDS" : "FINAL CARDS"}
                    labelPlacement="right"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontFamily: "Outfit",
                        fontWeight: 800,
                        color: "#202426",
                        fontSize: "0.81rem",
                        letterSpacing: "0.09rem",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={6} sm={3}>
                  <PDFDownloadLink
                    document={
                      <MyDocument
                        payRollData={payRollData}
                        selectedPayRoll={selectedPayRoll}
                        sortKey={sortConfig.key}
                        sortDirection={sortConfig.direction}
                      />
                    }
                    fileName={`${calculatedDate}_Payroll_Summary_${selectedPropertyCode}.pdf`}
                  >
                    {() => (
                      <IconButton
                        sx={{
                          fontSize: "1.5rem",
                          color: "#FF0000",
                          backgroundColor: "#fcfcfc",
                        }}
                      >
                        <FaFilePdf />
                      </IconButton>
                    )}
                  </PDFDownloadLink>
                  <Typography
                    variant="caption"
                    sx={{
                      fontFamily: "Outfit",
                      fontWeight: 800,
                      color: "#202426",
                      fontSize: "0.81rem",
                      letterSpacing: "0.09rem",
                    }}
                  >
                    DL PDF
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={3}>
                  <IconButton
                    onClick={() => {
                      exportToExcel(
                        prepareDataForExport(sortedAndFilteredData),
                        `${calculatedDate}_Payroll_Summary_${selectedPropertyCode}`,
                      );
                    }}
                    sx={{
                      fontSize: "1.5rem",
                      color: "green",
                      backgroundColor: "#fcfcfc",
                    }}
                  >
                    <FaFileExcel />
                  </IconButton>
                  <Typography
                    variant="caption"
                    sx={{
                      fontFamily: "Outfit",
                      fontWeight: 800,
                      color: "#202426",
                      fontSize: "0.81rem",
                      letterSpacing: "0.09rem",
                    }}
                  >
                    DL EXCEL
                  </Typography>
                </Grid>
              </Grid>

              <TableContainer sx={{ overflowX: "auto" }} component={Paper}>
                <Table id="PAYROLL-SUMMARY" sx={{ width: "100%" }}>
                  <TableHead>
                    <TableRow>
                      {" "}
                      <TableCell align="right" colSpan={14}></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{ ...totals, fontWeight: "800" }}
                        colSpan={1}
                      >
                        {tableTitle}
                      </TableCell>
                      <TableCell align="left" sx={totals}>
                        <TableSortLabel
                          active={sortConfig.key === "FirstName"}
                          direction={sortConfig.direction}
                          onClick={() => requestSort("FirstName")}
                        >
                          FIRST
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left" sx={totals}>
                        <TableSortLabel
                          active={sortConfig.key === "LastName"}
                          direction={sortConfig.direction}
                          onClick={() => requestSort("LastName")}
                        >
                          LAST
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left" sx={totals}>
                        TIME
                      </TableCell>
                      <TableCell align="left" sx={totals}>
                        DEC
                      </TableCell>
                      <TableCell align="left" sx={totals}>
                        OT
                      </TableCell>
                      <TableCell align="center" sx={totals}>
                        VAC
                      </TableCell>
                      <TableCell align="center" sx={totals}>
                        HOL
                      </TableCell>
                      <TableCell align="center" sx={totals}>
                        RMS
                      </TableCell>
                      <TableCell align="center" sx={totals}>
                        A
                      </TableCell>
                      <TableCell align="center" sx={totals}>
                        B
                      </TableCell>
                      <TableCell align="center" sx={totals}>
                        C
                      </TableCell>
                      <TableCell align="left" sx={totals}>
                        TIPS
                      </TableCell>
                      {canViewSection(
                        "hrViewPayRate",
                        selectedPropertyCode,
                      ) && (
                        <TableCell align="right" sx={totals}>
                          {totalGross}
                        </TableCell>
                      )}
                    </TableRow>
                    <TableRow>
                      {" "}
                      <TableCell align="right" colSpan={14}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedAndFilteredData.map((payRoll, index) => {
                      const borderedRowStyle = {
                        borderLeft: "0px solid black",
                        borderRight: "0px solid black",
                      };
                      return (
                        <React.Fragment key={payRoll.id}>
                          <TableRow
                            style={
                              payRoll.Status === "Void"
                                ? strikethroughStyle
                                : {
                                    ...borderedRowStyle,
                                    ...entryTopBorderStyle,
                                  }
                            }
                          >
                            <TableCell
                              align="left"
                              size="small"
                              sx={maintable3}
                            >
                              {payRoll.EmployeeId}
                            </TableCell>
                            <TableCell
                              align="left"
                              size="small"
                              sx={maintable3}
                            >
                              {payRoll.FirstName}
                            </TableCell>
                            <TableCell
                              align="left"
                              size="small"
                              sx={maintable3}
                            >
                              {payRoll.LastName}
                            </TableCell>
                            <TableCell
                              align="left"
                              size="small"
                              sx={maintable3}
                            >
                              {payRoll.Totals?.Time}
                            </TableCell>
                            <TableCell
                              align="left"
                              size="small"
                              sx={maintable3}
                            >
                              {payRoll.Totals?.Decimal
                                ? payRoll.Totals.Decimal.toFixed(2)
                                : ""}
                            </TableCell>
                            <TableCell
                              align="left"
                              size="small"
                              sx={maintable3}
                            >
                              {payRoll.Totals?.Overtime
                                ? payRoll.Totals.Overtime.toFixed(2)
                                : ""}
                            </TableCell>
                            <TableCell
                              align="center"
                              size="small"
                              sx={maintable3}
                            >
                              {payRoll.Totals?.PTO ? payRoll.Totals.PTO : ""}
                            </TableCell>
                            <TableCell
                              align="center"
                              size="small"
                              sx={maintable3}
                            >
                              {payRoll.Totals?.Holiday
                                ? payRoll.Totals.Holiday
                                : ""}
                            </TableCell>
                            <TableCell
                              align="center"
                              size="small"
                              sx={maintable3}
                            >
                              {payRoll.Totals?.Rooms
                                ? payRoll.Totals.Rooms
                                : ""}
                            </TableCell>
                            <TableCell
                              align="center"
                              size="small"
                              sx={maintable3}
                            >
                              {payRoll.Totals?.A ? payRoll.Totals.A : ""}
                            </TableCell>
                            <TableCell
                              align="center"
                              size="small"
                              sx={maintable3}
                            >
                              {payRoll.Totals?.B ? payRoll.Totals.B : ""}
                            </TableCell>
                            <TableCell
                              align="center"
                              size="small"
                              sx={maintable3}
                            >
                              {payRoll.Totals?.C ? payRoll.Totals.C : ""}
                            </TableCell>
                            <TableCell
                              align="left"
                              size="small"
                              sx={maintable3}
                            >
                              {payRoll.Totals?.Tips ? payRoll.Totals.Tips : ""}
                            </TableCell>
                            {canViewSection(
                              "hrViewPayRate",
                              selectedPropertyCode,
                            ) &&
                              payRoll.PayD1_Type !== "SALARY" &&
                              payRoll.PayD2_Type !== "SALARY" && (
                                <TableCell
                                  align="right"
                                  size="small"
                                  sx={maintable3}
                                >
                                  {payRoll.Totals?.Gross || "0.00"}
                                </TableCell>
                              )}
                          </TableRow>
                          {/* DEPT 1 Row */}
                          <TableRow
                            key={`${payRoll.id}-D1`}
                            style={borderedRowStyle}
                          >
                            <TableCell
                              align="left"
                              size="small"
                              sx={maintable}
                              colSpan={4}
                            >
                              {payRoll.PayD1} ({payRoll.PayD1_Type})
                            </TableCell>
                            <TableCell
                              align="left"
                              size="small"
                              sx={maintable}
                              style={
                                payRoll.PayD1_Type === "PER ROOM"
                                  ? strikethroughStyle
                                  : {}
                              }
                            >
                              {payRoll.D1?.HRS || "0.00"}
                            </TableCell>
                            <TableCell align="left" size="small" sx={maintable}>
                              {payRoll.D1?.OT || "0.00"}
                            </TableCell>
                            <TableCell
                              align="center"
                              size="small"
                              sx={maintable}
                            >
                              {payRoll.D1?.VAC || "0.00"}
                            </TableCell>
                            <TableCell
                              align="center"
                              size="small"
                              sx={maintable}
                            >
                              {payRoll.D1?.HOL || "0.00"}
                            </TableCell>
                            <TableCell colSpan={6} sx={maintable} />
                          </TableRow>

                          {/* DEPT 2 Row */}
                          {payRoll.PayD2 && payRoll.PayD2 !== "N/A" && (
                            <TableRow
                              key={`${payRoll.id}-D2`}
                              style={{
                                ...borderedRowStyle,
                                ...entryBottomBorderStyle,
                              }}
                            >
                              <TableCell
                                align="left"
                                size="small"
                                sx={maintable}
                                colSpan={4}
                              >
                                {payRoll.PayD2} (
                                {payRoll.PayD2_Type || "Unknown Type"})
                              </TableCell>
                              <TableCell
                                align="left"
                                size="small"
                                sx={maintable}
                              >
                                {payRoll.D2?.HRS || "0.00"}
                              </TableCell>
                              <TableCell
                                align="left"
                                size="small"
                                sx={maintable}
                              >
                                {payRoll.D2?.OT || "0.00"}
                              </TableCell>
                              <TableCell
                                align="center"
                                size="small"
                                sx={maintable}
                              >
                                {payRoll.D2?.VAC || "0.00"}
                              </TableCell>
                              <TableCell
                                align="center"
                                size="small"
                                sx={maintable}
                              >
                                {payRoll.D2?.HOL || "0.00"}
                              </TableCell>
                              <TableCell colSpan={6} sx={maintable} />
                            </TableRow>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      marginLeft: 1,
                      letterSpacing: "0.18rem",
                      fontWeight: "200",
                      fontSize: "0.8rem",
                      color: "#101010",
                      paddingTop: "1.8rem",
                      paddingLeft: "0.54rem",
                    }}
                  >
                    NOTES THIS PERIOD
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(
                          groupChangesByEmployee(payrollChanges),
                        ).map(([empId, changes]) => (
                          <React.Fragment key={empId}>
                            {changes.map((change, index) => (
                              <TableRow key={index}>
                                <TableCell
                                  align="left"
                                  size="small"
                                  sx={maintable}
                                >
                                  {change.EmpID}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  size="small"
                                  sx={maintable}
                                >{`${change.NameFirst} ${change.NameLast}`}</TableCell>
                                <TableCell
                                  align="left"
                                  size="small"
                                  sx={maintable}
                                >
                                  {change.changes.join(", ")}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  size="small"
                                  sx={maintable}
                                >
                                  {change.ChangedBy}
                                </TableCell>
                              </TableRow>
                            ))}
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Box>
          </Card>
        </div>
      )}

      {actionType === "timecards" && (
        <div>
          <PunchTimecards />
        </div>
      )}
    </>
  );
};

export default PunchSummary;

const maintable = {
  fontWeight: 400,
  fontSize: "0.81rem",
  backgroundColor: "#ffffff",
  color: "grey",
  minWidth: "2.7rem",
  textTransform: "uppercase",
  letterSpacing: "0.027rem",
};
const maintable3 = {
  letterSpacing: "0.018rem",
  fontWeight: 600,
  fontSize: "0.81rem",
  backgroundColor: "#ffffff",
  color: "#202426",
  minWidth: "2.7rem",
};
const totals = {
  fontFamily: "Outfit",
  fontWeight: 400,
  fontSize: "0.9rem",
  backgroundColor: "#f5f5f5",
  color: "#020202",
  maxWidth: "3.6rem",
  letterSpacing: "0.1rem",
};
const selectInputLabel = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Outfit",
  fontWeight: 400,
  color: "#202426",
  transition: "0.3s",
  textTransform: "uppercase",
};
const selectSelect = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Nunito",
  backgroundColor: "#ffffff",
  borderRadius: "18px",
  textTransform: "uppercase",
};
const selectMenuItem = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Nunito",
  fontWeight: 400,
  letterSpacing: "0.045rem",
  color: "#202426",
  transition: "0.3s",
  textTransform: "uppercase",
};
