import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import "./components/App.css";
import Home from "./pages/home/Home";
import NightShift from "./pages/nightShift/punch";
import NsSummary from "./pages/nightShift/summary";
import Roster from "./pages/nightShift/roster";
import AddRecord from "./pages/nightShift/AddRecord";
import UpdateRecord from "./pages/nightShift/UpdateRecord";
import Nav from "./components/Nav";
import SignUp from "./pages/home/SignUp";
import { useAuth } from "./contexts/AuthContext";
import Login from "./pages/home/Login";
import Apps from "./pages/apps/apps";
import Stubs from "./pages/nightShift/stubs";
import Stats from "./pages/home/stats";
import PunchTimecards from "./pages/nightShift/timecards";
import BreakRm from "./pages/breakRoom/breakRm";
import Portfolio from "./pages/Cheddar/portfolio";

function App() {
  const { user, authStateChecked } = useAuth();
  return (
    <>
      {authStateChecked && (
        <Router>
          <Nav />
          <div className="app-container">
            <Routes>
              <Route
                path="/Stats"
                element={
                  user && user.emailVerified ? (
                    <Stats />
                  ) : (
                    <Navigate to="/Stats" replace />
                  )
                }
              />
              <Route
                path="/breakRm"
                element={
                  user && user.emailVerified ? (
                    <BreakRm />
                  ) : (
                    <Navigate to="/nightShift/breakRm" replace />
                  )
                }
              />
              <Route
                path="/stubs"
                element={
                  user && user.emailVerified ? (
                    <Stubs />
                  ) : (
                    <Navigate to="/stubs" replace />
                  )
                }
              />
              <Route
                path="/punch"
                element={
                  user && user.emailVerified ? (
                    <NightShift />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/timecards"
                element={
                  user && user.emailVerified ? (
                    <PunchTimecards />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/summary"
                element={
                  user && user.emailVerified ? (
                    <NsSummary />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/Roster"
                element={
                  user && user.emailVerified ? (
                    <Roster />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/add-Record"
                element={
                  user && user.emailVerified ? (
                    <AddRecord />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/update-Record"
                element={
                  user && user.emailVerified ? (
                    <UpdateRecord />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/portfolio"
                element={
                  user && user.emailVerified ? (
                    <Portfolio />
                  ) : (
                    <Navigate to="/portfolio" replace />
                  )
                }
              />
              <Route
                path="/apps"
                element={
                  user && user.emailVerified ? (
                    <Apps />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/signup"
                element={!user ? <SignUp /> : <Navigate to="/" replace />}
              />
              <Route
                path="/login"
                element={!user ? <Login /> : <Navigate to="/" replace />}
              />
              <Route
                exact
                path="/"
                element={
                  user && user.emailVerified ? (
                    <Home />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
            </Routes>
          </div>
        </Router>
      )}
    </>
  );
}

export default App;
