import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  ListItemButton,
  DialogActions,
  Badge,
  IconButton,
  ClickAwayListener,
  Grid,
} from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase/firebase-config";
import {
  collection,
  query,
  orderBy,
  where,
  onSnapshot,
  addDoc,
  serverTimestamp,
  getDocs,
  doc,
  getDoc,
  startAfter,
  limit,
  updateDoc,
  arrayUnion,
  increment,
} from "firebase/firestore";
import axios from "axios";
import { useInView } from "react-intersection-observer";
import InfiniteScroll from "react-infinite-scroll-component";
import loginButtonImage from "../../assets/btVamos.png";
import loginButtonImage2 from "../../assets/btVamos2.png";
import likeButton from "../../assets/ico/SVG/like.svg";
import commentButton from "../../assets/ico/SVG/comment.svg";
import reshareButton from "../../assets/ico/SVG/repost.svg";
import breakRm from "../../assets/ico/SVG/breakRoomW.svg";
import debounce from "lodash/debounce";
import Lottie from "lottie-react";
import animationData from "../../assets/loading.json";
import Garage from "../../assets/ico/SVG/garage.svg";
import Scooter from "../../assets/ico/SVG/scooter.svg";
import Bike from "../../assets/ico/SVG/bike.svg";
import Plane from "../../assets/ico/SVG/plane.svg";
import HeartOut from "../../assets/ico/reactions/heartOut.svg";
import HeartFill from "../../assets/ico/reactions/heartFill.svg";
import Fukc from "../../assets/ico/reactions/fukc.svg";
import Haha from "../../assets/ico/reactions/haha.svg";
import Lol from "../../assets/ico/reactions/lol.svg";
import Feedback from "../../assets/ico/SVG/feedback.svg";
import CloseIcon from "@mui/icons-material/Close";
import { PiSealQuestionBold } from "react-icons/pi";
import { TiThumbsUp, TiThumbsDown } from "react-icons/ti";
import { BsPatchExclamationFill } from "react-icons/bs";
import { IoTrashBinOutline } from "react-icons/io5";

export default function BreakRm() {
  const { user } = useAuth();
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState("");
  const [profileData, setProfileData] = useState(null); // User profile data
  const [filteredPropIDs, setFilteredPropIDs] = useState([]);
  const [userCity, setUserCity] = useState("");
  const [userState, setUserState] = useState("");
  const [lastVisible, setLastVisible] = useState(null); // Tracks the last fetched document
  const [hasMore, setHasMore] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentPostId, setCurrentPostId] = useState(null);
  const [commentText, setCommentText] = useState("");
  const [isCommenting, setIsCommenting] = useState(false);
  const [currentPost, setCurrentPost] = useState("");
  const [isLiking, setIsLiking] = useState(false);
  const [lastSeenTimestamp, setLastSeenTimestamp] = useState(null);
  const seenPostsRef = useRef(null);
  const [seenPostIds, setSeenPostIds] = useState(new Set());
  const [isEditing, setIsEditing] = useState(false);
  const [filterType, setFilterType] = useState("all");
  const scrollRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState(["general"]);
  const [selectedReshareTargets, setSelectedReshareTargets] = useState([
    "general",
  ]);
  const [currentEverywhereFilter, setCurrentEverywhereFilter] = useState("all");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  // Detects URLs and converts them into clickable links
  const [externalLink] = useState("");
  const [showExternalWarning, setShowExternalWarning] = useState(false);
  const [externalLinkToOpen, setExternalLinkToOpen] = useState("");

  const detectLinksWithEnhancements = (text) => {
    // Improved regex to capture more diverse URLs
    const urlRegex =
      /((?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?)/gi;

    return text.split(urlRegex).map((part, index) => {
      if (urlRegex.test(part)) {
        // Check if the URL is blacklisted
        if (isBlacklisted(part)) {
          return (
            <span
              key={index}
              style={{ color: "red", textDecoration: "line-through" }}
            >
              ⚠️ Blocked Link
            </span>
          );
        }

        // Allow other links with a user confirmation
        return (
          <a
            key={index}
            onClick={(e) => {
              e.preventDefault();
              handleExternalLinkClick(part);
            }}
            style={{
              color: "#BB2E33",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {part}
          </a>
        );
      }

      // Render non-URL parts as plain text
      return <span key={index}>{part}</span>;
    });
  };

  // Blacklisted Domains
  const BLACKLISTED_DOMAINS = [""];

  // Helper Function: Check if the domain is blacklisted
  const isBlacklisted = (url) => {
    const blacklist = ["example.com", "baddomain.net"];
    return blacklist.some((domain) => url.includes(domain));
  };

  const setExternalLink = (url) => {
    // Normalize URL by adding protocol if missing
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = `https://${url}`;
    }

    // Extract hostname from URL and handle normalization
    try {
      const urlObj = new URL(url);
      const hostname = urlObj.hostname;

      // Handle specific domains to always open externally
      const externalDomains = ["aol.com", "www.aol.com"];
      if (externalDomains.includes(hostname)) {
        setExternalLinkToOpen(urlObj.href); // Set for dialog
        setShowExternalWarning(true); // Trigger dialog
        return;
      }

      // Set the external link for dialog confirmation
      setExternalLinkToOpen(urlObj.href);
      setShowExternalWarning(true);
    } catch (error) {
      console.error("Invalid URL:", url);
      alert("⚠️ The provided URL is invalid.");
    }
  };

  // Helper Function: Open Links with Confirmation for Unlisted Domains
  const handleExternalLinkClick = (url) => {
    if (isBlacklisted(url)) {
      alert("⚠️ This link is blocked due to security concerns.");
      return;
    }

    // Normalize the URL by adding protocol if missing
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = `https://${url}`;
    }

    // Verify if the URL is valid after normalization
    try {
      const urlObj = new URL(url);

      // Example: Show confirmation dialog for unlisted domains
      setExternalLink(urlObj.href);
      setShowExternalWarning(true);
    } catch (error) {
      console.error("Invalid URL:", url);
      alert("⚠️ This link is invalid and cannot be opened.");
    }
  };

  // Icon dynamic island LIKES
  const [showReactions, setShowReactions] = useState(false);
  const [activeReactionPostId, setActiveReactionPostId] = useState(null); // Track which post's reactions are open
  const reactionBoxRef = useRef(null);
  const [openDialogPostId, setOpenDialogPostId] = useState(null);
  const [likesDialogOpen, setLikesDialogOpen] = useState(false);

  const reactionIcons = {
    love: (
      <img
        src={HeartFill}
        alt="Heart"
        style={{ color: "#BB2E33", width: 18, height: 18 }}
      />
    ),
    like: <TiThumbsUp style={{ fill: "#202426", width: 18, height: 18 }} />,
    dislike: (
      <TiThumbsDown style={{ fill: "#202426", width: 18, height: 18 }} />
    ),
    XYZ: <img src={Fukc} alt="xyz" style={{ width: 18, height: 18 }} />,
    haha: <img src={Haha} alt="Haha" style={{ width: 18, height: 18 }} />,
    lol: <img src={Lol} alt="LOL" style={{ width: 18, height: 18 }} />,
    question: (
      <PiSealQuestionBold style={{ fill: "#202426", width: 18, height: 18 }} />
    ),
  };

  const getHeartIcon = (post) => {
    return post.reactions?.some((r) => r.uid === profileData.uid)
      ? HeartFill
      : HeartOut;
  };

  // Close the reaction popup when clicking outside
  const handleClickAway = () => {
    setActiveReactionPostId(null); // Close all popups
  };

  // Show top 3 reactions stacked under the heart icon
  const getUniqueReactions = (post) =>
    [...new Set(post.reactions?.map((r) => r.type))].slice(0, 3);

  // LANGUAGE
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const availableLanguages = ["en", "es"];

  const toggleLanguage = () => {
    const currentIndex = availableLanguages.indexOf(currentLanguage);
    const nextIndex = (currentIndex + 1) % availableLanguages.length;
    const nextLanguage = availableLanguages[nextIndex];

    setCurrentLanguage(nextLanguage);
  };

  // post property capsule helpers
  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        setShowLeftArrow(scrollRef.current.scrollLeft > 0);
        const isScrollable =
          scrollRef.current.scrollWidth > scrollRef.current.clientWidth;
        setShowRightArrow(
          scrollRef.current.scrollLeft + scrollRef.current.clientWidth <
            scrollRef.current.scrollWidth && isScrollable,
        );
      }
    };

    const checkOverflow = () => {
      if (scrollRef.current) {
        const isOverflowing =
          scrollRef.current.scrollWidth > scrollRef.current.clientWidth;
        setShowRightArrow(isOverflowing);
      }
    };

    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", checkOverflow);
      checkOverflow();
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
      window.removeEventListener("resize", checkOverflow);
    };
  }, [selectedProperties]);

  const togglePropertySelection = (prop) => {
    if (prop === "general") {
      // Select only 'general' and deselect others
      setSelectedProperties(["general"]);
    } else {
      setSelectedProperties((prevSelected) => {
        // Deselect 'general' when any property is selected
        const updatedSelection = prevSelected.filter((p) => p !== "general");
        if (updatedSelection.includes(prop)) {
          // Deselect if already selected
          return updatedSelection.filter((p) => p !== prop);
        } else {
          // Add the selected property
          return [...updatedSelection, prop];
        }
      });
    }
  };

  const toggleResharePropertySelection = (prop) => {
    if (prop === "general") {
      setSelectedReshareTargets(["general"]); // Select only "general"
    } else {
      setSelectedReshareTargets((prevSelected) => {
        const withoutGeneral = prevSelected.filter((p) => p !== "general");
        if (withoutGeneral.includes(prop)) {
          return withoutGeneral.filter((p) => p !== prop); // Deselect if already selected
        } else {
          return [...withoutGeneral, prop]; // Add property
        }
      });
    }
  };

  // State to control the reshare dialog
  const [isReshareDialogOpen, setIsReshareDialogOpen] = useState(false);
  const [selectedResharePostId, setSelectedResharePostId] = useState(null);
  const [selectedReshareTarget, setSelectedReshareTarget] = useState("general");

  // Open dialog when reshare is clicked
  const openReshareDialog = (postId) => {
    setSelectedResharePostId(postId);
    setIsReshareDialogOpen(true);
  };

  // Close dialog
  const closeReshareDialog = () => {
    setIsReshareDialogOpen(false);
    setSelectedResharePostId(null);
    setSelectedReshareTarget("general");
  };

  const POSTS_BATCH_SIZE = 10;

  // Fetch User Data
  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) {
        console.error("No current user is logged in.");
        return;
      }

      try {
        const cachedData = localStorage.getItem("userData");
        if (cachedData) {
          const parsedData = JSON.parse(cachedData);
          console.log("Using cached user data:", parsedData);
          setProfileData(parsedData);
          return;
        }

        const userDocRef = doc(db, "emailAuthUsers", user.email);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log("Fetched userData:", userData);

          // Ensure propertyRoles is properly extracted
          let propertyRoles = userData.propertyRoles || {};

          // Convert Firestore Map to a standard object if necessary
          if (propertyRoles instanceof Map) {
            propertyRoles = Object.fromEntries(propertyRoles.entries());
          }

          console.log("Extracted propertyRoles:", propertyRoles);

          // Extract all property codes
          const propID = Object.keys(propertyRoles);
          console.log("Extracted propIDs:", propID);

          const combinedData = {
            uid: userData.uid || user.uid,
            name: userData.name || user.displayName || "Anonymous",
            profilePictureURL:
              userData.profilePictureURL || user.photoURL || "",
            propID,
            AdCity: userData.AdCity || "",
            AdState: userData.AdState || "",
          };

          console.log("Final combined user data:", combinedData);

          localStorage.setItem("userData", JSON.stringify(combinedData));
          setProfileData(combinedData);

          const lastSeen = userData.lastSeenTimestamp;
          setLastSeenTimestamp(lastSeen ? lastSeen.toDate() : null);
        } else {
          console.error(
            "No document found in emailAuthUsers for user:",
            user.email,
          );
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [user]);

  // Set profile-based filters
  useEffect(() => {
    if (!profileData) return;

    setFilteredPropIDs(profileData.propID || []);
    setUserCity(profileData.AdCity || "");
    setUserState(profileData.AdState || "");
  }, [profileData]);

  useEffect(() => {
    if (!profileData) return;

    const postsCollection = collection(db, "breakRoom");
    let unsubscribe;

    const fetchPostsWithRealtimeUpdates = () => {
      let q;

      if (filterType === "feedback") {
        // Feedback: Fetch only feedback posts, ignore language
        q = query(
          postsCollection,
          where("isFeedback", "==", true),
          orderBy("createdAt", "desc"),
          limit(POSTS_BATCH_SIZE),
        );
      } else if (filterType === "propID" && filteredPropIDs.length) {
        // Property Filter: Exclude feedback and match language
        q = query(
          postsCollection,
          where("isFeedback", "==", false),
          where("propID", "array-contains-any", filteredPropIDs),
          where("language", "==", currentLanguage), // Language filter
          orderBy("createdAt", "desc"),
          limit(POSTS_BATCH_SIZE),
        );
      } else if (filterType === "city" && userCity) {
        // City Filter: Exclude feedback and match language
        q = query(
          postsCollection,
          where("isFeedback", "==", false),
          where("city", "==", userCity),
          where("language", "==", currentLanguage), // Language filter
          orderBy("createdAt", "desc"),
          limit(POSTS_BATCH_SIZE),
        );
      } else if (filterType === "state" && userState) {
        // State Filter: Exclude feedback and match language
        q = query(
          postsCollection,
          where("isFeedback", "==", false),
          where("state", "==", userState),
          where("language", "==", currentLanguage), // Language filter
          orderBy("createdAt", "desc"),
          limit(POSTS_BATCH_SIZE),
        );
      } else {
        // General Posts: Exclude feedback and match language
        q = query(
          postsCollection,
          where("isFeedback", "==", false),
          where("language", "==", currentLanguage), // ✅ Language filter
          orderBy("createdAt", "desc"),
          limit(POSTS_BATCH_SIZE),
        );
      }

      // Listen for real-time updates
      unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedPosts = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          likes: doc.data().likes || 0,
          reshares: doc.data().reshares || 0,
          comments: doc.data().comments || [],
          isUnseen: lastSeenTimestamp
            ? doc.data().createdAt?.toMillis() > lastSeenTimestamp.getTime()
            : true,
        }));

        const rankedPosts = fetchedPosts.sort((a, b) => {
          const createdAtA = a.createdAt ? a.createdAt.toMillis() : 0;
          const createdAtB = b.createdAt ? b.createdAt.toMillis() : 0;
          return createdAtB - createdAtA;
        });

        setPosts(rankedPosts);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        setHasMore(snapshot.docs.length === POSTS_BATCH_SIZE);
      });
    };

    setPosts([]);
    setLastVisible(null);
    setHasMore(true);
    fetchPostsWithRealtimeUpdates();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [
    filterType,
    filteredPropIDs,
    userCity,
    userState,
    lastSeenTimestamp,
    profileData,
    currentLanguage,
  ]);

  // Batch Update Seen Posts
  useEffect(() => {
    if (!profileData?.uid) return;

    const updateSeenPostsInFirestore = debounce(async () => {
      if (seenPostIds.size === 0) return;

      try {
        const seenPostsArray = Array.from(seenPostIds);

        const response = await fetch(
          "https://us-central1-doorman-1985.cloudfunctions.net/updateSeenPosts",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: profileData.uid,
              seenPosts: seenPostsArray,
            }),
          },
        );

        if (response.ok) {
          console.log("Seen posts updated successfully");
          setSeenPostIds(new Set());
        } else {
          console.error("Error updating seen posts:", response.statusText);
        }
      } catch (error) {
        console.error("Error updating seen posts:", error);
      }
    }, 5000);

    updateSeenPostsInFirestore();

    return () => {
      updateSeenPostsInFirestore.cancel();
    };
  }, [seenPostIds, profileData]);

  const Post = ({ post, handlePostSeen, openCommentDialog }) => {
    const { ref, inView } = useInView({
      threshold: 0.5,
      triggerOnce: true,
    });

    useEffect(() => {
      if (inView) {
        handlePostSeen(post);
      }
    }, [inView, handlePostSeen, post]);

    return (
      <Box
        ref={ref}
        key={post.id}
        sx={{
          maxWidth: "450px",
          minWidth: "18rem",
          padding: "0.9rem 1.8rem",
          marginBottom: "8.1px",
          border: "1px solid #e0e0e0",
          borderRadius: "18px",
          backgroundColor: "#f5f5f5",
        }}
      >
        {/* Conditional "Under Review" Message */}
        {post.underReview || post.deleted ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffe6e6",
              padding: "6.3px",
              borderRadius: "9px",
              marginBottom: "4.5px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                marginBottom: "4.5px",
                fontFamily: "Outfit",
                letterSpacing: "0.036rem",
                fontWeight: "800",
                textTransform: "lowercase",
                color: "#BB2E33",
                fontSize: "0.9rem",
              }}
            >
              This post is unavailable.
            </Typography>
          </Box>
        ) : (
          <>
            {/* Post Header */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "3.6px",
              }}
            >
              <Badge
                variant="dot"
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "#93A683",
                    width: "12.6px",
                    height: "12.6px",
                    borderRadius: "50%",
                  },
                }}
                invisible={post.verified !== "staff"}
                overlap="circular"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <Avatar
                  src={post.profilePictureURL}
                  alt={post.userName}
                  sx={{ marginRight: "9px", width: "33.3px", height: "33.3px" }}
                />
              </Badge>
              <Tooltip
                title={
                  <React.Fragment>
                    Posted at{" "}
                    {post.createdAt?.toDate().toLocaleString() || "Just now"}
                  </React.Fragment>
                }
                enterTouchDelay={50}
                leaveTouchDelay={1500}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{
                    fontFamily: "Outfit",
                    letterSpacing: "0.036rem",
                    fontWeight: "800",
                    textTransform: "lowercase",
                    color: "black",
                  }}
                >
                  {post.userName}
                </Typography>
              </Tooltip>
              {post.propID && post.propID.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "1.8px",
                    backgroundColor: "#F2B70A",
                    borderRadius: "4.5px",
                    marginLeft: "0.45rem",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      fontFamily: "Outfit",
                      fontWeight: "600",
                      color: "#f5f5f5",
                    }}
                  >
                    {Array.isArray(post.propID)
                      ? post.propID.join(", ")
                      : post.propID}
                  </Typography>
                </Box>
              )}
            </Box>

            {/* Reshared Post Hidden If Under Review */}
            {(!post.underReview || post.deleted) && post.isReshare && (
              <Box
                sx={{
                  backgroundColor: "#e9ecef",
                  borderRadius: "12px",
                  padding: "9px",
                  marginBottom: "1.8px",
                  border: "1px solid #ccc",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "2.7px",
                  }}
                >
                  <Badge
                    variant="dot"
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "#93A683",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                      },
                    }}
                    invisible={post.originalVerified !== "staff"}
                    overlap="circular"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <Avatar
                      src={post.originalPictureURL}
                      alt={post.originalAuthor}
                      sx={{ marginRight: "6px", width: "27px", height: "27px" }}
                    />
                  </Badge>
                  <Typography
                    variant="caption"
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "Outfit",
                      color: "#202426",
                    }}
                  >
                    shared a post from {post.originalAuthor}
                  </Typography>
                </Box>

                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Outfit",
                    color: "#333",
                    marginBottom: "4.5px",
                  }}
                >
                  {post.text}
                </Typography>
              </Box>
            )}

            {/* Post Content */}
            {!post.isReshare && (
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    marginBottom: "4.5px",
                    fontFamily: "Outfit",
                    letterSpacing: "0.036rem",
                    fontWeight: "400",
                    textTransform: "lowercase",
                    color: "black",
                  }}
                >
                  {detectLinksWithEnhancements(post.text)}
                </Typography>
              </Box>
            )}

            {/* Post Time */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            ></Box>
          </>
        )}

        <Dialog
          open={showExternalWarning}
          onClose={() => setShowExternalWarning(false)}
          PaperProps={{ sx: { ...dialogMain } }}
        >
          <DialogTitle sx={{ ...dialogTitle }}>
            You're Leaving This Site
            <IconButton
              aria-label="close"
              onClick={() => setShowExternalWarning(false)}
              sx={{ position: "absolute", right: 9, top: 9 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ ...dialogContent }}>
            <Typography sx={{ marginBottom: "16px" }}>
              You are about to visit:
            </Typography>

            <Typography
              sx={{
                color: "#BB2E33",
                fontWeight: "bold",
                wordBreak: "break-word",
                marginBottom: "16px",
              }}
            >
              {externalLinkToOpen}
            </Typography>

            <Typography sx={{ fontSize: "0.9rem", color: "gray" }}>
              This link is not verified. Please proceed with caution.
            </Typography>
          </DialogContent>

          <DialogActions sx={{ justifyContent: "center", marginTop: "16px" }}>
            <Button
              onClick={() => {
                window.open(externalLinkToOpen, "_blank"); // Open only when user confirms
                setShowExternalWarning(false);
              }}
              sx={{
                ...redButton,
              }}
            >
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
        {/* Interaction Buttons */}
        {post.underReview || post.deleted ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1.8px",
              alignItems: "center",
            }}
          >
            <Badge
              variant="dot"
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "#93A683",
                  width: "12.6px",
                  height: "12.6px",
                  borderRadius: "50%",
                },
              }}
              invisible={post.verified !== "staff"}
              overlap="circular"
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Outfit",
                  letterSpacing: "0.036rem",
                  fontWeight: "800",
                  textTransform: "lowercase",
                  color: "black",
                }}
              >
                {post.userName}
              </Typography>
            </Badge>
            <Typography variant="caption" color="lightgrey">
              Posted at{" "}
              {post.createdAt?.toDate().toLocaleString() || "Just now"}
            </Typography>
            {post.isReshare && (
              <Typography
                variant="caption"
                sx={{
                  fontWeight: "400",
                  fontFamily: "Outfit",
                  color: "#202426",
                }}
              >
                shared a post from {post.originalAuthor}
              </Typography>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1.8px",
              alignItems: "center",
            }}
          >
            {/* REACTIONS */}
            <ClickAwayListener onClickAway={handleClickAway}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "9px",
                  position: "relative",
                }}
              >
                <Box
                  ref={reactionBoxRef}
                  onClick={() =>
                    setActiveReactionPostId(
                      activeReactionPostId === post.id ? null : post.id,
                    )
                  } // Toggle popup only for this post
                  onTouchEnd={() =>
                    setActiveReactionPostId(
                      activeReactionPostId === post.id ? null : post.id,
                    )
                  } // Ensure toggle works on touch devices
                  sx={{ position: "relative", cursor: "pointer" }}
                >
                  <img
                    src={getHeartIcon(post)}
                    alt="Heart Icon"
                    style={{ width: 18, height: 18 }}
                  />

                  {/* Stacked Reactions */}
                  <Box
                    sx={{
                      display: "flex",
                      position: "absolute",
                      top: 18.9,
                      left: 0,
                      opacity: activeReactionPostId === post.id ? 1 : 0.63,
                      transition: "opacity 0.3s ease",
                      "&:hover": {
                        opacity: 1,
                      },
                    }}
                  >
                    {getUniqueReactions(post).map((type, index) => (
                      <Box
                        key={type}
                        sx={{
                          marginLeft: index * 0,
                          opacity: 0.9,
                          transition: "opacity 0.3s ease",
                          "&:hover": {
                            opacity: 1,
                          },
                        }}
                      >
                        {reactionIcons[type]}
                      </Box>
                    ))}
                  </Box>

                  {/* Dynamic Island - Reaction Popup */}
                  {activeReactionPostId === post.id && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: -54,
                        left: -18,
                        display: "flex",
                        gap: "8px",
                        backgroundColor: "rgba(255, 255, 255, 0.936)",
                        border: "3.6px solid #202426",
                        padding: "9px",
                        borderRadius: "9px",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                        zIndex: 10,
                        "&::after": {
                          content: '""',
                          position: "absolute",
                          bottom: -8.1,
                          left: "15.3px",
                          width: 0,
                          height: 0,
                          borderLeft: "9px solid transparent",
                          borderRight: "9px solid transparent",
                          borderTop: "10px solid white",
                          filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1))",
                        },
                      }}
                    >
                      {(() => {
                        const userReaction = post.reactions?.find(
                          (reaction) => reaction.uid === profileData.uid,
                        );

                        if (userReaction) {
                          const { type } = userReaction;
                          return (
                            <Tooltip key={type} title={type} arrow>
                              <Box
                                onClick={() => {
                                  handleReaction(post.id, type);
                                  setShowReactions(false);
                                }}
                                onTouchEnd={(e) => {
                                  e.stopPropagation();
                                  handleReaction(post.id, type);
                                  setShowReactions(false);
                                }}
                                sx={{
                                  fontSize: "26px",
                                  cursor: "pointer",
                                  transition: "transform 0.2s",
                                  "&:hover": { transform: "scale(1.3)" },
                                }}
                                enterTouchDelay={50}
                                leaveTouchDelay={1500}
                              >
                                {reactionIcons[type]}
                              </Box>
                            </Tooltip>
                          );
                        }

                        return Object.entries(reactionIcons).map(
                          ([type, icon]) => (
                            <Tooltip key={type} title={type} arrow>
                              <Box
                                onClick={() => {
                                  handleReaction(post.id, type);
                                  setShowReactions(false);
                                }}
                                onTouchEnd={(e) => {
                                  e.stopPropagation();
                                  handleReaction(post.id, type);
                                  setShowReactions(false);
                                }}
                                sx={{
                                  fontSize: "26px",
                                  cursor: "pointer",
                                  transition: "transform 0.2s",
                                  "&:hover": { transform: "scale(1.3)" },
                                }}
                                enterTouchDelay={50}
                                leaveTouchDelay={1500}
                              >
                                {icon}
                              </Box>
                            </Tooltip>
                          ),
                        );
                      })()}
                    </Box>
                  )}
                </Box>

                <Typography
                  variant="caption"
                  sx={{
                    color: "grey",
                    fontFamily: "Outfit",
                    fontWeight: "800",
                    fontSize: "0.9rem",
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                      color: "#93A683",
                    },
                  }}
                  onClick={() => setOpenDialogPostId(post.id)}
                >
                  {post.reactions?.length || 0}
                </Typography>
              </Box>
            </ClickAwayListener>

            {/* Comment Button */}
            <Tooltip title="Comment">
              <Box
                onClick={() => openCommentDialog(post.id)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  position: "relative",
                }}
                enterTouchDelay={50}
                leaveTouchDelay={1500}
              >
                <img
                  src={commentButton}
                  alt="Comment"
                  style={{ width: "19px", marginRight: "9px" }}
                />

                <Typography
                  variant="caption"
                  onClick={() => setLikesDialogOpen(true)} // Opens the likes dialog
                  sx={{
                    color: "grey",
                    fontFamily: "Outfit",
                    fontWeight: "800",
                    fontSize: "0.9rem",
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                      color: "#93A683",
                    },
                  }}
                >
                  {post.comments?.length || 0}
                </Typography>
              </Box>
            </Tooltip>

            {/* Reshare Button */}
            <Tooltip title="Share">
              <Box
                onClick={() => openReshareDialog(post.id)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  position: "relative",
                }}
                enterTouchDelay={50}
                leaveTouchDelay={1500}
              >
                <img
                  src={reshareButton}
                  alt="Reshare"
                  style={{ width: "18px", marginRight: "9px" }}
                />

                <Typography
                  variant="caption"
                  onClick={() => setLikesDialogOpen(true)} // Opens the likes dialog
                  sx={{
                    color: "grey",
                    fontFamily: "Outfit",
                    fontWeight: "800",
                    fontSize: "0.81rem",
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                      color: "#93A683",
                    },
                  }}
                >
                  {post.reshares || 0}
                </Typography>
              </Box>
            </Tooltip>
            <Tooltip
              title={
                post.userId === profileData.uid
                  ? "Delete this post"
                  : "Flag this post"
              }
              arrow
              enterTouchDelay={50}
              leaveTouchDelay={1500}
            >
              <IconButton
                onClick={() =>
                  post.userId === profileData.uid
                    ? flagPostAsDeleted(post.id)
                    : handleFlagPost(post.id)
                }
                sx={{ color: "black", fontSize: "1.08rem" }}
              >
                {post.userId === profileData.uid ? (
                  <IoTrashBinOutline />
                ) : (
                  <BsPatchExclamationFill />
                )}
              </IconButton>
              {post.userId !== profileData.uid && (
                <Typography
                  variant="caption"
                  sx={{
                    color: "grey",
                    fontFamily: "Outfit",
                    fontWeight: "800",
                    fontSize: "0.9rem",
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                      color: "#93A683",
                    },
                  }}
                >
                  {post.flags?.length || 0}
                </Typography>
              )}
            </Tooltip>
          </Box>
        )}
        {/* Likes Dialog */}
        <Dialog
          open={openDialogPostId === post.id}
          onClose={() => setOpenDialogPostId(null)}
          PaperProps={{ sx: { ...dialogMain } }}
        >
          <DialogTitle sx={{ ...dialogTitle }}>
            Reactions
            <IconButton
              aria-label="close"
              onClick={() => setOpenDialogPostId(null)}
              sx={{ position: "absolute", right: 9, top: 9 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            {post?.reactions && post.reactions.length > 0 ? (
              post.reactions.map((reaction, index) => (
                <Box
                  key={`${reaction.uid}-${index}`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "9px",
                    marginBottom: "9px",
                    backgroundColor: "#f5f5f5",
                    padding: "9px",
                    borderRadius: "9px",
                  }}
                >
                  <Badge
                    variant="dot"
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "#93A683",
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%",
                      },
                    }}
                    invisible={!reaction.isVerified}
                    overlap="circular"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <Avatar
                      src={reaction.userPicture}
                      alt={reaction.userName}
                    />
                  </Badge>
                  <Typography variant="body2" sx={{ flexGrow: 1 }}>
                    {reaction.userName}
                  </Typography>

                  {/* Reaction Icon */}
                  <Box>
                    {reaction.type === "love" && (
                      <img
                        src={HeartFill}
                        alt="love"
                        style={{ width: 24, height: 24 }}
                      />
                    )}
                    {reaction.type === "like" && (
                      <TiThumbsUp
                        style={{ fill: "#202426", width: 24, height: 24 }}
                      />
                    )}
                    {reaction.type === "dislike" && (
                      <TiThumbsDown
                        style={{ fill: "#202426", width: 24, height: 24 }}
                      />
                    )}
                    {reaction.type === "XYZ" && (
                      <img
                        src={Fukc}
                        alt="xyz"
                        style={{ width: 24, height: 24 }}
                      />
                    )}
                    {reaction.type === "haha" && (
                      <img
                        src={Haha}
                        alt="Haha"
                        style={{ width: 24, height: 24 }}
                      />
                    )}
                    {reaction.type === "lol" && (
                      <img
                        src={Lol}
                        alt="LOL"
                        style={{ width: 24, height: 24 }}
                      />
                    )}
                    {reaction.type === "question" && (
                      <PiSealQuestionBold
                        style={{ fill: "#202426", width: 24, height: 24 }}
                      />
                    )}
                  </Box>
                </Box>
              ))
            ) : (
              <Typography variant="body2">Nah.</Typography>
            )}
          </DialogContent>
        </Dialog>
      </Box>
    );
  };

  const handlePostSeen = (post) => {
    if (post.seen) return; // If already marked as seen, skip

    setSeenPostIds((prevIds) => new Set([...prevIds, post.id])); // Add post ID to the Set

    // Update local state to mark the post as seen and set isUnseen to false
    setPosts((prevPosts) =>
      prevPosts.map((p) =>
        p.id === post.id ? { ...p, seen: true, isUnseen: false } : p,
      ),
    );
  };

  // Fetch More Posts for Infinite Scroll
  const loadMorePosts = async () => {
    if (!hasMore || !lastVisible) return;

    try {
      const postsCollection = collection(db, "breakRoom");
      let q;

      if (filterType === "feedback") {
        // Load more feedback posts
        q = query(
          postsCollection,
          where("isFeedback", "==", true),
          orderBy("createdAt", "desc"),
          startAfter(lastVisible),
          limit(POSTS_BATCH_SIZE),
        );
      } else if (filterType === "propID" && filteredPropIDs.length) {
        // Load more property-specific posts, excluding feedback
        q = query(
          postsCollection,
          where("isFeedback", "==", false),
          where("propID", "array-contains-any", filteredPropIDs),
          where("language", "==", currentLanguage), // ✅ Language filter
          orderBy("createdAt", "desc"),
          startAfter(lastVisible),
          limit(POSTS_BATCH_SIZE),
        );
      } else {
        // Load more general posts, excluding feedback
        q = query(
          postsCollection,
          where("isFeedback", "==", false),
          where("language", "==", currentLanguage), // ✅ Language filter
          orderBy("createdAt", "desc"),
          startAfter(lastVisible),
          limit(POSTS_BATCH_SIZE),
        );
      }

      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        const fetchedPosts = snapshot.docs.map((doc) => ({
          id: doc.id,
          likes: doc.data().likes || 0,
          comments: doc.data().comments || [],
          reshares: doc.data().reshares || 0,
          ...doc.data(),
        }));

        setPosts((prevPosts) => [...prevPosts, ...fetchedPosts]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        setHasMore(snapshot.docs.length === POSTS_BATCH_SIZE);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error loading more posts:", error);
    }
  };

  const handlePostSubmit = async () => {
    if (newPost.trim() === "") return;

    try {
      if (!profileData || !profileData.uid) {
        console.error("Profile data is not available or UID is missing.");
        return;
      }

      const postsCollection = collection(db, "breakRoom");

      const newPostData = {
        text: newPost,
        userId: profileData.uid,
        userName: profileData.displayName || user.displayName || "Anonymous",
        profilePictureURL: profileData.profilePictureURL || "",
        propID:
          filterType === "feedback"
            ? []
            : selectedProperties.includes("general")
              ? []
              : selectedProperties,
        city: profileData.AdCity || "",
        state: profileData.AdState || "",
        likes: 0,
        comments: [],
        reshares: 0,
        createdAt: serverTimestamp(),
        language: "en",
        verified: "staff",
        isFeedback: filterType === "feedback" ? true : false,
      };

      await addDoc(postsCollection, newPostData);
      console.log("Post successfully submitted:", newPostData);

      setNewPost("");
      setSelectedProperties([]);
      setIsEditing(false);
    } catch (error) {
      console.error("Error submitting post:", error);
    }
  };

  // Function to handle REACTIONS
  const handleReaction = async (postId, reactionType) => {
    if (isLiking) return;
    setIsLiking(true);

    const userId = profileData.uid;
    const userName = profileData.displayName || user.displayName || "Anonymous";
    const userPicture = profileData.profilePictureURL || "";
    const isVerified = "staff"; // Manually setting the verification status

    const postIndex = posts.findIndex((p) => p.id === postId);
    if (postIndex === -1) {
      console.error("Post not found.");
      setIsLiking(false);
      return;
    }

    const post = posts[postIndex];

    // Check if the current user is the author of the post
    if (post.userId === userId) {
      alert("You cannot react to your own post.");
      setIsLiking(false);
      return;
    }

    const userReaction = post.reactions?.find((r) => r.uid === userId);
    const isSameReaction = userReaction?.type === reactionType;

    let updatedReactions;

    if (isSameReaction) {
      // Remove reaction if the same one is clicked
      updatedReactions = post.reactions.filter((r) => r.uid !== userId);
    } else {
      // Add or replace reaction
      updatedReactions = [
        ...(post.reactions || []).filter((r) => r.uid !== userId),
        { uid: userId, userName, userPicture, isVerified, type: reactionType },
      ];
    }

    // Optimistically update local state
    const updatedPosts = [...posts];
    updatedPosts[postIndex] = { ...post, reactions: updatedReactions };
    setPosts(updatedPosts);

    try {
      // Update Firestore
      const postRef = doc(db, "breakRoom", postId);
      await updateDoc(postRef, { reactions: updatedReactions });
    } catch (error) {
      console.error("Error updating reaction:", error);
      setPosts(posts); // Rollback state if Firestore update fails
    } finally {
      setIsLiking(false);
    }
  };

  // Function to handle COMMENTS
  const handleCommentSubmit = async () => {
    if (commentText.trim() === "") return;

    if (!currentPostId) {
      console.error("currentPostId is null or undefined");
      return;
    }

    if (!profileData || !profileData.uid) {
      console.error("profileData or profileData.uid is null or undefined");
      return;
    }

    try {
      const postRef = doc(db, "breakRoom", currentPostId);

      const newComment = {
        userId: profileData.uid,
        userName: profileData.displayName || user.displayName || "Anonymous",
        profilePictureURL: profileData.profilePictureURL || "",
        propID: profileData.propID || [],
        city: profileData.AdCity || "",
        state: profileData.AdState || "",
        text: commentText,
        createdAt: new Date().toISOString(),
        language: "en",
        verified: "staff",
      };

      const postSnapshot = await getDoc(postRef);
      const existingComments = postSnapshot.data().comments || [];

      const updatedComments = [...existingComments, newComment];

      await updateDoc(postRef, { comments: updatedComments });

      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === currentPostId
            ? {
                ...post,
                comments: updatedComments,
              }
            : post,
        ),
      );

      setIsDialogOpen(false);
      setCommentText("");
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  // Function to handle SHARES
  const handleReshare = async (postId, targets) => {
    try {
      const postRef = doc(db, "breakRoom", postId);
      const userId = profileData.uid;

      const post = posts.find((post) => post.id === postId);

      if (post.resharedBy?.includes(userId)) {
        console.warn("User has already reshared this post.");
        return;
      }

      await updateDoc(postRef, {
        reshares: increment(1),
        resharedBy: arrayUnion(userId),
      });

      for (const target of targets) {
        const resharedPost = {
          text: post.text,
          originalPostId: postId,
          userId: profileData.uid,
          resharedBy: userId,
          userName: profileData.displayName || user.displayName || "Anonymous",
          profilePictureURL: profileData.profilePictureURL || "",
          createdAt: serverTimestamp(),
          isReshare: true,
          originalAuthor: post.userName || "Anonymous",
          originalPictureURL: post.profilePictureURL,
          originalVerified: post.verified,
          language: "en",
          city: profileData.AdCity || "",
          state: profileData.AdState || "",
          propID: target === "general" ? [] : [target],
          comments: [],
          likes: 0,
          reshares: 0,
          verified: "staff",
        };

        await addDoc(collection(db, "breakRoom"), resharedPost);
        console.log(
          `Post reshared to ${target === "general" ? "General" : target}`,
        );
      }
    } catch (error) {
      console.error("Error resharing post:", error);
    }
  };

  // Function to handle POST FLAGS
  const handleFlagPost = async (postId) => {
    // Confirm with the user before flagging
    if (
      !window.confirm("Are you sure you want to flag this post for review?")
    ) {
      return; // Exit if the user cancels
    }

    const userId = profileData.uid || "Unknown";
    const userName = profileData.displayName || user.displayName || "Anonymous";
    const userPicture = profileData.profilePictureURL || "";
    const isVerified = "staff"; // Hardcoded for testing

    const postIndex = posts.findIndex((p) => p.id === postId);
    if (postIndex === -1) {
      console.error("Post not found.");
      return;
    }

    const post = posts[postIndex];
    const userFlag = post.flags?.find((f) => f.uid === userId);

    if (userFlag) {
      alert("You have already flagged this post.");
      return;
    }

    const updatedFlags = [
      ...(post.flags || []),
      { uid: userId, userName, userPicture, isVerified },
    ];

    const staffFlags = updatedFlags.filter(
      (flag) => flag.isVerified === "staff",
    );
    const regularUserFlags = updatedFlags.filter(
      (flag) => flag.isVerified !== "staff",
    );

    const isUnderReview =
      staffFlags.length >= 2 || regularUserFlags.length >= 5;

    const updatedPosts = [...posts];
    updatedPosts[postIndex] = {
      ...post,
      flags: updatedFlags,
      underReview: isUnderReview,
    };
    setPosts(updatedPosts);

    try {
      const postRef = doc(db, "breakRoom", postId);
      console.log("Updating post with:", {
        flags: updatedFlags,
        underReview: isUnderReview,
      });

      await updateDoc(postRef, {
        flags: updatedFlags || [],
        underReview: isUnderReview || false,
      });

      if (isUnderReview) {
        await axios.post(
          "https://prod-158.westus.logic.azure.com:443/workflows/df0a9547f10244f2b63c482e577fd4a9/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ltx6ZfWiLkZ8bBctvl8KlOjsQsKhqspdcGeVNz0yY04",
          {
            trigger: "underReview",
            oldEmail: "",
            newEmail: post.userName,
            code: postId,
            oldPhoneNumber: post.userId,
            newPhoneNumber: "",
            message: post.text,
          },
        );
        console.log("Post sent for review successfully.");
      }
    } catch (error) {
      console.error("Error flagging post:", error);
      setPosts(posts); // Rollback state if Firestore update fails
    }
  };

  const flagPostAsDeleted = async (postId) => {
    const userId = profileData.uid;

    // Confirm with the user before deletion
    const confirmDeletion = window.confirm(
      "Are you sure you want to delete this post? This action cannot be undone.",
    );
    if (!confirmDeletion) {
      return; // Exit if the user cancels
    }

    // Find the post
    const postIndex = posts.findIndex((p) => p.id === postId);
    if (postIndex === -1) {
      console.error("Post not found.");
      return;
    }

    const post = posts[postIndex];

    // Check if the current user is the author of the post
    if (post.userId !== userId) {
      alert("You can only delete your own posts.");
      return;
    }

    // Optimistically update the local state
    const updatedPosts = [...posts];
    updatedPosts[postIndex] = { ...post, deleted: true };
    setPosts(updatedPosts);

    try {
      // Update Firestore
      const postRef = doc(db, "breakRoom", postId);
      await updateDoc(postRef, { deleted: true });
      console.log("Post flagged as deleted successfully.");
    } catch (error) {
      console.error("Error flagging post as deleted:", error);
      setPosts(posts); // Rollback state if Firestore update fails
    }
  };

  // Function to handle COMMENT FLAGS
  const handleFlagComment = async (postId, commentIndex) => {
    if (
      !window.confirm("Are you sure you want to flag this comment for review?")
    ) {
      return; // Exit if user cancels
    }

    const userId = profileData.uid || "Unknown";
    const userName = profileData.displayName || user.displayName || "Anonymous";
    const userPicture = profileData.profilePictureURL || "";
    const isVerified = "staff"; // Hardcoded for testing

    try {
      const postRef = doc(db, "breakRoom", postId);
      const postSnapshot = await getDoc(postRef);

      if (!postSnapshot.exists()) {
        console.error("Post not found.");
        return;
      }

      const comments = postSnapshot.data().comments || [];
      const comment = comments[commentIndex];

      if (!comment) {
        console.error("Comment not found.");
        return;
      }

      // Check if user already flagged this comment
      const userFlag = comment.flags?.find((f) => f.uid === userId);
      if (userFlag) {
        alert("You have already flagged this comment.");
        return;
      }

      // Add the flag
      const updatedFlags = [
        ...(comment.flags || []),
        { uid: userId, userName, userPicture, isVerified },
      ];

      // Check for review thresholds
      const staffFlags = updatedFlags.filter(
        (flag) => flag.isVerified === "staff",
      );
      const regularUserFlags = updatedFlags.filter(
        (flag) => flag.isVerified !== "staff",
      );
      const isUnderReview =
        staffFlags.length >= 2 || regularUserFlags.length >= 5;

      // Update the flagged comment
      const updatedComment = {
        ...comment,
        flags: updatedFlags,
        underReview: isUnderReview,
      };

      const updatedComments = [...comments];
      updatedComments[commentIndex] = updatedComment;

      // Update Firestore
      await updateDoc(postRef, { comments: updatedComments });

      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId
            ? {
                ...post,
                comments: updatedComments,
              }
            : post,
        ),
      );

      if (isUnderReview) {
        await axios.post(
          "https://prod-158.westus.logic.azure.com:443/workflows/df0a9547f10244f2b63c482e577fd4a9/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ltx6ZfWiLkZ8bBctvl8KlOjsQsKhqspdcGeVNz0yY04",
          {
            postId,
            commentText: comment.text,
            commentIndex,

            trigger: "commentUnderReview",
            oldEmail: comment.userId,
            newEmail: currentPost.userName,
            code: comment.userName,
            oldPhoneNumber: currentPost.userId,
            newPhoneNumber: comment.text,
            message: currentPost.text,
          },
        );
        console.log("Comment sent for review successfully.");
      }
    } catch (error) {
      console.error("Error flagging comment:", error);
      setPosts(posts); // Rollback state if Firestore update fails
    }
  };

  const flagCommentAsDeleted = async (postId, commentIndex) => {
    const userId = profileData.uid;

    // Confirm with the user before deletion
    const confirmDeletion = window.confirm(
      "Are you sure you want to delete this comment? This action cannot be undone.",
    );
    if (!confirmDeletion) {
      return; // Exit if the user cancels
    }

    // Find the post
    const postIndex = posts.findIndex((p) => p.id === postId);
    if (postIndex === -1) {
      console.error("Post not found.");
      return;
    }

    const post = posts[postIndex];
    const comments = post.comments || [];

    // Ensure the comment exists
    if (!comments[commentIndex]) {
      console.error("Comment not found.");
      return;
    }

    const comment = comments[commentIndex];

    // Check if the current user is the author of the comment
    if (comment.userId !== userId) {
      alert("You can only delete your own comments.");
      return;
    }

    // Flag the comment as deleted
    const updatedComments = [...comments];
    updatedComments[commentIndex] = { ...comment, deleted: true };

    // Optimistically update the local state for posts
    const updatedPosts = [...posts];
    updatedPosts[postIndex] = { ...post, comments: updatedComments };
    setPosts(updatedPosts);

    // Update the currentPost if it matches
    if (currentPost.id === postId) {
      setCurrentPost({ ...currentPost, comments: updatedComments });
    }

    try {
      // Update Firestore
      const postRef = doc(db, "breakRoom", postId);
      await updateDoc(postRef, { comments: updatedComments });
      console.log("Comment flagged as deleted successfully.");
    } catch (error) {
      console.error("Error flagging comment as deleted:", error);
      setPosts(posts); // Rollback state if Firestore update fails
    }
  };

  const openCommentDialog = async (postId) => {
    try {
      const postRef = doc(db, "breakRoom", postId);
      const postSnapshot = await getDoc(postRef);

      if (postSnapshot.exists()) {
        setCurrentPostId(postId); // Set the currentPostId here
        setCurrentPost({ id: postId, ...postSnapshot.data() }); // Set the current post data
        setIsDialogOpen(true); // Open the comment dialog
      } else {
        console.error("Post does not exist.");
      }
    } catch (error) {
      console.error("Error fetching post data:", error);
    }
  };

  const filteredPosts = useMemo(() => {
    console.log("Recomputing filtered posts...");
    return posts.filter((post) => post.isUnseen);
  }, [posts]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "45px",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "space-evenly", // Ensures even spacing between items
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 0.54)",
          backdropFilter: "blur(9px)",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          zIndex: 1200,
          padding: "0.18rem 0",
        }}
      >
        {/* Homebase Icon */}
        <ListItemButton
          onClick={() => setFilterType("propID")}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingY: "0.45rem",
            position: "relative",
            "&:hover": { backgroundColor: "transparent" },
          }}
        >
          <Box
            component="img"
            src={Garage}
            alt="Home Icon"
            sx={{ width: 19.8, height: 19.8, marginBottom: "0.27rem" }}
          />
          <Typography
            variant="caption"
            sx={{
              fontWeight: "400",
              color: "black",
              fontSize: "0.72rem",
              fontFamily: "Outfit",
              letterSpacing: "0.045rem",
              textTransform: "lowercase",
            }}
          >
            Homebase
          </Typography>
          {filterType === "propID" && (
            <Box
              sx={{
                width: "45%",
                height: "2px",
                backgroundColor: "black",
                borderRadius: "4px",
                marginTop: "0.18rem",
              }}
            />
          )}
        </ListItemButton>

        <ListItemButton
          onClick={() => setIsDropdownVisible(!isDropdownVisible)}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingY: "0.45rem",
            position: "relative",
            "&:hover": { backgroundColor: "transparent" },
          }}
        >
          {/* Everywhere Icon with Dropdown */}
          <Box sx={{ position: "relative" }}>
            <ListItemButton
              onClick={() => setIsDropdownVisible(!isDropdownVisible)}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingY: "0.45rem",
                position: "relative",
                "&:hover": { backgroundColor: "transparent" },
              }}
            >
              <Box
                component="img"
                src={
                  currentEverywhereFilter === "city"
                    ? Scooter
                    : currentEverywhereFilter === "state"
                      ? Bike
                      : Plane
                } // Dynamically update icon
                alt={`${currentEverywhereFilter} Icon`}
                sx={{ width: 19.8, height: 19.8, marginBottom: "0.27rem" }}
              />
              <Typography
                variant="caption"
                sx={{
                  fontWeight: "400",
                  color: "black",
                  fontSize: "0.72rem",
                  fontFamily: "Outfit",
                  letterSpacing: "0.045rem",
                  textTransform: "lowercase",
                }}
              >
                {currentEverywhereFilter === "city"
                  ? "Here"
                  : currentEverywhereFilter === "state"
                    ? "There"
                    : "Everywhere"}{" "}
                {/* Dynamically update text */}
              </Typography>
            </ListItemButton>

            {/* Dropdown Menu */}
            {isDropdownVisible && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "3rem", // Popup above
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fff",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  borderRadius: "6px",
                  zIndex: 1300,
                  minWidth: "9rem",
                  padding: "0.5rem",
                }}
              >
                {[
                  { filterType: "city", name: "Here", icon: Scooter },
                  { filterType: "state", name: "There", icon: Bike },
                  { filterType: "all", name: "Everywhere", icon: Plane },
                ].map(({ filterType, name, icon }) => (
                  <ListItemButton
                    key={name}
                    onClick={() => {
                      setFilterType(filterType);
                      setCurrentEverywhereFilter(filterType); // Update current filter
                      setIsDropdownVisible(false);
                    }}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingY: "0.45rem",
                      position: "relative",
                      "&:hover": {
                        backgroundColor: "rgba(0,0,0,0.1)",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      src={icon}
                      alt={`${name} Icon`}
                      sx={{
                        width: 16,
                        height: 16,
                        marginBottom: "0.27rem",
                      }}
                    />
                    <Typography
                      variant="caption"
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: "0.72rem",
                        textTransform: "lowercase",
                        color: "#000",
                      }}
                    >
                      {name}
                    </Typography>
                  </ListItemButton>
                ))}
              </Box>
            )}
          </Box>
        </ListItemButton>
        {/* Feedback Icon */}
        <ListItemButton
          onClick={() => setFilterType("feedback")}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingY: "0.45rem",
            position: "relative",
            "&:hover": { backgroundColor: "transparent" },
          }}
        >
          <Box
            component="img"
            src={Feedback}
            alt="Feedback Icon"
            sx={{ width: 19.8, height: 19.8, marginBottom: "0.27rem" }}
          />
          <Typography
            variant="caption"
            sx={{
              fontWeight: "400",
              color: "black",
              fontSize: "0.72rem",
              fontFamily: "Outfit",
              letterSpacing: "0.045rem",
              textTransform: "lowercase",
            }}
          >
            Feedback
          </Typography>
          {filterType === "feedback" && (
            <Box
              sx={{
                width: "45%",
                height: "2px",
                backgroundColor: "black",
                borderRadius: "4px",
                marginTop: "0.18rem",
              }}
            />
          )}
        </ListItemButton>
      </Box>
      ;{/* Post Creation */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          marginBottom: "24px",
          position: "relative",
          justifyContent: "center",
          maxWidth: "540px",
        }}
      >
        {/* Filter Title or TextField */}
        {!isEditing ? (
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Outfit",
              letterSpacing: "0.09rem",
              fontWeight: "800",
              fontSize: "2.07rem",
              color: "#202426",
              textTransform: "lowercase",
              transition: "opacity 0.3s ease-in-out",
            }}
          >
            {filterType === "propID"
              ? `${filteredPropIDs.join(", ")}`
              : filterType === "city"
                ? `${userCity || "unknown"}`
                : filterType === "state"
                  ? `${userState || "unknown"}`
                  : "All Posts"}
          </Typography>
        ) : (
          <Box sx={{ position: "relative", width: "100%" }}>
            {/* Post Input with Character Counter */}
            <TextField
              fullWidth
              variant="outlined"
              placeholder={"what's happening?"}
              value={newPost}
              onChange={(e) => {
                if (e.target.value.length <= 180) {
                  setNewPost(e.target.value);
                }
              }}
              multiline
              rows={3}
              sx={{
                ...textfieldSx,
                transition: "opacity 0.3s ease-in-out",
              }}
            />

            {/* Capsule Property Selector */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "absolute",
                top: 0,
                left: 18,
                width: "calc(100% - 36px)",
                overflow: "hidden",
              }}
            >
              {/* Left Scroll Arrow */}
              {showLeftArrow && (
                <Button
                  onClick={() => {
                    if (scrollRef.current) {
                      scrollRef.current.scrollBy({
                        left: -150,
                        behavior: "smooth",
                      });
                    }
                  }}
                  sx={{
                    minWidth: "30.6px",
                    height: "30.6px",
                    borderRadius: "50%",
                    border: "3.6px solid black",
                    backgroundColor: "lightgrey",
                    fontSize: "1.26rem",
                    fontFamily: "Nunito",
                    fontWeight: "200",
                    color: "black",
                    marginRight: "9px",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      backgroundColor: "grey",
                      color: "white",
                    },
                  }}
                >
                  -
                </Button>
              )}

              {/* Scrollable Pills */}

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  padding: "0 8px",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                  "&::-webkit-scrollbar": { display: "none" },
                  flexGrow: 1,
                }}
                ref={scrollRef}
              >
                <Button
                  variant={
                    selectedProperties.includes("") ? "contained" : "outlined"
                  }
                  onClick={() => togglePropertySelection("")}
                  sx={{
                    borderRadius: "16px",
                    padding: "6px 20px",
                    textTransform: "none",
                    fontWeight: "bold",
                    fontFamily: "Outfit",
                    fontSize: "0.81rem",
                    borderColor: "#202426",
                    backgroundColor: selectedProperties.includes("")
                      ? "#93A683"
                      : "rgba(245, 245, 245, 0.9)",
                    color: selectedProperties.includes("") ? "#fff" : "#202426",
                    "&:hover": {
                      backgroundColor: selectedProperties.includes("")
                        ? "grey"
                        : "#f0f0f0",
                      borderColor: "#202426",
                    },
                    display: "flex",
                    alignItems: "center",
                    minWidth: "120px",
                  }}
                >
                  FEEDBACK
                </Button>
                {/* General Capsule with Icons */}
                <Button
                  variant={
                    selectedProperties.includes("general")
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => togglePropertySelection("general")}
                  sx={{
                    borderRadius: "16px",
                    padding: "6px 20px",
                    textTransform: "none",
                    fontWeight: "bold",
                    fontFamily: "Outfit",
                    fontSize: "0.81rem",
                    borderColor: "#202426",
                    backgroundColor: selectedProperties.includes("general")
                      ? "#202426"
                      : "rgba(245, 245, 245, 0.9)",
                    color: selectedProperties.includes("general")
                      ? "#fff"
                      : "#202426",
                    "&:hover": {
                      backgroundColor: selectedProperties.includes("general")
                        ? "#333"
                        : "#f0f0f0",
                      borderColor: "#202426",
                    },
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    minWidth: "120px",
                  }}
                >
                  {selectedProperties.includes("general") ? (
                    "GENERAL"
                  ) : (
                    <>
                      <img
                        src={Scooter}
                        alt="Scooter Icon"
                        style={{ height: "18px", width: "18px" }}
                      />
                      <img
                        src={Bike}
                        alt="Bike Icon"
                        style={{ height: "18px", width: "18px" }}
                      />
                      <img
                        src={Plane}
                        alt="Plane Icon"
                        style={{ height: "18px", width: "18px" }}
                      />
                    </>
                  )}
                </Button>

                {/* Dynamic Property Pills with Icons */}
                {filteredPropIDs.map((prop) => (
                  <Button
                    key={prop}
                    variant={
                      selectedProperties.includes(prop)
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => togglePropertySelection(prop)}
                    sx={{
                      borderRadius: "16px",
                      padding: "6px 20px",
                      textTransform: "none",
                      fontWeight: "bold",
                      fontFamily: "Outfit",
                      fontSize: "0.81rem",
                      borderColor: "#202426",
                      backgroundColor: selectedProperties.includes(prop)
                        ? "black"
                        : "rgba(245, 245, 245, 0.9)",
                      color: selectedProperties.includes(prop)
                        ? "#fff"
                        : "#202426",
                      "&:hover": {
                        backgroundColor: selectedProperties.includes(prop)
                          ? "#333"
                          : "#f0f0f0",
                        borderColor: "#202426",
                      },
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      minWidth: "120px",
                    }}
                  >
                    {!selectedProperties.includes(prop) && (
                      <img
                        src={Garage}
                        alt="Garage Icon"
                        style={{ height: "18px", width: "18px" }}
                      />
                    )}
                    {prop}
                  </Button>
                ))}
              </Box>

              {/* Right Scroll Arrow */}
              {showRightArrow && (
                <Button
                  onClick={() => {
                    if (scrollRef.current) {
                      scrollRef.current.scrollBy({
                        left: 150,
                        behavior: "smooth",
                      });
                    }
                  }}
                  sx={{
                    minWidth: "30.6px",
                    height: "30.6px",
                    borderRadius: "50%",
                    border: "3.6px solid black",
                    backgroundColor: "lightgrey",
                    fontSize: "1.26rem",
                    fontFamily: "Nunito",
                    fontWeight: "200",
                    color: "black",
                    marginLeft: "9px",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      backgroundColor: "grey",
                      color: "white",
                    },
                  }}
                >
                  +
                </Button>
              )}
            </Box>

            {/* Character Counter */}
            <Typography
              variant="caption"
              sx={{
                position: "absolute",
                bottom: 18,
                right: 63,
                fontSize: "0.81rem",
                fontFamily: "Outfit",
                fontWeight: "800",
                color:
                  newPost.length > 171
                    ? newPost.length === 180
                      ? "red" // Limit reached
                      : "#F2B70A" // Warning zone
                    : "#202426", // Normal
              }}
            >
              {newPost.length}/180
            </Typography>
          </Box>
        )}

        {/* Floating Badge Button (Post Button) */}
        <Button
          onClick={() => {
            if (isEditing) {
              if (newPost.trim() === "") {
                setIsEditing(false);
              } else {
                handlePostSubmit();
              }
            } else {
              setIsEditing(true);
            }
          }}
          disabled={isEditing && selectedProperties.length === 0}
          sx={{
            position: "absolute",
            bottom: "-4.5px",
            right: "-9px",
            minWidth: "36px",
            minHeight: "36px",
            borderRadius: "50%",
            padding: "0",
            ...postButton,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            "&:hover": {
              ...postButton,
              boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)",
            },
            "&:active": {
              ...postButton,
              transform: "scale(0.95)",
            },
            "&:focus": {
              ...postButton,
              outline: "none",
            },
          }}
        >
          <img
            src={
              isEditing
                ? newPost.trim() === ""
                  ? loginButtonImage2
                  : loginButtonImage
                : breakRm
            }
            alt="Toggle Button"
            style={{
              height: "27px",
              width: "27px",
            }}
          />
        </Button>

        {/* Floating Language Toggle Button */}

        <Button
          variant="outlined"
          onClick={toggleLanguage}
          sx={{
            position: "absolute",
            bottom: "-0.9px",
            left: "-9px",
            minWidth: "54px",
            minHeight: "54px",
            borderRadius: "50%",
            padding: "0",
            ...languageButton,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: "transform 0.3s ease-in-out",
            backgroundColor: currentLanguage === "en" ? "#ded8cf" : "#F2B70A",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            "&:hover": {
              ...languageButton,
              boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)",
              backgroundColor: currentLanguage === "en" ? "#ded8cf" : "#F2B70A",
            },
            "&:active": {
              ...languageButton,
              transform: "scale(0.95)",
            },
            "&:focus": {
              ...languageButton,
              outline: "none",
            },
          }}
        >
          <Typography
            variant="caption"
            sx={{
              fontSize: "0.72rem",
              fontFamily: "Outfit",
              fontWeight: "800",
              letterSpacing: "0.09rem",
              color: "black",
            }}
          >
            {currentLanguage.toUpperCase()} {/* Show the active language */}
          </Typography>
        </Button>
      </Box>
      {/* Posts Feed */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          maxWidth: "540px",
        }}
      >
        {/* Unseen Posts with InfiniteScroll */}
        <InfiniteScroll
          dataLength={filteredPosts.length}
          next={loadMorePosts}
          hasMore={hasMore}
          loader={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "18px",
              }}
            >
              <Lottie
                animationData={animationData}
                style={{ width: "45%", height: "45%" }}
              />
            </Box>
          }
          endMessage={
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                textAlign: "center",
                marginTop: "18px",
                fontSize: "15.3px",
              }}
            >
              {"back to work!"}
            </Typography>
          }
        >
          {filteredPosts
            .filter((post) => {
              if (filterType === "propID") {
                // Home: Show all property-specific posts, ignore language
                return (
                  Array.isArray(post.propID) &&
                  post.propID.some((prop) => filteredPropIDs.includes(prop))
                );
              } else {
                // Other Tabs: Show only general posts (no propID) and match language
                return !post.propID || post.propID.length === 0;
              }
            })
            .map((post) => (
              <Post
                key={post.id}
                post={post}
                handlePostSeen={handlePostSeen}
                openCommentDialog={openCommentDialog}
                handleReshare={handleReshare}
              />
            ))}
        </InfiniteScroll>

        {/* Seen Posts */}
        <Box
          ref={seenPostsRef}
          sx={{
            width: "100%",
            maxWidth: "600px",
            marginTop: "16px",
          }}
        >
          {posts
            .filter((post) => {
              if (filterType === "feedback") {
                return !post.isUnseen && post.isFeedback === true;
              } else if (filterType === "propID") {
                return (
                  !post.isUnseen &&
                  Array.isArray(post.propID) &&
                  post.propID.some((prop) => filteredPropIDs.includes(prop)) &&
                  post.isFeedback !== true // Exclude feedback posts
                );
              } else {
                return (
                  !post.isUnseen &&
                  (post.isFeedback === false || post.isFeedback === undefined)
                );
              }
            })
            .sort((a, b) => {
              const createdAtA = a.createdAt ? a.createdAt.toMillis() : 0;
              const createdAtB = b.createdAt ? b.createdAt.toMillis() : 0;
              return createdAtB - createdAtA;
            })
            .map((post) => (
              <Post
                key={post.id}
                post={post}
                handlePostSeen={handlePostSeen}
                openCommentDialog={openCommentDialog}
                handleReshare={handleReshare}
              />
            ))}
        </Box>
      </Box>
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        fullWidth
        PaperProps={{
          sx: { ...dialogMain },
        }}
      >
        <DialogTitle sx={{ ...dialogTitle }}>Comments</DialogTitle>

        <DialogContent>
          {/* Original Post */}
          {currentPost && (
            <Box
              sx={{
                border: "1px solid lightgrey",
                borderRadius: "18px",
                padding: "9px",
                marginBottom: "9px",
                backgroundColor: "white",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "9px",
                }}
              >
                <Badge
                  variant="dot"
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: "#93A683",
                      width: "12.6px",
                      height: "12.6px",
                      borderRadius: "50%",
                    },
                  }}
                  invisible={currentPost.verified !== "staff"}
                  overlap="circular"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <Avatar
                    src={currentPost.profilePictureURL}
                    alt={currentPost.userName}
                    sx={{
                      marginRight: "9px",
                      width: "36px",
                      height: "36px",
                    }}
                  />
                </Badge>

                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{
                    fontFamily: "Outfit",
                    letterSpacing: "0.036rem",
                    fontWeight: "800",
                    textTransform: "lowercase",
                    color: "black",
                  }}
                >
                  {currentPost.userName}
                </Typography>
              </Box>
              <Typography
                variant="body2"
                sx={{
                  marginBottom: "9px",
                  fontFamily: "Outfit",
                  letterSpacing: "0.036rem",
                  fontWeight: "400",
                  textTransform: "lowercase",
                  color: "black",
                }}
              >
                {currentPost.text}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Posted at{" "}
                {currentPost.createdAt?.toDate().toLocaleString() || "Just now"}
              </Typography>
            </Box>
          )}

          {/* Past Comments */}

          {currentPost?.comments?.length > 0 ? (
            <Box
              sx={{
                maxHeight: "306px",
                overflowY: "auto",
                padding: "9px",
              }}
            >
              {currentPost.comments.map((comment, index) => (
                <Box
                  key={index}
                  sx={{
                    marginBottom: "8px",
                    padding: "8px",
                    border: "1px solid lightgrey",
                    borderRadius: "18px",
                    backgroundColor: "rgba(255, 255, 255, 0.90)",
                  }}
                >
                  {comment.underReview || comment.deleted ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#ffe6e6",
                          padding: "8.1px",
                          borderRadius: "9px",
                          marginBottom: "9px",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            marginBottom: "4.5px",
                            fontFamily: "Outfit",
                            letterSpacing: "0.036rem",
                            fontWeight: "800",
                            textTransform: "lowercase",
                            color: "#BB2E33",
                          }}
                        >
                          This comment unavailable.
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "18px",
                          alignItems: "center",
                        }}
                      >
                        <Badge
                          variant="dot"
                          sx={{
                            "& .MuiBadge-badge": {
                              backgroundColor: "#93A683",
                              width: "12px",
                              height: "12px",
                              borderRadius: "50%",
                            },
                          }}
                          invisible={comment.verified !== "staff"}
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            sx={{
                              fontFamily: "Outfit",
                              letterSpacing: "0.036rem",
                              fontWeight: "800",
                              textTransform: "lowercase",
                              color: "black",
                            }}
                          >
                            {comment.userName}
                          </Typography>
                        </Badge>
                        <Typography variant="caption" color="textSecondary">
                          {new Date(comment.createdAt).toLocaleString()}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                      {/* Regular Comment Display */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "9px",
                        }}
                      >
                        <Badge
                          variant="dot"
                          sx={{
                            "& .MuiBadge-badge": {
                              backgroundColor: "#93A683",
                              width: "12px",
                              height: "12px",
                              borderRadius: "50%",
                            },
                          }}
                          invisible={comment.verified !== "staff"}
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <Avatar
                            src={comment.profilePictureURL}
                            alt={comment.userName}
                            sx={{
                              marginRight: "9px",
                              width: "36px",
                              height: "36px",
                            }}
                          />
                        </Badge>
                        <Tooltip
                          title={
                            <React.Fragment>
                              Commented at{" "}
                              {new Date(comment.createdAt).toLocaleString()}
                            </React.Fragment>
                          }
                          enterTouchDelay={50}
                          leaveTouchDelay={1500}
                        >
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            sx={{
                              fontFamily: "Outfit",
                              letterSpacing: "0.036rem",
                              fontWeight: "800",
                              textTransform: "lowercase",
                              color: "black",
                            }}
                          >
                            {comment.userName}
                          </Typography>
                        </Tooltip>
                        <Tooltip
                          title={
                            comment.userId === profileData.uid
                              ? "Delete this comment"
                              : "Flag this comment"
                          }
                          arrow
                          enterTouchDelay={50}
                          leaveTouchDelay={1500}
                        >
                          <IconButton
                            onClick={() =>
                              comment.userId === profileData.uid
                                ? flagCommentAsDeleted(currentPost.id, index)
                                : handleFlagComment(currentPost.id, index)
                            }
                            sx={{ color: "black", fontSize: "1.08rem" }}
                          >
                            {comment.userId === profileData.uid ? (
                              <IoTrashBinOutline />
                            ) : (
                              <BsPatchExclamationFill />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{
                          marginBottom: "4.5px",
                          fontFamily: "Outfit",
                          letterSpacing: "0.036rem",
                          fontWeight: "400",
                          textTransform: "lowercase",
                          color: "black",
                        }}
                      >
                        {comment.text}
                      </Typography>
                    </>
                  )}
                </Box>
              ))}
            </Box>
          ) : (
            <Typography
              sx={{ marginTop: "1.8rem" }}
              variant="body2"
              color="textSecondary"
            >
              {"Nah."}
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              marginBottom: "9px",
              position: "relative", // Enable positioning for the child elements
            }}
          >
            {/* Add New Comment */}
            <Box sx={{ position: "relative", width: "100%", marginTop: "9px" }}>
              {!isCommenting ? (
                // Button to show comment input
                <Button
                  variant="outlined"
                  onClick={() => setIsCommenting(true)} // Show comment input
                  sx={{
                    position: "absolute",
                    bottom: "-4.5px",
                    right: "-9px",
                    minWidth: "36px",
                    minHeight: "36px",
                    borderRadius: "50%",
                    padding: "0",
                    ...postButton,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    "&:hover": {
                      ...postButton,
                      boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)",
                    },
                    "&:active": {
                      ...postButton,
                      transform: "scale(0.95)",
                    },
                    "&:focus": {
                      ...postButton,
                      outline: "none",
                    },
                  }}
                >
                  <img
                    src={breakRm}
                    alt="Add Comment"
                    style={{
                      height: "27px",
                      width: "27px",
                    }}
                  />
                </Button>
              ) : (
                // Comment Input Field
                <Box sx={{ position: "relative", width: "100%" }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    type="text"
                    fullWidth
                    placeholder="Add your comment..."
                    value={commentText}
                    onChange={(e) => {
                      if (e.target.value.length <= 180) {
                        setCommentText(e.target.value);
                      }
                    }}
                    sx={{
                      ...textfieldSx,
                      marginTop: "18px",
                    }}
                    multiline
                    rows={3}
                  />

                  {/* Character Counter */}
                  <Typography
                    variant="caption"
                    sx={{
                      position: "absolute",
                      bottom: 18,
                      left: 18,
                      fontSize: "0.81rem",
                      fontFamily: "Outfit",
                      fontWeight: "800",
                      color:
                        commentText.length > 171
                          ? commentText.length === 180
                            ? "red" // Limit reached
                            : "#F2B70A" // Warning zone
                          : "#202426", // Normal
                    }}
                  >
                    {commentText.length}/180
                  </Typography>

                  {/* Submit Comment Button */}
                  <Button
                    disabled={!commentText.trim()}
                    variant="outlined"
                    onClick={() => {
                      handleCommentSubmit();
                      setIsCommenting(false); // Hide input after submitting
                      setCommentText(""); // Clear comment input
                    }}
                    sx={{
                      position: "absolute",
                      bottom: "-4.5px",
                      right: "-9px",
                      minWidth: "36px",
                      minHeight: "36px",
                      borderRadius: "50%",
                      padding: "0",
                      ...postButton,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      "&:hover": {
                        ...postButton,
                        boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)",
                      },
                      "&:active": {
                        ...postButton,
                        transform: "scale(0.95)",
                      },
                      "&:focus": {
                        ...postButton,
                        outline: "none",
                      },
                    }}
                  >
                    <img
                      src={loginButtonImage}
                      alt="Submit Comment"
                      style={{
                        height: "27px",
                        width: "27px",
                      }}
                    />
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isReshareDialogOpen}
        onClose={closeReshareDialog}
        PaperProps={{
          sx: {
            ...dialogMain,
            justifyContent: "center",
          },
        }}
      >
        <DialogTitle sx={{ ...dialogTitle }}>
          share
          <IconButton
            aria-label="close"
            onClick={() => closeReshareDialog(true)}
            sx={{ position: "absolute", right: 9, top: 9 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "9px",
            flexWrap: "wrap",
            paddingTop: "18px",
            justifyContent: "left",
          }}
        >
          {/* General Capsule */}
          <Button
            variant={
              !selectedReshareTargets.includes("general")
                ? "contained"
                : "outlined"
            }
            onClick={() => toggleResharePropertySelection("general")}
            sx={{
              borderRadius: "16px",
              padding: "4px 12px",
              textTransform: "none",
              fontWeight: "bold",
              fontFamily: "Outfit",
              fontSize: "0.81rem",
              borderColor: "#f5f5f5",
              backgroundColor: selectedReshareTargets.includes("general")
                ? "#202426"
                : "rgba(245, 245, 245, 0.9)",
              color: selectedReshareTargets.includes("general")
                ? "#fff"
                : "#202426",
              "&:hover": {
                backgroundColor: selectedReshareTargets.includes("general")
                  ? "#333"
                  : "#f0f0f0",
              },
              display: "flex",
              alignItems: "center",
              gap: "6px",
            }}
          >
            {selectedReshareTargets.includes("general") ? (
              "GENERAL"
            ) : (
              <>
                <img
                  src={Scooter}
                  alt="Scooter Icon"
                  style={{ height: "18px", width: "18px" }}
                />
                <img
                  src={Bike}
                  alt="Bike Icon"
                  style={{ height: "18px", width: "18px" }}
                />
                <img
                  src={Plane}
                  alt="Plane Icon"
                  style={{ height: "18px", width: "18px" }}
                />
              </>
            )}
          </Button>

          {/* Dynamic Property Pills */}
          {filteredPropIDs.map((prop) => (
            <Button
              key={prop}
              variant={
                !selectedReshareTargets.includes(prop)
                  ? "contained"
                  : "outlined"
              }
              onClick={() => toggleResharePropertySelection(prop)}
              sx={{
                borderRadius: "16px",
                padding: "4px 12px",
                textTransform: "none",
                fontWeight: "bold",
                fontFamily: "Outfit",
                fontSize: "0.81rem",
                borderColor: "#f5f5f5",
                backgroundColor: selectedReshareTargets.includes(prop)
                  ? "#202426"
                  : "rgba(245, 245, 245, 0.9)",
                color: selectedReshareTargets.includes(prop)
                  ? "#fff"
                  : "#202426",
                "&:hover": {
                  backgroundColor: selectedReshareTargets.includes(prop)
                    ? "#333"
                    : "#f0f0f0",
                },
                display: "flex",
                alignItems: "center",
                gap: "6px",
              }}
            >
              {prop}
            </Button>
          ))}
        </DialogContent>

        {/* Reshare Action Button */}
        <DialogActions sx={{ justifyContent: "right", padding: "12px 24px" }}>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              handleReshare(selectedResharePostId, selectedReshareTargets);
              closeReshareDialog();
            }}
            sx={{
              padding: "18px",
              borderRadius: "18px",
              backgroundColor: "lightgrey",
              border: "4.5px solid lightgrey",
              fontFamily: "Outfit",
              fontWeight: "800",
              fontSize: "1.35rem",
              color: "white",
              textTransform: "lowercase",
              "&:hover": {
                border: "4.5px solid lightgrey",
                backgroundColor: "#f5f5f5",
                color: "#202426",
              },
            }}
          >
            <img
              src={reshareButton}
              alt="Plane Icon"
              style={{ height: "20.7px", width: "20.7px" }}
            />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const postButton = {
  padding: "0.9rem",
  borderRadius: "18px",
  fontSize: "0.81rem",
  fontFamily: "Outfit",
  letterSpacing: "0.09rem",
  border: "2.7px solid #202426",
  backgroundColor: "#202426",
  color: "#f5f5f5",
  fontWeight: "400",
};
const languageButton = {
  padding: "0.63rem",
  borderRadius: "50%",
  fontSize: "0.81rem",
  fontFamily: "Outfit",
  fontWeight: "400",
  letterSpacing: "0.09rem",
  border: "4.5px solid #202426",
};
const textfieldSx = {
  marginBottom: "0.9rem",
  marginTop: "0.9rem",
  transition: "0.3s",
  fontWeight: 400,
  color: "#202426",
  letterSpacing: "0.027rem",
  textTransform: "uppercase",
  ".MuiInputLabel-root": {
    fontSize: { xs: "0.99rem" },
    fontFamily: "Nunito",
    fontWeight: 800,
    color: "#202426",
    transition: "0.3s",
    textTransform: "uppercase",
  },
  ".MuiInputBase-input": {
    fontSize: { xs: "0.99rem" },
    fontWeight: 400,
    letterSpacing: "0.036rem",
    color: "#202426",
    transition: "0.3s",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "18px",
    border: "4.5px solid black", // Custom border
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none", // Hides the default border
    },
  },
};
const dialogMain = {
  padding: "0.45rem",
  borderRadius: "36px",
  backgroundColor: "rgba(255, 255, 255, 0.945)",
};
const dialogTitle = {
  fontFamily: "Outfit",
  letterSpacing: "0.09rem",
  fontWeight: "800",
  fontSize: "2.07rem",
  textTransform: "lowercase",
  color: "black",
  backgroundColor: "transparent",
  borderRadius: "18px",
};
const dialogContent = {
  fontFamily: "Outfit",
  letterSpacing: "0.4rem",
  fontWeight: "400",
  fontSize: "1rem",
};
const redButton = {
  borderRadius: "9px",
  padding: "9px 18px 9px 18px",
  fontWeight: "400",
  fontSize: "0.81rem",
  fontFamily: "Outfit",
  letterSpacing: "0.09rem",
  border: "2.7px solid #BB2E33",
  backgroundColor: "#BB2E33",
  color: "#f5f5f5",
  "&:hover": {
    border: "2.7px solid #BB2E33",
    backgroundColor: "transparent",
    color: "#BB2E33",
  },
};
