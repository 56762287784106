import React, { useState, useEffect, useMemo } from "react";
import { db } from "../../firebase/firebase-config";
import { useAuth } from "../../contexts/AuthContext";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  setDoc,
  doc,
  Timestamp,
  getDoc,
  limit,
} from "firebase/firestore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Box,
  Card,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import * as XLSX from "xlsx";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { FaFilePdf } from "react-icons/fa";
import { BsFillPatchCheckFill } from "react-icons/bs";

const Stubs = () => {
  const { user, userPermissions, userRole, availableProperties } = useAuth();
  const [payRollData, setPayRollData] = useState([]);
  const [propertyCode, setPropertyCode] = useState([]);
  const [selectedPropertyCode, setSelectedPropertyCode] = useState("");
  const [currentPayRollIndex, setCurrentPayRollIndex] = useState(0);
  const [tableTitle, setTableTitle] = useState("");
  const [selectedPayRoll, setSelectedPayRoll] = useState(null);
  const [value, setValue] = React.useState(0);
  const [employeesMap, setEmployeesMap] = useState(new Map());
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [confirmationData, setConfirmationData] = useState(null);
  const [payrollData, setPayrollData] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [payrollConfirmationData, setPayrollConfirmationData] = useState(null);
  const [otherConfirmationData, setOtherConfirmationData] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [stubsData, setStubsData] = useState([]);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "none",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [payStubs, setPaystubs] = useState({});
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const [setPermissionsForProperty] = useState({});

  // PERMISSIONS

  const hasPermissionForProperty = (permission, propertyCode) => {
    // If the user's role is 'Global' or 'MGMT', they have all permissions
    if (
      userRole === "Global" ||
      userRole === "MGMT" ||
      userRole === "PropertyAdmin"
    ) {
      return true;
    }

    // Otherwise, check the permissions for the specific property
    const permissionsForProperty =
      userPermissions[propertyCode]?.permissions || {};
    return permissionsForProperty[permission] === true;
  };

  // Filter availableProperties to include only those with 'payrollViewStubs' permission
  const viewPayrollStubs = useMemo(
    () =>
      availableProperties.filter((prop) => {
        return (
          userPermissions &&
          hasPermissionForProperty("payrollViewStubs", prop.propertyCode)
        );
      }),
    [availableProperties, userPermissions],
  );

  useEffect(() => {
    if (!propertyCode || !userPermissions[propertyCode]) return;

    // Avoid setting state if permissions haven't changed to prevent re-renders
    const propertyPermissions = userPermissions[propertyCode].permissions || {};
    const permissionsArray = Object.keys(propertyPermissions).filter(
      (key) => propertyPermissions[key] === true,
    );

    setPermissionsForProperty((prevPermissions) => {
      // Only update state if permissions have changed
      const permissionsChanged =
        JSON.stringify(prevPermissions) !== JSON.stringify(permissionsArray);
      return permissionsChanged ? permissionsArray : prevPermissions;
    });
  }, [propertyCode, userPermissions]);

  // Function to determine if a user can view a section
  const canViewSection = (permissions = [], propertyCode) => {
    const requiredPermissions = Array.isArray(permissions)
      ? permissions
      : [permissions];

    // Grant access to Global, MGMT, and PropertyAdmin roles
    if (["Global", "MGMT", "PropertyAdmin"].includes(userRole)) return true;

    // Check specific permissions for the property
    return requiredPermissions.every((permission) => {
      const propertyPermissions =
        userPermissions[propertyCode]?.permissions || {};
      return propertyPermissions[permission] === true;
    });
  };

  const handlePropertyCodeChange = (event) => {
    const newPropertyCode = event.target.value;

    // Update the selected property code in AuthContext
    setSelectedPropertyCode(newPropertyCode);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (selectedEmployee) {
      const q = query(
        collection(db, "STUBS"),
        where("EmployeeId", "==", selectedEmployee),
        orderBy("Date", "desc"),
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        if (snapshot.empty) {
          console.log("No matching documents.");
          setStubsData([]);
        } else {
          const fetchedStubs = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            PeriodOrOther:
              doc.data().Date === "other" ? "other" : doc.data().Date,
            DocString: `${doc.data().EmployeeId}_${
              doc.data().Date === "other" ? "other" : doc.data().Date
            }_${doc.id.split("_").pop()}`,
          }));
          console.log("Fetched Stubs: ", fetchedStubs);
          setStubsData(fetchedStubs);
        }
      });

      return () => unsubscribe();
    }
  }, [selectedEmployee]);

  // USE EFFECT FOR PAYROLL SUMMARY
  useEffect(() => {
    let formattedDate = "";
    if (payRollData.length > 0 && currentPayRollIndex < payRollData.length) {
      let periodEndDate = new Date(
        payRollData[currentPayRollIndex].PeriodEndDate?.toDate(),
      );
      periodEndDate.setDate(periodEndDate.getDate() - 1); // subtract one day
      formattedDate = periodEndDate.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    }
    setTableTitle(`${selectedPropertyCode} PAYROLL - ${formattedDate}`);
  }, [selectedPropertyCode, currentPayRollIndex, payRollData]);

  useEffect(() => {
    if (
      payRollData.length > 0 &&
      currentPayRollIndex >= 0 &&
      currentPayRollIndex < payRollData.length
    ) {
      setSelectedPayRoll(payRollData[currentPayRollIndex]);
    }
  }, [currentPayRollIndex, payRollData]);

  useEffect(() => {
    if (selectedPropertyCode) {
      const q = query(
        collection(db, "payRoll"),
        where("PropertyCode", "==", selectedPropertyCode),
        orderBy("PeriodEndDate", "desc"),
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedPayRollData = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });

        setPayRollData(fetchedPayRollData);
      });

      return () => unsubscribe();
    }
  }, [selectedPropertyCode]);

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      if (!selectedPropertyCode) return;
      const docRef = doc(db, "Properties", selectedPropertyCode);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setPropertyDetails({
          propertyName: data.propertyName,
          propEntity: data.propEntity,
          Ad1: data.Ad1,
          city: data.city,
          state: data.state,
          AdZip: data.AdZip,
        });
      } else {
        console.log("No such property!");
        setPropertyDetails({});
      }
    };

    fetchPropertyDetails();
  }, [selectedPropertyCode]);

  const getUniqueDates = (data) => {
    const uniqueDates = new Set();
    data.forEach((item) => {
      let endDate = new Date(item.PeriodEndDate.toDate());
      endDate.setDate(endDate.getDate() - 1); // Subtract one day from the end date
      uniqueDates.add(endDate.toISOString().split("T")[0]);
    });
    return Array.from(uniqueDates).sort((a, b) => new Date(b) - new Date(a)); // Sort descending
  };

  const [selectedDate, setSelectedDate] = useState("");
  const uniqueDates = getUniqueDates(payRollData);

  useEffect(() => {
    const dates = getUniqueDates(payRollData);
    if (dates.length > 0) {
      setSelectedDate(dates[0]);
    }
  }, [payRollData]);

  useEffect(() => {
    if (selectedDate) {
      const newIndex = payRollData.findIndex(
        (period) =>
          new Date(period.PeriodEndDate.toDate())
            .toISOString()
            .split("T")[0] === selectedDate,
      );
      if (newIndex !== -1) {
        setCurrentPayRollIndex(newIndex);
      }
    }
  }, [selectedDate, payRollData]);

  useEffect(() => {
    if (!selectedPropertyCode || !selectedDate) return;

    const formattedDate = formatDate(selectedDate);

    // Define the base query for fetching employees
    const employeeQuery = query(
      collection(db, "empRegister"),
      where("PropID", "==", selectedPropertyCode),
    );

    const unsubscribe = onSnapshot(employeeQuery, async (snapshot) => {
      const canViewSalary =
        user.photoURL.includes("Global") ||
        user.photoURL.includes("Accounting");
      let employees = [];
      let paystubPromises = [];
      let empMap = new Map(); // To keep track of PeachTree IDs

      // Filter based on salary type and status checks within the application logic
      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        const isTerminated = data.Status === "Terminated";
        const isEligibleForView =
          canViewSalary ||
          (data.PayD1_Type !== "SALARY" && data.PayD2_Type !== "SALARY");

        if (!isTerminated && isEligibleForView) {
          employees.push({
            EmployeeId: doc.id,
            FirstName: data.NameFirst,
            LastName: data.NameLast,
            PayD1_Type: data.PayD1_Type,
            PayD2_Type: data.PayD2_Type,
            PeachTreeID: data.peachTreeID, // If needed
          });
          // Set the PeachTree ID map
          if (data.peachTreeID && typeof data.peachTreeID === "string") {
            empMap.set(data.peachTreeID, doc.id);
          }
        }
      });

      // Map employees to promises checking for paystubs
      paystubPromises = employees.map((employee) => {
        const stubsQuery = query(
          collection(db, "STUBS"),
          where("EmployeeId", "==", employee.EmployeeId),
          where("PeriodEndDate", "==", formattedDate),
          limit(1), // Assumes you only need to check if at least one exists
        );
        return getDocs(stubsQuery).then((stubsSnapshot) => ({
          EmployeeId: employee.EmployeeId,
          hasPaystub: !stubsSnapshot.empty,
        }));
      });

      // Wait for all paystub checks to complete
      const paystubResults = await Promise.all(paystubPromises);
      const paystubData = paystubResults.reduce((acc, curr) => {
        acc[curr.EmployeeId] = curr.hasPaystub;
        return acc;
      }, {});

      setEmployees(employees); // Update the employees list
      setPaystubs(paystubData); // Update paystubs data
      setEmployeesMap(empMap); // Update the map of PeachTree IDs
    });

    return () => unsubscribe(); // Cleanup on unmount or prop changes
  }, [selectedPropertyCode, selectedDate, user.photoURL, reloadTrigger]);

  function excelSerialDateToDate(serial) {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    // Optional: Adjust for your timezone if necessary
    const offset = date_info.getTimezoneOffset() * 60000;
    const date = new Date(date_info.getTime() + offset);

    return date;
  }

  useEffect(() => {
    // Reset the data when the selected property code changes
    setError("");
    setPayrollData([]);
    setOtherData([]);
    setPayrollConfirmationData(null);
    setOtherConfirmationData(null);
  }, [selectedPropertyCode]);

  useEffect(() => {
    // Reset the data when the selected date changes
    if (value === 0) {
      // Only reset for the PAYROLL tab
      setError("");
      setPayrollData([]);
      setPayrollConfirmationData(null);
    }
  }, [selectedDate]);

  const SubmissionDialog = ({ open, onClose, isSubmitting }) => (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: "1rem",
          borderRadius: "27px",
          backgroundColor: "#f9f9f9",
        },
      }}
    >
      <DialogContent>
        {isSubmitting ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              backgroundColor: "#f9f9f9",
            }}
          >
            <CircularProgress style={{ color: "#101010" }} />{" "}
          </div>
        ) : (
          <p
            style={{
              textAlign: "center",
              color: "#101010",
              fontSize: "1rem",
              fontWeight: "800",
              letterSpacing: "0.1rem",
            }}
          >
            SUBMITTED
          </p>
        )}
      </DialogContent>
    </Dialog>
  );

  const sortedStubsData = useMemo(() => {
    let sortableItems = [...stubsData];
    if (sortConfig !== null && sortConfig.key) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [stubsData, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  function formatDate(dateString) {
    // Parse the date and add one day
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1); // Add one day

    let month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear().toString().slice(-2); // Get last two digits of year

    // Format month and day to be two digits
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  }
  const handleFileChange = (event, tabValue) => {
    setError(""); // Clear any existing errors
    handleFile(event, tabValue); // Process the file
  };

  // Use this new handler in the file input
  <input
    type="file"
    accept=".xlsx, .xls"
    onChange={(e) => handleFileChange(e, value)} // Now using the new handler
    aria-label="Upload payroll data file"
  />;

  const handleFile = (event, tabValue) => {
    if (!selectedPropertyCode) {
      setError("Please select a property code before uploading a file.");
      return;
    }

    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const ab = e.target.result;
      const workbook = XLSX.read(ab, { type: "array" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      if (
        !jsonData ||
        jsonData.length === 0 ||
        !jsonData[0].hasOwnProperty("Employee ID") ||
        !jsonData[0].hasOwnProperty("Pay Type")
      ) {
        setError(
          "Invalid file format. Ensure the file includes 'Employee ID' and 'Pay Type' columns.",
        );
        tabValue === 0 ? setPayrollData([]) : setOtherData([]);
        return;
      }

      const missingIDs = new Set();
      const validEntries = jsonData.filter((entry) => {
        if (
          !employeesMap.has(entry["Employee ID"]) &&
          !["Summary Total", "Report Date Final Total"].includes(
            entry["Employee ID"],
          )
        ) {
          missingIDs.add(entry["Employee ID"]);
          return false; // Skip this entry
        }
        return true;
      });

      if (missingIDs.size > 0) {
        const userConfirmation = window.confirm(
          `Missing EmpID for Employee ID(s): ${Array.from(missingIDs).join(
            " // ",
          )}. Skip these entries and continue?`,
        );
        if (!userConfirmation) {
          setError(
            `Process aborted. Correct the data for Employee ID(s): ${Array.from(
              missingIDs,
            ).join(", ")}`,
          );
          return;
        }
      }

      // Continue processing with validEntries
      const transformedData = validEntries.map((entry) => {
        if (employeesMap.has(entry["Employee ID"])) {
          entry["Employee ID"] = employeesMap.get(entry["Employee ID"]);
        }
        if (entry["Pay Type"]) {
          // Check if "Pay Hrs" exists, if not set it to "0"
          entry[`${entry["Pay Type"]} Hrs`] = entry["Pay Hrs"] || "0";
          delete entry["Pay Hrs"];

          // Process "Pay Amt" only if it exists
          if (entry["Pay Amt"]) {
            entry[`${entry["Pay Type"]} Amt`] = parseFloat(
              entry["Pay Amt"],
            ).toFixed(2);
            delete entry["Pay Amt"];
          }
        }
        if (entry["Date"] && !isNaN(entry["Date"])) {
          const date = excelSerialDateToDate(entry["Date"]);
          entry["Date"] = `${date.getMonth() + 1}/${date.getDate()}/${date
            .getFullYear()
            .toString()
            .slice(-2)}`;
        }
        return entry;
      });

      const groupedData = transformedData.reduce((acc, cur) => {
        acc[cur["Employee ID"]] = acc[cur["Employee ID"]] || [];
        acc[cur["Employee ID"]].push(cur);
        return acc;
      }, {});

      const mergedData = Object.values(groupedData).map((entries) =>
        entries.reduce((acc, cur) => {
          Object.keys(cur).forEach((key) => {
            acc[key] = acc[key] || cur[key];
          });
          return acc;
        }, {}),
      );

      setData(mergedData);
      setReloadTrigger((prev) => prev + 1);
      setConfirmationData({
        PropertyCode: selectedPropertyCode,
        PeriodEndDate: tabValue === 0 ? selectedDate : "other",
        SummaryData: JSON.stringify(mergedData, null, 2),
      });
      if (tabValue === 0) {
        setPayrollData(transformedData);
        setPayrollConfirmationData({
          PropertyCode: selectedPropertyCode,
          PeriodEndDate: selectedDate,
          SummaryData: JSON.stringify(transformedData, null, 2),
        });
      } else {
        setOtherData(transformedData);
        setOtherConfirmationData({
          PropertyCode: selectedPropertyCode,
          PeriodEndDate: "other",
          SummaryData: JSON.stringify(transformedData, null, 2),
        });
      }
      setError("");
    };
    reader.readAsArrayBuffer(file);
  };

  const confirmAndPostData = async (isOtherTab = false) => {
    setOpenDialog(true);
    setIsSubmitting(true);

    const confirmationData = isOtherTab
      ? otherConfirmationData
      : payrollConfirmationData;
    const formattedPeriodEndDate = formatDate(confirmationData.PeriodEndDate);
    const periodOrOther = isOtherTab
      ? "other"
      : formattedPeriodEndDate.replace(/[^a-zA-Z0-9]/g, "_");

    const entries = JSON.parse(confirmationData.SummaryData);
    try {
      for (const entry of entries) {
        if (
          ["Summary Total", "Report Date Final Total"].includes(
            entry["Employee ID"],
          )
        )
          continue;
        if (!entry["Employee ID"] || entry["Employee ID"] === undefined) {
          console.error("Skipped entry due to undefined EmployeeId:", entry);
          continue;
        }

        const datePart = entry["Date"]
          ? formatDate(entry["Date"]).replace(/[^a-zA-Z0-9]/g, "_")
          : "UnknownDate";
        const docId = `${entry["Employee ID"]}_${periodOrOther}_${datePart}`;
        const docRef = doc(db, "STUBS", docId);
        const entryData = {
          ...entry,
          PropertyCode: confirmationData.PropertyCode,
          propertyName: propertyDetails.propertyName,
          Ad1: propertyDetails.Ad1,
          city: propertyDetails.city,
          state: propertyDetails.state,
          AdZip: propertyDetails.AdZip,
          propEntity: propertyDetails.propEntity,
          PeriodEndDate: formattedPeriodEndDate,
          EmployeeId: entry["Employee ID"],
          EntryDate: Timestamp.now(),
        };

        await setDoc(docRef, entryData, { merge: true });
      }
      // Reset states after successful data submission
      setPayrollData([]);
      setOtherData([]);
      setPayrollConfirmationData(null);
      setOtherConfirmationData(null);
      setError("");
      // Optionally clear other UI elements or selections
    } catch (error) {
      console.error("Error during data submission: ", error);
    }

    setIsSubmitting(false);
    setTimeout(() => {
      setOpenDialog(false); // Close the dialog after a delay
    }, 2000);
  };

  // Create styles
  const styles = StyleSheet.create({
    page: {
      padding: 9,
    },
    section: {
      marginLeft: 9,
      marginRight: 9,
      marginTop: 9,
      padding: 9,
      // flexGrow: 1,
    },
    header: {
      fontSize: 12,
      textAlign: "left",
      marginBottom: 1.8,
    },

    row: {
      flexDirection: "row",
      borderBottomWidth: 0.5,
      borderBottomColor: "grey",
      pageBreakInside: "avoid",
      borderBottomStyle: "solid",
      alignItems: "stretch",
      paddingBottom: 5,
    },
    table: { display: "table", width: "auto", margin: "auto" },
    tableRow: {
      flexDirection: "row",
      justifyContent: "space-between",
      borderBottomWidth: 1,
      borderBottomColor: "#cccccc",
      borderBottomStyle: "solid",
      paddingVertical: 2,
    },
    tableHead: {
      fontWeight: "bold",
      fontSize: 10,
    },
    tableCol: { width: "7%", flexDirection: "column" },
    tableColWide: { width: "10%", flexDirection: "column" },
    tableColWider: { width: "20%", flexDirection: "column" },
    tableCell: { margin: 1, fontSize: 9 },
    amountColumn: {
      width: "25%",
      textAlign: "right",
    },
  });

  // Helper function to organize hours and amounts
  const organizePayDetails = (data) => {
    const hours = [];
    const deductions = [];

    const excludeKeys = [
      "id",
      "Employee",
      "Amount",
      "Date",
      "PeriodEndDate",
      "Masked SS No",
      "Reference",
      "Gross",
      "Employee ID",
      "EmployeeId",
      "Pay Type",
      "EntryDate",
      "PeriodOrOther",
      "DocString",
      "PropertyCode",
      "propertyName",
      "propEntity",
      "PropertyCode",
      "Ad1",
      "city",
      "state",
      "AdZip",
      // Exclude any field that ends with "_C"
      ...Object.keys(data).filter((key) => key.endsWith("_C")),
    ];

    Object.keys(data).forEach((key) => {
      if (key.includes("Hrs") && !excludeKeys.includes(key)) {
        const amtKey = key.replace("Hrs", "Amt");
        if (data[amtKey] !== undefined) {
          // Ensure there's a corresponding amount key and it's not in the exclude list
          hours.push({ label: key, value: data[key], amtValue: data[amtKey] });
        }
      } else if (!key.includes("Amt") && !excludeKeys.includes(key)) {
        deductions.push({ label: key, value: data[key] });
      }
    });

    return { hours, deductions };
  };

  // Create Document Component
  const PayStubPDF = ({ employeeData }) => {
    const { hours, deductions } = organizePayDetails(employeeData);

    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View style={styles.tableColWider}>
                <Text style={styles.header}>{employeeData.propertyName}</Text>
                <Text style={styles.tableCell}>{employeeData.propEntity}</Text>
              </View>
              <View style={styles.tableColWider}>
                <Text style={styles.tableCell}>{employeeData.Ad1}</Text>
                <Text style={styles.tableCell}>{employeeData.city},</Text>
                <Text style={styles.tableCell}>
                  {employeeData.state} {employeeData.AdZip}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View style={styles.tableColWider}>
                <Text style={styles.tableHead}>Employee Name:</Text>
                <Text style={styles.tableCell}>{employeeData.Employee}</Text>
              </View>
              <View style={styles.tableColWider}>
                <Text style={styles.tableHead}>Masked SSN:</Text>
                <Text style={styles.tableCell}>
                  {employeeData["Masked SS No"]}
                </Text>
              </View>
              <View style={styles.tableColWider}>
                <Text style={styles.tableHead}>Check Date:</Text>
                <Text style={styles.tableCell}>{employeeData.Date}</Text>
              </View>
              <View style={styles.tableColWider}>
                <Text style={styles.tableHead}>Check Number:</Text>
                <Text style={styles.tableCell}>{employeeData.Reference}</Text>
              </View>
            </View>
          </View>

          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View style={styles.tableColWider}>
                <Text style={styles.tableHead}>Period Ending:</Text>
                <Text style={styles.tableCell}>
                  {employeeData.PeriodEndDate}
                </Text>
              </View>
            </View>
            {/* Render hours and amounts */}
            {hours.map((item) => (
              <View style={styles.tableRow}>
                <View style={styles.tableColWider}>
                  <Text style={styles.tableHead}>{item.label}:</Text>
                  <Text style={styles.tableCell}>{item.value}</Text>
                </View>
                <View style={styles.tableColWider}>
                  <Text style={styles.tableHead}>
                    {item.label.replace("Hrs", "Amt")}:
                  </Text>
                  <Text style={styles.tableCell}>{item.amtValue}</Text>
                </View>
              </View>
            ))}
          </View>
          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View style={styles.tableColWider}>
                <Text style={styles.tableHead}>Gross Amount</Text>
              </View>
              <View style={styles.tableColWider}>
                <Text style={styles.tableCell}>{employeeData.Gross}</Text>
              </View>
            </View>

            {/* Render deductions */}
            {deductions.map((deduction) => (
              <View style={styles.tableRow} key={deduction.label}>
                <View style={styles.tableColWider}>
                  <Text style={styles.tableHead}>{deduction.label}:</Text>
                </View>
                <View style={styles.tableColWider}>
                  <Text style={styles.tableCell}>
                    {typeof deduction.value === "number"
                      ? deduction.value.toFixed(2)
                      : deduction.value}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View style={styles.tableColWider}>
                <Text style={styles.tableHead}>CHECK TOTAL</Text>
              </View>
              <View style={styles.tableColWider}>
                <Text style={styles.tableCell}>${employeeData.Amount}</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "transparent",
          borderRadius: "0.9rem",
          marginBottom: "1.8rem",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="left"
          spacing={2}
          style={{ margin: "9px" }}
        >
          <Grid item>
            <Typography
              sx={{
                marginRight: 4.5,
                marginLeft: 1.8,
                fontFamily: "Outfit",
                letterSpacing: "0.09rem",
                fontWeight: "800",
                fontSize: "2.07rem",
                color: "#202426",
                textTransform: "lowercase",
                position: "relative",
                "::after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: 0.9,
                  width: "100%",
                  height: "2.7px",
                  backgroundColor: "grey",
                },
              }}
              variant="body1"
              align="left"
            >
              STUBS
            </Typography>
          </Grid>
          <Grid item>
            <FormControl
              variant="outlined"
              sx={{
                minWidth: "15rem",
                "@media (max-width: 600px)": { minWidth: "9rem" },
              }}
            >
              <InputLabel
                sx={{
                  ...selectInputLabel,
                }}
                htmlFor="property-code"
              >
                TEAM
              </InputLabel>
              <Select
                value={selectedPropertyCode || ""}
                onChange={handlePropertyCodeChange}
                inputProps={{ name: "property-code", id: "property-code" }}
                // readOnly={!!selectedPropertyCode} // Makes it read-only if selectedPropertyCode exists
                sx={{
                  ...selectSelect,
                }}
              >
                {viewPayrollStubs
                  .slice()
                  .sort((a, b) => {
                    const nameA = (
                      a.propertyCode ||
                      a.propertyName ||
                      ""
                    ).toString();
                    const nameB = (
                      b.propertyCode ||
                      b.propertyName ||
                      ""
                    ).toString();
                    return nameA.localeCompare(nameB);
                  })
                  .map((property) => (
                    <MenuItem
                      key={property.propertyCode}
                      value={property.propertyCode}
                      sx={{ ...selectMenuItem }}
                    >
                      {property.propertyName
                        ? `${property.propertyCode} - ${property.propertyName}`
                        : property.propertyCode}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box height={16} />

      {canViewSection("payrollViewStubs", selectedPropertyCode) && (
        <Card
          sx={{ borderRadius: "18px", padding: 1.26, marginBottom: "1.8rem" }}
        >
          <Typography
            sx={{
              marginLeft: 4.5,
              marginTop: 1.8,
              fontFamily: "Outfit",
              letterSpacing: "0.045rem",
              fontWeight: "800",
              fontSize: "0.9rem",
              textTransform: "uppercase",
            }}
            variant="body1"
            align="left"
          >
            view stubs
          </Typography>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            backgroundColor="#fcfcfc"
            padding={2}
          >
            <Grid
              item
              xs={2}
              container
              sx={{
                padding: "1.8rem",
                borderRadius: "36px",
              }}
            >
              <FormControl
                sx={{
                  minWidth: "15rem",
                  "@media (max-width: 600px)": {
                    minWidth: "9rem",
                  },
                  paddingLeft: "1rem",
                }}
              >
                <InputLabel
                  id="employee-select-label"
                  sx={{
                    ...selectInputLabel,
                    paddingLeft: 3,
                  }}
                >
                  TEAM MEMBER
                </InputLabel>
                <Select
                  labelId="employee-select-label"
                  value={selectedEmployee}
                  onChange={(e) => setSelectedEmployee(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Select Employee" }}
                  sx={{
                    ...selectSelect,
                  }}
                >
                  {employees
                    .sort((a, b) =>
                      (a.FirstName + " " + a.LastName).localeCompare(
                        b.FirstName + " " + b.LastName,
                      ),
                    )
                    .map((emp) => (
                      <MenuItem
                        key={emp.EmployeeId}
                        value={emp.EmployeeId}
                        sx={{ ...selectMenuItem }}
                      >
                        {`${emp.FirstName} ${emp.LastName} (${emp.EmployeeId})`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TableContainer
                component={Paper}
                sx={{
                  overflowX: "auto",
                }}
              >
                <Table sx={{ width: "100%" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={maintitle200header} align="left">
                        EMPLOYEE
                      </TableCell>
                      <TableCell
                        sx={maintitle200header}
                        align="left"
                        onClick={() => requestSort("Amount")}
                      >
                        AMOUNT{" "}
                        {sortConfig.key === "Amount"
                          ? sortConfig.direction === "ascending"
                            ? "↑"
                            : "↓"
                          : ""}
                      </TableCell>
                      <TableCell
                        sx={maintitle200header}
                        align="left"
                        onClick={() => requestSort("Date")}
                      >
                        CHECK DATE{" "}
                        {sortConfig.key === "Date"
                          ? sortConfig.direction === "ascending"
                            ? "↑"
                            : "↓"
                          : ""}
                      </TableCell>
                      <TableCell
                        sx={maintitle200header}
                        align="left"
                        onClick={() => requestSort("PeriodEndDate")}
                      >
                        PERIOD END DATE{" "}
                        {sortConfig.key === "PeriodEndDate"
                          ? sortConfig.direction === "ascending"
                            ? "↑"
                            : "↓"
                          : ""}
                      </TableCell>
                      <TableCell
                        sx={maintitle200header}
                        align="left"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedStubsData.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={maintable}
                          size="small"
                          align="left"
                        >
                          {row.Employee}
                        </TableCell>
                        <TableCell sx={maintable} size="small" align="left">
                          {row.Amount}
                        </TableCell>
                        <TableCell sx={maintable} size="small" align="left">
                          {row.Date}
                        </TableCell>
                        <TableCell
                          sx={{ ...maintable, fontWeight: "bold" }}
                          size="small"
                          align="left"
                        >
                          {!row.PeriodEndDate ||
                          row.PeriodEndDate === "NaN/NaN/aN"
                            ? "OTHER"
                            : row.PeriodEndDate}
                        </TableCell>
                        <TableCell sx={maintable} size="small" align="left">
                          <PDFDownloadLink
                            document={<PayStubPDF employeeData={row} />}
                            fileName={`pay_stub_${
                              row.EmployeeId
                            }_${row.Date.replace(/[/]/g, "_")}.pdf`}
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? (
                                "Loading document..."
                              ) : (
                                <span>
                                  <IconButton
                                    sx={{
                                      fontSize: "1.5rem",
                                      color: "#FF0000",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    <FaFilePdf />
                                  </IconButton>
                                </span>
                              )
                            }
                          </PDFDownloadLink>
                          <Typography
                            variant="caption"
                            sx={{
                              fontFamily: "Outfit",
                              fontWeight: 800,
                              color: "#202426",
                              fontSize: "0.72rem",
                              letterSpacing: "0.09rem",
                            }}
                          >
                            DL PDF
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Card>
      )}
      {canViewSection("payrollUploadStubs", selectedPropertyCode) && (
        <Card sx={{ borderRadius: "18px" }}>
          <Typography
            sx={{
              marginLeft: 4.5,
              marginTop: 3.6,
              fontFamily: "Outfit",
              letterSpacing: "0.045rem",
              fontWeight: "800",
              fontSize: "0.9rem",
              textTransform: "uppercase",
            }}
            variant="body1"
            align="left"
          >
            upload stubs
          </Typography>
          <Box
            sx={{
              padding: "1rem",
              backgroundColor: "#fcfcfc",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="green"
              textColor="inherit"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#93A683",
                },
                "& .MuiTab-root": {
                  fontWeight: 800,
                  fontSize: "0.81rem",
                },
              }}
            >
              <Tab label="PAYROLL" />

              <Tab label="OTHER" />
            </Tabs>
          </Box>
          <Box height={16} />
          {value === 0 && (
            <Box>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                backgroundColor="#f5f5f5"
                sx={{
                  border: "0.5px solid lightgrey",
                  marginTop: 1,
                  marginBottom: 2.5,
                }}
                padding={2}
              >
                <Grid
                  item
                  xs={6}
                  container
                  sx={{
                    padding: "1rem",
                    borderRadius: "9px",
                  }}
                >
                  <FormControl
                    sx={{
                      minWidth: "9rem",
                      marginLeft: "29px",
                    }}
                  >
                    <Select
                      value={selectedDate || ""}
                      onChange={(event) => setSelectedDate(event.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Select Pay Period" }}
                      sx={{
                        ...selectSelect,
                      }}
                    >
                      {uniqueDates.map((date, index) => (
                        <MenuItem
                          key={index}
                          value={date}
                          sx={{ ...selectMenuItem }}
                        >
                          {new Date(date).toLocaleDateString("en-US", {
                            timeZone: "UTC",
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          })}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Grid
                    item
                    xs={6}
                    container
                    sx={{
                      paddingTop: "1rem",
                      paddingLeft: "2rem",
                    }}
                  >
                    <div>
                      <input
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={(e) => handleFileChange(e, 0)}
                        aria-label="Upload payroll data file"
                      />
                      {error && <div style={{ color: "red" }}>{error}</div>}
                      {payrollConfirmationData && (
                        <div>
                          <p
                            style={{
                              color: "#101010",
                              fontSize: "1rem",
                              fontWeight: "200",
                              letterSpacing: "0.1rem",
                            }}
                          >
                            CONFIRM STUB DATA FOR:{" "}
                            {payrollConfirmationData.PropertyCode}, FOR THE
                            PERIOD ENDING:{" "}
                            {payrollConfirmationData.PeriodEndDate}
                          </p>
                          <textarea
                            readOnly
                            value={payrollConfirmationData.SummaryData}
                            style={{ width: "100%", height: "200px" }}
                          />
                          <button onClick={() => confirmAndPostData(false)}>
                            Confirm and Submit Stubs
                          </button>
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Box sx={{ p: 3 }}>
                <Box
                  sx={{
                    paddingBottom: 4.5,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: 800,
                      fontFamily: "Outfit",
                      letterSpacing: "0.018rem",
                      color: "#202426",
                      textTransform: "uppercase",
                    }}
                  >
                    {propertyDetails.propertyName}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "0.81rem",
                      fontWeight: 400,
                      fontFamily: "Nunito",
                      color: "#202426",
                    }}
                  >
                    {propertyDetails.propEntity}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns:
                      "repeat(auto-fill, minmax(200px, 1fr))",
                    gap: "1rem",
                  }}
                >
                  {employees
                    .slice() // Create a shallow copy to avoid mutating the original array
                    .sort((a, b) => a.FirstName.localeCompare(b.FirstName)) // Sort employees by first name
                    .map((employee) => (
                      <Box
                        key={employee.EmployeeId}
                        sx={{
                          display: "flow",
                          alignItems: "left",
                          fontSize: "0.9rem",
                          fontWeight: 400,
                          fontFamily: "Nunito",
                          color: "#202426",
                        }}
                      >
                        {payStubs[employee.EmployeeId] && (
                          <BsFillPatchCheckFill
                            style={{
                              color: "#4CAF50",
                              marginRight: "0.5rem",
                              fontSize: "0.9rem",
                            }}
                          />
                        )}
                        {` ${employee.EmployeeId} - ${employee.FirstName} ${
                          employee.LastName
                        } (${employee.PeachTreeID || "~undefined~"}) `}
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          )}
          {value === 1 && (
            <Box>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                backgroundColor="#fcfcfc"
                sx={{ borderBottom: "0.5px solid black" }}
                padding={5}
              >
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: 400,
                    fontFamily: "Nunito",
                    letterSpacing: "0.018rem",
                    color: "#202426",
                    paddingLeft: 1,
                    paddingBottom: 3.9,
                  }}
                >
                  UPLOAD MISCELLANEOUS STUBS
                </Typography>
                <Grid
                  item
                  xs={12}
                  container
                  sx={{
                    padding: "1rem",
                    borderRadius: "9px",
                  }}
                >
                  <div>
                    <input
                      type="file"
                      accept=".xlsx, .xls"
                      onChange={(e) => handleFile(e, 1)}
                      aria-label="Upload payroll data file"
                    />
                    {error && <div style={{ color: "red" }}>{error}</div>}
                    {otherConfirmationData && (
                      <div>
                        <p
                          style={{
                            color: "#101010",
                            fontSize: "1rem",
                            fontWeight: "200",
                            letterSpacing: "0.1rem",
                          }}
                        >
                          CONFIRM MISC STUB DATA BELOW:
                        </p>
                        <textarea
                          readOnly
                          value={otherConfirmationData.SummaryData}
                          style={{ width: "100%", height: "200px" }}
                        />
                        <button onClick={() => confirmAndPostData(true)}>
                          Confirm and Submit Stubs
                        </button>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Box>
          )}
        </Card>
      )}
      <SubmissionDialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setReloadTrigger((prev) => prev + 1);
        }}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default Stubs;

const maintitle200header = {
  fontWeight: 600,
  fontSize: "0.81rem",
  fontFamily: "Outfit",
  backgroundColor: "lightgrey",
  color: "#202426",
};
const maintable = {
  letterSpacing: "0.09rem",
  fontWeight: 400,
  fontSize: "0.9rem",
  fontFamily: "Nunito",
  backgroundColor: "#ffffff",
  color: "#101010",
  minWidth: "2.7rem",
  borderRadius: "18px",
};
const selectInputLabel = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Outfit",
  fontWeight: 400,
  color: "#202426",
  transition: "0.3s",
  textTransform: "uppercase",
};
const selectSelect = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Nunito",
  backgroundColor: "#ffffff",
  borderRadius: "18px",
  textTransform: "uppercase",
};
const selectMenuItem = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Nunito",
  fontWeight: 400,
  letterSpacing: "0.045rem",
  color: "#202426",
  transition: "0.3s",
  textTransform: "uppercase",
};
