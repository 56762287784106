import { createContext, useReducer, useEffect, useContext } from "react";
import { auth, db } from "../firebase/firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";

const FirebaseAuthContext = createContext();

const initialState = {
  user: null,
  authStateChecked: false,
  userPermissions: {},
  availableProperties: [],
  userRole: "",
  selectedPropertyCode: "",
};

const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload };
    case "LOGOUT":
      return { ...initialState, authStateChecked: true };
    case "AUTH_STATE_CHECKED":
      return { ...state, user: action.payload, authStateChecked: true };
    case "SET_USER_DATA":
      return {
        ...state,
        userPermissions: action.payload.userPermissions,
        availableProperties: action.payload.availableProperties,
        userRole: action.payload.userRole,
        selectedPropertyCode: action.payload.selectedPropertyCode,
      };
    case "SET_SELECTED_PROPERTY":
      return {
        ...state,
        userPermissions: action.payload.userPermissions,
        userRole: action.payload.userRole,
        selectedPropertyCode: action.payload.selectedPropertyCode,
      };
    default:
      return state;
  }
};

export const FirebaseAuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  console.log("user from authContext", state.user);

  //IF USER IS LOGGED IN FETCH ROLES AND PERMISSIONS
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      dispatch({ type: "AUTH_STATE_CHECKED", payload: user });

      if (user) {
        await fetchUserPropertiesAndPermissions(user);
      } else {
        // User is logged out, reset state
        dispatch({ type: "LOGOUT" });
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  // Fetch User Properties and Permissions
  // CHECK IF USER EXISTS IN emailAuthUsers, if user exists Extract roles and permissions from the collection
  const fetchUserPropertiesAndPermissions = async (user) => {
    try {
      if (!user) {
        console.error("User is required to fetch properties and permissions.");
        return;
      }

      const userEmail = user.email;
      const userRef = doc(db, "emailAuthUsers", userEmail);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        // User exists in 'emailAuthUsers'
        const userData = userSnap.data();
        const propertyRoles = userData.propertyRoles || {};
        const propertyCodes = Object.keys(propertyRoles);

        const userPermissions = {}; // Initialize the permissions object

        const properties = await Promise.all(
          propertyCodes.map(async (propertyCode) => {
            const propertyData = propertyRoles[propertyCode];
            const { role, ...permissions } = propertyData;

            // Fetch property name and GroupID
            const { propertyName, GroupID } =
              await fetchPropertyName(propertyCode);

            // Store permissions per property code
            userPermissions[propertyCode] = {
              permissions,
              role,
            };

            return {
              propertyCode,
              propertyName,
              GroupID,
              role,
              permissions,
            };
          }),
        );

        // Dispatch action to update context with fetched data
        if (properties.length > 0) {
          const defaultProperty = properties[0];

          dispatch({
            type: "SET_USER_DATA",
            payload: {
              userPermissions, // Now contains all permissions per property
              availableProperties: properties,
              userRole: defaultProperty.role,
              selectedPropertyCode: defaultProperty.propertyCode,
            },
          });
        } else {
          // Handle case where user exists but has no properties assigned
          dispatch({
            type: "SET_USER_DATA",
            payload: {
              userPermissions: {},
              availableProperties: [],
              userRole: "",
              selectedPropertyCode: "",
            },
          });
        }
      } else {
        // User does not exist in 'emailAuthUsers'; check photoURL for role or GroupID
        if (user.photoURL) {
          const userRoleFromPhotoURL = user.photoURL;

          if (userRoleFromPhotoURL === "Global") {
            // Fetch all properties for Global user
            const propertiesRef = collection(db, "Properties");
            const propertiesSnapshot = await getDocs(propertiesRef);
            const properties = [];

            propertiesSnapshot.forEach((doc) => {
              const propertyData = doc.data();
              properties.push({
                propertyCode: propertyData.propCode,
                propertyName:
                  propertyData.propertyName || propertyData.propCode,
                role: "Global",
                permissions: {}, // Set default permissions or as needed
              });
            });

            // Dispatch action to update context
            if (properties.length > 0) {
              const defaultProperty = properties[0];
              dispatch({
                type: "SET_USER_DATA",
                payload: {
                  userPermissions: defaultProperty.permissions,
                  availableProperties: properties,
                  userRole: "Global",
                  selectedPropertyCode: defaultProperty.propertyCode,
                },
              });
            } else {
              dispatch({
                type: "SET_USER_DATA",
                payload: {
                  userPermissions: {},
                  availableProperties: [],
                  userRole: "Global",
                  selectedPropertyCode: "",
                },
              });
            }
          } else if (userRoleFromPhotoURL === "Staff") {
            // Staff user with limited access
            dispatch({
              type: "SET_USER_DATA",
              payload: {
                userPermissions: {},
                availableProperties: [],
                userRole: "Staff",
                selectedPropertyCode: "",
              },
            });
          } else {
            // Treat photoURL as GroupID
            const groupID = userRoleFromPhotoURL;
            const propertiesRef = collection(db, "Properties");
            const q = query(propertiesRef, where("GroupID", "==", groupID));
            const propertiesSnapshot = await getDocs(q);
            const properties = [];

            propertiesSnapshot.forEach((doc) => {
              const propertyData = doc.data();
              properties.push({
                propertyCode: propertyData.propCode,
                propertyName:
                  propertyData.propertyName || propertyData.propCode,
                GroupID: propertyData.GroupID || null,
                role: "",
                permissions: {},
              });
            });

            // Dispatch action to update context
            if (properties.length > 0) {
              const defaultProperty = properties[0];
              dispatch({
                type: "SET_USER_DATA",
                payload: {
                  userPermissions: defaultProperty.permissions,
                  availableProperties: properties,
                  userRole: "Global",
                  selectedPropertyCode: defaultProperty.propertyCode,
                },
              });
            } else {
              // No matching properties found
              dispatch({
                type: "SET_USER_DATA",
                payload: {
                  userPermissions: {},
                  availableProperties: [],
                  userRole: userRoleFromPhotoURL,
                  selectedPropertyCode: "",
                },
              });
            }
          }
        } else {
          // User has no role defined
          dispatch({
            type: "SET_USER_DATA",
            payload: {
              userPermissions: {},
              availableProperties: [],
              userRole: "",
              selectedPropertyCode: "",
            },
          });
        }
      }
    } catch (error) {
      console.error("Error fetching user properties and permissions:", error);
    }
  };

  // FETCH PROPERTY NAME(S) AND GROUPID
  const fetchPropertyName = async (propertyCode) => {
    try {
      const propertiesRef = collection(db, "Properties");
      const q = query(propertiesRef, where("propCode", "==", propertyCode));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const propertyDoc = querySnapshot.docs[0];
        const propertyData = propertyDoc.data();

        return {
          propertyName: propertyData.propertyName || propertyCode,
          GroupID: propertyData.GroupID || null,
        };
      } else {
        return {
          propertyName: propertyCode,
          GroupID: null,
        };
      }
    } catch (error) {
      console.error("Error fetching property data:", error);
      return {
        propertyName: propertyCode,
        GroupID: null,
      };
    }
  };

  // Handle Property Change
  const handlePropertyChange = (propertyCode) => {
    const selectedProperty = state.availableProperties.find(
      (prop) => prop.propertyCode === propertyCode,
    );

    if (selectedProperty) {
      dispatch({
        type: "SET_SELECTED_PROPERTY",
        payload: {
          userRole: selectedProperty.role,
          selectedPropertyCode: propertyCode,
        },
      });
    } else {
      dispatch({
        type: "SET_SELECTED_PROPERTY",
        payload: {
          userRole: "",
          selectedPropertyCode: "",
        },
      });
    }
  };

  // Logout Function
  const logout = () => {
    auth
      .signOut()
      .then(() => {
        localStorage.removeItem("userData");
        dispatch({ type: "LOGOUT" });
        // Redirect after logout success
        window.location.href = "/login";
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  };

  return (
    <FirebaseAuthContext.Provider
      value={{
        ...state,
        dispatch,
        handlePropertyChange,
        logout,
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(FirebaseAuthContext);
};
