import React, { useState, useEffect, useMemo } from "react";
import { db } from "../../firebase/firebase-config";
import { useAuth } from "../../contexts/AuthContext";
import { doc, getDoc } from "firebase/firestore";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Box,
  Typography,
} from "@mui/material";

const Portfolio = () => {
  const { user, userPermissions, userRole, availableProperties } = useAuth();
  const [propertyCode, setPropertyCode] = useState([]);
  const [selectedPropertyCode, setSelectedPropertyCode] = useState("");
  const [propertyDetails, setPropertyDetails] = useState({});

  const [setPermissionsForProperty] = useState({});

  // PERMISSIONS

  const hasPermissionForProperty = (permission, propertyCode) => {
    // If the user's role is 'Global' or 'MGMT', they have all permissions
    if (
      userRole === "Global" ||
      userRole === "MGMT" ||
      userRole === "PropertyAdmin"
    ) {
      return true;
    }

    // Otherwise, check the permissions for the specific property
    const permissionsForProperty =
      userPermissions[propertyCode]?.permissions || {};
    return permissionsForProperty[permission] === true;
  };

  // Filter availableProperties to include only those with 'payrollViewStubs' permission
  const viewPayrollStubs = useMemo(
    () =>
      availableProperties.filter((prop) => {
        return (
          userPermissions &&
          hasPermissionForProperty("payrollViewStubs", prop.propertyCode)
        );
      }),
    [availableProperties, userPermissions],
  );

  useEffect(() => {
    if (!propertyCode || !userPermissions[propertyCode]) return;

    // Avoid setting state if permissions haven't changed to prevent re-renders
    const propertyPermissions = userPermissions[propertyCode].permissions || {};
    const permissionsArray = Object.keys(propertyPermissions).filter(
      (key) => propertyPermissions[key] === true,
    );

    setPermissionsForProperty((prevPermissions) => {
      // Only update state if permissions have changed
      const permissionsChanged =
        JSON.stringify(prevPermissions) !== JSON.stringify(permissionsArray);
      return permissionsChanged ? permissionsArray : prevPermissions;
    });
  }, [propertyCode, userPermissions]);

  // Function to determine if a user can view a section
  const canViewSection = (permissions = [], propertyCode) => {
    const requiredPermissions = Array.isArray(permissions)
      ? permissions
      : [permissions];

    // Grant access to Global, MGMT, and PropertyAdmin roles
    if (["Global", "MGMT", "PropertyAdmin"].includes(userRole)) return true;

    // Check specific permissions for the property
    return requiredPermissions.every((permission) => {
      const propertyPermissions =
        userPermissions[propertyCode]?.permissions || {};
      return propertyPermissions[permission] === true;
    });
  };

  const handlePropertyCodeChange = (event) => {
    const newPropertyCode = event.target.value;

    // Update the selected property code in AuthContext
    setSelectedPropertyCode(newPropertyCode);
  };

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      if (!selectedPropertyCode) return;
      const docRef = doc(db, "Properties", selectedPropertyCode);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setPropertyDetails({
          propertyName: data.propertyName,
          propEntity: data.propEntity,
          Ad1: data.Ad1,
          city: data.city,
          state: data.state,
          AdZip: data.AdZip,
        });
      } else {
        console.log("No such property!");
        setPropertyDetails({});
      }
    };

    fetchPropertyDetails();
  }, [selectedPropertyCode]);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "transparent",
          borderRadius: "0.9rem",
          marginBottom: "1.8rem",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="left"
          spacing={2}
          style={{ margin: "9px" }}
        >
          <Grid item>
            <Typography
              sx={{
                marginRight: 4.5,
                marginLeft: 1.8,
                fontFamily: "Outfit",
                letterSpacing: "0.09rem",
                fontWeight: "800",
                fontSize: "2.07rem",
                color: "#202426",
                textTransform: "lowercase",
                position: "relative",
                "::after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: 0.9,
                  width: "100%",
                  height: "2.7px",
                  backgroundColor: "grey",
                },
              }}
              variant="body1"
              align="left"
            >
              PORTFOLIO
            </Typography>
          </Grid>
          <Grid item>
            <FormControl
              variant="outlined"
              sx={{
                minWidth: "15rem",
                "@media (max-width: 600px)": { minWidth: "9rem" },
              }}
            >
              <InputLabel
                sx={{
                  ...selectInputLabel,
                }}
                htmlFor="property-code"
              >
                TEAM
              </InputLabel>
              <Select
                value={selectedPropertyCode || ""}
                onChange={handlePropertyCodeChange}
                inputProps={{ name: "property-code", id: "property-code" }}
                // readOnly={!!selectedPropertyCode} // Makes it read-only if selectedPropertyCode exists
                sx={{
                  ...selectSelect,
                }}
              >
                {viewPayrollStubs
                  .slice()
                  .sort((a, b) => {
                    const nameA = (
                      a.propertyCode ||
                      a.propertyName ||
                      ""
                    ).toString();
                    const nameB = (
                      b.propertyCode ||
                      b.propertyName ||
                      ""
                    ).toString();
                    return nameA.localeCompare(nameB);
                  })
                  .map((property) => (
                    <MenuItem
                      key={property.propertyCode}
                      value={property.propertyCode}
                      sx={{ ...selectMenuItem }}
                    >
                      {property.propertyName
                        ? `${property.propertyCode} - ${property.propertyName}`
                        : property.propertyCode}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Portfolio;

const selectInputLabel = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Outfit",
  fontWeight: 400,
  color: "#202426",
  transition: "0.3s",
  textTransform: "uppercase",
};
const selectSelect = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Nunito",
  backgroundColor: "#ffffff",
  borderRadius: "18px",
  textTransform: "uppercase",
};
const selectMenuItem = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Nunito",
  fontWeight: 400,
  letterSpacing: "0.045rem",
  color: "#202426",
  transition: "0.3s",
  textTransform: "uppercase",
};
