import React, { useState, useEffect, useMemo } from "react";
import { db } from "../../firebase/firebase-config";
import { useAuth } from "../../contexts/AuthContext";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  doc,
  updateDoc,
} from "firebase/firestore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Box,
  Card,
  Typography,
  TextField,
  Button,
  Tooltip,
  FormControlLabel,
  Switch,
} from "@mui/material";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { MdEdit } from "react-icons/md";
import { MdOutlineKingBed } from "react-icons/md";
import { TbClockHour10 } from "react-icons/tb";
import { MdOutlineHealthAndSafety } from "react-icons/md";
import { FaRegIdBadge, FaFilePdf } from "react-icons/fa";
import { ImCoinDollar } from "react-icons/im";
import { TiBusinessCard } from "react-icons/ti";
import { BsBatteryCharging, BsRobot } from "react-icons/bs";
import { RiCake2Line } from "react-icons/ri";

const PunchSummary = () => {
  const { user, userPermissions, userRole, availableProperties } = useAuth();
  const [payRollData, setPayRollData] = useState([]);
  const [propertyCode, setPropertyCode] = useState([]);
  const [selectedPropertyCode, setSelectedPropertyCode] = useState("");
  const [totalVacationHours, setTotalVacationHours] = useState(0);
  const [allEmployees, setAllEmployees] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [value, setValue] = React.useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: "FirstName",
    direction: "asc",
  });
  const [averageRooms, setAverageRooms] = useState({});
  const [averageTimes, setAverageTimes] = useState({});
  const [currentAverageTimes, setCurrentAverageTimes] = useState({});
  const [filterStatus, setFilterStatus] = useState("Active");
  const [editID, setEditID] = useState(null);
  const [tempID, setTempID] = useState("");
  const [setPermissionsForProperty] = useState({});
  const [hireDates, setHireDates] = useState({});
  const [vacationEligibility, setVacationEligibility] = useState({});
  const [healthInsuranceEligibility, setHealthInsuranceEligibility] = useState(
    {},
  );

  // PERMISSIONS

  const hasPermissionForProperty = (permission, propertyCode) => {
    // If the user's role is 'Global' or 'MGMT', they have all permissions
    if (
      userRole === "Global" ||
      userRole === "MGMT" ||
      userRole === "PropertyAdmin"
    ) {
      return true;
    }

    // Otherwise, check the permissions for the specific property
    const permissionsForProperty =
      userPermissions[propertyCode]?.permissions || {};
    return permissionsForProperty[permission] === true;
  };

  // Filter availableProperties to include only those with 'landPayroll' permission
  const viewPayrollRoster = useMemo(
    () =>
      availableProperties.filter((prop) => {
        return (
          userPermissions &&
          hasPermissionForProperty("payrollRoster", prop.propertyCode)
        );
      }),
    [availableProperties, userPermissions],
  );

  useEffect(() => {
    if (!propertyCode || !userPermissions[propertyCode]) return;

    // Avoid setting state if permissions haven't changed to prevent re-renders
    const propertyPermissions = userPermissions[propertyCode].permissions || {};
    const permissionsArray = Object.keys(propertyPermissions).filter(
      (key) => propertyPermissions[key] === true,
    );

    setPermissionsForProperty((prevPermissions) => {
      // Only update state if permissions have changed
      const permissionsChanged =
        JSON.stringify(prevPermissions) !== JSON.stringify(permissionsArray);
      return permissionsChanged ? permissionsArray : prevPermissions;
    });
  }, [propertyCode, userPermissions]);

  // Function to determine if a user can view a section
  const canViewSection = (permissions = [], propertyCode) => {
    const requiredPermissions = Array.isArray(permissions)
      ? permissions
      : [permissions];

    // Grant access to Global, MGMT, and PropertyAdmin roles
    if (["Global", "MGMT", "PropertyAdmin"].includes(userRole)) return true;

    // Check specific permissions for the property
    return requiredPermissions.every((permission) => {
      const propertyPermissions =
        userPermissions[propertyCode]?.permissions || {};
      return propertyPermissions[permission] === true;
    });
  };

  const handlePropertyCodeChange = (event) => {
    const newPropertyCode = event.target.value;

    // Update the selected property code in AuthContext
    setSelectedPropertyCode(newPropertyCode);
  };

  useEffect(() => {
    if (selectedPropertyCode) {
      const q = query(
        collection(db, "empRegister"),
        where("PropID", "==", selectedPropertyCode),
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const employees = snapshot.docs.map((doc) => ({
          id: doc.id,
          EmployeeId: doc.data().EmpID,
          FirstName: doc.data().NameFirst,
          LastName: doc.data().NameLast,
          Phone: doc.data().AdPhone,
          PayD1: doc.data().PayD1,
          PayD1_Rate: doc.data().PayD1_Rate,
          PayD1_Type: doc.data().PayD1_Type,
          PayD2: doc.data().PayD2,
          PayD2_Rate: doc.data().PayD2_Rate,
          PayD2_Type: doc.data().PayD2_Type,
          Status: doc.data().Status,
          PRR: doc.data().PRR,
          Email: doc.data().Email,
          peachTreeID: doc.data().peachTreeID,
          "W4-IRS": doc.data()["W4-IRS"],
          "W4-LOCAL": doc.data()["W4-LOCAL"],
          SSN: doc.data().SSN,
          ID: doc.data().ID,
          paySelection: doc.data().paySelection,
          health: doc.data().health,
          DateHired: doc.data().DateHired,
        }));

        const hireDates = {};
        employees.forEach((employee) => {
          hireDates[employee.EmployeeId] = employee.DateHired
            ? new Date(employee.DateHired)
            : null;
        });

        setHireDates(hireDates); // Save hire dates in state for use in payroll function

        const filteredEmployees = employees.filter((employee) => {
          const isSalary =
            employee.PayD1_Type === "SALARY" ||
            employee.PayD2_Type === "SALARY";
          return !isSalary || (isSalary && canViewSection);
        });

        setAllEmployees(filteredEmployees);
        setEmployeeList(filteredEmployees);

        if (filteredEmployees.length > 0) {
          setEmployeeName(
            `${filteredEmployees[0].FirstName} ${filteredEmployees[0].LastName}`,
          );
        } else {
          setEmployeeName("");
        }
      });

      return () => unsubscribe();
    }
  }, [selectedPropertyCode, canViewSection]);

  const sortedEmployees = React.useMemo(() => {
    let sortableEmployees = [...allEmployees].filter(
      (employee) => employee.Status !== "Terminated",
    );
    if (sortConfig !== null) {
      sortableEmployees.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableEmployees;
  }, [allEmployees, sortConfig]);

  const requestSort = (key) => {
    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  // CALCULATE AVERAGES

  useEffect(() => {
    if (selectedPropertyCode && hireDates) {
      const q = query(
        collection(db, "payRoll"),
        where("PropertyCode", "==", selectedPropertyCode),
        orderBy("PeriodEndDate", "desc"),
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedPayRollData = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });

        setPayRollData(fetchedPayRollData);

        let averageTimes = {};
        let currentAverageTimes = {};
        let averageRooms = {};
        let totalVacationHours = {};
        let vacationEligibility = {};
        let healthInsuranceEligibility = {};
        const currentDate = new Date();

        fetchedPayRollData.forEach((doc) => {
          const hireDate = hireDates[doc.EmployeeId];
          if (hireDate) {
            const anniversaryDateCurrentYear = new Date(hireDate);
            anniversaryDateCurrentYear.setFullYear(currentDate.getFullYear());

            if (anniversaryDateCurrentYear > currentDate) {
              anniversaryDateCurrentYear.setFullYear(
                currentDate.getFullYear() - 1,
              );
            }

            const anniversaryDatePreviousYear = new Date(
              anniversaryDateCurrentYear,
            );
            anniversaryDatePreviousYear.setFullYear(
              anniversaryDatePreviousYear.getFullYear() - 1,
            );

            // Vacation Taken (current year)
            if (
              doc.PeriodEndDate?.toDate() >= anniversaryDateCurrentYear &&
              doc.Status !== "Void"
            ) {
              const d1Vacation = doc.D1?.VAC || 0;
              const d2Vacation = doc.D2?.VAC || 0;
              const totalVacation = d1Vacation + d2Vacation;

              if (doc.EmployeeId in totalVacationHours) {
                totalVacationHours[doc.EmployeeId] += totalVacation;
              } else {
                totalVacationHours[doc.EmployeeId] = totalVacation;
              }

              // Current year average for display
              const timeParts = doc.Totals?.Time.split(" ");
              const hours = timeParts[0] ? parseInt(timeParts[0]) : 0;
              const minutes = timeParts[2] ? parseInt(timeParts[2]) : 0;
              const totalMinutes = hours * 60 + minutes;

              if (doc.EmployeeId in currentAverageTimes) {
                currentAverageTimes[doc.EmployeeId].push(totalMinutes / 2);
              } else {
                currentAverageTimes[doc.EmployeeId] = [totalMinutes / 2];
              }
            }

            // Eligible Vacation (previous year)
            if (
              doc.PeriodEndDate?.toDate() >= anniversaryDatePreviousYear &&
              doc.PeriodEndDate?.toDate() < anniversaryDateCurrentYear &&
              doc.Status !== "Void"
            ) {
              const timeParts = doc.Totals?.Time.split(" ");
              const hours = timeParts[0] ? parseInt(timeParts[0]) : 0;
              const minutes = timeParts[2] ? parseInt(timeParts[2]) : 0;
              const totalMinutes = hours * 60 + minutes;

              if (doc.EmployeeId in averageTimes) {
                averageTimes[doc.EmployeeId].push(totalMinutes / 2);
              } else {
                averageTimes[doc.EmployeeId] = [totalMinutes / 2];
              }
            }

            // Current year average rooms
            if (
              doc.PeriodEndDate?.toDate() >= anniversaryDateCurrentYear &&
              doc.Status !== "Void"
            ) {
              const rooms = doc.Totals?.Rooms || 0;

              if (doc.EmployeeId in averageRooms) {
                averageRooms[doc.EmployeeId].push(rooms / 2);
              } else {
                averageRooms[doc.EmployeeId] = [rooms / 2];
              }
            }
          }
        });

        // Calculate eligible vacation hours and averages
        for (let employeeId in averageTimes) {
          const previousYearAverageMinutes =
            averageTimes[employeeId].reduce((a, b) => a + b, 0) /
            averageTimes[employeeId].length;
          const previousYearWeeklyAverageHours =
            previousYearAverageMinutes / 60;

          const hireDate = hireDates[employeeId];
          const hasWorkedOneYear =
            hireDate &&
            currentDate >=
              new Date(hireDate).setFullYear(hireDate.getFullYear() + 1);
          const hasWorkedNinetyDays =
            hireDate &&
            currentDate >= new Date(hireDate).setDate(hireDate.getDate() + 90);

          // Current year calculations
          if (currentAverageTimes[employeeId]?.length > 0) {
            const currentYearMinutes = currentAverageTimes[employeeId].reduce(
              (a, b) => a + b,
              0,
            );
            const currentYearWeeklyAverageHours =
              currentYearMinutes /
              (60 * currentAverageTimes[employeeId].length);

            // Update currentAverageTimes for display
            const currentAverageHours = Math.floor(
              currentYearMinutes / 60 / currentAverageTimes[employeeId].length,
            );
            const remainingMinutes = Math.round(
              (currentYearMinutes / currentAverageTimes[employeeId].length) %
                60,
            );
            currentAverageTimes[employeeId] =
              `${currentAverageHours}h ${remainingMinutes}m`;

            // Eligible vacation hours
            if (
              hasWorkedOneYear &&
              previousYearWeeklyAverageHours > 30 &&
              currentYearWeeklyAverageHours > 30
            ) {
              vacationEligibility[employeeId] = Math.ceil(
                previousYearWeeklyAverageHours,
              );
            } else {
              vacationEligibility[employeeId] = 0;
            }

            // Health insurance eligibility
            if (
              hasWorkedNinetyDays &&
              currentYearWeeklyAverageHours > 30 &&
              !fetchedPayRollData.find((doc) => doc.EmployeeId === employeeId)
                .health
            ) {
              healthInsuranceEligibility[employeeId] = true;
            } else {
              healthInsuranceEligibility[employeeId] = false;
            }
          } else {
            // Handle cases where no current data exists
            currentAverageTimes[employeeId] = "0h 0m";
            vacationEligibility[employeeId] = 0;
            healthInsuranceEligibility[employeeId] = false;
          }

          // Previous year averages for display
          const averageHours = Math.floor(previousYearAverageMinutes / 60);
          const remainingMinutes = Math.round(previousYearAverageMinutes % 60);
          averageTimes[employeeId] = `${averageHours}h ${remainingMinutes}`;
        }

        for (let employeeId in averageRooms) {
          const average =
            averageRooms[employeeId].reduce((a, b) => a + b, 0) /
            averageRooms[employeeId].length;
          averageRooms[employeeId] = Math.round(average);
        }

        setAverageTimes(averageTimes); // Previous year averages for display
        setCurrentAverageTimes(currentAverageTimes); // Current year averages for display
        setAverageRooms(averageRooms);
        setTotalVacationHours(totalVacationHours); // Total vacation hours taken this year
        setVacationEligibility(vacationEligibility); // Eligible vacation hours
        setHealthInsuranceEligibility(healthInsuranceEligibility); // Save health eligibility in state
      });

      return () => unsubscribe();
    }
  }, [selectedPropertyCode, hireDates]); // Added hireDates as a dependency

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  // PDF STYLESHEET
  const styles = StyleSheet.create({
    page: { flexDirection: "row", backgroundColor: "#ffffff", padding: 9 },
    table: { display: "table", width: "auto", margin: "auto" },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 0.5,
      borderBottomColor: "grey",
      pageBreakInside: "avoid",
      borderBottomStyle: "solid",
      alignItems: "stretch",
      paddingBottom: 5,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    tableCol: { width: "7%", flexDirection: "column" },
    tableColWide: { width: "10%", flexDirection: "column" },
    tableColWider: { width: "20%", flexDirection: "column" },
    tableCell: { margin: 3, fontSize: 9 },
    strikethrough: {
      textDecoration: "line-through",
    },
    tableHead: { margin: 3, fontSize: 11, fontWeight: "bold" },
  });

  // ROSTER EXPORT:
  const EmployeeRosterPDF = ({ employees }) => (
    <View style={styles.section}>
      <Text>{`${new Date().toLocaleDateString(
        "en-US",
      )} - Employee Roster (${selectedPropertyCode})`}</Text>

      <View style={styles.section}>
        <View style={styles.tableRow}>
          {/* Add headers here */}
          <View style={styles.tableColWider}>
            <Text style={styles.tableHead}>ID</Text>
          </View>
          <View style={styles.tableColWider}>
            <Text style={styles.tableHead}>NAME</Text>
          </View>
          <View style={styles.tableColWider}>
            <Text style={styles.tableHead}>LAST</Text>
          </View>
          <View style={styles.tableColWider}>
            <Text style={styles.tableHead}>HIRED</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableHead}>AVG HRS</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableHead}>AVG RMS</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableHead}>VAC</Text>
          </View>
          <View style={styles.tableColWider}>
            <Text style={styles.tableHead}>PHONE</Text>
          </View>
          <View style={styles.tableColWider}>
            <Text style={styles.tableHead}>D1</Text>
          </View>
          <View style={styles.tableColWider}>
            <Text style={styles.tableHead}>D2</Text>
          </View>
        </View>

        {employees.map((employee, index) => (
          <View style={styles.tableRow} key={index}>
            <View style={styles.tableColWider}>
              <Text style={styles.tableCell}>{employee.EmployeeId}</Text>
            </View>
            <View style={styles.tableColWider}>
              <Text style={styles.tableCell}>{employee.FirstName}</Text>
            </View>
            <View style={styles.tableColWider}>
              <Text style={styles.tableCell}>{employee.LastName}</Text>
            </View>
            <View style={styles.tableColWider}>
              <Text style={styles.tableCell}>{employee.DateHired}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {currentAverageTimes[employee.EmployeeId]}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {averageRooms[employee.EmployeeId]}
              </Text>
            </View>
            <View style={styles.tableCol}>
              {totalVacationHours[employee.EmployeeId] != null && (
                <Text style={styles.tableCell}>
                  {`${Math.ceil(totalVacationHours[employee.EmployeeId] || 0)}/${Math.ceil(
                    vacationEligibility[employee.EmployeeId] || 0,
                  )}`}
                </Text>
              )}
            </View>
            <View style={styles.tableColWider}>
              <Text style={styles.tableCell}>{employee.Phone}</Text>
            </View>
            <View style={styles.tableColWider}>
              <Text style={styles.tableCell}>{employee.PayD1}</Text>
            </View>
            <View style={styles.tableColWider}>
              <Text style={styles.tableCell}>{employee.PayD2}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );

  const MyDocument3 = ({ employees }) => (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <EmployeeRosterPDF employees={employees} />
      </Page>
    </Document>
  );

  const handleEdit = (employee) => {
    setEditID(employee.id);
    setTempID(employee.peachTreeID || "");
  };

  const handleCancel = () => {
    setEditID(null);
    setTempID("");
  };

  const handleSave = async (employee) => {
    const employeeRef = doc(db, "empRegister", employee.id);
    try {
      await updateDoc(employeeRef, { peachTreeID: tempID });
      setEditID(null);
      setTempID("");
      // Optionally show a success message
    } catch (error) {
      // Handle the error, e.g., show an error message
      console.error("Error updating document: ", error);
    }
  };

  return (
    <div>
      {/* HEADER */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "transparent",
          borderRadius: "0.9rem",
          marginBottom: "1.8rem",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="left"
          spacing={2}
          style={{ margin: "9px" }}
        >
          <Grid item>
            <Typography
              sx={{
                marginRight: 4.5,
                marginLeft: 1.8,
                fontFamily: "Outfit",
                letterSpacing: "0.09rem",
                fontWeight: "800",
                fontSize: "2.07rem",
                color: "#202426",
                textTransform: "lowercase",
                position: "relative",
                "::after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: 0.9,
                  width: "100%",
                  height: "2.7px",
                  backgroundColor: "grey",
                },
              }}
              variant="body1"
              align="left"
            >
              ROSTER
            </Typography>
          </Grid>

          <Grid item>
            <FormControl
              variant="outlined"
              sx={{
                minWidth: "15rem",
                "@media (max-width: 600px)": { minWidth: "9rem" },
                marginLeft: "1.26rem",
              }}
            >
              <InputLabel
                sx={{
                  ...selectInputLabel,
                }}
                htmlFor="property-code"
              >
                TEAM
              </InputLabel>
              <Select
                value={selectedPropertyCode || ""}
                onChange={handlePropertyCodeChange}
                inputProps={{ name: "property-code", id: "property-code" }}
                // readOnly={!!selectedPropertyCode} // Makes it read-only if selectedPropertyCode exists
                sx={{
                  ...selectSelect,
                }}
              >
                {viewPayrollRoster
                  .slice()
                  .sort((a, b) => {
                    const nameA = (
                      a.propertyCode ||
                      a.propertyName ||
                      ""
                    ).toString();
                    const nameB = (
                      b.propertyCode ||
                      b.propertyName ||
                      ""
                    ).toString();
                    return nameA.localeCompare(nameB);
                  })
                  .map((property) => (
                    <MenuItem
                      key={property.propertyCode}
                      value={property.propertyCode}
                      sx={{ ...selectMenuItem }}
                    >
                      {property.propertyName
                        ? `${property.propertyCode} - ${property.propertyName}`
                        : property.propertyCode}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={filterStatus === "Active"}
                  onChange={(e) =>
                    setFilterStatus(e.target.checked ? "Active" : "Terminated")
                  }
                  name="employeeStatusSwitch"
                  color="default"
                  sx={{
                    "& .MuiSwitch-switchBase": {
                      "&.Mui-checked": {
                        color: "#74c365",
                        "& + .MuiSwitch-track": {
                          backgroundColor: "#74c365",
                        },
                      },
                      "&.Mui-checked:hover": {
                        backgroundColor: "rgba(249, 139, 133, 0.08)",
                      },
                      "&.Mui-checked .MuiSwitch-thumb": {
                        backgroundColor: "#74c365",
                      },
                      "& .MuiSwitch-thumb": {
                        backgroundColor: "#F98B85",
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          backgroundSize: "cover",
                        },
                      },
                    },
                    "& .MuiSwitch-thumb": {
                      backgroundColor: "#F98B85",
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backgroundSize: "cover",
                      },
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor: "#F98B85",
                    },
                  }}
                />
              }
              label={
                filterStatus === "Active" ? "ACTIVE EMPLOYEES" : "INACTIVE"
              }
              labelPlacement="top"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontWeight: 800,
                  color: "#202426",
                  fontSize: "0.72rem",
                  letterSpacing: "0.09rem",
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box height={16} />

      <Card sx={{ borderRadius: "18px" }}>
        {/* ROSTER */}

        <Box>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            backgroundColor="#fcfcfc"
            // sx={{ borderBottom: "0.5px solid black" }}
          >
            <Box
              sx={{
                margin: "1.8rem",
              }}
            >
              <Grid item>
                <PDFDownloadLink
                  document={<MyDocument3 employees={sortedEmployees} />}
                  fileName={`${new Date().toLocaleDateString(
                    "en-US",
                  )}_Employee_Roster_${selectedPropertyCode}.pdf`}
                >
                  {() => (
                    <span>
                      <IconButton
                        sx={{
                          fontSize: "1.5rem",
                          color: "#FF0000",
                          backgroundColor: "transparent",
                        }}
                      >
                        <FaFilePdf />
                      </IconButton>
                    </span>
                  )}
                </PDFDownloadLink>
                <Typography
                  variant="caption"
                  sx={{
                    fontFamily: "Outfit",
                    fontWeight: 800,
                    color: "#202426",
                    fontSize: "0.72rem",
                    letterSpacing: "0.09rem",
                  }}
                >
                  DL PDF
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <TableContainer
            sx={{
              overflowX: "auto",
            }}
            component={Paper}
          >
            <Table id="ROSTER" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={maintitle200header} align="left">
                    ID
                  </TableCell>

                  <TableCell align="left" sx={maintitle200header}>
                    <TableSortLabel
                      active={sortConfig && sortConfig.key === "FirstName"}
                      direction={
                        sortConfig && sortConfig.key === "FirstName"
                          ? sortConfig.direction
                          : "asc"
                      }
                      onClick={() => requestSort("FirstName")}
                    >
                      NAME
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left" sx={maintitle200header}>
                    <TableSortLabel
                      active={sortConfig && sortConfig.key === "LastName"}
                      direction={
                        sortConfig && sortConfig.key === "LastName"
                          ? sortConfig.direction
                          : "asc"
                      }
                      onClick={() => requestSort("LastName")}
                    >
                      LAST
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" sx={maintitle200header}></TableCell>
                  <TableCell align="left" sx={maintitle200header}></TableCell>
                  <TableCell align="left" sx={maintitle200header}></TableCell>
                  <TableCell align="left" sx={maintitle200header}></TableCell>
                  {canViewSection("hrViewPayRate", selectedPropertyCode) && (
                    <>
                      <TableCell align="left" sx={maintitle200header}>
                        <TableSortLabel
                          active={sortConfig && sortConfig.key === "PayD1"}
                          direction={
                            sortConfig && sortConfig.key === "PayD1"
                              ? sortConfig.direction
                              : "asc"
                          }
                          onClick={() => requestSort("PayD1")}
                        >
                          D1
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left" sx={maintitle200header}>
                        D2
                      </TableCell>
                      <TableCell align="left" sx={maintitle200header}>
                        PRR
                      </TableCell>
                    </>
                  )}
                  {canViewSection("payrollID", selectedPropertyCode) && (
                    <>
                      <TableCell align="left" sx={maintitle200header}>
                        PAYROLL ID
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {allEmployees
                  .filter((employee) => {
                    if (filterStatus === "Active")
                      return employee.Status !== "Terminated";
                    return employee.Status === filterStatus;
                  })
                  .sort((a, b) => {
                    if (sortConfig === null) {
                      return 0;
                    }
                    if (
                      sortConfig.key === "FirstName" ||
                      sortConfig.key === "LastName" ||
                      sortConfig.key === "PayD1"
                    ) {
                      if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === "asc" ? -1 : 1;
                      }
                      if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === "asc" ? 1 : -1;
                      }
                    }
                    return 0;
                  })
                  .map((employee) => (
                    <TableRow hover key={employee.id}>
                      <TableCell
                        sx={{ ...maintable, fontWeight: "bold" }}
                        size="small"
                        align="left"
                      >
                        {employee.EmployeeId}
                      </TableCell>
                      <TableCell
                        sx={{ ...maintable }}
                        align="left"
                        size="small"
                      >
                        {employee.FirstName}
                      </TableCell>
                      <TableCell
                        sx={{ ...maintable }}
                        align="left"
                        size="small"
                      >
                        {employee.LastName}
                      </TableCell>
                      <TableCell
                        sx={{ ...maintable, fontWeight: "bold" }}
                        align="left"
                        size="small"
                      >
                        <Box display="flex" flexDirection="column">
                          {/* Date Hired */}
                          {employee.DateHired && (
                            <Box display="flex" alignItems="center">
                              <Tooltip title="Date Hired">
                                <IconButton
                                  sx={{
                                    fontSize: "1.44rem",
                                    color: "grey",
                                  }}
                                >
                                  <RiCake2Line />
                                </IconButton>
                              </Tooltip>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: "400",
                                  fontSize: {
                                    xs: "0.54rem",
                                    sm: "0.63rem",
                                    md: "0.72rem",
                                  },
                                }}
                              >
                                {new Date(
                                  employee.DateHired,
                                ).toLocaleDateString("en-US", {
                                  month: "short",
                                  day: "2-digit",
                                })}
                              </Typography>
                            </Box>
                          )}

                          {/* Vacation Hours */}
                          {totalVacationHours[employee.EmployeeId] != null &&
                            vacationEligibility[employee.EmployeeId] !=
                              null && (
                              <Box display="flex" alignItems="center">
                                <Tooltip title="Vacation Hours Taken vs Eligible">
                                  <IconButton
                                    sx={{
                                      fontSize: "1.53rem",
                                      color: "#F2B70A",
                                    }}
                                  >
                                    <BsBatteryCharging />
                                  </IconButton>
                                </Tooltip>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontWeight: "400",
                                    fontSize: {
                                      xs: "0.54rem",
                                      sm: "0.63rem",
                                      md: "0.72rem",
                                    },
                                  }}
                                >
                                  {`${Math.ceil(totalVacationHours[employee.EmployeeId] || 0)}/${Math.ceil(
                                    vacationEligibility[employee.EmployeeId] ||
                                      0,
                                  )} HRS`}
                                </Typography>
                              </Box>
                            )}
                        </Box>
                      </TableCell>

                      <TableCell
                        sx={{ ...maintable, fontWeight: "bold" }}
                        align="left"
                        size="small"
                      >
                        <Box display="flex" flexDirection="column">
                          {/* Average Hours */}
                          {averageTimes[employee.EmployeeId] != null && (
                            <Box display="flex" alignItems="center">
                              <Tooltip title="Average Weekly Hours">
                                <IconButton
                                  sx={{
                                    fontSize: "1.53rem",
                                    color: "grey",
                                  }}
                                >
                                  <TbClockHour10 />
                                </IconButton>
                              </Tooltip>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: "400",
                                  fontSize: {
                                    xs: "0.54rem",
                                    sm: "0.63rem",
                                    md: "0.72rem",
                                  },
                                }}
                              >
                                {currentAverageTimes[employee.EmployeeId]}
                              </Typography>
                            </Box>
                          )}

                          {/* Average Rooms */}
                          {averageRooms[employee.EmployeeId] != null && (
                            <Box display="flex" alignItems="center">
                              <Tooltip title="Average Rooms Cleaned">
                                <IconButton
                                  sx={{
                                    fontSize: "1.53rem",
                                    color: "grey",
                                  }}
                                >
                                  <MdOutlineKingBed />
                                </IconButton>
                              </Tooltip>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: "400",
                                  fontSize: {
                                    xs: "0.54rem",
                                    sm: "0.63rem",
                                    md: "0.72rem",
                                  },
                                }}
                              >
                                {averageRooms[employee.EmployeeId]} RMS
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                      {
                        <TableCell sx={maintable} align="center" size="small">
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            sx={{ borderRadius: "9.9px", overflow: "hidden" }}
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <Box
                                width="50%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minWidth="1.8rem"
                                bgcolor={
                                  !employee["W4-IRS"]
                                    ? "#f5f5f5"
                                    : employee["W4-IRS"] === "APPROVED"
                                      ? "#c7d57f"
                                      : employee["W4-IRS"] === "SUBMITTED"
                                        ? "#FFC107"
                                        : employee["W4-IRS"] === "REJECTED"
                                          ? "#F98B85"
                                          : "#f5f5f5"
                                }
                                height="40px"
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 800,
                                    fontSize: "0.72rem",
                                    color: "#202426",
                                    fontFamily: "Nunito",
                                  }}
                                >
                                  W4
                                </Typography>
                              </Box>
                              <Box
                                width="50%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minWidth="1.8rem"
                                bgcolor={
                                  !employee["SSN"]
                                    ? "#f5f5f5"
                                    : employee["SSN"] === "APPROVED"
                                      ? "#c7d57f"
                                      : employee["SSN"] === "SUBMITTED"
                                        ? "#FFC107"
                                        : employee["SSN"] === "REJECTED"
                                          ? "#F98B85"
                                          : "#f5f5f5"
                                }
                                height="40px"
                              >
                                <Tooltip title={"SSN"}>
                                  <span>
                                    <IconButton
                                      sx={{
                                        color: "#202426",
                                        fontSize: "1.35rem",
                                      }}
                                    >
                                      <TiBusinessCard />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Box>

                              <Box
                                width="50%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minWidth="1.8rem"
                                bgcolor={
                                  !employee["paySelection"]
                                    ? "#f5f5f5"
                                    : employee["paySelection"] === "APPROVED"
                                      ? "#c7d57f"
                                      : employee["paySelection"] === "SUBMITTED"
                                        ? "#FFC107"
                                        : employee["paySelection"] ===
                                            "REJECTED"
                                          ? "#F98B85"
                                          : "#f5f5f5"
                                }
                                height="40px"
                              >
                                <Tooltip title={"Pay Selection"}>
                                  <span>
                                    <IconButton
                                      sx={{
                                        color: "#202426",
                                        fontSize: "1.35rem",
                                      }}
                                    >
                                      <ImCoinDollar />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <Box
                                width="50%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minWidth="1.8rem"
                                bgcolor={
                                  !employee["W4-LOCAL"]
                                    ? "#f5f5f5"
                                    : employee["W4-LOCAL"] === "APPROVED"
                                      ? "#c7d57f"
                                      : employee["W4-LOCAL"] === "SUBMITTED"
                                        ? "#FFC107"
                                        : employee["W4-LOCAL"] === "REJECTED"
                                          ? "#F98B85"
                                          : "#f5f5f5"
                                }
                                height="40px"
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 800,
                                    fontSize: "0.72rem",
                                    color: "#202426",
                                  }}
                                >
                                  W4L
                                </Typography>
                              </Box>
                              <Box
                                width="50%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minWidth="1.8rem"
                                bgcolor={
                                  !employee["ID"]
                                    ? "#f5f5f5"
                                    : employee["ID"] === "APPROVED"
                                      ? "#c7d57f"
                                      : employee["ID"] === "SUBMITTED"
                                        ? "#FFC107"
                                        : employee["ID"] === "REJECTED"
                                          ? "#F98B85"
                                          : "#f5f5f5"
                                }
                                height="40px"
                              >
                                <Tooltip title={"Identification"}>
                                  <span>
                                    <IconButton
                                      sx={{
                                        color: "#202426",
                                        fontSize: "1.35rem",
                                      }}
                                    >
                                      <FaRegIdBadge />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Box>
                              <Box
                                width="50%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minWidth="1.8rem"
                                bgcolor={
                                  healthInsuranceEligibility[
                                    employee.EmployeeId
                                  ] && !employee["health"]
                                    ? "#8FCAE7"
                                    : employee["health"] === "ENROLLED"
                                      ? "#c7d57f"
                                      : employee["health"] === "SUBMITTED"
                                        ? "#FFC107"
                                        : employee["health"] === "DECLINED"
                                          ? "#F98B85"
                                          : "#f5f5f5"
                                }
                                height="40px"
                              >
                                {healthInsuranceEligibility[
                                  employee.EmployeeId
                                ] && (
                                  <Tooltip title={"Health Insurance"}>
                                    <span>
                                      <IconButton
                                        sx={{
                                          color: "#202426",
                                          fontSize: "1.35rem",
                                        }}
                                      >
                                        <MdOutlineHealthAndSafety />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                      }
                      <TableCell
                        sx={{ ...maintable }}
                        align="left"
                        size="small"
                      >
                        <Box display="flex" flexDirection="column">
                          <Typography
                            variant="body2"
                            sx={{ ...maintable, fontWeight: "800" }}
                          >
                            {employee.Phone}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ ...maintable, textTransform: "lowercase" }}
                          >
                            {employee.Email}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell
                        sx={{ ...maintable }}
                        align="left"
                        size="small"
                      >
                        <Box display="flex" flexDirection="column">
                          <Typography
                            variant="body2"
                            sx={{ ...maintable, fontWeight: "bold" }}
                          >
                            {employee.PayD1}
                          </Typography>
                          {canViewSection(
                            "hrViewPayRate",
                            selectedPropertyCode,
                          ) && (
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: "400",
                                fontSize: {
                                  xs: "0.54rem",
                                  sm: "0.63rem",
                                  md: "0.72rem",
                                },
                              }}
                            >
                              ${employee.PayD1_Rate} {employee.PayD1_Type}
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{ ...maintable }}
                        align="left"
                        size="small"
                      >
                        <Box display="flex" flexDirection="column">
                          <Typography
                            variant="body2"
                            sx={{ ...maintable, fontWeight: "bold" }}
                          >
                            {employee.PayD2}
                          </Typography>
                          {canViewSection(
                            "hrViewPayRate",
                            selectedPropertyCode,
                          ) && (
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: "400",
                                fontSize: {
                                  xs: "0.54rem",
                                  sm: "0.63rem",
                                  md: "0.72rem",
                                },
                              }}
                            >
                              ${employee.PayD2_Rate} {employee.PayD2_Type}
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                      {canViewSection(
                        "hrViewPayRate",
                        selectedPropertyCode,
                      ) && (
                        <TableCell
                          sx={{ ...maintable }}
                          align="left"
                          size="small"
                        >
                          {employee.PRR && !isNaN(new Date(employee.PRR))
                            ? new Date(employee.PRR).toLocaleDateString("en-US")
                            : ""}
                        </TableCell>
                      )}
                      {canViewSection("payrollID", selectedPropertyCode) && (
                        <>
                          {editID === employee.id ? (
                            <TableCell sx={maintable} align="left" size="small">
                              <TextField
                                value={tempID}
                                onChange={(e) => setTempID(e.target.value)}
                                size="small"
                              />
                              <Button onClick={() => handleSave(employee)}>
                                Save
                              </Button>
                              <Button onClick={handleCancel}>Cancel</Button>
                            </TableCell>
                          ) : (
                            <TableCell sx={maintable} align="left" size="small">
                              {employee.peachTreeID || "N/A"}
                              <IconButton onClick={() => handleEdit(employee)}>
                                <MdEdit />
                              </IconButton>
                            </TableCell>
                          )}
                        </>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box height={16} />
      </Card>
    </div>
  );
};

export default PunchSummary;

const maintitle200header = {
  fontWeight: 800,
  fontSize: "0.9rem",
  fontFamily: "Outfit",
  backgroundColor: "#f5f5f5",
  color: "#202426",
  maxWidth: "3.6rem",
};
const maintable = {
  letterSpacing: "0.018rem",
  fontWeight: 600,
  fontSize: "0.9rem",
  fontFamily: "Nunito",
  backgroundColor: "#ffffff",
  color: "#202426",
  minWidth: "2.7rem",
  textTransform: "uppercase",
};
const selectInputLabel = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Outfit",
  fontWeight: 400,
  color: "#202426",
  transition: "0.3s",
  textTransform: "uppercase",
};
const selectSelect = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Nunito",
  backgroundColor: "#ffffff",
  borderRadius: "18px",
  textTransform: "uppercase",
};
const selectMenuItem = {
  fontSize: { xs: "0.81rem" },
  fontFamily: "Nunito",
  fontWeight: 400,
  letterSpacing: "0.045rem",
  color: "#202426",
  transition: "0.3s",
  textTransform: "uppercase",
};
