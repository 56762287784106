import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Tabs,
  Tab,
  Switch,
  FormControlLabel,
  Grid,
  Container,
} from "@mui/material";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/firebase-config";

const FeedbackModal = ({ open, handleClose, user }) => {
  const [feedback, setFeedback] = useState("");
  const [name, setName] = useState("");
  const [propCode, setPropCode] = useState("");
  const [error, setError] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isPhonePreFilled, setIsPhonePreFilled] = useState(false);
  const [isEmailPreFilled, setIsEmailPreFilled] = useState(false);
  const [language, setLanguage] = useState("en");

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    if (user) {
      // Check if user exists
      setName(user.displayName || ""); // Safe access to displayName
      if (user.phoneNumber) {
        setPhone(user.phoneNumber);
        setIsPhonePreFilled(true); // Phone is pre-filled
      }
      if (user.email) {
        setEmail(user.email);
        setIsEmailPreFilled(true); // Email is pre-filled
      }
    }
  }, [user]);

  // Validate email format
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Validate phone format (basic validation)
  const isValidPhone = (phone) => {
    const phoneRegex = /^[0-9]{10,15}$/; // Modify regex as per your phone format
    return phoneRegex.test(phone);
  };

  const handleSubmit = async () => {
    if (!feedback) {
      setError("Please enter your feedback.");
      return;
    }

    if (!isValidEmail(email)) {
      setError("Please enter a valid email.");
      return;
    }

    if (!isValidPhone(phone)) {
      setError("Please enter a valid phone number.");
      return;
    }

    try {
      // Firestore: Save feedback with feedback type and propCode (if available)
      await addDoc(collection(db, "feedback"), {
        name,
        email,
        phone,
        propCode, // Ensure this field is set somewhere in your form
        feedback,
        feedbackType:
          tabIndex === 0 ? "General" : tabIndex === 1 ? "Idea" : "Bug",
        timestamp: new Date(),
      });

      // Reset form fields after successful submission
      setPropCode(""); // Reset the property code field
      setFeedback(""); // Clear feedback text
      setName(""); // Clear name
      setEmail(""); // Clear email
      setPhone(""); // Clear phone
      setError(""); // Clear any error messages
      handleClose(); // Close modal after submission
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setError(
        language === "en"
          ? "Failed to submit feedback."
          : "No se pudo enviar los comentarios.",
      );
    }
  };

  const translate = (en, es) => (language === "en" ? en : es);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: "75%", md: 450 }, // Responsive width
          padding: { xs: "1rem", sm: "1.5rem", md: "2rem" }, // Adjust padding for different screen sizes
          borderRadius: "36px",
          backgroundColor: "#f5f5f5",
          border: "2px solid #000",
          boxShadow: 24,
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          sx={{
            fontFamily: "Outfit",
            letterSpacing: "0.09rem",
            fontWeight: "800",
            fontSize: "2.07rem",
            color: "#202426",
            textTransform: "lowercase",
          }}
        >
          {translate("maintenance", "maintenance")}
        </Typography>

        {/* Language Toggle Switch */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            top: 9,
            right: 9,
            backgroundColor: "#f5f5f5",
            padding: "0.3rem 0.3rem",
            borderRadius: "0.5rem",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={language === "en"}
                onChange={() =>
                  setLanguage((prevLanguage) =>
                    prevLanguage === "en" ? "es" : "en",
                  )
                }
                sx={{
                  "& .MuiSwitch-switchBase": {
                    "&.Mui-checked": {
                      color: "#FFC107",
                      "& + .MuiSwitch-track": {
                        backgroundColor: "#FFC107",
                      },
                    },
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: "#74c365",
                  },
                }}
              />
            }
            label={language === "en" ? "EN" : "ES"}
            labelPlacement="right"
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "#101010",
                fontWeight: "800",
                fontSize: "0.9rem",
              },
              marginBottom: "10px",
            }}
          />
        </Box>

        {/* Tabs for selecting feedback type */}
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="feedback tabs"
          indicatorColor="#93A683"
          textColor="inherit"
          sx={{
            marginTop: "9px",
            marginBottom: "9px",
            "& .MuiTabs-indicator": {
              backgroundColor: "#74c365",
            },
            "& .MuiTab-root": {
              fontWeight: 400,
              fontSize: "0.9rem",
              fontFamily: "Nunito",
              letterSpacing: "0.045rem",
            },
          }}
        >
          <Tab label={translate("General", "General")} />
          <Tab label={translate("Idea", "Idea")} disabled={!user} />
          <Tab label={translate("Bug", "Error")} disabled={!user} />
        </Tabs>

        {/* Form Grid */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              label={translate("NAME", "NOMBRE")}
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              margin="normal"
              variant="outlined"
              InputProps={{
                readOnly: !!user,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                ...commonSx,
                ".MuiInputBase-input": {
                  fontSize: "1rem",
                  backgroundColor: !!user ? "#f0f0f0" : "",
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={translate("EMAIL", "CORREO ELECTRÓNICO")}
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              variant="outlined"
              InputProps={{
                readOnly: !!user && isEmailPreFilled,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                ...commonSx,
                ".MuiInputBase-input": {
                  fontSize: "1rem",
                  backgroundColor: !!user && isEmailPreFilled ? "#f0f0f0" : "",
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={translate("PHONE", "TELÉFONO")}
              fullWidth
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              margin="normal"
              variant="outlined"
              InputProps={{
                readOnly: !!user && isPhonePreFilled,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                ...commonSx,
                ".MuiInputBase-input": {
                  fontSize: "1rem",
                  backgroundColor: !!user && isPhonePreFilled ? "#f0f0f0" : "",
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={translate("PROPERTY CODE", "CÓDIGO DE PROPIEDAD")}
              fullWidth
              value={propCode}
              onChange={(e) => setPropCode(e.target.value)}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                ...commonSx,
                ".MuiInputBase-input": {
                  fontSize: "1rem",
                  backgroundColor: !!user && isPhonePreFilled ? "#f0f0f0" : "",
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={
                tabIndex === 0
                  ? translate("GENERAL FEEDBACK", "COMENTARIOS GENERALES")
                  : tabIndex === 1
                    ? translate("IDEA", "IDEA")
                    : translate("REPORT A BUG", "INFORMAR DE UN ERROR")
              }
              multiline
              fullWidth
              rows={4}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                ...commonSx,
                ".MuiInputBase-input": { fontSize: "1rem" },
              }}
            />
          </Grid>

          {error && <Typography color="error">{error}</Typography>}

          <Grid item xs={12}>
            <Button
              onClick={handleSubmit}
              fullWidth
              sx={{
                borderRadius: "18px",
                padding: "9.9px 27px",
                marginTop: "9px",
                marginBottom: "2.7px",
                fontWeight: "800",
                fontSize: "1rem",
                fontFamily: "Nunito",
                letterSpacing: "0.1rem",
                border: "3.6px solid lightgrey",
                backgroundColor: "lightgrey",
                color: "#202426",
                "&:hover": {
                  border: "3.6px solid #grey",
                  backgroundColor: "transparent",
                  color: "#202426",
                },
              }}
            >
              {translate("Submit Ticket", "Enviar")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default FeedbackModal;

const commonSx = {
  marginBottom: "0.2rem",
  transition: "0.3s",
  // maxWidth: "18rem",
  ".MuiInputLabel-root": {
    fontSize: { xs: "0.81rem", sm: "0.81rem", md: "0.9rem" },
    fontWeight: 400,
    fontFamily: "Outfit",
    letterSpacing: "0.045rem",
    color: "#020202",
  },
  ".MuiInputBase-input": {
    fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.9rem" },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "9px",
  },
};
