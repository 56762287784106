import React, { useState, useEffect } from "react";
import { db } from "../../firebase/firebase-config";
import { useAuth } from "../../contexts/AuthContext";
import { collection, onSnapshot } from "firebase/firestore";
import {
  query,
  orderBy,
  limit as firestoreLimit,
  startAfter,
} from "firebase/firestore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Box,
  Card,
  Typography,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import { Link as LinkIcon } from "@mui/icons-material";

const Apps = () => {
  const { user } = useAuth();
  const [appData, setAppData] = useState([]);
  const [cityFilter, setCityFilter] = useState("");
  const [positionFilter, setPositionFilter] = useState("");
  const [firstNameFilter, setFirstNameFilter] = useState("");
  const [lastNameFilter, setLastNameFilter] = useState("");
  const [limit, setLimit] = useState(10);
  const [allData, setAllData] = useState([]);
  const displayedData = allData.slice(0, limit);

  const loadMore = () => {
    if (limit < filteredData.length) {
      setLimit((prevLimit) => prevLimit + 20);
    }
  };

  useEffect(() => {
    let q = query(collection(db, "champEmpApp"), orderBy("Date"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      let apps = snapshot.docs.map((doc) => ({
        id: doc.id,
        City: doc.data().City,
        Date: new Date(doc.data().Date).toLocaleDateString("en-US"),
        Email: doc.data().Email,
        FirstName: doc.data().FirstName,
        LastName: doc.data().LastName,
        LINK: doc.data().LINK,
        Phone: doc.data().Phone,
        Position: doc.data().Position,
        State: doc.data().State,
      }));

      // Sort apps by Date in descending order
      apps.sort((a, b) => new Date(b.Date) - new Date(a.Date));

      setAllData(apps);
    });

    return unsubscribe;
  }, []);

  const filteredData = allData.filter(
    (app) =>
      app.City.toLowerCase().includes(cityFilter.toLowerCase()) &&
      app.Position.toLowerCase().includes(positionFilter.toLowerCase()) &&
      app.FirstName.toLowerCase().includes(firstNameFilter.toLowerCase()) &&
      app.LastName.toLowerCase().includes(lastNameFilter.toLowerCase()),
  );

  return (
    <div>
      {/* HEADER */}
      <Grid
        container
        direction="row"
        alignItems="center"
        wrap="nowrap"
        sx={{
          padding: "2rem",
          "@media (max-width: 600px)": {
            flexDirection: "column",
          },
        }}
      >
        <Grid item>
          <Typography
            sx={{
              marginRight: 4.5,
              marginLeft: 1.8,
              fontFamily: "Outfit",
              letterSpacing: "0.09rem",
              fontWeight: "800",
              fontSize: "2.07rem",
              color: "#202426",
              textTransform: "lowercase",
              position: "relative",
              "::after": {
                content: '""',
                position: "absolute",
                left: 0,
                bottom: 0.9,
                width: "100%",
                height: "2.7px",
                backgroundColor: "grey",
              },
            }}
            variant="body1"
            align="left"
          >
            JOB APPS
          </Typography>
        </Grid>
      </Grid>
      <Card
        sx={{
          borderRadius: "18px",
        }}
      >
        <Box
          sx={{
            padding: "1rem",
            backgroundColor: "#fcfcfc",
          }}
        >
          <Typography
            sx={{
              letterSpacing: "0.1rem",
              fontWeight: "600",
              fontSize: "0.9rem",
              color: "#000000",
              marginBottom: "9px",
            }}
            variant="body1"
            align="left"
          >
            FILTER
          </Typography>
          <TextField
            label="First Name"
            value={firstNameFilter}
            onChange={(e) => setFirstNameFilter(e.target.value)}
            sx={{
              fontSize: "0.9rem",
              backgroundColor: "#fcfcfc",
              minWidth: "5em",
              marginBottom: "18px",
              marginLeft: "29px",
            }}
            variant="standard"
          />
          <TextField
            label="Last Name"
            value={lastNameFilter}
            onChange={(e) => setLastNameFilter(e.target.value)}
            sx={{
              fontSize: "0.9rem",
              backgroundColor: "#fcfcfc",
              minWidth: "5em",
              marginBottom: "18px",
              marginLeft: "29px",
            }}
            variant="standard"
          />
          <TextField
            label="City"
            value={cityFilter}
            onChange={(e) => setCityFilter(e.target.value)}
            sx={{
              fontSize: "0.9rem",
              backgroundColor: "#fcfcfc",
              minWidth: "5em",
              marginBottom: "18px",
              marginLeft: "29px",
            }}
            variant="standard"
          />
          <TextField
            label="Position"
            value={positionFilter}
            onChange={(e) => setPositionFilter(e.target.value)}
            sx={{
              fontSize: "0.9rem",
              backgroundColor: "#fcfcfc",
              minWidth: "5em",
              marginBottom: "18px",
              marginLeft: "29px",
            }}
            variant="standard"
          />
        </Box>
        <Box>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            backgroundColor="#fcfcfc"
            sx={{ borderBottom: "0.5px solid black" }}
          >
            <Grid item xs={12} sm={6} md={4} lg={3}></Grid>
          </Grid>
          <TableContainer
            sx={{
              overflowX: "auto",
            }}
            component={Paper}
          >
            <Table id="APPS" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={totals}>
                    DATE
                  </TableCell>
                  <TableCell align="left" sx={totals}>
                    NAME
                  </TableCell>
                  <TableCell align="left" sx={totals}></TableCell>
                  <TableCell align="left" sx={totals}>
                    POSITION
                  </TableCell>
                  <TableCell align="left" sx={totals}>
                    CITY
                  </TableCell>
                  <TableCell align="left" sx={totals}>
                    STATE
                  </TableCell>
                  <TableCell align="left" sx={totals}>
                    PHONE
                  </TableCell>
                  <TableCell align="left" sx={totals}>
                    EMAIL
                  </TableCell>
                  <TableCell align="left" sx={totals}>
                    APPLICATION
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.slice(0, limit).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="left" sx={maintable} size="small">
                      {row.Date}
                    </TableCell>
                    <TableCell align="left" sx={maintable} size="small">
                      {row.FirstName}
                    </TableCell>
                    <TableCell align="left" sx={maintable} size="small">
                      {row.LastName}
                    </TableCell>
                    <TableCell align="left" sx={maintable} size="small">
                      {row.Position}
                    </TableCell>
                    <TableCell align="left" sx={maintable} size="small">
                      {row.City}
                    </TableCell>
                    <TableCell align="left" sx={maintable} size="small">
                      {row.State}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ ...maintable, whiteSpace: "nowrap" }}
                      size="small"
                    >
                      {row.Phone}
                    </TableCell>
                    <TableCell align="left" sx={maintable} size="small">
                      {row.Email}
                    </TableCell>
                    <TableCell align="left" sx={maintable} size="small">
                      <IconButton href={row.LINK} target="_blank">
                        <LinkIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={loadMore}
            sx={{
              letterSpacing: "0.1rem",
              fontWeight: "600",
              fontSize: "0.9rem",
              color: "#000000",
              marginBottom: "9px",
              marginTop: "9px",
              marginLeft: "9px",
            }}
          >
            Load More
          </Button>
        </Box>
      </Card>
    </div>
  );
};

export default Apps;

const maintable = {
  fontWeight: 400,
  fontSize: "0.7rem",
  backgroundColor: "#fcfcfc",
  color: "#101010",
  rowHeight: "0.1rem",
};
const totals = {
  fontWeight: 600,
  fontSize: "0.7rem",
  backgroundColor: "#fcfcfc",
  color: "#101010",
};
