import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { signOut } from "firebase/auth";
import { auth, db } from "../../firebase/firebase-config";
import { useAuth } from "../../contexts/AuthContext";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import "../../components/App.css";
import logo from "../../assets/bhlogo.png";
import loginButtonImage from "../../assets/btVamos.png";
import { MdAlternateEmail } from "react-icons/md";
import {
  MdOutlineDoNotDisturbOff,
  MdOutlineDoNotDisturbOnTotalSilence,
} from "react-icons/md";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuth();
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState("");
  const [showResetButton, setShowResetButton] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const sendResetEmail = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setError("Password reset email sent. Check your inbox.");
      setShowResetButton(false); // Hide the reset button after success
    } catch (error) {
      setError("Failed to send password reset email. Please try again later.");
    }
  };

  const login = async (email, password) => {
    setIsPending(true);
    setError(null);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );

      if (!userCredential) {
        throw new Error("Could not login. Try again later.");
      }

      if (!userCredential.user.emailVerified) {
        setError(
          "Please VERIFY YOUR EMAIL before proceeding. Check your inbox or spam folder for the verification email. Alternatively, press the RESET PASSWORD button below and follow instructions in the RESET EMAIL.",
        );
        handleClickLogOut();
        return;
      }

      dispatch({ type: "LOGIN", payload: userCredential.user });

      console.log(userCredential);
      setIsPending(false);
      setError(null);
      navigate("/");
    } catch (error) {
      console.log("error", error);

      if (error.code === "auth/wrong-password") {
        setError("Incorrect password. Please try again.");
      } else if (error.code === "auth/user-not-found") {
        setError("User not found.");
      } else {
        setError(error.message);
      }

      setIsPending(false);
    }
  };

  const handleClickLogOut = async () => {
    await signOut(auth);
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    login(email, password);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "69vh",
      }}
    >
      <Card
        sx={{
          borderRadius: "36px",
          backgroundColor: "#f5f5f5",
          maxWidth: "27rem",
        }}
      >
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              sx={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    marginBottom: "3.6rem",
                    marginTop: "1.8rem",
                  }}
                >
                  <img
                    src={logo}
                    alt="Static Logo"
                    style={{
                      height: "7.2rem",
                      width: "auto",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    sx={{
                      ".MuiInputBase-input": {
                        fontSize: { xs: "0.99rem" },
                        fontFamily: "Nunito",
                        fontWeight: 600,
                        letterSpacing: "0.045rem",
                        color: "#202426",
                        transition: "0.3s",
                        textAlign: "left",
                        textTransform: "uppercase",
                      },
                      "& .MuiOutlinedInput-root": {
                        height: "63px",
                        borderRadius: "18px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#93A683",
                        },
                      },
                    }}
                    label=""
                    type="text"
                    value={userInput}
                    autoComplete="username"
                    onChange={(e) => {
                      const input = e.target.value;
                      setUserInput(input);
                      setEmail(input.toLowerCase());
                    }}
                    required
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Box
                            sx={{
                              backgroundColor: "transparent",
                              color: "#202426",
                              borderRadius: "45%",
                              padding: "0.27rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <MdAlternateEmail size={36} />
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    sx={{
                      ".MuiInputBase-input": {
                        fontSize: { xs: "0.99rem" },
                        fontFamily: "Nunito",
                        fontWeight: 600,
                        letterSpacing: "0.045rem",
                        color: "#202426",
                        transition: "0.3s",
                        textAlign: "left",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "18px 0px 0px 18px",
                        height: "63px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#93A683",
                        },
                      },
                    }}
                    label=""
                    type={showPassword ? "text" : "password"} // Dynamically set type
                    value={password}
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            onClick={() => setShowPassword((prev) => !prev)}
                            edge="end"
                            aria-label="toggle password visibility"
                            sx={{ color: "#202426" }}
                          >
                            {showPassword ? (
                              <MdOutlineDoNotDisturbOnTotalSilence size={27} />
                            ) : (
                              <MdOutlineDoNotDisturbOff size={27} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    type="submit"
                    disabled={isPending}
                    sx={{
                      ...greenButton,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "63px",
                      borderRadius: "0px 18px 18px 0px",
                      padding: 0,
                      minWidth: "72px",
                    }}
                  >
                    <img
                      src={loginButtonImage}
                      alt="Login Button"
                      style={{
                        height: "1.89rem", // Adjust image height if needed
                        width: "auto",
                      }}
                    />
                  </Button>
                </Box>
              </Grid>

              {error && (
                <Grid item xs={12}>
                  <Typography
                    color="error"
                    sx={{
                      color: "#202426",
                      fontFamily: "Outfit",
                      fontWeight: "800",
                      fontSize: "1.17rem",
                      paddingTop: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    {error === "Password reset email sent. Check your inbox." ||
                    error ===
                      "Failed to send password reset email. Please try again later."
                      ? error
                      : "email / password error"}
                  </Typography>
                </Grid>
              )}
              {showResetButton && error && (
                <Grid item xs={12} sx={{ width: "100%", marginTop: "1.8rem" }}>
                  <Button
                    variant="outlined"
                    onClick={sendResetEmail}
                    fullWidth
                    sx={{ ...greyButton }}
                  >
                    Reset Password
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
}

const greenButton = {
  padding: "9.18px 18px 9.18px 18px",
  borderRadius: "0px 18px 18px 0px",
  fontSize: "0.81rem",
  fontFamily: "Outfit",
  letterSpacing: "0.09rem",
  border: "2.7px solid #93A683",
  backgroundColor: "#93A683",
  color: "#f5f5f5",
  fontWeight: "400",
  "&:hover": {
    border: "2.7px solid #202426",
    backgroundColor: "transparent",
    color: "#202426",
  },
};
const greyButton = {
  borderRadius: "9px",
  padding: "18px",
  fontWeight: "400",
  fontSize: "0.81rem",
  fontFamily: "Outfit",
  letterSpacing: "0.09rem",
  border: "2.7px solid lightgrey",
  backgroundColor: "lightgrey",
  color: "#202426",
  "&:hover": {
    border: "2.7px solid #202426",
    backgroundColor: "transparent",
    color: "#202426",
  },
};
